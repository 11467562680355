import { createApp } from 'vue'
import App from './App.vue'
import {setupRouter} from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import dynamicForm from "./custom_lib/dynamic_forms/dynamicformtemplate.vue";
import showForm from "./custom_lib/dynamic_forms/forms.vue";
import editForm from "./custom_lib/dynamic_forms/editForm.vue";
import viewForm from "./custom_lib/dynamic_forms/viewform.vue";
import VueApexCharts from "vue3-apexcharts";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import gAuthPlugin from 'vue3-google-oauth2';
import formjsfile from "./custom_lib/dynamic_forms/forms.js";
import Pivot from "vue-webdatarocks";
import 'webdatarocks/webdatarocks.css';
import en from './locales/en.json';
import { setupI18n } from '@/i18n';
import dateTimeFormats from './locales/dateTimeFormats.json';
import 'clickout-event';
import Flatpickr from 'vue-flatpickr-component';
import mitt from 'mitt';
import 'flatpickr/dist/flatpickr.css'; // Import the Flatpickr CSS
// const dateTimeFormats = ;
export const EventBus = mitt();
const i18n = setupI18n({
  datetimeFormats: dateTimeFormats,
    globalInjection: true,
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
      en,
    },
  });
const app = createApp(App);
const router = setupRouter(i18n)
app.config.globalProperties.$eventBus = EventBus;
app.component("dynamicform", dynamicForm);
app.component("showForm", showForm);
app.component("editForm", editForm);
app.component("viewForm", viewForm);
app.component('Flatpickr', Flatpickr);
app.use(i18n);
app.use(formjsfile);
app.use(Loading);
app.use(VueApexCharts);
app.use(store);
app.use(router);
app.use(Pivot);
const OOuthScope1 =  ["https://www.googleapis.com/auth/fitness.activity.read" ,
    "https://www.googleapis.com/auth/fitness.activity.write",
    "https://www.googleapis.com/auth/fitness.blood_glucose.read" ,
    "https://www.googleapis.com/auth/fitness.blood_glucose.write",
    "https://www.googleapis.com/auth/fitness.blood_pressure.read" ,
    "https://www.googleapis.com/auth/fitness.blood_pressure.write",
    "https://www.googleapis.com/auth/fitness.body.read" ,
    "https://www.googleapis.com/auth/fitness.body.write",
    "https://www.googleapis.com/auth/fitness.body_temperature.read" ,
    "https://www.googleapis.com/auth/fitness.body_temperature.write",
    "https://www.googleapis.com/auth/fitness.heart_rate.read" ,
    "https://www.googleapis.com/auth/fitness.heart_rate.write",
    "https://www.googleapis.com/auth/fitness.location.read" ,
    "https://www.googleapis.com/auth/fitness.location.write",
    "https://www.googleapis.com/auth/fitness.nutrition.read" ,
    "https://www.googleapis.com/auth/fitness.nutrition.write",
    "https://www.googleapis.com/auth/fitness.oxygen_saturation.read" ,
    "https://www.googleapis.com/auth/fitness.oxygen_saturation.write",
    "https://www.googleapis.com/auth/fitness.reproductive_health.read" ,
    "https://www.googleapis.com/auth/fitness.reproductive_health.write",
    "https://www.googleapis.com/auth/fitness.sleep.read" ,
    "https://www.googleapis.com/auth/fitness.sleep.write",
]

const gauthClientId = "551308054506-cldapqh6o3kcliqssrdavdjokkqo2hc1.apps.googleusercontent.com";
app.use(gAuthPlugin, { clientId: gauthClientId, scope: OOuthScope1.join(" "), prompt: 'select_account', fetch_basic_profile: false })
app.mount('#app');
