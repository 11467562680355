<template> 
  <div class="ibox-content mb-1 px-3 py-2" v-if="!requiredsubmit && requiredlanguage && !requiredrole">
    <div class="d-flex flex-lg-row justify-content-between">
      <div class="form-group width-20 mb-0">
        <label class="mb-0">{{$t('navigations.available languages')}}</label>
        <select
          class="form-select"
          name="changelanguage"
          @change.prevent="getselectedtemplate($event.target.value)"
        >
          <option value="" selected >--{{$t('navigations.select')}}--</option>
          <option :value="templateid">{{ $t('navigations.english') }} </option>
          <option
            v-for="template in templist"
            :key="template.templateId"
            :value="template.templateId"
          >
            {{ template.language }}
          </option>
        </select>            
      </div>
       <div class="form-group width-20 mb-0">
        <label class="mb-0">{{$t('navigations.available versions')}}</label>
        <select
          class="form-select"
          name="changelanguage"
          @change.prevent="getselectedtemplate($event.target.value)"
        >
          <option value="" selected >--{{$t('navigations.select')}}--</option>
          <option
            v-for="version in versionlist"
            :key="version.version"
            :value="version.templateId"
          >
            {{ version.version }}
          </option>
        </select>            
      </div>
      <div v-if="islanenglish == 'en'" class="rightdiv">
        <div class="d-flex flex-md-row">
          <div class="form-group mb-0 w-100">
            <label class="mb-0">{{$t('navigations.language')}}</label>
             <select
              class="form-control"
              name="changelanguage"
              v-model="laguageselected"
            >
              <option selected value="" disabled>{{ $t("navigations.select your language") }}</option>
              <option v-for="lang in language" :key="lang.id" :value="lang">
                {{ lang.language }}
              </option>
            </select>
          </div>
          <span type="button"
            class="save_btn px-5 mx-lg-1 align-self-end"
            @click.prevent="translatelabel(laguageselected.code)"
          >
            {{ $t("navigations.translate") }}
          </span>
        </div>
      </div>
    </div>
<!-- Langusage -->
</div>
<div class="ibox-content mb-1 px-3 py-2" v-if="requiredrole">
<div class="form-group row mb-0">
  <div
    class="
      d-flex
      flex-column flex-lg-row
      justify-content-lg-start
      my-2 my-lg-1
    "
  >
    <div class="col-md-12 my-1 my-lg-0 pl-0 pr-1">
      <div class="form-group mb-md-0">
        <label for="rolelist" class="mb-0">{{ $t('navigations.roles') }}</label>
        <select
          class="form-select"
          id="rolelist"
          v-model="selectedrole"
          >
          <option selected disabled value="">{{ $t('navigations.select') }}</option>
          <option
            v-for="role in roles"
            :value="role.id"
            :key="role.id"
            >
            {{ role.role_Name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
</div>
<div id="formElements">
<form>
  <div class="form-group" v-for="input in htmlarray" :key="input.folder_Id">
    <div v-for="(item, pageIndex) in input.pages" :key="pageIndex">
      <div v-if="item.pageNo == pageno" class="pageContainer mainbgchange">
        <div>
          <div
            class="groupsdiv"
            v-for="(groupno, key) in groups[pageno]"
            :key="key"
          >
            <div class="flexContainer">
              <template
                v-for="(newfield, fieldIndex) in item.field"
                :key="fieldIndex"
              >
                <div
                  class="flex-sub-container"
                  v-if="newfield.groupOrderNo == groupno"
                >
                 <template v-if="visibility[newfield.fieldName] || skipcheckingdependency">
                  <!-- headder starts -->
                  <div v-if="newfield.type == 'header'" class="headerdiv">
                    <div class="text-center">
                      <h5 class="headdertext align-self-center my-1" :class="{'cursor-pointer': fromUrlStatus===true}" @click.prevent="openModal(newfield.label,groupno)">{{ newfield.label }}</h5>
                    </div>
                  </div>
                   <!-- headder ends -->
                   <!-- subHeader starts -->
                   <div
                    v-else-if="newfield.type == 'subHeader'"
                    class="subheadder"
                    @click.prevent="openModal(newfield.label,groupno)"
                    :class="{'cursor-pointer': fromUrlStatus===true}"
                  >
                    <h4>{{ newfield.label }}</h4>
                  </div>
                  <!-- subHeader ends -->
                  <!-- input starts -->
                  <div
                    v-else-if="inputElements.includes(newfield.type)"
                    class="flex-item"
                    :class="{'cursor-pointer': fromUrlStatus===true}"
                    @load="testfun()"
                  >
                    <baseinput
                      @click.prevent="assignprivilege(newfield.fieldName,newfield.label,groupno)"
                      :class="{'cursor-pointer': fromUrlStatus===true}"
                      :sourceValue="fromUrlStatus"
                      :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                      :requiredname="requiredclasslist[newfield.fieldName]"
                      v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]"
                      :autofill="isautofill(newfield.fieldName)"
                      :label="newfield.label"
                      :inputtype="newfield.type"
                      type="text"
                       @focusoutfun="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                    />
                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                  </div>
                  <!-- input ends -->
                  <!-- image starts -->
                  <div
                                v-else-if="newfield.type == 'image'"
                                class="flex-item displayflex"
                              >
                                <div>
                                  <div class="labelclass marginauto">
                                     {{ newfield.label }}
                                  </div>
                                </div> 
                                <div class="d-flex flex-column">    
                                <input class="form-control col-md-6 mb-2" v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]" type="text">                        
                                <img
                                  class="templatepreview max-image"
                                  :src="`${fileuploadurl}/file/${
                                    newfield.imagePath.split(',')[0]
                                  }?versionid=${
                                    newfield.imagePath.split(',')[1]
                                  }`"
                                />
                                </div>
                                <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                  </div>
                  <!-- image ends -->
                  <!-- scales starts -->
                  <div
                                v-else-if="newfield.type == 'scales'"
                                :class="{'cursor-pointer': fromUrlStatus===true}"
                                @click.prevent="openModal(newfield.label,groupno)"
                                class="flex-item d-flex flex-column"                                
                              >
                                <label class="labelclass mr-2" :class="{'cursor-pointer': fromUrlStatus===true}" >
                                  {{newfield.label}}<sup v-if="requiredclasslist[newfield.fieldName] == 'required'"><i class="fa fa-asterisk px-1 imp"></i></sup>
                                </label>
                                 <div class="d-flex"> <h6>{{ newfield.limit[0].min }}</h6><input
                                    type="range"
                                    :disabled="calculatedfields.includes(newfield.fieldName)"
                                    :class="{'cursor-pointer': fromUrlStatus===true} "
                                    :min="newfield.limit[0].min"
                                    :max="newfield.limit[0].max"
                                    step="1"
                                    v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]"
                                    @blur="focusOutFunction(item.pageNo,
                                  newfield.fieldName,'')"
                                  /><h6>{{ newfield.limit[0].max }}</h6></div>
                                  <input
                                  :disabled="calculatedfields.includes(newfield.fieldName)"
                                    class="form-control"
                                    :class="{'cursor-pointer': fromUrlStatus===true} "
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                    type="number"
                                    :min="newfield.limit[0].min"
                                    :max="newfield.limit[0].max"
                                    v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]"
                                    @blur="focusOutFunction(item.pageNo,
                                  newfield.fieldName,'')"
                                  />
                                  <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                            
                  </div>
                  <!-- scales ends -->
                  <!-- time element starts -->
                  <div v-else-if="newfield.type == 'time'" class="flex-item bgblue">
                                  <basetime
                      :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                      @click="assignprivilege(newfield.fieldName,newfield.label,groupno)"
                      :sourceValue="fromUrlStatus"
                      :class="{'cursor-pointer': fromUrlStatus===true}"
                      :requiredname="requiredclasslist[newfield.fieldName]"
                      v-model="
                        formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                      "
                      :timeFormat="gettimeformat(newfield.validation)"
                      :label="newfield.label"
                      type="text"
                      @focusoutfun="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                    />
                                  <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                  </div>
                  <!-- time element ends -->
                  <!-- dateandtime element starts -->
                  <div v-else-if="newfield.type == 'dateandtime'" class="flex-item bgblue">
                    
                    <basedatetime
                    :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                    @click="openModal(newfield.label,groupno)"
                    :sourceValue="fromUrlStatus"
                    :class="{'cursor-pointer': sourceValue===true}"
                    :label="newfield.label" 
                    :requiredname="newfield.validation[0]"
                    type="datetime-local"
                    @focusoutfun="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                    v-model="
                        formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                      " />
                      <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                  </div>
                  <!-- date element ends -->
                  <!-- date element starts -->
                  <div
                    v-else-if="newfield.type == 'date'"
                    class="flex-item"
                    :class="{'cursor-pointer': fromUrlStatus===true}"
                    @load="testfun()"
                  >
                    <basedate
                    :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                      @click="assignprivilege(newfield.fieldName,newfield.label,groupno)"
                      :sourceValue="fromUrlStatus"
                      :class="{'cursor-pointer': fromUrlStatus===true}"
                      :requiredname="requiredclasslist[newfield.fieldName]"
                      v-model="
                        formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                      "
                      :dateFormat="getdateformat(newfield.validation)"
                      :label="newfield.label"
                      type="text"
                      @focusoutfun="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                    />
                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                  </div>
                  <!-- date element ends -->
                  <!-- calculation starts -->
                  <div
                                  v-else-if="newfield.type == 'calculation'"
                                  class="flex-item"
                                >
                                <div class="d-flex p-2">
                                  <div class="labelclass">
                                    {{ newfield.label }}
                                  </div>
                                  <div hidden>
                                  {{ calculateResult(item.pageNo, newfield.options, newfield.fieldName) }}
                                  </div>
                                  <div class="labelclass ml-2">
                                  {{ calculatedresult[newfield.fieldName] }}
                                </div>
                              </div>
                  </div>
                  <!-- calculation ends -->
                  <!-- Grid starts -->
                  <div
                                class="elem"
                                v-else-if="newfield.type == 'grid'"
                              >
                            
                                <div class="notesstyle gridbackground">
                                  <div class="ibox-content-edit flextable gridbackground">
                                    <div class="addfieldtable">
                                      <div
                                        class="rowcolmn"
                                        v-for="(gridrow, index) in gridoptarray[newfield.fieldName][0].Rows"
                                        :key="gridrow"
                                      >
                                        <div class="rowlabelgrid rowcolmnc">
                                          {{ gridrow }}
                                        </div>
                                        <div
                                          class="rowcolmnc"
                                          v-for="gridcolumn in gridoptarray[newfield.fieldName][0].Columns"
                                          :key="gridcolumn"
                                        >
                                          <div v-if="index == 0">
                                            {{ gridcolumn }}
                                          </div>
                                          <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'text'">
                                          <input
                                            class="form-control"
                                            v-model="gridlinkeddatas[newfield.fieldName][gridrow+gridcolumn]"
                                            type="text"
                                          />
                                          </div>
                                          <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'number'">
                                          <input
                                            class="form-control"
                                            v-model="gridlinkeddatas[newfield.fieldName][gridrow+gridcolumn]"
                                            type="number"
                                          />
                                          </div>
                                          <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'date'">
                                          <input
                                            class="form-control"
                                            v-model="gridlinkeddatas[newfield.fieldName][gridrow+gridcolumn]"
                                            type="date"
                                          />
                                          </div>
                                          <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'time'">
                                          <input
                                            class="form-control"
                                            v-model="gridlinkeddatas[newfield.fieldName][gridrow+gridcolumn]"
                                            type="time"
                                          />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                  </div> 
                  <!-- Grid ends -->
                  <!-- dropdown menu starts -->
                  <div
                    v-else-if="newfield.type == 'dropdown'"
                    class="flex-item"
                    :class="{'cursor-pointer': fromUrlStatus===true}"
                  >
                    <baseselect
                    :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                    @click.prevent="assignprivilege(newfield.fieldName,newfield.label,groupno)"
                    :sourceValue="fromUrlStatus"
                    :class="{'cursor-pointer': fromUrlStatus===true}"
                    :options="newfield.options"
                    :requiredname="requiredclasslist[newfield.fieldName]"
                      v-model="
                        formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                      "
                      :label="newfield.label"
                      @focusoutfun="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  $event
                                )
                              "
                    />
                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                  </div>
                  <!-- dropdown menu ends -->
                  <!-- text area starts -->
                  <div
                    class="elem"
                    v-else-if="
                      (newfield.type == 'description') ||
                        (newfield.type == 'textarea')
                    "
                  >
                    <div class="txtarealabel" :class="{'cursor-pointer': fromUrlStatus===true}">
                      <label :for="newfield.fieldName" class="labelstyle">{{
                        newfield.label
                      }}<sup v-if="requiredclasslist[newfield.fieldName] == 'required'"><i class="fa fa-asterisk px-1 imp"></i></sup></label>
                    </div>
                    <div class="txtar" :class="{'cursor-pointer': fromUrlStatus===true}">
                      <textarea
                      :disabled="calculatedfields.includes(newfield.fieldName)"
                      @click.prevent="assignprivilege(newfield.fieldName,newfield.label,groupno)"
                        :id="newfield.fieldName"
                        :name="newfield.fieldName"
                        :class="[`form-control`,{'cursor-pointer': fromUrlStatus===true}]"
                        class="textbox "
                        v-model="
                          formdata[`pageNo${item.pageNo}`][
                            newfield.fieldName
                          ]
                        "
                        @blur="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                      />

                      <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                    </div>
                  </div>
                  <!-- text area ends -->
                  <!-- summery starts -->
                  <div
                    class="ml-2 pb-2"
                    v-else-if="newfield.type == 'summary'"
                    :class="{'cursor-pointer': fromUrlStatus===true}"
                  >
                      <div class="txtarealabel" :class="{'cursor-pointer': fromUrlStatus===true}"><i>
                        <label :for="newfield.fieldName" :class="{'cursor-pointer': fromUrlStatus===true}" class="textsize">{{
                        newfield.label}}</label></i>
                      </div>                                    
                  </div>
                  <!-- summery ends -->
                  <!-- notes starts -->
                  <div
                    class="ml-2 pb-2"
                    v-else-if="newfield.type == 'notes'"
                  >
                    <div class="notesstyle">
                      {{ newfield.label }}
                    </div>
                  </div>
                  <!-- notes ends -->
                  <!-- file upload starts -->
                  <div
                                v-else-if="newfield.type == 'uploadfile'"
                                class="flex-item"
                              >
                                <div class="labelclass">
                                  {{ newfield.label }}<sup v-if="requiredclasslist[newfield.fieldName] == 'required'"><i class="fa fa-asterisk px-1 imp"></i></sup>
                                </div>
                                <div class="twoitemflex">
                                  <input
                                    :ref="newfield.fieldName"
                                    :class="[`form-control`,{'cursor-pointer': fromUrlStatus===true}]"
                                    :label="newfield.label"
                                    type="file"
                                    @change="helloButton($event, item.pageNo, newfield.fieldName)"
                                   @focus="onFileInputFocus(newfield.fieldName)"
                                   @blur="onFileInputBlur(item.pageNo, newfield.fieldName, newfield.validation)"
                                  />
                                </div>
                                <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                  </div>
                  <!-- file upload ends -->
                  <!-- year starts -->
                  <div
                    :class="{'cursor-pointer': fromUrlStatus===true}"
                                v-else-if="newfield.type == 'year'"
                                class="flex-item"
                                @click.prevent="openModal(newfield.label,groupno)"
                              >
                                <basenumber
                                :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                                :sourceValue="fromUrlStatus"
                                :class="{'cursor-pointer': fromUrlStatus===true}"
                                  :label="newfield.label"
                                  :id="newfield.fieldName"
                                  classname="form-control"
                                  :gettype="newfield.type"
                                  :requiredname="newfield.validation[0]"
                                  type="number"
                                  step="1"
                                  :minValue=1900
                                  v-model="
                                    formdata[`pageNo${item.pageNo}`][
                                      newfield.fieldName
                                    ]
                                  "
                                  @input="truncateInput($event, newfield.fieldName, 4)"
                                  @focusoutfun="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                                  />
                                  <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                  </div>
                  <!-- year ends -->
                  <!-- qr code starts -->            
                  <div
                                class="elem"
                                v-else-if="newfield.type == 'qrcode'"
                                >
                                <div hidden>
                                    {{
                                      qrcodefun(
                                        newfield.fieldName,
                                        newfield.qrData
                                      )
                                    }}
                                  </div>
                               <div class="labelstyle mb-2">  {{ newfield.label }} </div>   
                              <iframe :src="qrcodetxt[newfield.fieldName]" title="qr code">
                  </iframe>
                  </div>
                  <!-- qr code ends -->
                  <!-- numberanddate starts -->
                  <div
                    v-else-if="newfield.type == 'numberanddate'"
                    class="flex-item single-line-input"
                  >
                        <div hidden>
                                    {{ numbanddatefocusoutfun(newfield.fieldName)}}
                                  </div>
                          <label class="labelclass"  :class="{'cursor-pointer': fromUrlStatus===true}" @click="openModal(newfield.label,groupno)">
                            {{ newfield.label }}<sup v-if="requiredclasslist[newfield.fieldName] == 'required'"><i class="fa fa-asterisk px-1 imp"></i></sup>
                          </label>
                          <div v-if="numberanddateval[newfield.fieldName]" class="twoitemflex input-container">
                            <input
                            :disabled="calculatedfields.includes(newfield.fieldName)"
                              @click="openModal(newfield.label,groupno)"
                              :label="newfield.label"
                              :class="[`form-control number-input`,{'cursor-pointer': fromUrlStatus===true}]"
                              type="number"
                              v-model="numberanddateval[newfield.fieldName].numberdata"
                              @blur="numberanddateblurfun(
                                  item.pageNo,
                                  newfield.fieldName
                                )"
                            />
                            <input
                            :disabled="calculatedfields.includes(newfield.fieldName)"
                              @click="openModal(newfield.label,groupno)"
                              :class="[`form-control date-input`,{'cursor-pointer': fromUrlStatus===true}]"
                              :label="newfield.label"
                              type="date"
                              v-model="numberanddateval[newfield.fieldName].datedata"
                              @blur="numberanddateblurfun(
                                  item.pageNo,
                                  newfield.fieldName
                                )"
                            />
                          </div>
                          <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                  </div>  
                  <!-- numberanddate ends -->
                  <!-- checkboxes starts -->
                  <div
                    v-else-if="newfield.type == 'checkbox'"
                    class="form-group sticktobottom"
                    :class="{'cursor-pointer': fromUrlStatus===true}"
                  >
                    <div class="checkboxentry">
                      
                      <input
                      :disabled="calculatedfields.includes(newfield.fieldName)"
                      @click="assignprivilege(newfield.fieldName,newfield.label,groupno)"
                        type="checkbox"
                        class="form-check-input"
                        :id="newfield.fieldName"
                        :name="newfield.fieldName"
                        v-model="
                          formdata[`pageNo${item.pageNo}`][
                            newfield.fieldName
                          ]
                        "
                        @change="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                      /><span class="labelstyle">
                        {{ newfield.label }}<sup v-if="requiredclasslist[newfield.fieldName] == 'REQUIRED' || requiredclasslist[newfield.fieldName] == 'Required'|| requiredclasslist[newfield.fieldName] == 'required'"><i class="fa fa-asterisk px-1 imp"></i></sup>
                      </span>
                    </div>
                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                  </div>
                  <!-- checkboxes ends -->
                  <!-- radio button starts -->
                  <div
                    v-else-if="newfield.type == 'radio' && newfield.align =='vertical'"
                    class="form-group sticktobottom"
                    :class="{'cursor-pointer': fromUrlStatus===true}"
                  >
                    <baseradiogroup
                    :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                    @click="assignprivilege(newfield.fieldName,newfield.label,groupno)"
                    :sourceValue="fromUrlStatus"
                    :class="{'cursor-pointer': fromUrlStatus===true}"
                      :label="newfield.label"
                      :name="newfield.fieldName"
                      :options="newfield.options"
                      :requiredname="requiredclasslist[newfield.fieldName]"
                      v-model="
                        formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                      "
                       @focusoutfunwithopt="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  $event
                                )
                              "
                    >
                    </baseradiogroup>
                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                  </div>
                  <!-- radio button ends -->
                  <!-- radio button starts -->
                  <div
                    v-else-if="newfield.type == 'radio' && newfield.align !='vertical'"
                    class="form-group sticktobottom"
                  >
                    <baseradiogroup
                    :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                    @click="assignprivilege(newfield.fieldName)"
                      :label="newfield.label"
                      :name="newfield.fieldName"
                      :options="newfield.options"
                      :requiredname='newfield.validation.includes("Required") || newfield.validation.includes("REQUIRED")? "Required" : ""'
                      orientation="horizondal"
                      v-model="
                        formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                      "
                        @focusoutfunwithopt="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  $event
                                )
                              "
                    >
                    </baseradiogroup>
                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                  </div>
                  <!-- radio button ends -->
                  <!-- repeatedmeasure starts -->
                  <div
                        v-else-if="
                            newfield.type == 'repeatedmeasure'  ||
                            newfield.type == 'RepeatingMeasure' ||
                            newfield.type == 'repeatingfield'
                        "
                       >
                       <div class="headerdiv d-flex justify-content-between mb-1">
                        <h5 class="headdertext align-self-center pl-2 mb-0">{{newfield.label}}</h5>
                        <span class="position-relative create-repeated-block">
                          <img class="cursor-pointer pr-2" @click.prevent="addrepeatedfields(fieldIndex,pageIndex,newfield.fieldName)" src="../../assets/img/create.png"  alt="Add repeated fields">
                          <span class="tool-tip">Create repeated fields</span>
                        </span>
                       </div>  
                       <div>
                          <div class="repeatContainer">
                            <div
                              v-for="(fields,rfieldIndex) in newfield.repeatingFields"
                                      :key="rfieldIndex"
                              class=" mainbgchange"
                            >
                            <!--  -->
                            <div class="text-right">
                              <span class="position-relative create-repeated-block">
                                <img class="cursor-pointer p-2" 
                                     @click.prevent="removeRepeatedfields(pageIndex,fieldIndex,rfieldIndex,newfield.fieldName,fields.fieldIdentifier,item.pageNo)" 
                                     src="../../assets/img/remove.png"  alt="Remove repeated fields">
                                <span class="tool-tip">Remove repeated fields</span>
                              </span>
                            </div>
                            <div 
                            
                              v-for="(repgroupno, key) in repeatedgroupgroupnos[newfield.fieldName]"
                                     :key="key"
                              class="groupsdiv mainbgchange">
                          <div class="flexContainer mainbgchange">
                              <template
                                v-for="field in fields.fields"
                                :key="field.groupOrderNo"
                              >
                                <div
                                  v-if="field.groupOrderNo == repgroupno"
                                  class="flex-sub-container mainbgchange"
                                >
                                <template v-if="(visibility[field.fieldName+fields.fieldIdentifier] || visibility[field.fieldName]) || skipcheckingdependency">
                                <!-- Header -->
                                  <div
                                    v-if="field.type == 'header'"
                                    class="headerdiv text-center"
                                  >
                                      <h5
                                        class="headdertext align-self-center mb-0"
                                        :class="{
                                          'cursor-pointer':
                                            fromUrlStatus === true,
                                        }"
                                        @click.prevent="
                                          openModal(
                                            field.label,
                                            field.groupOrderNo
                                          )
                                        "
                                      >
                                        {{ field.label }}
                                      </h5>
                                  </div>
                                  <!-- Input -->
                                  <div
                                    v-else-if="inputElements.includes(field.type)"
                                    class="flex-item "
                                    :class="{'cursor-pointer': fromUrlStatus === true,}"
                                    @load="testfun()"
                                  >
                                    <baseinput
                                      @click.prevent="
                                        assignprivilege(
                                          field.fieldName,
                                          field.label,
                                          field.groupOrderNo
                                        )"
                                      :fielddisabled="calculatedfields.includes(field.fieldName)"
                                      :class="{'cursor-pointer': fromUrlStatus === true,}"
                                      :sourceValue="fromUrlStatus"
                                      :requiredname='field.validation.includes("REQUIRED") ? "Required" : ""'
                                       v-model="
                                        formdata[`pageNo${item.pageNo}`][newfield.fieldName][fields.fieldIdentifier][field.fieldName]"
                                      :label="field.label"
                                      :autofill="isautofill(newfield.fieldName)"
                                      :inputtype="field.type"
                                      type="text"
                                      @focusoutfun="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                    />
                                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                              ref="validationMsgs"
                            />
                                  </div>
                                  <!-- radio button starts -->
                                <div
                                  v-else-if="field.type == 'radio' && field.align !='vertical'"
                                  class="form-group sticktobottom"
                                >
                                  <baseradiogroup
                                  @click="assignprivilege(field.fieldName)"
                                    :label="field.label"
                                    :name="field.fieldName"
                                    :options="field.options"
                                    :fielddisabled="calculatedfields.includes(field.fieldName)"
                                    :requiredname='field.validation.includes("Required") || field.validation.includes("REQUIRED")? "Required" : ""'
                                    orientation="horizondal"
                                     v-model="
                                        formdata[`pageNo${item.pageNo}`][
                                          newfield.fieldName][fields.fieldIdentifier][field.fieldName]
                                      "
                                      @focusoutfunwithopt="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  $event
                                )
                              "
                                  >
                                  </baseradiogroup>
                                  <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                                </div>
                                <!-- radio button ends -->
                                <!-- Date starts -->
                                  <div
                                    v-else-if="field.type == 'date'"
                                    class="flex-item"
                                    :class="{
                                      'cursor-pointer': fromUrlStatus === true,
                                    }"
                                    @load="testfun()"
                                  >
                                    <basedate
                                      @click="
                                        assignprivilege(
                                          field.fieldName,
                                          field.label,
                                          field.groupOrderNo
                                        )
                                      "
                                      :fielddisabled="calculatedfields.includes(field.fieldName)"
                                      :dateFormat="getdateformat(field.validation)"
                                      :sourceValue="fromUrlStatus"
                                      :class="{
                                        'cursor-pointer':
                                          fromUrlStatus === true,
                                      }"
                                       :requiredname='field.validation.includes("REQUIRED") ? "Required" : ""'
                                      v-model="
                                        formdata[`pageNo${item.pageNo}`][newfield.fieldName
                                        ][fields.fieldIdentifier][field.fieldName]
                                      "
                                      :label="field.label"
                                      type="text"
                                      @focusoutfun="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                    />
                                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                                  </div>
                                  <!-- summary starts -->
                                  <div
                                    v-else-if="field.type == 'summary'"
                                    class="ml-2 pb-2"
                                    :class="{'cursor-pointer': fromUrlStatus===true}"
                                  >
                                  <div class="txtarealabel" :class="{'cursor-pointer': fromUrlStatus===true}"><i>
                                      <label :for="field.fieldName" :class="{'cursor-pointer': fromUrlStatus===true}" class="textsize">{{
                                        field.label
                                      }}</label></i>
                                    </div>   
                                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />                                  
                                  </div>
                                <!-- summary ends -->
                                 <!-- notes starts -->
                                <div
                                  class="ml-2 pb-2"
                                  v-else-if="field.type == 'notes'"
                                >
                                  <div class="notesstyle">
                                    {{ field.label }}
                                  </div>
                                </div>
                                <!-- notes ends -->
                                  <!-- dropdown menu starts -->
                                  <div
                                    v-else-if="field.type == 'dropdown'"
                                    class="flex-item"
                                    :class="{
                                      'cursor-pointer': fromUrlStatus === true,
                                    }"
                                  >
                                    <baseselect
                                      @click="
                                        assignprivilege(
                                          field.fieldName,
                                          field.label,
                                          field.groupOrderNo
                                        )
                                      "
                                      :fielddisabled="calculatedfields.includes(field.fieldName)"
                                      :sourceValue="fromUrlStatus"
                                      :class="{
                                        'cursor-pointer':
                                          fromUrlStatus === true,
                                      }"
                                      :options="field.options"
                                      :requiredname='field.validation.includes("REQUIRED") ? "Required" : ""'
                                      v-model="
                                        formdata[`pageNo${item.pageNo}`][newfield.fieldName
                                        ][fields.fieldIdentifier][field.fieldName]
                                      "
                                      :label="field.label"
                                      @focusoutfun="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  $event
                                )
                              "
                                    />
                                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                                  </div>
                                  <!-- dropdown menu ends -->
                                  <!-- subHeader starts -->
                                  <div
                                    v-else-if="field.type == 'subHeader'"
                                    class="subheadder"
                                    @click.prevent="
                                      openModal(field.label, field.groupOrderNo)
                                    "
                                    :class="{
                                      'cursor-pointer': fromUrlStatus === true,
                                    }"
                                  >
                                    <h4>{{ field.label }}</h4>
                                  </div>
                                  <!-- number and date starts -->
                                  <div
                                    v-else-if="field.type == 'numberanddate'"
                                    class="flex-item single-line-input"
                                  >
                                    <label class="labelclass">
                                      {{ field.label}}<sup v-if='field.validation.includes("REQUIRED")'><i class="fa fa-asterisk imp px-2"></i></sup>
                                    </label>
                                    <div hidden>
                                    {{
                                      repeatednumbanddatefocusoutfun(
                                        field.fieldName+fields.fieldIdentifier
                                      )
                                    }}
                                  </div>
                                    <div                           
                                      class="twoitemflex input-container"
                                      v-if="repeatednumberanddateval[field.fieldName+fields.fieldIdentifier]"
                                    >
                                      <input
                                        class="form-control number-input"
                                        :class="[`form-control date-input`,{'cursor-pointer': fromUrlStatus===true}]"
                                        :label="field.label"
                                        type="number"
                                        v-model="
                                        repeatednumberanddateval[field.fieldName+fields.fieldIdentifier]
                                            .numberdata"
                                            :disabled="calculatedfields.includes(field.fieldName)"
                                      />
                                      <input
                                        class="form-control date-input"
                                        :class="[`form-control date-input`,{'cursor-pointer': fromUrlStatus===true}]"

                                        :label="field.label"
                                        type="date"
                                        :disabled="calculatedfields.includes(field.fieldName)"
                                        v-model="
                                        repeatednumberanddateval[field.fieldName+fields.fieldIdentifier]
                                            .datedata
                                        "
                                      />
                                    </div>
                                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                                  </div>
                                  <!-- number and date ends -->
                                  <!-- scales starts -->
                                  <div
                                    v-else-if="field.type == 'scales'"
                                    :class="{'cursor-pointer': fromUrlStatus===true}"
                                    @click.prevent="openModal(field.label,field.groupOrderNo)"
                                    class="flex-item d-flex flex-column"                                    
                                  >
                                    <label class="labelclass mr-2" :class="{'cursor-pointer': fromUrlStatus===true}" >
                                      {{field.label}}<sup v-if='field.validation.includes("REQUIRED")'><i class="fa fa-asterisk imp px-2"></i></sup>
                                    </label>

                                     <div class="d-flex"> <h6>{{ field.limit[0].min }}</h6>
                                      <input
                                        type="range"
                                        :class="{'cursor-pointer': fromUrlStatus===true}"
                                        :min="parseFloat(field.limit[0].min)"
                                        :max="parseFloat(field.limit[0].max)"
                                        v-model="formdata[`pageNo${item.pageNo}`][
                                          newfield.fieldName
                                        ][fields.fieldIdentifier][
                                          field.fieldName
                                        ]"
                                        :disabled="calculatedfields.includes(field.fieldName)"
                                        step="1"
                                      />
                                     <h6>{{ field.limit[0].max}}</h6></div>
                                      <input
                                        class="form-control"
                                        :class="[{'cursor-pointer': fromUrlStatus===true}]"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                        type="number"
                                        :min="parseFloat(field.limit[0].min)"
                                        :max="parseFloat(field.limit[0].max)"
                                        v-model="formdata[`pageNo${item.pageNo}`][
                                          newfield.fieldName
                                        ][fields.fieldIdentifier][
                                          field.fieldName
                                        ]"
                                        :disabled="calculatedfields.includes(field.fieldName)"
                                      />    
                                      <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />                                 
                                  </div>
                                  <!-- scales ends -->
                                   <!-- qrcode starts -->
                                  <div
                                    class="elem"
                                    v-else-if="field.type == 'qrcode'"
                                    >
                                    <div hidden>
                                        {{
                                          qrcodefun(
                                            field.fieldName,
                                            field.qrData
                                          )
                                        }}
                                      </div>
                                  <div class="labelstyle mb-2">  {{ field.label }} </div>   
                                  <img :src="qrcodetxt[field.fieldName]" title="qr code"/>                                  </div>
                                   <!-- qrcode ends -->
                                  <!-- checkboxes starts -->
                                  <div
                                    v-else-if="field.type == 'checkbox'"
                                    class="form-group sticktobottom"
                                    :class="{
                                      'cursor-pointer': fromUrlStatus === true,
                                    }"
                                  >                          
                                    <div class="ml-4">
                                      <input
                                        @click="
                                          assignprivilege(
                                            field.fieldName,
                                            field.label,
                                            field.groupOrderNo
                                          )
                                        "
                                        type="checkbox"
                                        class="form-check-input"
                                        :id="field.fieldName"
                                        :name="field.fieldName"
                                         v-model="
                                        formdata[`pageNo${item.pageNo}`][newfield.fieldName
                                        ][fields.fieldIdentifier][field.fieldName]
                                      "
                                        @change="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                      /><span class="labelstyle">
                                        {{ field.label }}&nbsp;<sup
                                        v-if='
                                        field.validation.includes("required") ? "Required" : ""
                                        '
                                        ><i class="fa fa-asterisk px-1 imp"></i></sup
                                    >
                                      </span>
                                    </div> 
                                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                                  </div>
                                  <!-- checkboxes ends -->
                                  <!-- radio button starts -->
                                  <div
                                    v-else-if="field.type == 'radio' && field.align =='vertical'"
                                    class="form-group sticktobottom"
                                    :class="{
                                      'cursor-pointer': fromUrlStatus === true,
                                    }"
                                  >
                                    <baseradiogroup
                                      @click="
                                        assignprivilege(
                                          field.fieldName,
                                          field.label,
                                          field.groupOrderNo
                                        )
                                      "
                                      :sourceValue="fromUrlStatus"
                                      :class="{
                                        'cursor-pointer':
                                          fromUrlStatus === true,
                                      }"
                                      :label="field.label"
                                      :name="field.fieldName"
                                      :options="field.options"
                                      :requiredname='field.validation.includes("REQUIRED") ? "Required" : ""'
                                       v-model="
                                        formdata[`pageNo${item.pageNo}`][
                                          newfield.fieldName
                                        ][fields.fieldIdentifier][
                                          field.fieldName
                                        ]
                                      "
                                      :fielddisabled="calculatedfields.includes(field.fieldName)"
                                      @focusoutfunwithopt="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  $event
                                )
                              "
                                    >
                                    </baseradiogroup>
                                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                                  </div>
                                  <!-- radio button ends -->
                                  <!-- time element starts -->
                                  <div
                                    v-else-if="field.type == 'time'"
                                    class="flex-item bgblue"
                                  >
                                    <label class="labelclass">
                                      {{ field.label
                                      }}<sup v-if="field.validation.includes('REQUIRED')"><i class="fa fa-asterisk px-1 imp"></i></sup>
                                    </label>
                                    <basetime
                                    :fielddisabled="calculatedfields.includes(field.fieldName)"
                                      class="form-control"
                                      classname="time"
                                      :label="field.label"
                                      :timeFormat="gettimeformat(field.validation)"
                                      type="time"
                                       v-model="
                                        formdata[`pageNo${item.pageNo}`][
                                          newfield.fieldName
                                        ][fields.fieldIdentifier][
                                          field.fieldName
                                        ]
                                      "
                                       @focusoutfun="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                    />
                                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                                  </div>
                                  <!-- time element ends -->
                                  <!-- text area starts -->
                                  <div
                                    class="elem"
                                    v-else-if="
                                      (field.type == 'description') ||
                                        (field.type == 'textarea')
                                    "
                                  >
                                    <div class="txtarealabel" :class="{'cursor-pointer': fromUrlStatus===true}">
                                      <label :for="field.fieldName" class="labelstyle">{{
                                        field.label
                                      }}<sup v-if="field.validation.includes('REQUIRED')"><i class="fa fa-asterisk px-1 imp"></i></sup></label>
                                    </div>
                                    <div class="txtar" :class="{'cursor-pointer': fromUrlStatus===true}">
                                      <textarea
                                      @click="
                                        assignprivilege(
                                          field.fieldName,
                                          field.label,
                                          field.groupOrderNo
                                        )
                                      "
                                        :id="field.fieldName"
                                        :name="field.fieldName"
                                        :class="[`form-control`,{'cursor-pointer': fromUrlStatus===true}]"
                                        class="textbox "
                                         v-model="
                                        formdata[`pageNo${item.pageNo}`][
                                          newfield.fieldName
                                        ][fields.fieldIdentifier][
                                          field.fieldName
                                        ]
                                      "
                                      :disabled="calculatedfields.includes(field.fieldName)"
                                      @blur="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                      /> 
                                    </div>
                                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                                  </div>
                                  <!-- text area ends -->
                                  <!-- dateandtime element starts -->
                                  <div
                                    v-else-if="field.type == 'dateandtime'"
                                    class="flex-item bgblue"
                                  >
                                    <basedatetime
                                      @click="
                                        openModal(
                                          field.label,
                                          field.groupOrderNo
                                        )
                                      "
                                      :sourceValue="fromUrlStatus"
                                      :class="{
                                        'cursor-pointer': sourceValue === true,
                                      }"
                                      :label="field.label"
                                      :requiredname='field.validation.includes("REQUIRED") ? "Required" : ""'
                                      type="datetime-local"
                                       v-model="
                                        formdata[`pageNo${item.pageNo}`][
                                          newfield.fieldName
                                        ][fields.fieldIdentifier][
                                          field.fieldName
                                        ]
                                      "
                                      :fielddisabled="calculatedfields.includes(field.fieldName)"
                                      @focusoutfun="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                    />
                                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                                  </div>
                                  <!-- date element ends -->
                                  <!-- year starts -->
                                  <div
                                    :class="{
                                      'cursor-pointer': fromUrlStatus === true,
                                    }"
                                    v-else-if="field.type == 'year'"
                                    class="flex-item"
                                    @click.prevent="
                                      openModal(field.label, field.groupOrderNo)
                                    "
                                  >
                                    <basenumber
                                      :sourceValue="fromUrlStatus"
                                      :class="{
                                        'cursor-pointer':
                                          fromUrlStatus === true,
                                      }"
                                      :fielddisabled="calculatedfields.includes(field.fieldName)"
                                      :label="field.label"
                                      :id="field.fieldName"
                                      classname="form-control"
                                      :requiredname='field.validation.includes("REQUIRED") ? "Required" : ""'
                                      type="number"
                                      step="1"
                                      :minValue="1900"
                                      v-model="
                                        formdata[`pageNo${item.pageNo}`][
                                          newfield.fieldName
                                        ][fields.fieldIdentifier][
                                          field.fieldName
                                        ]
                                      "
                                      @input="
                                        truncateInput(
                                          $event,
                                          field.fieldName,
                                          4
                                        )
                                      "
                                      @focusoutfun="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                    />
                                    <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                            />
                                  </div>
                                  <!-- {{fields}} -->
                                 
                                </template>
                              </div>
                                
                              </template>
                              
                              </div>
                        
                              </div>
                              <div class="repeat-border-bottom mb-2 col-md-12"></div>
                            </div>
                          </div>
                       </div>
                  </div>
                  <!-- repeatedmeasure ends -->
                </template>
              </div>
              </template>
            </div>
          </div>
          <!-- view button  -->
          <div class="row mx-0 paginationpadding mt-4" v-if="!requiredsubmit">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="dataTables_paginate paging_simple_numbers pagination float-right"
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="pageno"
                    :pages="totalpages"
                    :range-size="1"
                    active-color="#618bd7"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- view button ends -->
          <div class="buttonContainer col-md-10" v-if="requiredsubmit">
            <div class="leftbutton">
              <button
                v-if="pageno > 1"
                type="button"
                class="btn btn-next py-0"
                @click.prevent="previousFun"
              >
                Previous
              </button>
            </div>
            Page {{ pageno }}
            <div class="rightbutton">
              <button
                v-if="pageno < totalpages"
                type="button"
                class="btn btn-next py-0"
                @click.prevent="nextfun"
              >
                Next
              </button>
              <!-- {{ isView }} -->
              <button
                v-if="pageno == totalpages && isPatientVisit!='true'"
                type="button"
                class="btn btn-next py-0"
                @click.prevent="reviewAndSubmit"
              >
             {{ $t("navigations.review and submit") }}
              </button>
             
              <button
                v-if="pageno == totalpages && isPatientVisit!='true'"
                type="button"
                class="btn btn-next mr-2 py-0"
                @click.prevent="testfun"
              >
                 {{ $t("navigations.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</div>
<!-- repeatedmeasure  starts -->
<teleport to="#app">
<div class="add_page_modal_wrapper" v-if="repeatedmeasurepopup">
  <div class="option_modal__containers">
    <div
      class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
    >
      <h4 class="my-0">Manage Repeated Measures</h4>
      <a class="close-link">
        <i class="fa fa-times" @click.prevent="closerepeatedmeasuremodal()"></i>
      </a>
    </div>

    
              <div class="form-group col-lg-10 mx-auto autoscroll" v-if="!repeatedmeasurelistpopup">
                <div  class="form-group col-lg-10 mx-auto textgroup">
                    <label for="optionscheckbox">ID</label>
                    <div class="optgroup">
                      <input
                        type="text"
                        class="form-control"
                        id="optionscheckbox"
                        v-model="repratmeasureid"
                      />
                    </div>
                    </div>
                <div v-for="measureopts in repeatedoptions" :key="measureopts" class="form-group col-lg-10 mx-auto textgroup">
                    <label for="optionscheckbox">{{ measureopts.fieldName }}</label>
                    <div v-if="measureopts.type =='text'" class="optgroup">
                      <input
                        type="text"
                        class="form-control"
                        v-model="temprepeatedmeasureval[measureopts.fieldName]"
                        id="optionscheckbox"
                        :disabled="repeathidden"
                      />
                    </div>
                    <div v-if="measureopts.type =='date'" class="optgroup">
                      <input
                        type="date"
                        class="form-control"
                        v-model="temprepeatedmeasureval[measureopts.fieldName]"
                        id="optionscheckbox"
                        :disabled="repeathidden"
                      />
                    </div>
                    <div v-if="measureopts.type =='number'" class="optgroup">
                      <input
                        type="number"
                        class="form-control"
                        v-model="temprepeatedmeasureval[measureopts.fieldName]"
                        id="optionscheckbox"
                        :disabled="repeathidden"
                      />
                    </div>
                    <div v-if="measureopts.type =='time'" class="optgroup">
                      <input
                        type="time"
                        class="form-control"
                        v-model="temprepeatedmeasureval[measureopts.fieldName]"
                        id="optionscheckbox"
                        :disabled="repeathidden"
                      />
                    </div>
                    <div v-if="measureopts.type =='dropdown'" class="optgroup">
                      
                      <select class="form-control" v-model="temprepeatedmeasureval[measureopts.fieldName]">
                      <option v-for="(option, index) in measureopts.options" :key="index" :value="option">{{ option }}</option>
                      </select>
                    </div>
                    <div v-if="measureopts.type =='radio'" class="optgroup">
                    <label class="mr-2" v-for="(option, index) in measureopts.options" :key="index">
                            <input type="radio" :value="option" v-model="temprepeatedmeasureval[measureopts.fieldName]">
                    {{ option }}
                    </label>
                    </div>
                    
                  </div>
                  


                  <div class="form-group col-lg-10 mx-auto textgroup">
                  <button
                  v-if="!repeathidden"
                        type="button"
                        class="btn btn-next float-right ml-1"
                        @click.prevent="addrepeatemesureentries()"
                      >
                      Add
                </button>
                <button
                        type="button"
                        class="btn btn btn btn-secondary float-right"
                        @click.prevent="repeatedmeasurlist()"
                      >
                      Back
                </button>
              </div>
              </div>
    <div class="form-group d-flex flex-column col-lg-10 mx-auto" v-if="repeatedmeasurelistpopup">
      <div class="table-responsive">
      <table class="repeatedtable table table-striped table-bordered dataTables mt-3">
        <thead>
          <tr>
            <th>Repeated Measure Name </th>
            <th class="text-center">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in repeatedmeasurevalarray" :key="item.ID">
            <td @click.prevent="reloadrepeatarray(item)">{{ item.ID }}</td>
            <td class="text-center">
              <i class="fa fa-trash edit-delete" aria-hidden="true" @click="deleterepeatedmeasure(item)"></i>
            </td>
          </tr>
        </tbody>
      </table>
      
    </div>
    <div class="form-group text-right">
      <button
                        type="button"
                        class="btn btn-next mr-2"
                        @click.prevent="repeatedmeasurdatapopup()"
                      >
                      Add Measures
                </button>
                  <button
                        type="button"
                        class="btn btn-next"
                        @click.prevent="saverepeatedmeasurdata()"
                      >
                      Save
                </button>
              </div>
                  </div>

  </div>
</div>
</teleport>
<!-- repeat measures ends -->
<!-- modal starts -->
<div v-if="fieldprivilegepopup == true" class="privilege__modal__wrapper">
<div class="privilege__modal__container">
  <div
    class="
      privilege__modal__title
      d-flex
      justify-content-between
      py-2
      px-3
    "
  >
    <h4 class="my-0">Select Privileges</h4>
    <div class="cursor-pointer">
      <i class="fa fa-times" @click.prevent="closemodallll()"></i>
    </div>
  </div>
  <!-- Popup -->
  <div
    class="privilege__modal__content p-3"
    :class="{ heightBig: changeHeight }"
  >
<!-- <select class="form-select" id="sel2" name="sellist2" v-model="selectedprivilege">
  <option selected value="">Select</option>
  <option value="Masking">Masking</option>
  <option value="View">View</option>
  <option value="Edit">Edit</option>
</select> -->
<div class="alignradio">
<div class="form-check">
<input class="form-check-input" v-model="selectedprivilege" type="radio" name="exampleRadios" id="exampleRadios1" value="Mask">
<label class="form-check-label" for="exampleRadios1">
{{ $t("navigations.masking") }}
</label>
</div>
<div class="form-check">
<input class="form-check-input" v-model="selectedprivilege" type="radio" name="exampleRadios" id="exampleRadios2" value="View">
<label class="form-check-label" for="exampleRadios2">
{{ $t("navigations.view") }}
</label>
</div>
<div class="form-check">
<input class="form-check-input" v-model="selectedprivilege" type="radio" name="Edit" id="Edit" value="Edit">
<label class="form-check-label" for="exampleRadios2">
{{ $t("navigations.edit") }}
</label>
</div>
</div>
<div class="col-md-4 my-1 btncenter">
                  <button
                    type="button"
                    class="btn btn-next"
                    @click.prevent="savefieldprivilege()"
                  >
                  {{ $t("navigations.save") }}
                  </button>
                </div>
  </div>
</div>
</div>
<digitalFormModal v-if="showModal"
:pageNo="pageno"
:pageArray="htmlarray[0]"
:modalFieldName="assignFieldValue"
@reloadForm="getformtemplate()"
@closeModal="showModal=false"/>
<span class="vidercallicon">
<div id="small-chat">
      <a data-toggle="modal"
     @click="openVideoCallSchedulePopup(CurrentPatientID)"
      data-target="#inviteVideocall" >
      <img src="../../assets/img/video_call.png" alt="Video Call Floating Icon">
    </a>
    <div class="tool-tip">Schedule Video call</div>
  </div>
  
</span>
  <CreateVideoCallSchedulePopUp v-if="CreateVideoCallSchedulePopUp == true"
          @closeModal="CreateVideoCallSchedulePopUp = false" :patientid="frompatid" :fromPage="'BlankForm'" />
<!--  popup -->
</template>

<script src="./dynamicformtemplate.js"></script>

<style scoped>
@import "./style.css";
.Page-active {
padding: 1rem;
color: #ffffff;
border: 1px solid #618bd7;
border-radius: inherit;
margin: 0;
}
.form-select{
border-radius:0;
}
.form-select:focus{
border-color:#145faf;
box-shadow: 0 0 0;
}
.rowlabelgrid {
margin-top: auto;
}
.imp {
color: #ff0000;
font-size: 7px;
}
.add-block-title {
color: #ffffff;
background: #768dd3;
}
.privilege__modal__wrapper {
position: fixed;
inset: 0;
background: rgb(0 0 0 / 18%);
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
z-index: 3000;
}
.add_page_modal_wrapper,
.modal__wrapper__component {
position: fixed;
inset: 0;
background: rgb(0 0 0 / 18%);
display: flex;
align-items: center;
justify-content: center;
transition: all 0.3s ease-in-out;
overflow: hidden;
z-index: 3000;
}
.maxheight {
height: 68%;
}
.option_modal__containers {
width: min(600px, 90%);
background: #fff;
border-radius: 4px;
height: 400px;
}
.autoscroll{
overflow:auto;
height:340px;
}
.repeatedtable{
max-height: 250px;
}
.add_page_modal__containers {
width: min(400px, 90%);
background: #fff;
border-radius: 4px;
overflow: hidden;
}
.privilege__modal__container {
width: min(70%, 270px);
position: fixed;
background: rgb(255, 255, 255);
border-radius: 4px;
overflow-y: hidden;
height: 160px;
}
.max-image {
max-width: 500px; /* Maximum width of 500 pixels */
max-height: 300px; /* Maximum height of 300 pixels */
}
.privilege__modal__title {
background-color: var(--pop-up-background);
color: #ffffff;
}
.privilege__modal__content {
height: 250px;
}
.headingunderline
{
text-decoration-line: underline;
}
.btncenter{
margin: auto;
padding-top: 20px;
}
.alignradio{
  display: flex;
gap: 20px;
}
.labelclass {
text-align: left;
font-size: 13px;
font-weight: 600;
float: left;
}
.labelstyle{
font-size: 14px;
margin-bottom: 0.1rem !important;
font-weight: 600;
}
.rowcolmn {
margin-top: 0px;
display: flex;
gap: 5px;
margin: 10px;
}
.errorlist{
border-width: 2px !important;
border-color: red !important;
}
.hightlight{
/* border-color: red !important; */
border-bottom: 6px solid #f8ac59;

}
.imp {
color: #ff0000;
font-size: 7px;
}
.borderValidated {
border:2px solid green!important;
}
.borderIncorrect{
border:2px solid orange!important;
}
.borderFail{
border:2px solid red!important;
}
.vidercallicon .tool-tip {
visibility: hidden;
width: max-content;
background-color: #616161;
color: #fff;
text-align: center;
border-radius: 0.3em;
padding: 0.3rem 0.8rem;
right: 50px;
bottom: 10px;
font-size: 0.8rem;

/* Position the tooltip */
position: absolute;
z-index: 1;
-webkit-transition: visibility 75ms ease-in;
transition: visibility 75ms ease-in;
}
.vidercallicon:hover .tool-tip {
visibility: visible;
-webkit-transition: visibility 30ms ease-in;
transition: visibility 30ms ease-in;
}
.create-repeated-block .tool-tip {
visibility: hidden;
width: max-content;
background-color: #616161;
color: #fff;
text-align: center;
border-radius: 0.3em;
padding: 0.3rem 0.8rem;
right: 5;
top: -38px;
font-size: 0.8rem;

/* Position the tooltip */
position: absolute;
z-index: 1;
-webkit-transition: visibility 75ms ease-in;
transition: visibility 75ms ease-in;
}

.create-repeated-block:hover .tool-tip {
visibility: visible;
-webkit-transition: visibility 30ms ease-in;
transition: visibility 30ms ease-in;
}
.repeat-border-bottom
{
  border-bottom:1px dashed #3c4192;
}
.repeatContainer{
  border:1px solid #b2c5f4;
  margin: 5px;
}
.single-line-input {
    display: flex;
    flex-direction: column;
}

.single-line-input label {
    margin-bottom: 8px; 
}

.input-container {
    display: flex;
}

.input-container input {
    flex: 1;
    margin-right: 0px; 
}

.input-container input:last-child {
    margin-right: 0;
}
</style>

