<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-6 align-self-center">
      <h2>{{ $t("navigations.toc generate") }}</h2>
    </div>
    <div class="col-lg-6 text-right">
      <button class="py-1 px-3 position-relative settings-block" :class="{'tooltip-hidden':openSettings==true}"
        @click="openSettings=true"><i class="fa fa-cog settings-icon"></i>
        <div class="settings-tooltip">{{ $t('navigations.show more options') }}</div>
      </button>
      <div class="toc-side-popup right-50" v-if="openSettings==true"  v-on:clickout="openSettings=false">
        <div @click.prevent="openAssignPopup(childlabels)">
          {{ $t("navigations.assign programmers") }}
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper wrapper-content">
  <div class="ibox-content">
    <!-- Tree View -->
    <div v-if="showTree" class="tree-container">
      <ul class="tree">
        <!-- Top-Level Nodes -->
        <li v-for="(node, index) in Object.keys(treeData)" :key="index">
            <input type="checkbox" :id="index" />
            <label class="tree_label" :for="index">{{ treeData[node].Headingindex }} {{ treeData[node].Headingname }}</label>

          <!-- Action Buttons for Top-Level Nodes -->
            <span
              class="position-relative cursor-pointer m-5"
              @click.prevent="toggleSidePopup(index, vIndex)"
              v-on:clickout="closeSidePopup(index,vIndex)"
            >
              <i class="fa fa-ellipsis-v"></i>
            </span>
            <div
              class="toc-side-popup left-220"
              v-if="showSidePopup[index] && showSidePopup[index][vIndex]"
            >
              <div @click.prevent="addreport(treeData[node].Headingid)">
                {{ $t("navigations.add") }}
              </div>
              <div @click.prevent="Addheadnodeafter(treeData[node].Headingid)">
              {{ $t("navigations.add headnode after") }}
            </div>

            <div @click.prevent="Addheadnodebefore(treeData[node].Headingid)">
              {{ $t("navigations.add headnode before") }}
            </div>
              <div
                @click="Editheading(treeData[node].Headingid,treeData[node].Headingname)"
              >
                {{ $t("navigations.edit") }}
              </div>
              <div @click.prevent="DeleteHeading(treeData[node].Headingid,treeData[node].Headingindex)">
                {{ $t("navigations.delete") }}
              </div>
            </div>
       

          <!-- Reports for Each Node -->
          <ul v-if="treeData[node].Reports.length != 0">
            <li
              v-for="(childlabels, vIndex) in treeData[node].Reports"
              :key="childlabels"
            >
              <label class="tree_label">
                {{ childlabels.Subindex }} {{ childlabels.Reportname }}
              </label>

              <!-- Status and Developer Information -->
              <template v-if="idSet.includes(childlabels.Reportid)">
                <template v-for="value in mapValue" :key="value.ReportId">
                  <template v-if="childlabels.Reportid == value.ReportId">
                    <span
                      class="px-2"
                      v-if="value.Status == 'No Output' || value.Status == 'No Program'"
                    >
                      <span class="badge badge-black">{{ value.Status }}</span> -
                      {{ value.DeveloperName }}
                    </span>
                    <span
                      class="px-2"
                      v-if="value.Status == 'Old Output' || value.Status == 'Ready For Validation'"
                    >
                      <span class="badge badge-danger">{{ value.Status }}</span>
                      - {{ value.DeveloperName }}
                    </span>
                    <span
                      class="px-2"
                      v-if="value.Status == 'In Development'"
                    >
                      <span class="badge badge-yellow">{{ value.Status }}</span>
                      - {{ value.DeveloperName }}
                    </span>
                    <span
                      class="px-2"
                      v-if="value.Status == 'Current Output'"
                    >
                      <span class="badge badge-orange">{{ value.Status }}</span>
                      - {{ value.DeveloperName }}
                    </span>
                    <span
                      class="px-2"
                      v-if="value.Status == 'Validated'"
                    >
                      <span class="badge badge-grn">{{ value.Status }}</span> -
                      {{ value.DeveloperName }}
                    </span>
                  </template>
                </template>
              </template>

              <!-- Action Buttons for Sub-Level Nodes -->
              <span
                class="position-relative cursor-pointer float-right px-2"
                @click.prevent="toggleSidePopup(index, vIndex)"
                v-on:clickout="closeSidePopup(index,vIndex)"
              >
                <i class="fa fa-ellipsis-v"></i>
              </span>
              <div
                class="toc-side-popup-sub"
                v-if="showSidePopup[index] && showSidePopup[index][vIndex]"
              >
                <div @click.prevent="goto(childlabels)">
                  {{ $t("navigations.create") }}
                </div>
                <div @click.prevent="changeStatus(childlabels, treeData._id)">
                  {{ $t("navigations.change validation status") }}
                </div>
                <div @click.prevent="mapProgramme(childlabels, treeData._id)">
                  {{ $t("navigations.map program") }}
                </div>
                <div @click.prevent="runBtn(childlabels.Reportid)">
                  {{ $t("navigations.run") }}
                </div>
                <div @click.prevent="Editsubheading(treeData[node].Headingid,childlabels.Reportid, childlabels.Reportname)">
                  {{ $t("navigations.edit") }}
                </div>
                <div @click.prevent="DeleteReport(treeData[node].Headingid,childlabels.Reportid,childlabels.Subindex)">
                  {{ $t("navigations.delete") }}
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="col-lg-6 text-left align-self-center">
      <button
        class="btn btn-primary"
        v-if="$store.getters.getTocGenrateBtn == false"
        @click="generateToC(studyId, foldername)"
        :disabled="showLoading"
      >
        <div class="d-flex align-items-center" v-if="showLoading">
          <img
            class="loading-img"
            width="20"
            src="../../assets/img/loading.webp"
            alt="Wait while the document is being generated"
          />
        </div>
        <div v-else>Generate ToC</div>
      </button>
     
    </div>
  </div>
</div>

  <!-- Query Open model starts -->
 <teleport to="#app">
  <div class="add_page_modal_wrapper" v-if="editpopup">
    <div class="option_modal__containers">
      <div
        class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
      >
        <h4 class="my-0">Edit Report</h4>
          <a class="close-link">
            <i
              class="fa fa-times"
              @click.prevent="closemodal()"
            ></i>
          </a>
      </div>
      
      <div
        class="form-group d-flex flex-column col-lg-10 mx-auto maxheightdesigncomplete pt-3"
      >

       <div>
        <label for="reportname" class="mt-2 mb-0">Current Name:</label>
        <input
        class="form-control"
          type="text"
          id="reportname"
          v-model="reportnamedit"
          placeholder="Edit Report Name"
          readonly
        />
      </div>
       <div>
        <label for="reportname" class="mt-2 mb-0">New Name</label>
        <input
        class="form-control"
          type="text"
          id="reportname"
          v-model="newreportname"
          placeholder="Edit Report Name"
        />
      </div>
        <div class="d-flex justify-content-center mt-2">
          <button
            type="button"
            class="btn btn-primary px-4 py-0"
            @click="onsave()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
   <div class="add_page_modal_wrapper" v-if="Addheadnodeafterpopup">
    <div class="option_modal__containers">
      <div
        class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
      >
        <h4 class="my-0">Add Headnode</h4>
          <a class="close-link">
            <i
              class="fa fa-times"
              @click.prevent="closeaftermodal()"
            ></i>
          </a>
      </div>
      
      <div
        class="form-group d-flex flex-column col-lg-10 mx-auto maxheightdesigncomplete pt-3"
      >

       <div>
        <label for="reportname" class="mt-2 mb-0">New Header Name</label>
        <input
        class="form-control"
          type="text"
          id="reportname"
          v-model="newheadnode"
          placeholder="New Heading Name"
        />
      </div>
        <div class="d-flex justify-content-center mt-2">
          <button
            type="button"
            class="btn btn-primary px-4 py-0"
            @click="Saveheadnodeafter()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
   <div class="add_page_modal_wrapper" v-if="Addheadnodebeforepopup">
    <div class="option_modal__containers">
      <div
        class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
      >
        <h4 class="my-0">Add Headnode</h4>
          <a class="close-link">
            <i
              class="fa fa-times"
              @click.prevent="closebeforemodal()"
            ></i>
          </a>
      </div>
      
      <div
        class="form-group d-flex flex-column col-lg-10 mx-auto maxheightdesigncomplete pt-3"
      >

       <div>
        <label for="reportname" class="mt-2 mb-0">New Header Name</label>
        <input
        class="form-control"
          type="text"
          id="reportname"
          v-model="newheadnode"
          placeholder="New Heading Name"
        />
      </div>
        <div class="d-flex justify-content-center mt-2">
          <button
            type="button"
            class="btn btn-primary px-4 py-0"
            @click="Saveheadnodebefore()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="add_page_modal_wrapper" v-if="editheadpopup">
    <div class="option_modal__containers">
      <div
        class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
      >
        <h4 class="my-0">Edit Heading</h4>
          <a class="close-link">
            <i
              class="fa fa-times"
              @click.prevent="closemodal()"
            ></i>
          </a>
      </div>
      
      <div
        class="form-group d-flex flex-column col-lg-10 mx-auto maxheightdesigncomplete pt-3"
      >

       <div>
        <label for="reportname" class="mt-2 mb-0">Current Name:</label>
        <input
        class="form-control"
          type="text"
          id="reportname"
          v-model="headingname"
          placeholder="Edit Heading Name"
          readonly
        />
      </div>
       <div>
        <label for="reportname" class="mt-2 mb-0">New Name</label>
        <input
        class="form-control"
          type="text"
          id="reportname"
          v-model="newheading"
          placeholder="New Heading Name"
        />
      </div>
        <div class="d-flex justify-content-center mt-2">
          <button
            type="button"
            class="btn btn-primary px-4 py-0"
            @click="onsave()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</teleport>
<teleport to="#app">
  <div class="add_page_modal_wrapper" v-if="addpopup">
    <div class="option_modal__containers">
      <div
        class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
      >
        <h4 class="my-0">Add Report</h4>
          <a class="close-link">
            <i
              class="fa fa-times"
              @click.prevent="closemodalforcreate()"
            ></i>
          </a>
      </div>
      
      <div
        class="form-group d-flex flex-column col-lg-10 mx-auto maxheightdesigncomplete pt-3"
      >
       <div>
        <label for="subreport">{{addindex}}:</label>
        <input
        class="form-control"
          type="text"
          id="subreport"
          v-model="subreport"
          placeholder="Add Report"
        />
      </div>
        <div class="d-flex justify-content-center mt-2">
          <button
            type="button"
            class="btn btn-primary px-4 py-0"
            @click="createsave()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</teleport>

  <mapProgrammePopup v-if="mapProgrammePopup" :reportList="modalChildLabels" :TocId="modalTocId"
    @closeModal="mapProgrammePopup = false" />
  <!-- Map program ends -->
  <!-- Report starts -->
  <div class="iframe-wrapper" v-if="displayModal == true">
    <template v-if="files.length == 0">
      <div class="toc-loader">
        <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
      </div>
    </template>
    <div class="iframe-container" v-else>
      <div class="titleclass d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ $t("navigations.reports") }}</h4>
        <a class="close-link">
          <i class="fa fa-times" @click.prevent="displayModal = false"></i>
        </a>
      </div>
      <div class="iframe-body  width-height p-4">
        <template v-for="(page, index) in files" :key="index">
          <iframe class="iframe-block" :id="`${page.Page}-${index}`" style="border: none;">
          </iframe>
        </template>
      </div>
    </div>
  </div>
  <!-- Report ends -->
  <!--Validation starts -->
  <div class="iframe-wrapper" v-if="displayStatusModal == true">
    <div class="iframe-container">
      <div class="titleclass d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ $t("navigations.validation status") }}</h4>
        <a class="close-link">
          <i class="fa fa-times" @click.prevent="closeModal('validation')"></i>
        </a>
      </div>
      <div class="iframe-body p-4">
        <div class="d-flex">
          <div>{{ $t('navigations.change validation status from') }}</div>
          <div class="w-25 px-2">
            <select v-model="status" class="study-select">
              <option v-for="option in statusOptions" :key="option">
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="px-2">
            <div>{{ $t('navigations.to') }}</div>
          </div>
          <div class="w-25 px-2">
            <select v-model="changedStatus" class="study-select">
              <option v-for="option in statusOptions" :key="option">
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="mt-3">
          <textarea placeholder="Comments" name="comments" id="comments"></textarea>
        </div>
        <div class="mt-3 text-center">
          <button type="button" class="save_btn px-5 position-relative" :disabled="loadingSaveBlock == true"
            @click.prevent="saveData()" :class="{ 'disabled-btn-cls': loadingSaveBlock == true }">
            <div class="toc-loader position-absolute"
              :class="{ 'opacity-0': loadingSaveBlock == false, 'opacity-1': loadingSaveBlock == true }">
              <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
            </div>
            <div :class="{ 'opacity-1': loadingSaveBlock == false, 'opacity-0': loadingSaveBlock == true }">{{
              $t("navigations.save") }}</div>
          </button>
        </div>

      </div>
    </div>
  </div>
  <!-- Validation ends -->
  <!--Assign programmers starts -->
  <div class="iframe-wrapper" v-if="openAssignModal == true">
    <div class="iframe-container">
      <div class="titleclass d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ $t("navigations.assign programmers") }}</h4>
        <a class="close-link">
          <i class="fa fa-times" @click.prevent="closeModal('assign')"></i>
        </a>
      </div>
      <div class="iframe-body height-300 p-3">
        <ul class="modal-tree">
          <li v-for="(node, index) in Object.keys(treeModal)" :key="`treeModal-${index}`">
            {{ treeModal._id }}
            <input class="mr-5" type="checkbox" :id="`treeModal-${index}`" v-model="rootChecked[`treeModal-${index}`]"
              @change="toggleChildren(treeModal[node], index,`treeModal-${index}`)" />
            <label class="modal_tree_label" :for="`treeModal-${index}`">{{ treeModal[node].Headingindex }} {{ node }}</label>
            <ul v-if="treeModal[node].Reports.length != 0">
              <li v-for="childlabels, vindex in treeModal[node].Reports" :key="`treeModal-${index}-${vindex}`">
                <input class="mr-5" type="checkbox" @change=" toggleChild(index, vindex, $event.target.checked)" :id="`treeModal-${index}-${vindex}`"
                  v-model="childrenChecked[`treeModal-${index}-${vindex}`]" />
                <label class="tree_label" :for="`treeModal-${index}-${vindex}`">{{ childlabels.Subindex }} {{
                  childlabels.Reportname }} </label>
                <template v-if="idSet.includes(childlabels.Reportid)">
                  <template v-for="value in mapValue" :key="value.ReportId">
                    <template v-if="childlabels.Reportid == value.ReportId">
                      <span class="px-2" v-if="value.Status == 'No Output' || value.Status == 'No Program'"><span
                          class="badge badge-black">{{
                            value.Status }} </span> - {{ value.DeveloperName }}</span>
                      <span class="px-2"
                        v-if="value.Status == 'Old Output' || value.Status == 'Ready For Validation'"><span
                          class="badge badge-danger">{{
                            value.Status }}</span> - {{ value.DeveloperName }}</span>
                      <span class="px-2" v-if="value.Status == 'In Development'"><span class="badge badge-yellow">{{
                        value.Status }}</span> - {{ value.DeveloperName }}</span>
                      <span class="px-2" v-if="value.Status == 'Current Output'"><span class="badge badge-orage">{{
                        value.Status }}</span> - {{ value.DeveloperName }}
                      </span>
                      <span class="px-2" v-if="value.Status == 'Validated'"><span class="badge badge-grn">{{
                        value.Status }}</span> - {{ value.DeveloperName }}
                      </span>
                    </template>
                  </template>
                </template>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="iframe-footer p-4">
        <div class="row">
          <div class="col-6">
            <label for="programmer">{{ $t('navigations.select primary programmer') }}</label>
            <select v-model="programmer" class="study-select" id="programmer">
              <option value="">{{ $t('navigations.none') }}</option>
              <option :value="user.fullName" v-for="user in userList" :key="user.id">
                {{ user.fullName}}
              </option>
            </select>
          </div>
          <div class="col-6">
            <label for="qcprogrammer">{{ $t('navigations.select qc programmer') }}</label>
            <select v-model="qcProgrammer" class="study-select" id="programmer">
              <option value="">{{ $t('navigations.none') }}</option>
              <option :value="user.fullName" v-for="user in userList" :key="user.id">
                {{ user.fullName }}
              </option>
            </select>
          </div>
        <div class="col-12 mt-3 text-center">
          <button type="button" class="save_btn px-5 position-relative" :disabled="loadingAssignBlock == true || (programmer=='' && qcProgrammer=='')"
            @click.prevent="assignProgrammer()" :class="{ 'disabled-btn-cls': loadingAssignBlock == true }">
            <div class="toc-loader position-absolute"
              :class="{ 'opacity-0': loadingAssignBlock == false, 'opacity-1': loadingAssignBlock == true }">
              <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
            </div>
            <div :class="{ 'opacity-1': loadingAssignBlock == false, 'opacity-0': loadingAssignBlock == true }">{{
              $t("navigations.save") }}</div>
          </button>
        </div>
        </div>
       
      </div>
    </div>
  </div>
  <!--Assign programmers ends -->
  <!-- Validation ends -->
  <!-- modal ends -->
</template>
<script src="./tocgenerate.js"></script>
<style scoped>
@import "./tree.css";
@import "../../assets/css/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";

.modal-tree input:checked~ul {
  display: block;
}

:checked ~ label.tree_label:before { content: url("../../assets/images/folder-close.png"); }

.modal-tree .tree_label:after {
  display: none;
}

label.modal_tree_label:before {
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 1em 0 -2em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  content: url("../../assets/images/folder-close.png");
  text-align: center;
  line-height: .8em;
  }
.linkstyle {
  cursor: pointer;
}

.settings-block{
  font-size: 1rem;
  color:hsl(0, 0%, 50%);
  border:none;
  transition: rotate 0.5s ease-in-out, background-color 0.5s ease-in-out ;
}
.settings-icon{
  transform-origin: center center; 
}
.position-relative:hover .settings-icon
{
  rotate:-160deg;
}

.position-relative .settings-tooltip{
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 35px;
  top: -10px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .settings-tooltip,
.position-relative:focus-within .settings-tooltip,
.position-relative:focus-visible .settings-tooltip{

  visibility: visible;
  transition: visibility 30ms ease-in;
}
.position-relative.tooltip-hidden .settings-tooltip {
  display:none !important;
}
.linkstyle {
  cursor: pointer;
}
.add-block-title {
  color: #ffffff;
  background-color: #768DD3;
}
.option_modal__containers {
  width: min(600px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  min-height: 150px;
  position: fixed;
  margin-top: 100px;
  max-height: 400px;
}
.add_page_modal_wrapper,
.modal__wrapper__component {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  height: 100pc;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}
.toc-side-popup {
  position: absolute;
  text-align: left;
  top: 6px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: #fff;
  width: min(150px,90%);
  box-shadow: 3px 3px 3px hsla(0,0%,62.7%,.5);
  z-index: 200;
}

.toc-side-popup>div {
  cursor: pointer;
  padding: 0.5rem;
}

.toc-side-popup>div:hover {
  background-color: #e4eaf9;
}

.float-right .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 15px;
  top: -15px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.plan-side-popup>div {
  cursor: pointer;
  padding: 0.5rem;
}

.plan-side-popup>div:hover {
  background-color: #e4eaf9;
}

.horizondalscroll {
  overflow-y: auto;
  max-height: 500px;
}

.modal__title {
  background-color: #618bd7;
  color: #fff;
}
.toc-side-popup-sub {
  position: absolute;
  text-align: left;
  right: 25px;
  top: 13px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(150px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}


.iframe-wrapper {
  position: fixed;
  display: grid;
  place-items: center;
  inset: 0;
  background: rgb(0 0 0 / 30%);
  transition: cubic-bezier;
  overflow: hidden;
  z-index: 10000;
}

.iframe-container {
  width: min(700px, 90%);
  background: #fff;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.iframe-block {
  width: 100%;
  height: 500px
}

.width-height {
  height: 500px;
  overflow: auto;
}

.toc-side-popup-sub > div {
  cursor: pointer;
  padding: 0.5rem;
}

.toc-side-popup-sub > div:hover {
  background-color: #e4eaf9;
}
.badge-grn {
  background-color: #1ab394;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}
.height-300{
  height:300px;
  overflow-y: scroll;
}
.left-220{
  left:220px;
}

.right-50{
  right:50px
}
</style>
