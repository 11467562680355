import { createStore } from 'vuex'

import authModule from "./modules/authenticationModule";
import selectStudyModule from "./modules/selectStudyModule";
import rolePrivilegeModule from "./modules/rolePrivilegeModule";
import savetemplateModule from "./modules/savetemplateModule";
import fieldQuerymodule from "./modules/fieldQuerymodule";
import folderModule from './modules/folderModule';
import siteModule from './modules/siteModule';
import selectQueryType from './modules/selectQueryType';
import formtransferModule from "./modules/formtransferModule";
import treeModule from "./modules/treeModule";
import viewformModule from "./modules/viewformModule";



const store = createStore({
  modules: {
    authModule,
    selectStudyModule,
    rolePrivilegeModule,
    savetemplateModule,
    fieldQuerymodule,
    folderModule,
    siteModule,
    selectQueryType,
    formtransferModule,
    viewformModule,
    treeModule,
     
  },
});

export default store;