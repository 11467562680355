/* eslint-disable */
// import {tokenAlive} from "@/Shared/jwtHelper";
const state = () => ({
  visitdetails: localStorage.getItem("visitdetails"),
  frompatientschedule: localStorage.getItem("frompatientschedule"),
  autofillsiteid: localStorage.getItem("autofillsiteiddata"),
  autofillsubjectid: localStorage.getItem("autofillsubjectiddata"),
  autofillenabled: localStorage.getItem("autofillenabled"),
});
const getters = {
  getvisitdetails(state: { visitdetails: any }) {
    return state.visitdetails;
  },
  getfrompatientschedule(state: { frompatientschedule: any }) {
    return state.frompatientschedule;
  },
  getautofillsiteid(state: { autofillsiteid: any }) {
    return state.autofillsiteid;
  },
  getautofillsubjectid(state: { autofillsubjectid: any }) {
    return state.autofillsubjectid;
  },
  getautofillenabled(state: { autofillenabled: any }) {
    return state.autofillenabled;
  },
};

const actions = {
  async commitvisitdetails({ commit }: any, data: any) {
    commit("setvisitdetails", data);
  },
  async setfrompatientschedule({ commit }: any, data: any) {
    commit("setfrompatientschedule", data);
  },
  async setautofillsiteid({ commit }: any, data: any) {
    commit("setautofillsiteid", data);
  },
  async setautofillsubjectid({ commit }: any, data: any) {
    commit("setautofillsubjectid", data);
  },
  async setautofillenabled({ commit }: any, data: any) {
    commit("setautofillenabled", data);
  },
};

const mutations = {
  setvisitdetails(state: { visitdetails: any }, templatevisitdata: any) {
    state.visitdetails = templatevisitdata;
    localStorage.setItem("visitdetails", templatevisitdata);
  },
  setfrompatientschedule(
    state: { frompatientschedule: any },frompatientscheduledata: any) {
    state.frompatientschedule = frompatientscheduledata;
    localStorage.setItem("frompatientschedule", frompatientscheduledata);
    },
  setautofillsiteid(state: { autofillsiteid: any }, autofillsiteiddata: any) {
    state.autofillsiteid = autofillsiteiddata;
    localStorage.setItem("autofillsiteiddata", autofillsiteiddata);
  },
  setautofillsubjectid(
    state: { autofillsubjectid: any },
    autofillsubjectiddata: any
  ) {
    state.autofillsubjectid = autofillsubjectiddata;
    localStorage.setItem("autofillsubjectiddata", autofillsubjectiddata);
  },
  setautofillenabled(state: { autofillenabled: any }, autofillenableddata: any) {
    state.autofillenabled = autofillenableddata;
    localStorage.setItem("autofillenabled", autofillenableddata);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
