/* eslint-disable */
import axios from "axios";
import VPagination from "@hennge/vue3-pagination";
import store from "@/store";
import moment from "moment";

export default {
  name: "SubjectManagement",
  components: {
    VPagination,
  },
  data() {
    return {
      subjects: [],
      editIndex: null,
      editableRow: {},
      baseurl: process.env.VUE_APP_Service_URL,
      idToken: "",
      protocolId: "",
      currentPage: 1,
      pageSize: 10,
      pageindex: 1,
      SortProperty: "CreatedAt",
      sortorder: 1,
      totalPages: 1,
      totalCount: 0,
      validationErrors: {},
      defaultdate:"",
      protocol : {},
    };
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    this.defaultTime = localStorage.getItem("timeformat");
    this.idToken = store.getters.getIdToken;
    this.protocolId = this.$route.query.id;
    await this.getProtocolbyId();
    if (this.protocolId) {
      await this.ListSubjects(this.protocolId);
    }
  },
  methods: {
    async ListSubjects(protocolId) {
      try {
        const response = await axios.get(
          `${this.baseurl}/management/studyprotocol/listdasubjects?protocolId=${protocolId}&pageindex=${this.pageindex}&pagesize=${this.pageSize}&SortProperty=${this.SortProperty}&SortOrder=${this.sortorder}`,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.subjects = response.data.results;
        this.subjects = response.data.results.map(subject => ({
          ...subject,
          enrolmentDate: moment(subject.enrolmentDate).format(this.defaultdate),
        }));
        this.totalCount = response.data.totalCount;
        this.totalPages = Math.ceil(this.totalCount / this.pageSize); 
      } catch (error) {
        console.error("Error in fetching subjects:", error);
      }
    },
    editRow(index, subject) {
      this.editIndex = index; 
      this.editableRow = { ...subject,enrolmentDate: moment(subject.enrolmentDate, this.defaultdate).isValid()
        ? moment(subject.enrolmentDate, this.defaultdate).format("YYYY-MM-DD")
        : "",};
      
      this.validationErrors = {};
    },
    validateRow() {
      this.validationErrors = {};
      let isValid = true; 
      if (!this.editableRow.siteCode) {
        this.validationErrors.siteCode = "Site Code is required.";
        isValid = false;
      }
      if (!this.editableRow.subjectID) {
        this.validationErrors.subjectID = "Subject ID is required.";
        isValid = false;
      }
      return isValid;
    },
    async updateRow(index,studyId) {
    
      try {
        if (!this.editableRow) {
          console.error("No editable row found.");
          return;
        }
        if (!this.validateRow()) {
          return; 
        }
        const formattedEnrolmentDate = moment(this.editableRow.enrolmentDate).format("YYYY-MM-DD");
        const updateData = {
          dA_SubId:index,
          subjectID: this.editableRow.subjectID,
          siteCode: this.editableRow.siteCode,
          enrolmentDate: formattedEnrolmentDate,
          investigatorEmail: this.editableRow.investigatorEmail,
          preVisit: this.editableRow.preVisit,
          postVisit: this.editableRow.postVisit,
          country: this.editableRow.country,
          city: this.editableRow.city,
          zipCode: this.editableRow.zipCode,
          languageCode: this.editableRow.languageCode,
          subjectNotes: this.editableRow.subjectNotes,
          createSchedule: this.editableRow.createSchedule,
          protocolRefId:this.protocolId,
          studyId:studyId
        };
    
        const response = await axios.put(
          `${this.baseurl}/management/studyprotocol/updatedasubject`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
          }
        );
    
        console.log("Update response:", response.data);
        this.subjects[index] = { ...this.editableRow };
        this.cancelEdit();
        alert("Subject details updated successfully");
        await this.ListSubjects(this.protocolId); 
        await this.getProtocolbyId();
      } catch (err) {
        console.error("Error during updateRow API call:", err);
        alert("Failed to update subject. Please try again.");
      }
    },
    
    cancelEdit() {
      this.editIndex = null;
      this.editableRow = null; 
    },
    clearFieldError(fieldName) {
      if (this.validationErrors[fieldName]) {
        delete this.validationErrors[fieldName]; 
      }
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageindex = page; 
      await this.ListSubjects(this.protocolId); 
    },
    async deleteRow(id) {
      try {
        const confirmation = confirm("Are you sure you want to delete this subject?");
        if (!confirmation) {
          return;
        }
    
        const response = await axios.delete(
          `${this.baseurl}/management/studyprotocol/deletebyid?collectionName=${'DaScrape_Subject'}&id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
          }
        );
    
        console.log("Delete response:", response.data);
        await this.ListSubjects(this.protocolId); 
    
        alert("Subject deleted successfully");
      } catch (error) {
        console.error("Error during subject delete API call:", error);
        alert("Failed to delete the subject. Please try again.");
      }
    },
    async getProtocolbyId(){
      const token = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/management/document/getprotocolbyid?protocolId=${this.protocolId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        console.log("protocol", res.data);
        this.protocol = res.data;
      }).catch(err => console.log("error in get protocol by id", err));
    }
  },
};
