/* eslint-disable */
const state = () => ({
    IsPatientTransferred: localStorage.getItem('IsPatientTransfer'),
    fromSiteName: localStorage.getItem('fromSite'),
    toSiteName: localStorage.getItem('toSite'),
    fromSiteId: localStorage.getItem('fromSiteId'),
    toSiteId: localStorage.getItem('toSiteId'),
    fromPatientName: localStorage.getItem('fromPatient'),
    fromPatientId: localStorage.getItem('fromPatientId'),
    tocGenerateBtn:false

});

const getters = {
    getIsPatientTransfer(state: { IsPatientTransferred: any }) {
        return state.IsPatientTransferred;
    },
    getFromSiteName(state: { fromSiteName: any }) {
        return state.fromSiteName;
    },
    getToSiteName(state: { toSiteName: any }) {
        return state.toSiteName;
    },
    getFromSiteId(state: { fromSiteId: any }) {
        return state.fromSiteId;
    },
    getToSiteId(state: { toSiteId: any }) {
        return state.toSiteId;
    },
    getFromPatientName(state: { fromPatientName: any }) {
        return state.fromPatientName;
    },
    getFromPatientId(state: { fromPatientId: any }) {
        return state.fromPatientId;
    },
    getTocGenrateBtn(state: { tocGenerateBtn: any }) {
        return state.tocGenerateBtn;
    },


};

const actions = {
    async setIsPatientTransfer({ commit }: any, data: any) {
        commit("setIsPatientTransfer", data);
    },
    async setFromSiteName({ commit }: any, data: any) {
        commit("setFromSiteName", data);
    },
    async setToSiteName({ commit }: any, data: any) {
        commit("setToSiteName", data);
    },
    async setFromSiteId({ commit }: any, data: any) {
        commit("setFromSiteId", data);
    },
    async setToSiteId({ commit }: any, data: any) {
        commit("setToSiteId", data);
    },
    async setFromPatientName({ commit }: any, data: any) {
        commit("setFromPatientName", data);
    },
    async setFromPatientId({ commit }: any, data: any) {
        commit("setFromPatientId", data);
    },
    async setTocGenerateBtn({ commit }: any, data: any) {
        commit("setTocGenerateBtn", data);
    },
};

const mutations = {
    setIsPatientTransfer(state: { IsPatientTransferred: any }, IsPatientTransferredData: any) {
        state.IsPatientTransferred = IsPatientTransferredData;
        localStorage.setItem("IsPatientTransfer", IsPatientTransferredData);
    },
    setFromSiteName(state: { fromSiteName: any }, siteNameData: any) {
        state.fromSiteName = siteNameData;
        localStorage.setItem("fromSite", siteNameData);
    },
    setToSiteName(state: { toSiteName: any }, toSiteNameData: any) {
        state.toSiteName = toSiteNameData;
        localStorage.setItem("toSite", toSiteNameData);
    },
    setFromSiteId(state: { fromSiteId: any }, fromSiteIdData: any) {
        state.fromSiteId = fromSiteIdData;
        localStorage.setItem("fromSiteId", fromSiteIdData);
    },
    setToSiteId(state: { toSiteId: any }, toSiteIdData: any) {
        state.toSiteId = toSiteIdData;
        localStorage.setItem("toSiteId", toSiteIdData);
    },
    setFromPatientName(state: { fromPatientName: any }, fromPatientData: any) {
        state.fromPatientName = fromPatientData;
        localStorage.setItem("fromPatient", fromPatientData);
    },
    setFromPatientId(state: { fromPatientId: any }, fromPatientIdData: any) {
        state.fromPatientId = fromPatientIdData;
        localStorage.setItem("fromPatientId", fromPatientIdData);
    },
    setTocGenerateBtn(state: { tocGenerateBtn: any }, tocGenerateBtnData: any) {
        state.tocGenerateBtn = tocGenerateBtnData;
        localStorage.setItem("tocGenerateBtn", tocGenerateBtnData);
    },

};

export default {
    state,
    getters,
    actions,
    mutations
}

