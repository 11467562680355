<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <h2>Manage Study</h2>
      <span class="float-right" v-if="!isEditable && protocol.status !== 'Generated'" @click="toggleEditMode"
        ><i class="fa fa-edit fs-5 edit-delete mr-2"></i>
        <div class="tool-tip">{{ $t("navigations.edit study") }}</div>
      </span>
    </div>
    <div class="wrapper wrapper-content">
      <div class="ibox-content p-3">
        <div class="form-group row">
          <div class="col-lg-4 mb-1">
            <label class="col-form-label"
              >Study ID <sup><i class="fa fa-asterisk imp"></i></sup>
            </label>
            <input
              type="text"
              class="form-control"
              maxlength="100"
              v-model="studyRef"
              :readonly="!isEditable"
              @input="clearFieldError('studyRef')"
            />
            <small v-if="errors.studyRef" class="text-danger">{{
              errors.studyRef
            }}</small>
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label"
              >Study Name<sup><i class="fa fa-asterisk imp"></i></sup
            ></label>
            <input
              type="text"
              maxlength="1000"
              class="form-control"
              v-model="studyName"
              :readonly="!isEditable"
              @input="clearFieldError('studyName')"
            />
            <small v-if="errors.studyName" class="text-danger">{{
              errors.studyName
            }}</small>
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">Sponsor</label>
            <input
              type="text"
              class="form-control"
              maxlength="1000"
              v-model="sponsor"
              :readonly="!isEditable"
            />
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">
              {{ $t("navigations.status") }}</label
            >
            <select
              class="form-select"
              name="account"
              v-model="status"
              :disabled="!isEditable"
            >
              <option>Archived</option>
              <option>Closed</option>
              <option>New</option>
              <option>Open</option>
            </select>
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">Pre-Visit</label>
            <input
              type="number"
              class="form-control"
              maxlength="1000"
              :min="0"
              @change="checknegative"
              v-model="preVisit"
              :readonly="!isEditable"
            />
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">Post-Visit</label>
            <input
              type="number"
              class="form-control"
              maxlength="1000"
              :min="0"
              @change="checknegative"
              v-model="postVisit"
              :readonly="!isEditable"
            />
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">Estimated No. of Sites</label>
            <input
              type="number"
              class="form-control"
              maxlength="1000"
              :min="0"
              @change="checknegative"
              v-model="numberOfSite"
              :readonly="!isEditable"
            />
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">Estimated No. of Subjects</label>
            <input
              type="number"
              class="form-control"
              maxlength="1000"
              :min="0"
              v-model="numberOfPatient"
              @change="checknegative"
              :readonly="!isEditable"
            />
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">Duration</label>
            <input
              type="number"
              class="form-control"
              maxlength="1000"
              :min="0"
              @change="checknegative"
              v-model="duration"
              :readonly="!isEditable"
            />
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">{{
              $t("navigations.study type")
            }}</label>
            <select
              class="form-select"
              v-model="studyType"
              :disabled="!isEditable"
            >
              <option value="" disabled selected>{{
                $t("navigations.select")
              }}</option>
              <template v-for="classification in classficationList">
                <template
                  v-if="
                    classification.classifier === 'Study Design (Allocation)'
                  "
                >
                  <option
                    v-for="studyTypes in classification.classifications"
                    :key="studyTypes"
                  >
                    {{ studyTypes }}
                  </option>
                </template>
              </template>
            </select>
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">Allocation</label>
            <select
              class="form-select"
              v-model="allocation"
              :disabled="!isEditable"
            >
              <option value="" disabled selected>{{
                $t("navigations.select")
              }}</option>
              <template v-for="classification in classficationList">
                <template v-if="classification.classifier === 'Allocation'">
                  <option
                    v-for="allocationOption in classification.classifications"
                    :key="allocationOption"
                  >
                    {{ allocationOption }}
                  </option>
                </template>
              </template>
            </select>
          </div>

          <div class="col-lg-4 mb-1">
            <label class="col-form-label">Intervention Model</label>
            <select
              class="form-select"
              v-model="interventionModel"
              :disabled="!isEditable"
            >
              <option value="" disabled selected>{{
                $t("navigations.select")
              }}</option>
              <template v-for="classification in classficationList">
                <template
                  v-if="
                    classification.classifier ===
                      'Nature of Interventions or Exposures'
                  "
                >
                  <option
                    v-for="interventionOption in classification.classifications"
                    :key="interventionOption"
                  >
                    {{ interventionOption }}
                  </option>
                </template>
              </template>
            </select>
          </div>

          <div class="col-lg-4 mb-1">
            <label class="col-form-label">Masking</label>
            <select
              class="form-select"
              v-model="masking"
              :disabled="!isEditable"
            >
              <option value="" disabled selected>{{
                $t("navigations.select")
              }}</option>
              <template v-for="classification in classficationList">
                <template v-if="classification.classifier === 'Masking'">
                  <option
                    v-for="maskingOption in classification.classifications"
                    :key="maskingOption"
                  >
                    {{ maskingOption }}
                  </option>
                </template>
              </template>
            </select>
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">Primary Purpose</label>
            <select
              class="form-select"
              v-model="primaryPurpose"
              :disabled="!isEditable"
            >
              <option value="" disabled selected>{{
                $t("navigations.select")
              }}</option>
              <template v-for="classification in classficationList">
                <template
                  v-if="classification.classifier === 'Primary Purpose'"
                >
                  <option
                    v-for="primaryPurposeOption in classification.classifications"
                    :key="primaryPurposeOption"
                  >
                    {{ primaryPurposeOption }}
                  </option>
                </template>
              </template>
            </select>
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">Official Title</label>
            <input
              type="text"
              class="form-control"
              maxlength="1000"
              v-model="officialTitle"
              :readonly="!isEditable"
            />
          </div>
          <div class="col-lg-8 mb-1">
            <label class="col-form-label">Study Notes </label>
            <textarea
              type="text"
              class="form-control"
              maxlength="1000"
              rows="3"
              cols="10"
              v-model="studyNotes"
              :readonly="!isEditable"
            />
          </div>
          <div class="col-lg-9"></div>
          <div class="col-lg-3 my-3 d-flex justify-content-lg-end">
            <button
              v-if="isEditable"
              class="cancel_btn py-1 px-4 mx-3"
              type="button"
              @click="clear()"
            >
              {{ $t("navigations.clear") }}
            </button>
            <button
              v-if="isEditable"
              class="save_btn py-1 px-4"
              type="button"
              @click="save()"
            >
              {{ $t("navigations.update") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.field-size {
  height: 38px;
  padding: 5px 10px;
  box-sizing: border-box;
}

.cancel_btn,
.save_btn {
  width: 50%;
}
.text-danger {
  font-size: 0.875rem;
  color: red;
}
label.col-form-label {
  color: black;
}
.update_btn {
  background-color: #145faf;
  color: white;
  border: none;
  cursor: pointer;
}

.update_btn:hover {
  background-color: #0056b3;
}
.float-right .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 50px;
  top: -25px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.float-right:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
</style>
<script src="./StudyManagment.js"></script>
