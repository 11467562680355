<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-10">
      <h2>{{ $t('navigations.Protocol data scraping') }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 px-1 pl-lg-0">
            <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0">File Name</label>
            <input v-model="searchData.fileName" type="text" maxlength="1000" class="form-control my-md-2 my-lg-0"  />
          </div>
          </div>
          <!-- <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0">Uploaded By</label>
            <input v-model="searchData.uploadedBy" type="text" maxlength="1000" class="form-control my-md-2 my-lg-0"  />
          </div> -->
          <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0">{{ $t("navigations.status") }}</label>
            <select v-model="searchData.status" class="form-select my-md-2 my-lg-0" name="account" placeholder="Search">
              <option selected value="">{{ $t("navigations.all") }}</option>
              <option value="Success">Success</option>
              <!-- <option value="Failed">Failed</option> -->
              <option value="Data conversion failed">Data conversion failed</option>
              <option value="Upload failed in datalake">Upload failed in datalake</option>
              <option value="Upload failed in DMS">Upload failed in DMS</option>
              <option value="Response upload failed">Response upload failed</option>
            </select>
          </div>
          <span typr="button" class="col-sm-12 col-lg-2 save_btn my-1 my-lg-0 mx-lg-1 align-self-end"
            @click.prevent="ListProtocol()">
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5> {{ $t('navigations.file list') }}</h5>
        <div class="d-flex align-items-center">
          <div class="position-relative align-self-center mr-3" aria-label="Upload subject file" tabindex="0">
            <input class="d-none" accept=".xls,.xlsx" id="InPutFile" type="file" />
            <img width="17" height="17" class="cursor-pointer" src="../../assets/img/upload.png" alt="Upload site"
              @click.prevent="openUploadModal()" />
            <div class="tool-tip">Upload Study Protocol</div>
          </div>
          <div class="position-relative cursor-pointer" v-if="protocolList.length != 0"
            @click.prevent="showFilter = !showFilter">
            <img src="../../assets/img/filter.png" width="17" height="17" alt="filter table" />
            <div class="tool-tip">{{ $t("navigations.filter") }}</div>
          </div>
        </div>
      </div>

      <div class="ibox-content">
        <div class="d-flex justify-content-end px-0 mb-2" v-if="showFilter">
          <div class="col-2 px-0" v-on:clickout="dropdownOpen = false">
            <div class=" table-multi-select-dropdown" @click.stop="dropdownOpen = !dropdownOpen;">
              <div class="selected-options">
                <span>{{ $t("navigations.select columns") }}</span>
              </div>
              <div class="dropdown-arrow"></div>
            </div>
            <div v-if="dropdownOpen" class="table-dropdown-content" @click.stop>
              <label v-for="(column, index) in getColumns" :key="index">
                <input type="checkbox" v-model="selectedcolumnobj[column]" :value="true" /> {{ column }}
              </label>
            </div>
          </div>
        </div>
        <div class="text-center" v-if="protocolList.length === 0">
          No data available
        </div>
        <div class="table-responsive" v-else>
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th v-if="selectedcolumnobj['File Name']" class="sort_block" @click="SortSelected('fileName', 1)"
                  id="1">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t('navigations.file name') }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Uploaded By']" class="sort_block" @click="SortSelected('CreatedUserEmail', 2)"
                  id="2">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t('navigations.uploaded by') }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Uploaded Date']" class="sort_block"
                  @click="SortSelected('CreatedAt', 3)" id="3">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t('navigations.uploaded date') }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="selectedcolumnobj['Status']" class="sort_block" @click="SortSelected('UploadStatus', 4)" id="4">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t('navigations.status') }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="text-center sort_block" v-if="selectedcolumnobj" id="5">
                  <div> {{ $t('navigations.protocol document') }} </div>
                </th>
                <th class="sort_block text-center" v-if="selectedcolumnobj" id="6">
                  <div> {{ $t('navigations.output document') }} </div>
                </th>
                  <th class="sort_block text-center" v-if="selectedcolumnobj" id="7">
                  <div> {{ $t('navigations.view') }} </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="file in protocolList" :key="file.id">
                <td v-if="selectedcolumnobj['File Name']" class="wrap-text">{{ file.fileName }}</td>
                <td v-if="selectedcolumnobj['Uploaded By']">{{ file.uploadedBy }}</td>
                <td v-if="selectedcolumnobj['Uploaded Date']">{{ filterDate(file.uploadedDateTime) }} {{
                  filterTime(file.uploadedDateTime) }} </td>
                <td v-if="selectedcolumnobj['Status']">{{ file.status }}</td>
                <td v-if="selectedcolumnobj && file.uploadDocRefId !== ''" class="text-center">
                  <i class="fa fa-download edit-delete"
                    @click="downloadFile(file.uploadDocRefId, file.uploadDocVersionId)">
                  </i>
                </td>
                <td v-if="selectedcolumnobj && file.uploadResultRefId !== '' " class="text-center">
                  <i class="fa fa-download edit-delete"
                    @click="downloadFile(file.uploadResultRefId, file.uploadResultVersionId)">

                  </i>
                </td>
                   <td class="text-center" v-if="file.uploadResultRefId !== ''">
                    <i class="fa fa-eye edit-delete" @click="goToViewProtocol(file.id)"
                @click.prevent="close"
                ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <!------ pagination -------->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  ">
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination v-model="currentPage" :pages="totalPages" :range-size="0" active-color="#618bd7"
                    @update:modelValue="loadPage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Upload study protocol modal Starts -->
  <div v-if="showUploadModal == true" class="modal_wrapper">
    <div class="modal_container">
      <div class="modal_title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0"> Upload Study Protocol </h4>
        <div class="cursor-pointer" @click.prevent="closeModal()">
          <i class="fa fa-times" title="Close"></i>
        </div>
      </div>
      <div class="modal__upload__content d-flex flex-column justify-content-between pb-4 pt-2 px-4">
        <div class="align-items-center">
        </div>
        <div class="input-group">
          <input accept=".pdf" id="InPutFile" type="file" class="form-control my-2 my-lg-0" @change="previewFiles" :disabled="showLoading" />
          <span class="input-group-append">
            <button type="button" class="stdy_upload_btn mr-0 py-1" @click="studyDataExport()" :disabled="showLoading">
              <div class="d-flex align-items-center" v-if="showLoading">
                <img class="loading-img" width="20" src="../../assets/img/loading.webp"
                  alt="Wait while the document is added" />
              </div>
              <div v-else>{{ "Upload" }}</div>
            </button>
          </span>
        </div>
        <span class="errorClass" v-if="showValidation">{{ $t("Please select a file") }} </span>
      </div>
    </div>
  </div>
  <!-- Upload study protocol modal Ends -->
</template>
<style scoped>
.wrap-text {
  word-wrap: break-word;
  white-space: normal;
}

</style>
<script src="./uploadStudyProtocol.js"></script>