<template>
  <div class="stat-card-white" ref="captureArea">
    <div class="stat-header-white">
      <span>{{ computedChartLabel }}</span>
      <div v-show="!loader" class="d-flex align-items-center" v-if="!loader">
        <div
          v-if="!loader"
          class="expand-block px-2 mb-1 cursor-pointer position-relative"
        >
          <img
            @click="showtable()"
            src="../../assets//img/table_view.png"
            alt="More block"
          />
          <div class="more-expand">
            <span>Data Overview</span>
          </div>
        </div>
        <div
          class="expand-block px-2 mb-1 cursor-pointer"
          @click.prevent="expandBlock = true"
        >
          <img src="../../assets//img/expand.png" alt="Expand block" />
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false,exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="stat-body-white d-flex flex-column"
      :class="{ 'h-100': loader }"
    >
      <div v-if="loader" class="loader"></div>
      <div
        v-if="!loader && selectedGroups.length != 0"
        class="selectdrill d-flex flex-row-reverse gap-2 w-100"
      >
        <img
          class="cursor-pointer"
          src="../../assets/img/chart_cancel.png"
          @click.prevent="cancelselection()"
          alt="Save profile"
        />
        <img
          class="cursor-pointer"
          src="../../assets/img/chart_submit.png"
          @click.prevent="recreatechart()"
          alt="Save profile"
        />
      </div>
      <div
      ref="containerWrapper"
       v-show="!loader"  class="w-100 overflow-auto">
        <svg ref="chartContainer"></svg>
        <div ref="tooltip" class="tooltip" style="opacity: 0;"></div>
      </div>
      <div v-if="colorcodes.length!=0" class="d-flex w-100">
          <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
          <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
          {{ colors.type }}
          </div>
        </div>
    </div>
  </div>

  <enlargerpopup
    v-if="expandBlock == true"
    @closeModal="(expandBlock = false), createStackedBarChart()"
  >
    <div class="stat-body-white d-flex flex-column">
      <div
        v-if="!loader && selectedGroups.length != 0"
        class="selectdrill d-flex flex-row-reverse gap-2 w-100"
      >
        <img
          class="cursor-pointer"
          src="../../assets/img/chart_cancel.png"
          @click.prevent="cancelselection()"
          alt="Save profile"
        />
        <img
          class="cursor-pointer"
          src="../../assets/img/chart_submit.png"
          @click.prevent="recreatechart()"
          alt="Save profile"
        />
      </div>
      <svg ref="popupChartContainer"></svg>
    </div>
  </enlargerpopup>
</template>

<script>
import * as d3 from "d3";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
export default {
  name: "StackedBarChart",
  components: {
    enlargerpopup,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    colors: {
        type: Object,
        required: true,
        default: () => ({
          "mild": '#A0D568',
    "moderate": '#FFCE54',
    "severe": '#FF6663'
        }),
      },
    colorcodes: { type: Array, default: () => [] },
    availableColumns: {
      type: Array,
      required: true,
      default: () => [],
    },
    loader: {
      type: Boolean,
      default: false,
    },
    xAxisLabel: {
      type: String,
      default: '',
    },
    yAxisLabel: {
      type: String,
      default: '',
    },
    xAxisKey: {
      type: String,
      default: "",
    },
    yAxisKey: {
      type: String,
      default: "subjects",
    },
    stacks:{
      type: Array,
      required: true,
      default: () => [],
},
    segmentKey: {
      type: String,
      default: "DTHCAUS",
    },
    chartwidth: {
      type: Number,
      default: 600,
    },
    chartheight: {
      type: Number,
      default: 400,
    },
    chartlabel: {
      type: String,
      default: "",
    },
    fromPage: {
      type: String,
      default: "",
    },
  },
  computed: {
    computedChartLabel() {
      return this.chartlabel.replace("$$", this.selectedXAxis || "");
    },
  },
  data() {
    return {
      showVisitPopup: false,
      expandBlock: false,
      selectedXAxis: "",
      selectedYAxis: "subjects",
      selectedSegment: "",
      chartContainer: null,
      tooltip: null,
      resizeObserver: null,
      barselectedarray: [],
      sortdata: [],
      filterkeyword: "",
      filterarray: [],
      selectedGroups: [],
      segmentgropup: [],
      keygroup: [],
      groupedData:[],
      color: {},
      mouseX: 0,
      mouseY: 0,
    };
  },
  mounted() {
    window.addEventListener("mousemove", this.updateMousePosition);
    this.selectedXAxis = this.xAxisKey;
    this.selectedYAxis = this.yAxisKey;
    this.selectedSegment = this.segmentKey;
    this.chartContainer = this.$refs.chartContainer;
    this.tooltip = this.$refs.tooltip;
    this.createStackedBarChart();

    // Add resize observer for both main and popup charts
    this.resizeObserver = new ResizeObserver(() =>
      this.createStackedBarChart()
    );
    this.resizeObserver.observe(this.$refs.chartContainer);

    if (this.$refs.popupChartContainer) {
      this.resizeObserver.observe(this.$refs.popupChartContainer);
    }
  },
  watch: {
    data: {
      handler(){
        this.cancelselection();
        this.createStackedBarChart();
      },
      deep: true,
    },
    selectedXAxis() {
      this.cancelselection();
      this.createStackedBarChart();
    },
    selectedYAxis() {
      this.createStackedBarChart();
    },
    selectedSegment() {
      this.createStackedBarChart();
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createStackedBarChart();
        });
      }
    },
  },
  methods: {
    updateMousePosition(event) {
      // Get the bounding rectangle of the component
      if (this.$refs.chartContainer) {
        const rect = this.$refs.chartContainer.getBoundingClientRect();

        // Calculate the mouse X and Y positions relative to the component
        this.mouseX = event.clientX - rect.left;
        this.mouseY = event.clientY - rect.top;
      } else {
        return;
      }
    },
    showtable(){
      this.showMore=false
      if(this.fromPage=='studySummary')
      {
        const uniqueData = this.data.reduce((acc, current) => {
            const uniqueKey = `${current.SITECODE}_${current.STUDYREF}`;
              if (current.SITECODE !== null && !acc.some(item => `${item.SITECODE}_${item.STUDYREF}` === uniqueKey)) { 
                  acc.push(current);
              }
                    return acc;
        }, [])
        this.$emit("opendrillpopup", uniqueData.map(item => {
              const newItem = { ...item }; // Create a shallow copy of the object
              delete newItem.STUDYID; // Remove the unwanted key
              return newItem; // Return the new object without the unwanted key
          }),"site")
      }
      else{
        this.$emit("opendrillpopup", this.data)
      }
     
    },
    backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
    async exporttoexcell() {
      if(this.fromPage=='studySummary'){
        const filteredData=this.data.map(item => {
              const newItem = { ...item }; // Create a shallow copy of the object
              delete newItem.FORMID; // Remove the unwanted key
              return newItem; // Return the new object without the unwanted key
          })
          const uniqueData = filteredData.reduce((acc, current) => {
            const uniqueKey = `${current.SITECODE}_${current.STUDYREF}`;
              if (current.SITECODE !== null && !acc.some(item => `${item.SITECODE}_${item.STUDYREF}` === uniqueKey)) {
                  acc.push(current);
              }
                    return acc;
        }, [])
          const worksheet = XLSX.utils.json_to_sheet(uniqueData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        let filename = this.computedChartLabel || "chart_data";
        XLSX.writeFile(workbook, filename + ".xlsx");
      }
      else{
        this.$emit("exporttoexcell");
      }
    
    },
    exporttopdf() {
      // Create a temporary container
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.captureArea.cloneNode(true);
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '100%';

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      if(this.fromPage=='studySummary')
      {
        let filename = this.computedChartLabel || "chart_data";
        const opt = {
          margin: 0,
          filename: `${filename}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
        };
      
        // Convert the temporary container to PDF
        html2pdf().set(opt).from(pdfContainer).save();
      } 
      else{
        let filename = this.computedChartLabel || "chart_data";
        const opt = {
          margin: 0,
          filename: `${filename}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
        };
      
        // Convert the temporary container to PDF
        html2pdf().set(opt).from(pdfContainer).save();
      }

    },
    exportAsImage() {
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const chartElement = this.$refs.captureArea;
      html2canvas(chartElement).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'chart.png'; // Set the file name
        link.click();

      });
    },
    captureImage() {
      const element = this.$refs.captureArea;
      html2canvas(element).then(canvas => {
      const imgData = canvas.toDataURL(); 
      localStorage.setItem('componentImage', imgData); 
      });
    },
    async cancelselection() {
      this.selectedGroups = [];
      this.sortdata = [];
      this.createStackedBarChart();
    },
    createStackedBarChart() {
  const chartContainer = this.expandBlock
    ? this.$refs.popupChartContainer
    : this.$refs.chartContainer;
  if (!chartContainer || !this.tooltip) return;

  // Clear any existing chart elements
  d3.select(chartContainer).selectAll("*").remove();

  const margin = { top: 30, right: 100, bottom: 70, left: 60 };
  
  // Calculate width and height dynamically based on container size
  const containerWidth = this.$refs.containerWrapper.getBoundingClientRect().width;
  const width = containerWidth - margin.left - margin.right;
  const height = this.chartheight - margin.top - margin.bottom;

  const svg = d3.select(chartContainer)
    .attr("width", containerWidth)
    .attr("height", height + margin.top + margin.bottom)
    .attr("viewBox", `0 0 ${containerWidth} ${this.chartheight}`)
    .attr("preserveAspectRatio", "xMinYMin meet")
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  // Extract the keys for stacking (mild, moderate, severe, etc.)
  const stackKeys = this.stacks; 
  
  // X domain is based on distinct xAxisKey values (e.g., siteName)
  const x = d3.scaleBand()
    .domain(this.data.map(d => d[this.xAxisKey]))
    .range([0, width])
    .padding(0.2);

  // Y domain is based on the max sum of the stack values
  const y = d3.scaleLinear()
    .domain([0, d3.max(this.data, d => d3.sum(stackKeys.map(k => d[k])))])
    .nice()
    .range([height, 0]);

  // Create stacked data
  const stack = d3.stack().keys(stackKeys)(this.data);

  // Add X-axis
  svg.append("g")
    .attr("transform", `translate(0,${height})`)
    .call(d3.axisBottom(x))
    .selectAll("text")
    .text(d => d.length > 13 ? d.slice(0,10) + "..." : d)
    .attr("transform", "translate(-10,0)rotate(-45)")
    .style("text-anchor", "end")
    .append("title")
    .text(d => d); // Show full text on hover

  // Add Y-axis
  svg.append("g")
    .call(d3.axisLeft(y));

  const tooltip = this.$refs.tooltip;

  // Add stacked bars
  svg.selectAll("g.layer")
    .data(stack)
    .enter()
    .append("g")
    .attr("class", "layer")
    .attr("fill", d => this.colors[d.key]) // Assign color based on stack key
    .selectAll("rect")
    .data(d => d)
    .enter().append("rect")
    .attr("x", d => x(d.data[this.xAxisKey]))
    .attr("y", d => y(d[1]))
    .attr("height", d => y(d[0]) - y(d[1]))
    .attr("width", x.bandwidth())
    .on("mouseover", (event, d) => {
      const segment = d3.select(event.currentTarget.parentNode).datum().key;
      d3.select(tooltip)
        .style("opacity", 1)
        .html(`
          ${this.xAxisKey} : ${d.data[this.xAxisKey]}<br>
          ${segment} : ${d.data[segment]}
        `)
      // Position tooltip
      d3.select(tooltip)
        .style("left", `${this.mouseX}px`)
        .style("top", `${this.mouseY}px`);
    })
    .on("mouseout", () => {
      d3.select(tooltip).style("opacity", 0);
    })
    .on("mousemove", (event) => {
      d3.select(tooltip)
        .style("left", `${this.mouseX}px`)
        .style("top", `${this.mouseY}px`);
    });

  // Add X-axis label
  svg.append('text')
    .attr('text-anchor', 'middle')
    .attr('x', width / 2)
    .attr('y', height + 60)
    .attr("font-size", "13px")
    .text(this.xAxisLabel)

  // Add Y-axis label
  svg.append('text')
    .attr('text-anchor', 'middle')
    .attr('transform', 'rotate(-90)')
    .attr('x', -height / 2)
    .attr('y', -margin.left + 10)
    .attr("font-size", "13px")
    .text(this.yAxisLabel);
},
    clickbarinchart(filteredData, filteredkeyword) {
      if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedGroups = this.selectedGroups.filter(
          (k) => k !== filteredkeyword
        );
        let result = this.sortdata.filter(
          (item) => !filteredData.includes(item)
        );
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(
          (item) => `(${filteredkeyword})` != item
        );
        this.filterarray = filterarraydeleted;
        this.filterkeyword = this.selectedGroups;
      } else {
        this.selectedGroups.push(filteredkeyword);
        this.filterarray.push(`(${filteredkeyword})`);
        this.filterkeyword = this.selectedGroups;
        this.sortdata = this.sortdata.concat(filteredData);
      }
      this.createStackedBarChart();
    },
    recreatechart() {
      // this.$emit("opendrillpopup", this.sortdata);
      const dataToEmit = {
        sortedData: this.sortdata,
        filterkeyword: this.filterkeyword,
      };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
    sendselecteddata(filteredData, segment) {
      const dataToEmit = {
        sortedData: filteredData,
        filterkeyword: `${this.selectedXAxis}-${segment}`,
      };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
  },
};
</script>

<style scoped>
@import "../../custom_lib/AiCharts/charts.css";

.bar-chart-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
}

svg {
  font-size: 10px;
}

text.bar-label {
  fill: black;
  font-size: 12px;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.plan-side-popup {
  position: absolute;
  text-align: left;
  right: 56px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(124px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.plan-side-popup > div {
  cursor: pointer;
  padding: 0.5rem;
}

.plan-side-popup > div:hover {
  background-color: #e4eaf9;
}
.biggerfont {
  font-size: 16px;
}
.legandbox {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}
</style>
