/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
export default {
  name: "sdtm",
  components: {
    Multiselect,
  },

  data() {
    return{
        baseapi:process.env.VUE_APP_Service_URL,
        uploadedFile:null,
        showLoading:false,
        validations:[false]
    }   
  },
  methods:{
    previewFiles(event) {
      const file = event.target.files[0]; 
        if (file) {
          this.uploadedFile = file; // Assign it to the reactive property
        } else {
          this.uploadedFile = null;
        }
    },
     async validateNull() {
      if (
        this.uploadedFile != "" &&
        this.uploadedFile !== null &&
        this.uploadedFile !== undefined
      ) {
        this.validations[0] = false;
      } else {
        this.validations[0] = true;
      }
    },
    async uploadDocument(){
            await this.validateNull();
            if (!this.validations[0]) {
              this.showLoading = true;
              const idtoken = store.getters.getIdToken;
      
              const formData = new FormData();
           
              formData.append("body", this.uploadedFile);
              formData.append("sdtm_file", this.uploadedFile);
              await axios
                .post(`${this.baseapi}/forms-ml/sdtmtoadam/uploadsdtmfile/`, formData, {
                  headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "multipart/form-data",
                  },
                  responseType: "blob",
                })
                .then((res) => {    
                  const disposition = res.headers["content-disposition"];
                  let fileName = "study_results.xlsx";
                  if (disposition && disposition.indexOf("filename=") !== -1) {
                    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
                    if (matches != null && matches[1]) {
                      fileName = matches[1].replace(/['"]/g, "");
                    }
                  }
                  this.showLoading = false;
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", fileName);
                  document.body.appendChild(link);
                  link.click();
                  alert(this.$t("navigations.ADaM file generated successfully"));
                })
                .catch((err) => {
                  this.showLoading = false;
                  if (err.response && err.response.data instanceof Blob) {
                    err.response.data
                      .text()
                      .then((blobText) => {
                        const errorData = JSON.parse(blobText);
                        console.log("Error while importing study:", errorData);
      
                        // Check for DomainValidations and handle specific errors
                        if (errorData && errorData.detail) {
                          const validationErrors = errorData.detail;
      
                          // Handle specific error messages
                          if (validationErrors.includes("400: Conversion failed")) {
                            alert("Conversion failed");
                          }  else {
                            alert("Something went wrong");
                          }
                        } else {
                          alert("Something went wrong");
                        }
                      })
                      .catch((blobError) => {
                        alert("Something went wrong");
                      });
                  } 
                });
            }
          }
    }
}