<template>
  <div class="form-check ml-2" :class="[classname,{'cursor-pointer':  sourceValue===true,}]">
  <input type="radio"
  class="form-check-input"
  :class="{classname,'cursor-pointer':  sourceValue===true}"
  :disabled="privilege == 'View' || fromarchive == 'true' || fielddisabled==true"
  :checked ="modelValue === value"
  :value="value"
  @change="$emit('update:modelValue',value)"
  v-bind="$attrs"
  />
  <label v-if="label" class="form-check-label">{{label}}</label>
</div>
</template>

<script>
export default{
  props:{
    sourceValue:{
        type: Boolean,
        default: false
    },
    fielddisabled:{
        type: Boolean,
        default: false
    },
    label:{
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default:''
    },
    value:{
      type: [String, Number],
      default: ''
    },
     privilege:{
        type: String,
        default: ''
      },
       fromarchive: {
        type: String,
        default: 'false'
      }
  }
}
</script>
