<template>
  <div class="wrapper wrapper-content">
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5>{{ $t("navigations.subject list") }}</h5>
      </div>
      <div class="ibox-content">
        <div v-if="subjects.length === 0" class="no-data-message text-center">
          <p>No data available.</p>
        </div>
        <div v-else>
          <div class="table-responsive">
            <table
              class="table table-striped table-bordered"
              style="table-layout: fixed; width: 200%;"
            >
              <thead>
                <tr>
                  <th style="width: 10%;">
                    Site Code <sup><i class="fa fa-asterisk imp"></i></sup>
                  </th>
                  <th style="width: 10%;">
                    Subject ID <sup><i class="fa fa-asterisk imp"></i></sup>
                  </th>
                  <th style="width: 15%;">Enrolment Date</th>
                  <th style="width: 20%;">Investigator Email</th>
                  <th style="width: 10%;">Pre-visit</th>
                  <th style="width: 10%;">Post-visit</th>
                  <th style="width: 15%;">Country</th>
                  <th style="width: 20%;">City</th>
                  <th style="width: 10%;">Post/Zip Code</th>
                  <th style="width: 10%;">Language Code</th>
                  <th style="width: 20%;">Subject Notes</th>
                  <th style="width: 10%;">Create Schedule?</th>
                  <th style="width: 5%;" class="text-center sticky-column">
                    Edit
                  </th>
                  <th style="width: 5%;" class="text-center">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(subject, index) in subjects"
                  :key="subject.subjectId"
                >
                  <td v-if="editIndex !== index">{{ subject.siteCode }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.siteCode"
                      class="form-control"
                      type="text"
                      @input="clearFieldError('siteCode')"
                    />
                    <div v-if="validationErrors.siteCode" class="text-danger">
                      {{ validationErrors.siteCode }}
                    </div>
                  </td>
                  <td v-if="editIndex !== index">{{ subject.subjectID }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.subjectID"
                      class="form-control"
                      type="text"
                      @input="clearFieldError('subjectID')"
                    />
                    <div v-if="validationErrors.subjectID" class="text-danger">
                      {{ validationErrors.subjectID }}
                    </div>
                  </td>
                  <td v-if="editIndex !== index">
                    {{ subject.enrolmentDate }}
                  </td>
                  <td v-else>
                  
                    <input
                      v-model="editableRow.enrolmentDate"
                      class="form-control"
                      type="date"
                    />
                  </td>
                  <td v-if="editIndex !== index">
                    {{ subject.investigatorEmail }}
                  </td>
                  <td v-else>
                    <input
                      v-model="editableRow.investigatorEmail"
                      class="form-control"
                      type="email"
                    />
                  </td>
                  <td v-if="editIndex !== index">{{ subject.preVisit }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.preVisit"
                      class="form-control"
                      type="text"
                    />
                  </td>
                  <td v-if="editIndex !== index">{{ subject.postVisit }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.postVisit"
                      class="form-control"
                      type="text"
                    />
                  </td>
                  <td v-if="editIndex !== index">{{ subject.country }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.country"
                      class="form-control"
                      type="text"
                    />
                  </td>
                  <td v-if="editIndex !== index">{{ subject.city }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.city"
                      class="form-control"
                      type="text"
                    />
                  </td>
                  <td v-if="editIndex !== index">{{ subject.zipCode }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.zipCode"
                      class="form-control"
                      type="text"
                    />
                  </td>
                  <td v-if="editIndex !== index">{{ subject.languageCode }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.languageCode"
                      class="form-control"
                      type="text"
                    />
                  </td>
                  <td v-if="editIndex !== index">
                    <div class="wrap-text">{{ subject.subjectNotes }}</div>
                  </td>
                  <td v-else>
                    <textarea
                      v-model="editableRow.subjectNotes"
                      class="form-control"
                      type="text"
                      :cols="30"
                      :rows="3"
                      style="width: 100%;"
                    ></textarea>
                  </td>
                  <td v-if="editIndex !== index">
                    {{ subject.createSchedule }}
                  </td>
                  <td v-else>
                    <input
                      v-model="editableRow.createSchedule"
                      class="form-control"
                      type="text"
                    />
                  </td>
                  <td class="text-center sticky-column">
                    <template v-if="editIndex !== index">
                      <i
                        class="fa fa-edit edit-delete"
                        v-if="protocol.status !== 'Generated'"
                        @click.prevent="editRow(index, subject)"
                      ></i>
                    </template>
                    <template v-else>
                      <i
                        class="fa fa-check action-icon text-success"
                        @click="updateRow(subject.id, subject.studyId)"
                        title="Update"
                      ></i>
                      <i
                        class="fa fa-times action-icon text-danger"
                        @click="cancelEdit"
                        title="Cancel"
                      ></i>
                    </template>
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-trash edit-delete"
                      v-if="protocol.status !== 'Generated'"
                      style="color: #a13b7c"
                      @click="deleteRow(subject.id)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Pagination  -->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  "
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="currentPage"
                    :pages="totalPages"
                    :range-size="0"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.action-icon {
  cursor: pointer;
  font-size: 18px;
  margin: 0 5px;
}

.action-icon:hover {
  opacity: 0.8;
}

.text-success {
  color: #28a745;
}

.text-danger {
  color: #dc3545;
}
.sticky-column {
  position: sticky;
  right: 0;
  background-color: #fff;
  z-index: 10;
  border-left: 1px solid #dee2e6;
}
.wrap-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 400px;
  overflow: hidden;
}

textarea.form-control {
  resize: vertical;
  max-height: 150px;
}
</style>
<script src="./SubjectManagement.js"></script>
