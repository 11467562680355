<template>
  <div ref="component" class="stat-card-white">
    <div class="stat-header-white">
      <span>{{ chartLabel }}</span>
      <div class="d-flex justify-content-end align-items-center">
        <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
          <img @click="showtable()" src="../../assets//img/table_view.png" alt="More block" />
          <div class="more-expand">
            <span>{{$t("navigations.data overview")}}</span>
          </div>
        </div>
        <div v-if="!loader" class="d-flex align-items-center">
          <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock = true">
            <img src="../../assets/img/expand.png" alt="Expand block" />
          </div>
          <div v-on:clickout="showVisitPopup = false">
            <div class="cursor-pointer biggerfont ml-2" @click.prevent="showVisitPopup = !showVisitPopup">
              <i class="fa fa-ellipsis-v"></i>
            </div>
            <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false; exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stat-body-white">
      <div v-if="loader" class="loader"></div>
      <div ref="chartContainerWrapper" class="w-100 overflow-auto">
        <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
          <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Cancel Selection"/>
          <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Apply Selection"/>
        </div>
        <svg ref="chartContainer" v-show="!loader"></svg>
        <div v-if="colorcodes.length!=0" class="d-flex">
          <div v-for="colors in colorcodes" :key="colors.type" class="mx-auto d-flex">
            <div class="legandbox" :style="`background-color: ${colors.color};`"></div>
            {{ colors.type }}
          </div>
        </div>
        <div ref="tooltip" class="tooltip"></div>
      </div>
    </div>
    <enlargerpopup v-if="expandBlock" @closeModal="expandBlock = false; createChart()">
      <div class="stat-body-white d-flex flex-column">
        <svg ref="popupChartContainer"></svg>
      </div>
    </enlargerpopup>
  </div>
</template>

<script>
import * as d3 from 'd3';
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";
import html2pdf from 'html2pdf.js';

export default {
  name: "comparativeLineChart",
  components: {
    enlargerpopup,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    xAxisValue: {
      type: String,
      default: "period",
    },
    xAxisLabel: {
      type: String,
      default: "Period",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    yAxisValue: {
      type: String,
      default: "",
    },
    groupCategoryValue: {
      type: String,
      default: "",
    },
    linegroups: {
      type: Array,
      required: true,
      default: () => ["mild","moderate","severe"],
    },
    chartLabel: {
      type: String,
      default: "Comparative Analysis",
    },
    custcolors: {
      type: Array,
      default: () => ['#A0D568','#FFCE54','#FF6663'],
    },
    colorcodes: {
      type: Array,
      default: () => [],
    },
    colors: {
        type: Object,
        required: true,
        default: () => ({
          "mild": '#A0D568',
    "moderate": '#FFCE54',
    "severe": '#FF6663'
        }),
      },
  },
  data() {
    return {
      expandBlock: false,
      showVisitPopup: false,
      tooltip: null,
      chartContainer: null,
      sortdata: [],
      filterkeyword: "",
      filterarray: [],
      selectedGroups: [],
      selectedKeywords: [],
      mouseX: 0,
      mouseY: 0,
      svg: null,
    };
  },
  watch: {
    data: {
      handler() {
        this.cancelselection();
        this.createChart();
      },
      deep: true,
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.updateMousePosition);
    this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
    this.tooltip = this.$refs.tooltip;
    this.createChart();
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
  },
  methods: {
    async exporttoexcell() {
      this.$emit("exporttoexcell");
    },
    exporttopdf() {
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.component.cloneNode(true);
      pdfContainer.appendChild(captureArea);
      pdfContainer.style.width = '100%';
      pdfContainer.style.display = 'block';

      const opt = {
        margin: 0,
        filename: `${this.chartLabel}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
      html2pdf().set(opt).from(pdfContainer).save();
    },
    showtable() {
      this.$emit("opendrillpopup", this.data);
    },
    async cancelselection() {
      this.selectedGroups = [];
      this.sortdata = [];
      this.createChart();
    },
    backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
    updateMousePosition(event) {
      if (this.$refs.component) {
        const rect = this.$refs.component.getBoundingClientRect();
        this.mouseX = event.clientX - rect.left;
        this.mouseY = event.clientY - rect.top;
      }
    },
    createChart() {
      this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;

      // Clear previous chart
      d3.select(this.chartContainer).selectAll("*").remove();

      if (!this.data || this.data.length === 0) return;

      const margin = { top: 20, right: 60, bottom: 80, left: 60 };
      const width = 500 - margin.left - margin.right;
      const height = 340 - margin.top - margin.bottom;

      // Extract all x-axis values
      const xValues = this.data.map(d => d[this.xAxisValue]);
      const xScale = d3.scalePoint()
        .domain(xValues)
        .range([0, width])
        .padding(0.5);

      // Determine the max y value from all linegroups
      const maxValue = d3.max(this.data, d => d3.max(this.linegroups, g => d[g]));
      const yScale = d3.scaleLinear()
        .domain([0, maxValue])
        .nice()
        .range([height, 0]);

      const svg = d3.select(this.chartContainer)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      // Line generator
      const lineGenerator = d3.line()
        .x(d => xScale(d.key))
        .y(d => yScale(d.value))
        .curve(d3.curveMonotoneX);

      // Color scale for lines
      const colorScale = d3.scaleOrdinal()
        .domain(this.linegroups)
        .range(this.custcolors);

      // For each group, prepare data
      this.linegroups.forEach(group => {
        const lineData = this.data.map(d => ({
          key: d[this.xAxisValue],
          value: d[group],
          originalData: d,
        }));

        // Draw line
        svg.append("path")
          .datum(lineData)
          .attr("fill", "none")
          .attr("stroke", colorScale(group))
          .attr("stroke-width", 2)
          .attr("d", lineGenerator);

        // Draw circles
        svg.selectAll(`circle-${group}`)
          .data(lineData)
          .enter()
          .append("circle")
          .attr("cx", d => xScale(d.key))
          .attr("cy", d => yScale(d.value))
          .attr("r", 4)
          .attr("fill", colorScale(group))
          .on("mouseover", (event, d) => {
            d3.select(this.tooltip)
              .style("opacity", 0.7)
              .html(`Category: ${group}<br/>Value: ${d.value}`)
              .style("left", `${this.mouseX + 20}px`)
              .style("top", `${this.mouseY - 28}px`);
          })
          .on("mouseout", () => d3.select(this.tooltip).style("opacity", 0))
          .on("mousemove", () => {
            d3.select(this.tooltip)
              .style("left", `${this.mouseX + 20}px`)
              .style("top", `${this.mouseY - 28}px`);
          })
          .on("click", (event, d) => {
            this.clicklinechart([d.originalData], d.key, group);
          });
      });

      // Add X axis
      svg.append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(xScale))
        .selectAll("text") 
        .style("text-anchor", "end") 
        .attr("transform", "rotate(-45)");

      // Add Y axis
      svg.append("g")
        .call(d3.axisLeft(yScale));

      // Add x-axis label
      svg.append("text")
        .attr("x", width / 2)
        .attr("y", height + margin.bottom -20)
        .attr("text-anchor", "middle")
        .text(this.xAxisLabel);

      // Add y-axis label
      svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("x", -height / 2)
        .attr("y", -margin.left + 10)
        .attr("text-anchor", "middle")
        .text("Monitoring Cost ($)");
    },
    async clicklinechart(filteredData, filteredkeyword, category) {
      if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedKeywords = this.selectedKeywords.filter((k) => k !== filteredkeyword + category);
        this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
        let result = this.sortdata.filter(item => !filteredData.includes(item));
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
        this.filterarray = filterarraydeleted;
        this.filterkeyword = `${this.xAxisValue}(${this.selectedGroups})`;
      } else {
        this.selectedGroups.push(filteredkeyword);
        this.selectedKeywords.push(filteredkeyword + category);
        this.filterarray.push(`(${filteredkeyword})`);
        this.filterkeyword = `${this.xAxisValue}(${this.selectedGroups})`;
        this.sortdata = this.sortdata.concat(filteredData);
      }
      this.createChart();
    },
    recreatechart() {
      const dataToEmit = {
        sortedData: this.sortdata,
        filterkeyword: this.filterkeyword
      };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
  },
};
</script>

<style scoped>
.legandbox{
  width: 15px;
  height: 15px;
  margin-right: 3px;
}
.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tooltip {
  position: absolute;
  text-align: center;
  padding: 5px;
  background: rgb(0, 0, 0);
  border: 1px solid rgb(117, 116, 116);
  border-radius: 3px;
  pointer-events: none;
  opacity: 0;
  z-index: 10;
}
</style>
