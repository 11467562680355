<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Macro Management</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-0 my-lg-0">
        <div
          class="d-flex flex-column flex-lg-row justify-content-lg-start my-2 my-lg-0 mb-lg-0"
        >
          <div class="col-lg-5 my-1 pl-lg-0 pr-lg-1">
            <div class="form-group mb-md-0">
              <label class="mb-0">Macro Name</label>
              <input v-model="searchData.searchMacroName"  type="email" class="form-control my-md-2 my-lg-0" />
            </div>
          </div>

          <div class="col-12 col-lg-2 my-1 align-self-end">
            <span type="button" class="save_btn w-100"  @click="SearchMacroByName()">
              {{ $t("navigations.search") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5>{{ "Macro List" }}</h5>
        <div
          class="position-relative align-self-center"
          aria-label="Create User"
          tabindex="0"
          @click.prevent="gotTo('stats')"
        >
          <img
            width="20"
            class="cursor-pointer"
            src="../../assets/img/create.png"
            alt="Create Macro"
          />
          <div class="tool-tip">Create Macro</div>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th class="sort_block" @click="SortSelected('firstName', 1)" id="1">
                  <div class="d-flex flex-row justify-content-between">
                    {{ "Macro Name " }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>

                <!-- <th>Email</th> -->
                <!-- <th class="sort_block" @click="SortSelected('role',3)" id="3">
                    Role
                    <div class="d-flex flex-row justify-content-between">              
                                     <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span></div>
                  </th> -->
                <th class="sort_block" @click="SortSelected('locked', 4)" id="4">
                  <div class="d-flex flex-row justify-content-between">
                    {{ "Updated By" }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('locked', 4)" id="4">
                  <div class="d-flex flex-row justify-content-between">
                    {{ "Updated Datetime" }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <!-- <th>Locked</th> -->

                <th class="text-center">{{ " Edit" }}</th>
                <th class="text-center">{{ "Delete" }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="macro in MacroList" :key="macro.id">
                <td>{{ macro.ProgramName }}</td>
                <td></td>
                <td>{{ filter(macro.CreatedAt) }}</td>
                <td class="text-center"><i class="fa fa-edit edit-delete"></i></td>
                <td class="text-center"><i class="fa fa-trash edit-delete"></i></td>
              </tr>
            </tbody>
          </table>
          <!------ pagination -------->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="dataTables_paginate paging_simple_numbers pagination float-right"
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    :pages="totalPages"
                    :range-size="0"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./Macro_Management.js"></script>

<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.form-group {
  margin-bottom: 0 !important;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.form-select {
  border-radius: 0 !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.scrollable3 {
  overflow-y: scroll;
  height: 250px;
}
.audit__details__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.audit__details__modal__container {
  width: min(90%, 365px);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}
.audit__details__modal__title,
.audit__history__modal__title {
  background-color: var(--pop-up-background);
  color: #ffffff;
}
.audit__details__modal__content {
  overflow-y: scroll;
  height: 250px;
}
.audit__history__modal__contents {
  overflow-y: scroll;
  height: 404px;
}
.audit__history__modal__wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 4000;
}
.audit__history__modal__container {
  width: min(45%, 90%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}
</style>
