/* eslint-disable */
import axios from "axios";
import "/src/assets/css/animate.css";
import "/src/assets/css/style.css";
import html2pdf from 'html2pdf.js';
import store from '@/store';
import * as XLSX from "xlsx";
import groupbarchart from '../../custom_lib/AiCharts/qrtbarchart.vue';
import grouplinechart from '../../custom_lib/AiCharts/qrystdgrouplinechart.vue';
import advtable from '../../custom_lib/AiCharts/advenenttable.vue';
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import barchart from '../../custom_lib/AiCharts/adbarchart.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
import stackedgroup from '../../custom_lib/AiCharts/groupbarchartwithstacks.vue';
import qrybarchart from '../../custom_lib/AiCharts/qrygroupbarchart.vue';
import qrylinechart from '../../custom_lib/AiCharts/qrylinechart.vue';
import survivalhorizondal from "../../custom_lib/AiCharts/qrthorizondalpercentage.vue";
export default {
  name: "labresultsreport",
  components: {
    groupbarchart,
    advtable,
    historytab,
    grouplinechart,
    chartdrill,
    barchart,
    stackedgroup,
    qrybarchart,
    qrylinechart,
    survivalhorizondal
  },
  props: {
    templateId: {
      type: String,
      default: ''
    },
    resultCount: {
      type: Object,
      default: () => ({
        noOfSubjects: 0,
        noOfSubjectsEnrolledByAI: 0,
        noOfSubjectsRejectedByAI: 0
      }),
    },
    fromPage:{
      type:String,
      default:""
    },
  },
  async unmounted() {
       store.dispatch("setCollapse", false);
     },
     async mounted() {
       store.dispatch("setCollapse", true);
    window.addEventListener("studyIdChanged", (event) => {
      this.historykeys=[];
      this.historydata={};
      console.log("StudyIDChanged into" + event.detail.storage);
       this.getsites();
       this.getdata();
    this.targetValue=this.resultCount.noOfSubjects
    this.startRolling();
  });
  await this.getsites();
    await this.getdata();
    this.targetValue=this.resultCount.noOfSubjects
    this.startRolling();
  },
  data() {
    return {
      groupwidth:1200,
      selectedSiteCode:"",
      siteCodes: [],
      arrayofkeys:[
        "siteCode",
        "subjectId",
        "visitName",
        "queryFor",
        "formName",
        "pageNo",
        "fieldName",
        "queryOpenStatus",
        "queryResponseStatus",
        "query_Subject",
        "priority",
        "closeBy",
        "closeByName",
        "closedDate",
        "isDeleted",
        "raisedByName",
        "createdUserEmail",
        "createdUserId",
        "createdAt",
        "lastModifiedUserEmail",
        "lastModifiedUserId",
        "lastModifiedAt"
    ],
    customcolors:[
        '#1AB394', '#FF5252', '#ABF0FF', '#C1D1FF', '#FBD164', '#FDAD73'
      ],
      prioritycustomcolors:[
        '#f3b600', '#1AB394', '#FF5252', '#C1D1FF', '#FBD164', '#FDAD73'
      ],
    groupchartkeys:{
      "xaxiskey":"siteCode",
    "groupkey":"queryStatus",
    },
    grouplinechartkeys:{
        "xaxiskey":"createdAt",
      "groupkey":"queryStatus",
      },
      yValues:["Abnormal","Normal","High","Low"],
      maximizedcomp:"",
      drilldata:{},
      sites: "",
      SiteCode: "",
      loader:true,
      expandBlock:false,
      data:[],
      querydata:[],
      datahistory:[],
      columns:[],
      historykeys:[],
      historydata:{},
      drilldowndata:[],
      currentValue: 0, // Start value
      targetValue: 0, // The target value you want to roll to
      duration: 2000, // Duration of the animation in milliseconds
      displayValue: 0,
      basetestUrl: process.env.VUE_APP_Service_URL,
      baseUrl: process.env.VUE_APP_Service_URL + "/forms-ml/patientenroll/",
      selectedChartType: 'Comparitive Bar chart',
      chartTypes: ['Comparitive Bar chart', 'Pie Chart', 'Bar Chart', 'Line Chart',],
      drillpopup: false,
      colorcodes:[
        {
            "color":"#ec7063",
            "type":"Missed Visits"
        },{
            "color":"#58d68d",
            "type":"Completed Visits"
        },
      ],
      barcolorcodes:[
        {
            "color":"#FF5252",
            "type":"Open Queries"
        },{
            "color":"#1AB394",
            "type":"Closed Queries"
        }
      ],
      barprioritycolorcodes:[
        {
          "color":"#f3b600",
          "type":"Medium"
      },{
          "color":"#1AB394",
          "type":"Low"
      },
      {
        "color":"#FF5252",
        "type":"High"
    }
      ],
      xaxiskeys:{
        "horizondalpercent":"",
        "groupbarchart":"",
        "barchart":"",
        "histogram":"",
        "piechart":"AESEV",
        "advtable":"",
        "aepiechart":"",
        "historytab":"",
        "chartdrill":""
      },
        customisationfields:[
          {
            fieldname:"completedOnTime",
            value:"number",
            name:"Completed Visits",
            color:"red"
        },
          {
              fieldname:"missed",
              value:"number",
              name:"Missed Visits",
              color:"blue"
          }
      ],
      namesobj:{
        siteCode:"Site Code",
        total:"Total Visits",
        missedPercentile:"% of Missed Visits",
        missed:"Missed Visits",
        completed:"Completed Visits"
      },
      yaxislabelsobj:{
        "Total Count":"Total Visits",
        "Total Missed":"Missed Visits",
        "Total Completed":"Completed Visits"
      }
    };
  },
  methods: {
    async getsites() {
      const currentstudy = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      
      await axios
        .get(
          `${this.basetestUrl}/management/site/getallsitesbyuser?studyId=${currentstudy}&sortProperty=siteCode&IsDes=false&getAll=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sites = res.data;
          this.SiteCode = this.sites[0].siteID;
          this.getquerydata();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async exporttoexcell() {
      const worksheet = XLSX.utils.json_to_sheet(this.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Site Activity Monitoring.xlsx");
    },
    exporttopdf() {
      // Create a temporary container
      const pdfContainer = document.createElement('div');
      const pdfContainer1 = document.createElement('div');
      const pdfContainer2 = document.createElement('div');
      const pdfContainer3 = document.createElement('div');
      const pdfContainer4 = document.createElement('div');

      const captureArea1 = this.$refs.captureArea1.cloneNode(true);
      const captureArea2 = this.$refs.captureArea2.cloneNode(true);
      const captureArea3 = this.$refs.captureArea3.cloneNode(true);
      const captureArea4 = this.$refs.captureArea4.cloneNode(true);


      captureArea2.classList.add('page-break');
      captureArea3.classList.add('page-break');
      captureArea4.classList.add('page-break');
      // Append cloned elements to the container
      pdfContainer1.appendChild(captureArea1);
      pdfContainer2.appendChild(captureArea2);
      pdfContainer3.appendChild(captureArea3);
      pdfContainer4.appendChild(captureArea4);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer1.style.width = '100%';
      pdfContainer2.style.width = '200%';
      pdfContainer3.style.width = '200%';
      pdfContainer4.style.width = '100%';
      
      pdfContainer.appendChild(pdfContainer1);
      pdfContainer.appendChild(pdfContainer2);
      pdfContainer.appendChild(pdfContainer3);
      pdfContainer.appendChild(pdfContainer4);

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      const opt = {
        margin: 0,
        filename: 'Site Activity Monitoring.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
    
      // Convert the temporary container to PDF
      html2pdf().set(opt).from(pdfContainer).save();
    },
    async retaingroupchartvars(groupvars){
      if(groupvars.xaxiskey!="" && groupvars.groupkey!=""){
      this.groupchartkeys = groupvars;
      }
    },
    async retainvisitkey(xaxiskey){
      if(xaxiskey!=""){
      this.xaxiskeys["horizondalpercent"] = xaxiskey;
      }
    },
    startRolling() {
      const start = this.currentValue;
      const end = this.targetValue;
      const duration = this.duration;
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        if (elapsedTime < duration) {
          const progress = elapsedTime / duration;
          this.displayValue = Math.floor(start + progress * (end - start));
          requestAnimationFrame(animate);
        } else {
          this.displayValue = end;
        }
      };

      requestAnimationFrame(animate);
    },
    async undobutton(){
      if(this.datahistory.length > 1){
      this.datahistory.pop();
      this.data=this.datahistory[this.datahistory.length-1];
      }
    },
    changetomaxcomponent(compval){
      this.maximizedcomp = compval;
    },
    changetominomponent(){
      this.maximizedcomp = "";
    },
    async onclosed(){
      this.drillpopup = false;
    },
    async drillpopupopened(datafromchild) {
      this.drilldata = datafromchild;
      this.drillpopup = true;

    },
    async drilldowncurrentchart(datafromchild){
      this.data = datafromchild;
      this.datahistory.push(this.data);
    },
    async drilldowncurrentchartforbar(datafromchild){
      console.log("sorteddataforbarchart",datafromchild);
      const lastkey = this.historykeys[this.historykeys.length - 1];
      // this.data = datafromchild.sortedData;
      if(this.historydata[lastkey].length != datafromchild.sortedData.length){
      // if(!this.historykeys.includes(datafromchild.filterkeyword)){
       this.historykeys.push(datafromchild.filterkeyword);
      this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
      this.data = this.historydata[datafromchild.filterkeyword];
      }
    },
    async gotohistorylevel(history){
      this.deleteAfterKeyvaluearrays(history);
      this.deleteAfterKeyarrays(history);
      this.data = this.historydata[history];
    },
    async deleteAfterKeyvaluearrays(selectedKey) {
      const entries = Object.entries(this.historydata); // Convert the object to an array of entries
      const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);
    
      // If the key is found, slice the array to keep only the entries up to the selected key
      const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;
    
      // Convert the array of entries back into an object
      return Object.fromEntries(newEntries);
    },
    async deleteAfterKeyarrays(selectedKey) {

      let index = this.historykeys.indexOf(selectedKey);

if (index !== -1) {
    // Slice the array up to the element (including the element)
    this.historykeys = this.historykeys.slice(0, index + 1);
}
    },
    async headderforbarcomparitive(repfieldname) {
      this.comparitivebarheader = repfieldname;
    },
    async sitechange(){
await this.getquerydata()
    },

    async getquerydata(sitecode){
        const idtoken = store.getters.getIdToken;
        this.querydata=[];
        await axios.get(
          `${this.basetestUrl}/management/site/sitereports?SiteId=${sitecode}`,
          {
              headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
              }
          })
          .then((res) => {
            console.log("getqueryreports?studyId",res.data);
            this.querydata=res.data;
            this.loader = false;
          })
          .catch((err) => console.log("error in screen list", err));
    },
    async getdata() {
      this.loader = true;
      const currentstudy = await store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios.get(
        `${this.basetestUrl}/management/site/studysitereports?StudyId=${currentstudy}`,
        {
            headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
            }
        })
        .then((res) => {
          console.log("management/site/studysitereports",res.data);
          this.data = res.data;
            this.loader = false;
          this.loader = false;
          this.columns = [...new Set(this.data.map(item => item.VISITNUM))];
          this.data
          this.datahistory.push(this.data);
          this.historydata['Home'] = this.data;
          this.historykeys.push('Home');
        })
        .catch((err) => console.log("error in screen list", err));
    },
    async pageclosed(){
      this.$router.push(`/studymonitoring`);
    }
  },
};