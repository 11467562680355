/* eslint-disable */
import config from "../../../config";
import baseinput from "./form_elements/baseinput";
import baseselect from "./form_elements/baseselect";
import basedate from "./form_elements/basedate";
import basedatetime from "./form_elements/basedatetime";
import basenumber from "./form_elements/basenumber";
import basecheckbox from "./form_elements/basecheckbox";
import baseradiogroup from "./form_elements/baseradiogroup";
import axios from "axios";
import moment from "moment";
import * as showformService from "./dynamicformservice";
import store from "../../store/index";
import jwt_decode from "jwt-decode";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import html2canvas from "html2canvas";
import signaturePopUp from "../../components/signaturePopUp/signaturePopUp.vue";
import QRCode from "qrcode";
import ValidationMessage from "./errormessages.vue";
import CreateVideoCallSchedulePopUp from "../../components/CreateVideoCallSchedulePopUp/CreateVideoCallSchedulePopUp.vue";
import basetime from "./form_elements/basetime.vue";
import jsPDF from "jspdf";
import { createDocumentRegistry } from "typescript";
export default {
  name: "newtemplate",
  components: {
    baseinput,
    baseselect,
    basecheckbox,
    baseradiogroup,
    basenumber,
    basedatetime,
    basedate,
    signaturePopUp,
    VPagination,
    QRCode,
    CreateVideoCallSchedulePopUp,
    ValidationMessage,
    basetime
  },
  props: {
    formsesid: {
      type: String,
      default: "",
    },
    isPatientVisit: {
      type: Boolean,
      default: false,
    },
    closedoption: {
      type: String,
      default: "",
    },
    isvalidate: {
      type: Boolean,
      default: true,
    },
    issavefun: {
      type: Boolean,
      default: false,
    },
    isfieldvalidate: {
      type: Boolean,
      default: false,
    },
    isqueryfield: {
      type: Boolean,
      default: true,
    },
    requiredsubmit: {
      type: Boolean,
      default: true,
    },
    fromArchive: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    await this.getuserIp();
    await this.setformid();
  },
  async mounted() {
    this.PathCheck();
    //this.CurrentPatientID = localStorage.getItem("patientId");
   //this.CurrentPatientID = localStorage.getItem("patientIdis");
   console.log("Patient id from query page is :",this.CurrentPatientID);
   this.rollsprivilages = await store.getters.getRolesprivilegeData;
    this.getsites();
    await this.getpatients();
    await this.getFromByVisit(); 
    window.addEventListener("FormDigitalSignature", (event) => {
      console.log("FormDigitalSignature == " + event.detail.storage);
      if (event.detail.storage == true) {
        //window.location.reload();
        this.getSignStatus();
      }
      this.searchData();
    });
    //console.clear();
    this.defaultdate = localStorage.getItem("dateformat");
    this.defaultTime = localStorage.getItem("timeformat");
    this.roleprivileges = store.getters.getRolesprivilegeData;
    this.fieldName = store.getters.getFieldName;
    const storageCode = store.getters.getStorageCode;

    await this.setformid();
    await this.getById();
    await this.getPageName();
    await this.getFormHeader();
    await this.patientId(this.forms[0].subjectId);
    await this.siteId(this.forms[0].siteId);
    await this.FormOpenQueryCount();
    await this.FormCloseQueryCount();
    await this.getSignStatus();
    await this.geteditcheckbytempid();

    //await this.setunverified();
    if (this.closeoption == "forms") {
      this.whenclosed = "listform";
    } else if (this.closedoption == "sitesummary") {
      this.whenclosed = "sitesummary";
    } else if (this.closedoption == "home") {
      this.whenclosed = "home";
    } else if (this.closedoption == "patientlist") {
      this.whenclosed = "patientlist";
    } else if (this.closedoption == "PatientScheduleView") {
      this.whenclosed = "PatientScheduleView";
    } else if (this.closedoption == "iotaptents") {
      this.whenclosed = "IOTPatients";
    } else {
      this.whenclosed = "formsummary";
    }
  },
  data() {
    return {
      calculatedfields:[],
      visibility:{},
      messagetype:{},
      ViewForm:"ViewForm",
      CreateVideoCallSchedulePopUp:false,
      patients: [],
      visitName: [],
      FormList: [],
      VisitNo:"",
      CurrentPatientID:"",
      currentFormIndex: 0,
      selectedFormId: "",
      selectedVisit: { visitNo: "", visitFormName: "" },
      repeatedmeasurevalarray: [],
      temprepeatedmeasureval: {},
      repeatedmeasurelistpopup: true,
      repeatedpageno: "",
      repeatedfieldname: "",
      repeatedoptions: "",
      repeathidden: false,
      repeatedmeasurepopup: false,
      calculatedresult: {},
      previouscalculatedresult: {},
      currentgrid: [],
      repeatednumberanddateval: {},
      gridoptarray: {},
      gridlinkeddatas: {},
      griddata: [
        {
          Rows: [""],
          Columns: [""],
          Fieldtypes: ["text"],
          Fielddata: {},
          Fieldoptions: {},
        },
      ],
      fileid: "",
      fileData: "",
      newrepeatingField: [],
      downloadedfilenames: {},
      scalevlidatemin: "",
      scalevalidatemax: "",
      numberVal: null,
      dateVal: null,
      qrcodetxt: {},
      numberanddateval: {},
      loggedinUser: "",
      signedby: "",
      signtime: "",
      signdate: "",
      signatureformId: "",
      signatuestatus: "",
      signStatus: "",
      signpopUp: false,
      iPaddress: "",
      defaultdate: "",
      defaultTime: "",
      fieldName: "",
      pagesavefieldnames: [],
      showdiv: false,
      modelinputclass: "form-control",
      modelradioclass: "col-md-10 my-1",
      testvar: "",
      groups: {},
      audit: [],
      formsid: "",
      modelelvalidation: "",
      modelcomments: "",
      modelpgno: "",
      modelelement: "",
      modelname: "hello",
      modelvalue: "mvalue",
      modelprivileges: "",
      modeltype: "mtype",
      repeatingFeildName: "",
      feildIdentifier: 0,
      isModelRepeating: false,
      receivedData: [],
      envvar: process.env.NODE_ENV,
      fileuploadurl: process.env.VUE_APP_File_upload_URL + "/document",
      baseurl: process.env.VUE_APP_Service_URL,
      saveid: "",
      errormsg: {},
      errorclasslist: {},
      repeatedgroupgroupnos: {},
      forms: [],
      isVerified: "",
      patientsId: "",
      sitesId: "",
      formName: "",
      pageName: [],
      formHeader: "",
      visits: [],
      pages: [],
      selectedValue: "",
      formdata: {},
      sendData: {
        formId: "string",
        templateId: "test",
        studyId: "test",
        folderId: "test",
        siteId: "test",
        subjectId: "test",
        visitId: "test",
        visitName: "test",
        totalPages: 0,
        version: "test",
        formStatus: "true",
        formName: "string",
        isDeleted: true,
        pages: [],
      },
      isvalid: [],
      bindarray: {
        page1: { siteCode: "test value", subjectNo: "" },
        page2: "",
        page3: "",
      },
      roleprivileges: "",
      pageno: 1,
      totalpages: 0,
      uploads: ["photo", "signature", "file"],
      inputElements: [
        "color",
        "email",
        "file",
        "hidden",
        "month",
        "number",
        "password",
        "range",
        "search",
        "tel",
        "text",
        "url",
        "week",
        "boxed",
        "textbox",
        "boxed",
      ],
      htmlarray: [],
      openQueryCount: 0,
      closeQueryCount: 0,
      Querydetails: "",
      formOpenQueryCount: 0,
      formClosedQueryCount: 0,
      pageSaveResult: [],
      borderClass: "",
      azuredata: {
        DocumentTypeName: "",
        DocumentTitle: "",
        DocumentDescription: "",
        PrimaryReferenceId: "",
        PrimaryReferenceType: "",
        SecondaryReferenceId: "",
        SecondaryReferenceType: "",
        OrganizationId: 0,
        Document: "",
      },
      documentVersionId: [],
      documentId: "",
      filename: [],
      docValidations: true,
      currentdocId: "",
      loaddata: false,
      documentName: "",
      Document: "",
      documentById: "",
      documentlist: [],
      pageNumber: "",

      subId: "",
      formId: "",
      loading: false,
      searchdata: {
        SiteCode: "",
        PatientId: "",
        FormName: "",
        FormStatus: "",
        Pageindex: 1,
        Pagesize: 10,
        SortProperty: "createdAt",
        SortOrder: 1,
      },
      rollsprivilages: "",
      validatevalidationarray: [],
    };
  },
  watch: {
    FormList(newList) {
      if (newList.length > 0) {
        this.selectedFormId = ""; 
      }
      
    },
  },
  methods: {
    async geteditcheckbytempid() {

      let idtoken = store.getters.getIdToken;

      await axios.get(`${this.baseurl}/forms/editcheck/geteditcheckbytempid?tempId=${this.receivedData[0].templateId}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
         this.editcheckvar = res.data;
         this.editcheckvar.fields.forEach((eacheditcheck)=>{
          console.log("eacheditcheck",eacheditcheck);
          if(eacheditcheck.calculations.length != 0){
            eacheditcheck.calculations.forEach((calculationoutput)=>{
              let targetfield = calculationoutput.outputfield;
              console.log("targetfield",targetfield);
                if(!this.calculatedfields.includes(targetfield)){
                    this.calculatedfields.push(targetfield);
                 }
            })
          }
          
        })
        })
        .catch((err) => {
          console.log(err);
        });
      },
    async PathCheck() {
      const previous = this.$router.options.history.state.back;
      console.log("Previous path is", previous);
  
      // Define path groups to match specific contexts
      const matchingPathsForPatientIdis = [
          "/query/allQueryList/",
          "/query/sentQueryList/",
          "/query/general_querylist",
          "/query/queryList"
      ];
  
      const matchingPathsForPatientId = [
          "/listform",
      ];
  
      const matchingForFormSummary = [
          "/formSummary"
      ];
      const matchingfromPatientSchedule =["/PatientScheduleView"];

      const matchingfromUnSchedule =["/unscheduledvisits"];

      const matchingfromDashboard =["/home"];

      const matchingfromNurseDashboard = ["/nursesummary"];
      const matchingfromSiteDashboard = ["/sitesummary"];
      if (matchingPathsForPatientIdis.some(path => previous.includes(path))) {
          
          this.CurrentPatientID = localStorage.getItem("patientIdis");
          console.log("Set CurrentPatientID to patientIdis");
      } else if (matchingPathsForPatientId.some(path => previous.includes(path))) {
       
          this.CurrentPatientID = localStorage.getItem("patientId");
          console.log("Set CurrentPatientID to patientId (list form)");
      } else if (matchingForFormSummary.some(path => previous.includes(path))) {
         
          const gotoPatientId = localStorage.getItem("patientIdFromGotoForm");
          const viewPatientId = localStorage.getItem("PatifFrmview");
          localStorage.removeItem("patientIdFromGotoForm","PatifFrmview")
          console.log("From tree:",gotoPatientId,"From Grid",viewPatientId,)
          if (gotoPatientId) {
              this.CurrentPatientID = gotoPatientId;
              console.log("Set CurrentPatientID to PatifGotoForm (gotoform)",this.CurrentPatientID);
          } else if (viewPatientId) {
              this.CurrentPatientID = viewPatientId;
              console.log("Set CurrentPatientID to PatifFrmSummary (viewforms)",this.CurrentPatientID);
          } 
          else {
              this.CurrentPatientID = null;
              console.log("No patient ID found for formSummary");
          }
      }
      else if(matchingfromPatientSchedule.some(path=>previous.includes(path))){
        this.CurrentPatientID = localStorage.getItem("IdFromPatschedule");
      } 
      else if(matchingfromUnSchedule.some(path=>previous.includes(path))){
        this.CurrentPatientID = localStorage.getItem("IdfromUnschedule");
        console.log("IdfromUnschedule",localStorage.getItem("IdfromUnschedule"));
      } 
      else if(matchingfromDashboard.some(path=>previous.includes(path)))
      {
        this.CurrentPatientID = localStorage.getItem("IdFromRecent");
        console.log("IdFromRecent",localStorage.getItem("IdFromRecent"));
      }
      else if(matchingfromNurseDashboard.some(path=>previous.includes(path)))
        {
          this.CurrentPatientID = localStorage.getItem("idFromNurseDashboard");
          console.log("Hello",localStorage.getItem("idFromNurseDashboard"));
        }
        else if(matchingfromSiteDashboard.some(path=>previous.includes(path)))
          {
            this.CurrentPatientID = localStorage.getItem("idFromsiteDash");
            console.log("Hello",localStorage.getItem("idFromsiteDash"));
          }
      else {
          // Default to 'patientId' if no specific match is found
          this.CurrentPatientID = localStorage.getItem("patientId");
          console.log("Reached else - Default to patientId");
      }
  }
,     
    gettimeformat(validation) {
      if (!Array.isArray(validation) || validation.length === 0) {
        return 'h:mm A'; // Default value when array is empty
      }
    
      // Find the element that starts with "DATEFORMAT"
      const timeFormatElement = validation.find((item) => item.startsWith("TIMEFORMAT"));
    
      if (timeFormatElement) {
        // Extract the string between parentheses
        const match = timeFormatElement.match(/\(([^)]+)\)/); // Regular expression to capture text inside parentheses
        return match ? match[1] : 'h:mm A'; // Return the captured group or default
      }
    
      return 'h:mm A'; // Default value when no matching element is found
    },
    getdateformat(validation) {
      if (!Array.isArray(validation) || validation.length === 0) {
        return 'MM-DD-YYYY'; // Default value when array is empty
      }
    
      // Find the element that starts with "DATEFORMAT"
      const dateFormatElement = validation.find((item) => item.startsWith("DATEFORMAT"));
    
      if (dateFormatElement) {
        // Extract the string between parentheses
        const match = dateFormatElement.match(/\(([^)]+)\)/); // Regular expression to capture text inside parentheses
        return match ? match[1].replace(/[a-z]/g, char => char.toUpperCase()) : 'MM-DD-YYYY'; // Return the captured group or default
      }
    
      return 'MM-DD-YYYY'; // Default value when no matching element is found
    },
    getCalculationsForField(fieldName) {

      if (!this.editcheckvar || !Array.isArray(this.editcheckvar.fields)) {
        return [];
      }
    
      const fieldObj = this.editcheckvar.fields.find(field => field.fieldName === fieldName);
            if(fieldObj){
              console.log("fieldObj.calculations",fieldObj.calculations);
                return fieldObj.calculations != null && fieldObj.calculations.length != 0 ? fieldObj.calculations[0].fields : [];
            }
            else{
              return [];
            }
      
    },
    async focusOutFunction(pageno,fieldname,sentvalue) {
      console.log("focusoutfun");
      this.errormsg[fieldname] = "";
      if(this.messagetype[fieldname]){
        delete this.messagetype[fieldname];
      }
     
      if(this.isvalid.includes(fieldname)){
        const index = this.isvalid.indexOf(fieldname);
        this.isvalid.splice(index, 1);

      }
      let value = "";
      if(sentvalue!=''){
        value = sentvalue;
      }
      else{
        value = this.formdata[`pageNo${pageno}`][fieldname];
      }
     console.log("value",value);
      let idtoken = store.getters.getIdToken;
      let calcfieldarray = await this.getCalculationsForField(fieldname);
      console.log("calcfieldarray",calcfieldarray);
      let computedfieldobj={};
      if(calcfieldarray.length > 0){
      await calcfieldarray.forEach(async (eachfields)=>{
        computedfieldobj[eachfields] = await this.getValueByKey(eachfields);
      })
    }
      
      let checkdata = {
        "fiedValue": value,
        "fieldName": fieldname,
        "templateId": this.receivedData[0].templateId,
        "computeFields": computedfieldobj
      }
      await axios.post(`${this.baseurl}/forms/editcheck/validatefield`,checkdata,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("res.data",res.data);
          const fieldVisibility = res.data.fieldVisibility;
          const computedField = res.data.computedField;
          if(res.data.validations.length > 0){
          const errval = res.data.validations[0];
          this.errormsg[fieldname] = errval.message;
          this.messagetype[fieldname] = errval.messageType;
          if(errval.messageType == "Alert"){
            if(!this.isvalid.includes(fieldname)){
           this.isvalid.push(fieldname);
         }
          }
        }
         if(fieldVisibility.length > 0){
          fieldVisibility.forEach((eachfieldvisibility)=>{
           this.visibility[eachfieldvisibility.fieldName] = eachfieldvisibility.isVisible
          })
         }
         if(computedField.length > 0){
          console.log("computedField",computedField);
          computedField.forEach((eachcomputedField)=>{
           this.htmlarray[0].pages.forEach((eachpages)=>{
             eachpages.field.forEach((eachfields)=>{
               if(eachfields.fieldName == eachcomputedField.fieldName){
                this.formdata[`pageNo${eachpages.pageNo}`][eachcomputedField.fieldName] = eachcomputedField.value;
               }
             })
           })
          })
         }
        })
        .catch((err) => {
          console.log(err);
        });
        
      // This function gets called when the input loses focus
      
      },
      async focusOutFunctionForRF(pageno,mainfieldname, rfieldIndex,fieldindex,fieldname,fieldidentifier,sentvalue) {
          console.log("RFfocusOutFun");
        this.errormsg[fieldname+fieldidentifier] = "";
        if(this.messagetype[fieldname+fieldidentifier]){
          delete this.messagetype[fieldname+fieldidentifier];
        }
       
        if(this.isvalid.includes(fieldname+fieldidentifier)){
          const index = this.isvalid.indexOf(fieldname+fieldidentifier);
          this.isvalid.splice(index, 1);
      
        }
      
        let value = "";
if(sentvalue!=''){
  value = sentvalue;
}
else{
  console.log("this.formdata[`pageNo${pageno}`]",this.formdata[`pageNo${pageno}`]);
  value = this.formdata[`pageNo${pageno}`][mainfieldname][rfieldIndex]['fields'][fieldindex].value;
}
        let idtoken = store.getters.getIdToken;
        if(value == undefined){
          value = "";
        }
        let calcfieldarray = await this.getCalculationsForField(fieldname);
        let computedfieldobj={};
        if(calcfieldarray.length > 0){
        await calcfieldarray.forEach(async (eachfields)=>{
          computedfieldobj[eachfields] = this.getrepeatedValueByKey(eachfields,mainfieldname,rfieldIndex,pageno);
        })
        console.log(computedfieldobj);
      }
        
        let checkdata = {
          "fiedValue": value,
          "fieldName": fieldname,
          "templateId": this.receivedData[0].templateId,
          "computeFields": computedfieldobj
        }
        await axios.post(`${this.baseurl}/forms/editcheck/validatefield`,checkdata,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            const fieldVisibility = res.data.fieldVisibility;
            const computedField = res.data.computedField;
            if(res.data.validations.length > 0){
            const errval = res.data.validations[0];
            
            if(errval.messageType == "Alert"){
              if(!this.isvalid.includes(fieldname+fieldidentifier)){
             this.isvalid.push(fieldname+fieldidentifier);
           }
            }
            this.errormsg[fieldname+fieldidentifier] = errval.message;
            this.messagetype[fieldname+fieldidentifier] = errval.messageType;
            
          }
          if(fieldVisibility.length > 0){
            fieldVisibility.forEach((eachfieldvisibility)=>{
             this.visibility[eachfieldvisibility.fieldName+fieldidentifier] = eachfieldvisibility.isVisible
            })
           }
          if(computedField.length > 0){
            computedField.forEach((eachcomputedField)=>{
              this.formdata[`pageNo${pageno}`][mainfieldname][rfieldIndex]['fields'].forEach((eachrepfields,rindex)=>{
                if(eachrepfields.fieldName == eachcomputedField.fieldName){
                  this.formdata[`pageNo${pageno}`][mainfieldname][rfieldIndex]['fields'][rindex].value = eachcomputedField.value;

                }
              })


           })
          }
          
          })
          .catch((err) => {
            console.log(err);
          });
          
        // This function gets called when the input loses focus
        
        },
    openVideoCallSchedulePopup(patientId) {
      this.selectedPatientId = patientId;
      this.CreateVideoCallSchedulePopUp = true;
    },
    async getsites() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseurl}/management/site/getallsitesbyuser?studyId=${currentstudy}&sortProperty=siteCode&IsDes=false&getAll=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //console.log("sites", res);
          this.sites = res.data;
          console.log("SITE LIST", this.sites);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getpatients(event) {
      this.searchdata.PatientId = ""; 
      this.visitName = []; 
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      console.log("current site code is ..", event);
      await axios
        .get(
          `${this.baseurl}/management/patient/listpatient?SiteId=${this.searchdata.SiteCode}&Studyid=${currentstudy}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(" get patients", res);
          this.patients = res.data.results;
          console.log(" get patients", this.patients);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getVisitName() {
      this.CurrentPatientID = this.searchdata.PatientId;
      this.visitName = [];
      this.getFromByVisit();
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      console.log(
        "current site code is ..",
        this.searchdata.PatientId,
        currentstudy
      );
      await axios
        .get(
          `${this.baseurl}/management/visit/listvisit?StudyID=${currentstudy}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(" get patients", res);
          this.visitName = res.data.results;
          console.log(" getVisitName", this.patients);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleVisitChange(event) {
      const selectedVisit = event.target.value;
      this.selectedVisitNo = selectedVisit.visitNo;
      this.selectedVisitFormName = selectedVisit.visitFormName;

      console.log("Selected visitNo:", this.selectedVisitNo);
      console.log("Selected visitFormName:", this.selectedVisitFormName);
      this.getFromByVisit();
    },
    handleVisitChange(event) {
      const selectedIndex = event.target.value;

      if (selectedIndex !== "") {
        const selectedVisit = this.visitName[selectedIndex];

        this.selectedVisitNo = selectedVisit.visitNo;
        this.selectedVisitFormName = selectedVisit.visitFormName;

        console.log("Selected visitNo:", this.selectedVisitNo);
        console.log("Selected visitFormName:", this.selectedVisitFormName);
      } else {
        this.selectedVisitNo = null;
        this.selectedVisitFormName = null;
      }

      this.getFromByVisit();
    },
    async getFromByVisit() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      if (this.selectedVisitNo == undefined) {
        this.selectedVisitNo = "";
      }

      console.log(
        "Getting forms for:",
        this.selectedVisitNo,
        this.selectedVisitFormName
      );

      await axios
        .get(
          `${this.baseurl}/forms/forms/formlist?StudyId=${currentstudy}&PatientId=${this.CurrentPatientID}&visitNo=${this.selectedVisitNo}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.FormList = res.data.results;
          this.selectedFormId = this.FormList[0].formId
          console.log("Main Result:", res);
          console.log("FormList:", res.data.results);
        
            this.GotoSelectedForms(this.selectedFormId);
          
        })
        .catch((err) => {
          console.error(err);
        });
    },

    async GotoSelectedForms(formId) {
      console.log("FORM ID FROM SELECTED FORM IS >>>>", formId);
      this.formsid = formId;

      // Update the current form index when a form is manually selected
      this.currentFormIndex = this.FormList.findIndex(
        (form) => form.formId === formId
      );

      this.getdata();
      await this.getById();
      await this.getPageName();
      await this.getFormHeader();
      await this.patientId(this.forms[0].subjectId);
      await this.siteId(this.forms[0].siteId);
      await this.FormOpenQueryCount();
      await this.FormCloseQueryCount();
      
      console.log("Form ID", formId);
    },

    navigateForm(direction) {
      if (
        direction === "next" &&
        this.currentFormIndex < this.FormList.length - 1
      ) {
        this.currentFormIndex++;
      } else if (direction === "prev" && this.currentFormIndex > 0) {
        this.currentFormIndex--;
      }

      // Update selected form in the dropdown
      const nextFormId = this.FormList[this.currentFormIndex].formId;
      this.selectedFormId = nextFormId; // Set the form ID in the dropdown
      this.GotoSelectedForms(nextFormId); // Navigate to the next/previous form
    },
    dropdownvalue(data, codevalue) {
      const result = data.find((item) => item.codevalue === codevalue);
      let outputres = "";
      if (result) {
        console.log(result.optionname);
        outputres = result.optionname;
      }
      return outputres;
    },
    async signatureClick(status) {
      this.signpopUp = true;
      this.signatuestatus = status;
      this.signatureformId = this.formsesid;
      if (status == "Signed") {
        this.modalStatus = "Sign";
      } else if (status == "Unsigned") {
        this.modalStatus = "Unsign";
      }
    },
    async calculateResult(pageno, caloptions, fieldname) {
      if (!this.calculatedresult[fieldname]) {
        console.log("calculatedresult[fieldname] does note exist" + fieldname);
        this.calculatedresult[fieldname] = "";
        this.previouscalculatedresult[fieldname] = "0";
      }
      if (
        this.previouscalculatedresult[fieldname] !=
        this.calculatedresult[fieldname]
      ) {
        let optionnsparsed = JSON.parse(caloptions);
        let firstclcname = this.formdata[`pageNo${pageno}`][
          optionnsparsed.firstfield
        ];
        let secondcalcname = this.formdata[`pageNo${pageno}`][
          optionnsparsed.secondfield
        ];
        let calcoperator = optionnsparsed.operations.trim();
        if (firstclcname != undefined && secondcalcname != undefined) {
          if (firstclcname != "" && secondcalcname != "") {
            let firstclcnametoint = parseInt(firstclcname);
            let secondcalcnametoint = parseInt(secondcalcname);
            if (!isNaN(firstclcnametoint) || !isNaN(secondcalcnametoint)) {
              console.log(
                "calcoperator",
                calcoperator,
                firstclcnametoint,
                secondcalcnametoint
              );
              if (calcoperator.trim() == "*") {
                console.log("calcoperator.trim", calcoperator.trim);
                this.calculatedresult[fieldname] =
                  firstclcnametoint * secondcalcnametoint;
              } else if (calcoperator.trim() == "+") {
                console.log("calcoperator.trim", calcoperator.trim);
                this.calculatedresult[fieldname] =
                  firstclcnametoint + secondcalcnametoint;
              } else if (calcoperator.trim() == "-") {
                this.calculatedresult[fieldname] =
                  firstclcnametoint - secondcalcnametoint;
              } else if (calcoperator.trim() == "/") {
                this.calculatedresult[fieldname] =
                  firstclcnametoint / secondcalcnametoint;
              } else {
                this.calculatedresult[fieldname] =
                  firstclcnametoint + secondcalcnametoint;
              }
            } else {
              this.calculatedresult[fieldname] = firstclcname + secondcalcname;
            }

            console.log("new calculatedresult[fieldname]");
            this.previouscalculatedresult[fieldname] = this.calculatedresult[
              fieldname
            ];
          }
        }
      }
    },
    truncateInput(event, fieldName, maxLength) {
      const inputValue = event.target.value.toString();
      if (inputValue.length > maxLength) {
        event.target.value = inputValue.slice(0, maxLength);
        this.formdata[`pageNo${item.pageNo}`][fieldName] = event.target.value;
      }
    },
    async CloseModelCall() {
      this.signpopUp = false;
    },
    async viewforms(id) {
      this.$router.push(`/query/showform/${id}`);
    },
    //Date time format starts//
    formatDate(dateString, type) {
      if (type == "dateandtime") {
        let momentObj;
        if (dateString.includes("_")) {
          const [time, date] = dateString.split("_");
          const [day, month, year] = date.split("-");
          const dateTimeString = `${year}-${month}-${day}T${time}`;

          // Create a moment object
          momentObj = moment(dateTimeString);
        } else {
          momentObj = moment(dateString);
        }

        // Check if the momentObj is valid
        if (!momentObj.isValid()) {
          return "";
        }

        // Format with local timezone offset
        return momentObj.format();
      } else if (type == "date") {
        // Split the input date by '-'
        const [day, month, year] = dateString.split("-");

        // Create the date string in the format YYYY-MM-DD
        const dateTimeString = `${year}-${month}-${day}`;

        // Create a moment object from the date string
        const momentObj = moment(dateTimeString, "YYYY-MM-DD");

        // Check if the moment object is valid
        if (!momentObj.isValid()) {
          return "";
        }

        // Format the date as YYYY-MM-DD
        return momentObj.format("YYYY-MM-DD");
      }
    },
    formatDateTime(inputDate) {
      // const hours = date.getUTCHours().toString().padStart(2, "0");
      // const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      // const seconds = date.getUTCSeconds().toString().padStart(2, "0");
      // const day = date.getUTCDate().toString().padStart(2, "0");
      // const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
      // const year = date.getUTCFullYear();

      // Convert input date to a Date object
      const date = new Date(inputDate);

      // Check if the date is valid
      if (isNaN(date)) {
        console.error("Invalid date");
        return "";
      }
      // Extract date and time components
      const hours = date
        .getHours()
        .toString()
        .padStart(2, "0");
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, "0");
      const seconds = "00"; // Assuming seconds are always 00 if not provided

      const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear().toString();

      console.log(
        "formatted date is ",
        `${hours}:${minutes}:${seconds}_${day}-${month}-${year}`
      );
      return `${hours}:${minutes}:${seconds}_${day}-${month}-${year}`;
    },
    formatDateNum(inputDate) {
      // Convert input date to a Date object
      const date = new Date(inputDate);
      // Check if the date is valid
      if (isNaN(date)) {
        console.error("Invalid date");
        return "";
      }
      // Extract date components
      const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear().toString();

      console.log("formatted date is ", `${day}-${month}-${year}`);
      return `${day}-${month}-${year}`;
    },
    payloadDate(inputDate) {
      const date = new Date(inputDate);

      if (isNaN(date)) {
        console.error("Invalid date", inputDate);
        return "";
      }

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    getformatDateNum(inputDate) {
      // Assuming inputDate is in the format "DD-MM-YYYY"
      const [day, month, year] = inputDate.split("-");

      // Create a new Date object with the parsed values
      const date = new Date(`${year}-${month}-${day}`);

      // Ensure the date is valid
      if (isNaN(date)) {
        console.error("Invalid date", inputDate);
        return "";
      }

      // Extract date components with zero-padding
      const formattedDay = day.padStart(2, "0");
      const formattedMonth = month.padStart(2, "0");
      const formattedYear = year;
      return `${formattedYear}-${formattedMonth}-${formattedDay}`;
    },
    convertTime(timeString) {
      // Split the time string into parts
      const timeParts = timeString.split(":");

      // Check if the seconds part is missing
      if (timeParts.length === 2) {
        // Append ':00' to add seconds
        return `${timeString}:00`;
      }

      // If the seconds are already present, return the original time string
      return timeString;
    },
    //date time format ends//
    async testmsgfun() {
      console.log("inside test function");
    },
    async archive() {
      const idtoken = store.getters.getIdToken;
      (this.formId = this.forms[0].formId),
        (this.subId = this.forms[0].subjectId);
      if (confirm("Are you sure you want to archive this form?")) {
        await axios
          .put(
            `${this.baseurl}/forms/forms/archive`,
            {
              createdUserEmail: "string",
              createdUserId: "string",
              createdAt: "2022-11-01T05:24:16.754Z",
              lastModifiedUserEmail: "string",
              lastModifiedUserId: "string",
              lastModifiedAt: "2022-11-01T05:24:16.754Z",
              formId: this.formId,
              subjectId: this.subId,
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("archive status", res);
            alert("Form archived successfully");
            this.$router.go(-1);
          })
          .catch((err) => {
            console.log(err);
            alert("Patient schedule not found!");
          });
      }
    },

    async getuserIp() {
      await axios.get(`https://api.ipify.org?format=json`).then((res) => {
        this.iPaddress = res.data.ip;
      });
    },

    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        //this.user = googleUser.getBasicProfile().getEmail();
        await this.getHealthData(googleUser.getAuthResponse().access_token);
        alert("This data is coming from google fit API");
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
    async getHealthData(g_token) {
      // ------------ to get current date for google fit -------
      // var start = new Date();
      // start.setHours(0,0,0,0);
      // start.setDate(start.getDate()-1);
      //
      // var end = new Date();
      // end.setHours(23,59,59,999);
      // end.setDate(end.getDate()-1);
      //-------------------end ----------------------------
      // let data = {
      //     "aggregateBy": [{
      //         "dataSourceId": "derived:com.google.step_count.delta:com.google.android.gms:estimated_steps"
      //     }],
      //     "bucketByTime": {"durationMillis": 86400000}, // This is 24 hours
      //     "startTimeMillis": 1546210381932,   // Start time
      //     "endTimeMillis": 1547210381932  // End Time
      // }
      await axios
        .post(
          `https://www.googleapis.com/fitness/v1/users/me/dataset:aggregate`,
          {
            aggregateBy: [
              {
                dataSourceId:
                  "derived:com.google.step_count.delta:com.google.android.gms:estimated_steps",
              },
              {
                dataSourceId:
                  "derived:com.google.weight:com.google.android.gms:merge_weight",
              },
              {
                dataSourceId:
                  "derived:com.google.height:com.google.android.gms:merge_height",
              },
              {
                dataSourceId:
                  "derived:com.google.calories.expended:com.google.android.gms:merge_calories_expended",
              },
              {
                dataSourceId:
                  "derived:com.google.heart_minutes:com.google.android.gms:merge_heart_minutes",
              },
              // {
              //     "dataSourceId": "derived:com.google.distance.delta:com.google.android.gms:merge_distance_delta",
              // },
            ],
            bucketByTime: { durationMillis: 86400000 },
            // "startTimeMillis": start.getTime(),
            // "endTimeMillis":  end.getTime()
            startTimeMillis: config.googlefitvalues.startTimeMillis, // Start time
            endTimeMillis: config.googlefitvalues.endTimeMillis, // End Time
          },
          {
            headers: {
              Authorization: "Bearer " + g_token,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          await this.updateFitnessData(res.data.bucket[0].dataset);

          // let healthData = {
          //     "formId":this.formsesid,
          //     "updateFieldDTO": [
          //         {
          //             "fieldName": "string",
          //             "value": "string",
          //             "status": "string",
          //             "comment": "string"
          //         }
          //     ]
          // }

          // let arr = [];
          // for (let i = 0; i < res.data.bucket[0].dataset.length; i++) {
          //     arr.push({
          //         fieldName: i.dataSourceId,
          //         value: true,
          //         status: true,
          //         comment: "string"
          //     });
          // }
          //this.updateFitnessData(re);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateFitnessData(data1) {
      const arr = [];
      for (let i = 0; i < data1.length; i++) {
        for (let j = 0; j < data1[i].point.length; j++) {
          console.log("j", data1[i].point[j]);
          for (let k = 0; k < data1[i].point[j].value.length; k++) {
            const mm = {
              fieldName: data1[i].point[j].dataTypeName,
              value:
                data1[i].point[j].value[k].intVal != null
                  ? data1[i].point[j].value[k].intVal.toString()
                  : data1[i].point[j].value[k].fpVal.toString(),
              status: null,
              comment: null,
            };
            arr.push(mm);
          }
        }
      }
      const idtoken = store.getters.getIdToken;

      await axios
        .put(
          `${this.baseurl}/forms/forms/updatefeildfromgoogle`,
          {
            formId: this.formsid,
            updateFieldDTO: arr,
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Form updated with google fit data", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async printFacture() {
      let pdffilename =
        this.sitesId + "_" + this.patientsId + "_" + this.formName;
      pdffilename = pdffilename.replace(/ +/g, "");
      this.showdiv = true;
      if (this.showdiv == true) {
        console.log("for pdf");
        let elementbyclassname = document.getElementsByClassName(
          "getdfcontentss"
        );
        let doc = new jsPDF("p", "mm");
        //foeeatch statement for paging pdf starts
        console.log(elementbyclassname.length);
        let countforlastpage = 1;
        let elementcount = elementbyclassname.length;
        // elementbyclassname.forEach(async element => {
        for (let i = 0; i <= elementcount; i++) {
          let element = elementbyclassname[i];

          // let element = document.getElementById("getdfcontentss");
          console.log("element is", element);
          await html2canvas(element).then((canvas) => {
            console.log("for pdf2");
            let imgData = canvas.toDataURL("image/png");

            let imgWidth = 210;
            let pageHeight = 295;
            let imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 10;
            doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
              position = heightLeft - imgHeight + 10;
              doc.addPage();
              doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;
            }
            //test pdf save ends
          });
          if (countforlastpage == elementbyclassname.length) {
            doc.save(pdffilename + ".pdf");
          } else {
            doc.addPage();
            countforlastpage++;
          }
        }
        // foeeatch statement for paging pdf ends
      }
    },
    async qrcodefun(fieldname, dataurl) {
      try {
        this.qrcodetxt[fieldname] = await QRCode.toDataURL(dataurl);
      } catch (err) {
        console.error(err);
      }
    },
    filter(date) {
      if (date) {
        return moment(date).format(this.defaultdate);
      }
      console.log("Reverse is");
      console.log(date);
    },
    formatTime(time) {
      if (time) {
        return moment(time).format(this.defaultTime);
      }
    },
    async onclosed() {
      // await this.$router.push(`/${this.closedoption}`);
      await this.$router.go(-1);
    },
    async getById() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/forms/getbyid/${this.formsid}?api-version=1`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.forms[0] = res.data;
          this.subId = this.forms[0].subjectId;
          this.formName = res.data.formName;
          this.selectedFormId = res.data.formId;
          this.VisitNo = res.data.visitNo
          console.log("Form is", this.forms[0]);
          console.log("Form name is", this.formName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async FormOpenQueryCount() {
      this.StudyId = store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/query/getquerycount?StudyId=${this.StudyId}&FormId=${this.formsid}&status=Open`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.formOpenQueryCount = res.data;
        })
        .catch((err) => console.log(err));
    },
    async FormCloseQueryCount() {
      this.StudyId = store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/query/getquerycount?StudyId=${this.StudyId}&FormId=${this.formsid}&status=Closed`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.formClosedQueryCount = res.data;
        })
        .catch((err) => console.log(err));
    },
    async FormQueryDetails(status) {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      console.log("In Form Query Details ", currentstudy, this.formsid, status);
      await axios
        .get(
          `${this.baseurl}/forms/query/getquerydetails?StudyId=${currentstudy}&FormId=${this.formsid}&status=${status}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.Querydetails = res.data;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      const modalWrapperQuery = document.querySelector(".modal__wrapper__Two");
      modalWrapperQuery.classList.add("active");
    },
    async FieldCloseQueryCount() {
      console.log("in field Query Count closed");
      this.StudyId = store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/query/getquerycount?StudyId=${this.StudyId}&FormId=${this.formsid}&status=Closed&PageNo=${this.modelpgno}&FieldName=${this.modelname}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.closeQueryCount = res.data;
        })
        .catch((err) => console.log(err));
    },
    async FieldOpenQueryCount() {
      this.StudyId = store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/query/getquerycount?StudyId=${this.StudyId}&FormId=${this.formsid}&status=Open&PageNo=${this.modelpgno}&FieldName=${this.modelname}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.openQueryCount = res.data;
        })
        .catch((err) => console.log(err));
    },
    async FieldQueryDetails(status) {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      console.log(
        "In field Query Details ",
        currentstudy,
        this.formsid,
        status,
        this.modelname
      );
      await axios
        .get(
          `${this.baseurl}/forms/query/getquerydetails?StudyId=${currentstudy}&FormId=${this.formsid}&status=${status}&PageNo=${this.modelpgno}&FieldName=${this.modelname}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.Querydetails = res.data;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      const modalWrapperQuery = document.querySelector(".modal__wrapper__Two");
      modalWrapperQuery.classList.add("active");
    },
    closeQueryModal: function() {
      const modalWrapperQuery = document.querySelector(".modal__wrapper__Two");
      modalWrapperQuery.classList.remove("active");
    },
    async getPageName() {
      const idtoken = store.getters.getIdToken;
      console.log("formid in getPageName fun is " + this.formsid);
      await axios
        .get(
          `${this.baseurl}/forms/forms/getallpages?FormId=${this.formsid}&api-version=1`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.pageName = res.data;
          this.getPages();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getPages() {
      this.pages = [];
      this.pageName.filter((item) => {
        if (item) {
          this.pages.push(item);
        }
      });
      console.log("Page names are", this.pages);
    },
    async getFormHeader() {
      const idtoken = store.getters.getIdToken;
      console.log("formid in getformheadder fun is " + this.formsid);
      await axios
        .get(
          `${this.baseurl}/forms/forms/getformname?formID=${this.formsid}&api-version=1`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.formHeader = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async patientId(patientId) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/patient/getpatientbyid/${patientId}?api-version=1`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.patientsId = res.data.subjectId;
          this.searchData.patientId = res.data.patientId
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async siteId(siteId) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/site/getbyid?id=${siteId}&api-version=1`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sitesId = res.data.siteCode;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkFormFieldsStatus() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/forms/checkformfieldstatus?FormID=${this.formsid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data == "All fields are validated") {
            this.verifyform();
          } else if (
            confirm(
              this.$t(
                "navigations.status of some of the fields are not validated or incorrect Do you want to verify this form?"
              )
            )
          ) {
            this.verifyform();
          }
        });
    },
    async verifyform() {
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      console.log(`formId: ${this.formsid},
      verifiedBy: ${usermail},
      verifiedAt: ${new Date()}`);
      await axios
        .put(
          `${this.baseurl}/forms/forms/verifyformstatus`,
          {
            formId: this.formsid,
            verifiedBy: usermail,
            verifiedAt: new Date(),
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          alert(this.$t("navigations.form verified successfully"));
          this.receivedData[0].formStatus = "Verified";
          this.forms[0].verifiedBy = usermail;
          this.forms[0].verifiedAt = new Date();
          this.isVerified = "Verified";
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async setformid() {
      this.formsid = this.formsesid.trim();
      this.getdata();
    },
    async testvarfun(
      field,
      value,
      pgn,
      feildIdentifier,
      repeatingFieldName,
      number,
      date
    ) {
      if (field.type == "numberanddate") {
      }
      if (field.type == "scales") {
        this.scalevlidatemin = field.limit[0].Min;
        this.scalevalidatemax = field.limit[0].Max;
      }
      if (this.isfieldvalidate) {
        if (this.fromArchive == "false" || this.fromArchive == false) {
          this.openModal(
            field,
            value,
            pgn,
            feildIdentifier,
            repeatingFieldName,
            number,
            date
          );
        }
      }
    },
    async helloButton(event) {
      this.selectedFile = "";
      this.filedata = "";
      console.log("Hello attribute");
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.filedata = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.selectedFile = input.files[0];
        console.log(this.selectedFile);
      }
      await this.submitFile();
    },
    async submitFile() {
      var formData = new FormData();
      formData.append("Document", this.selectedFile);
      formData.append("DocumentTypeName", "FORMS_DEV_FILEUPLOAD");
      formData.append("DocumentTitle", "");
      formData.append("DocumentDescription", "");
      formData.append("OrganizationId", "");
      console.log("final data", this.azuredata);

      await axios
        .post(
          `${this.fileuploadurl}/create
`,
          formData,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          console.log("retreieved data", response);
          this.fileid = `${response.data.data[0]},${response.data.data[1]}`;
          this.modelvalue = this.fileid;
          console.log("retreieved imageid", this.fileid);
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
    gotoraiseQuary(queryType) {
      this.xyz = this.formsid + "modelNameIs_" + this.modelname;
      this.$router.push(`/raiseQuery/${this.xyz}/${queryType}/${this.pageno}`);
    },
    getformtemplate: async function(tempid) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/forms/template/get/${tempid}?api-version=1.0`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res.data);
          this.htmlarray[0] = res.data;
          this.htmlarray[0].pages.forEach((page) => {
            page.field.forEach((fielddata, index) => {
              // console.log(page.field[index].type)
              // console.log(this.newrepeatingField[index])
              if (
                page.field[index].type == "repeatingfield" ||
                page.field[index].type == "repeatingMeasure"
              ) {
                // if(this.newrepeatingField[index].length!=0)
                // {
                //   page.field[index].repeatingFields=this.newrepeatingField[index]
                // }

                // else {
                fielddata.repeatingFields.forEach((data) => {
                  data["value"] = "";
                });

                fielddata.repeatingFields = [
                  {
                    fieldIdentifier: 1,
                    fields: fielddata.repeatingFields,
                  },
                ];
                console.log("get data is", fielddata.repeatingFields);
              }
            });
          });
          this.bindstatus();
          // console.clear();
        })
        .catch((err) => {
          console.log(err);
        });

      this.bindData();
    },
    async loadFileinitial(receivedfileid) {
      let fileUrl = `${this.fileuploadurl}/file/${
        receivedfileid.split(",")[0]
      }?versionid=${receivedfileid.split(",")[1]}`;

      const response = await fetch(fileUrl);
      const blob = await response.blob();
      this.selectedFile = new File([blob], "customFileName");
      console.log(blob);
      // You can perform additional operations with the selected file here
    },
    async getdownloadFilename(key, receivedfileid) {
      const fileUrl = `${this.fileuploadurl}/file/${
        receivedfileid.split(",")[0]
      }?versionid=${receivedfileid.split(",")[1]}`;

      try {
        const response = await axios.get(fileUrl, {
          responseType: "blob",
        });
        const fileData = response.data;
        // this.saveFile(fileData);
        if (response.headers["content-disposition"]) {
          const match = response.headers["content-disposition"].match(
            /filename=(.*)/
          );
          if (match && match.length > 1) {
            this.fileName = match[1];
            const regex = /"(.*?)"/g;
            const matches = match[1].match(regex);

            if (matches) {
              const extractedText = matches.map((match) => match.slice(1, -1));
              const lastIndex = extractedText[0].lastIndexOf("/");
              this.downloadedfilenames[key] = extractedText[0].substring(
                lastIndex + 1
              );
              // ["quoted", "with", "quotes"]
            } else {
              console.log("No matches found.");
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async downloaduploadedfile(receivedfileid) {
      const url = `${this.fileuploadurl}/file/${
        receivedfileid.split(",")[0]
      }?versionid=${receivedfileid.split(",")[1]}`;
      console.log(url);
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
    },
    bindstatus() {
      this.receivedData[0].pages.forEach((eachpages) => {
        let index = this.receivedData[0].pages.indexOf(eachpages);
        var i = 0;
        eachpages.field.forEach((item) => {
          this.htmlarray[0].pages[index].field[i].status = item.status;
          this.htmlarray[0].pages[index].field[i].privilege = item.privilage;
          // this.htmlarray[0].pages[index].field[i].privilege = "View";
          i++;
        });
      });
    },
    openModal: async function(
      field,
      value,
      pgn,
      feildIdentifier,
      repeatingFieldName,
      number,
      date
    ) {
      this.validatevalidationarray = field.validation;
      this.errormsg = {};
      this.isvalid = [];
      this.modelinputclass = "form-control";
      this.modelradioclass = "col-md-10 my-1";
      this.dropdownclass = "form-control m-b";
      this.modeltype = field.type;
      if (this.modeltype != "numberanddate") {
        this.modelvalue = value;
      } else {
        this.numberVal = number;
        this.dateVal = date;
      }
      this.modelcomments = "";
      this.modelpgno = pgn;
      this.modelelement = field;
      this.modelprivileges = field.privilege;
      this.modelelvalidation = field.validation;
      this.modelname = field.fieldName;
      if (feildIdentifier != 0) {
        this.isModelRepeating = true;
        this.feildIdentifier = feildIdentifier;
        this.repeatingFeildName = repeatingFieldName;
      } else {
        this.isModelRepeating = false;
        this.feildIdentifier = 0;
        this.repeatingFeildName = "";
      }
      let fieldName =
        feildIdentifier == 0
          ? field.fieldName
          : feildIdentifier + "." + field.fieldName;
      const modalWrapper = document.querySelector(".modal__wrapper");
      modalWrapper.classList.add("active");
      await this.getaudit(pgn, fieldName);
      await this.FieldOpenQueryCount();
      await this.FieldCloseQueryCount();
    },

    //Form Document Upload
    UploadDocumentModal() {
      const modalWrapper = document.querySelector(".modal-wrapper-upload");
      modalWrapper.classList.add("active");
    },
    CloseUploadModal() {
      this.docValidations = true;
      this.azuredata.Document = "";
      this.$refs.fileInput.value = null;
      const modalWrapper = document.querySelector(".modal-wrapper-upload");
      modalWrapper.classList.remove("active");
    },
    ViewDocumentModal() {
      this.getDocuments();
      const modalWrapper = document.querySelector(".modal-wrapper-view");
      modalWrapper.classList.add("active");
      // alert(formId);
    },
    ClosemodalView() {
      const modalWrapper = document.querySelector(".modal-wrapper-view");
      modalWrapper.classList.remove("active");
    },
    async previewFiles(event) {
      this.azuredata.Document = event.target.files[0];
      console.log("upload file is", this.azuredata.Document.name);
    },
    async CreateDocumentDMS() {
      this.loading = true;
      this.azuredata.DocumentTypeName = "Form";
      var formData = new FormData();

      console.log("form data", formData);
      formData.append("Document", this.azuredata.Document);
      formData.append("DocumentTypeName", this.azuredata.DocumentTypeName);
      formData.append("DocumentTitle", this.azuredata.DocumentTitle);
      formData.append(
        "DocumentDescription",
        this.azuredata.DocumentDescription
      );
      formData.append("OrganizationId", this.azuredata.OrganizationId);

      await this.validateDocumentIsNull();

      if (this.docValidations == true) {
        await axios
          .post(`${this.fileuploadurl}/create`, formData, {
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log("retreieved data", response);
            this.documentId = response.data.data[0];
            this.documentVersionId = [];
            this.documentVersionId = response.data.data[1];
            this.CreateDocumentEIDSA();
            this.loading = false;
          })
          .catch((err) => {
            console.log("error", err);
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
    async CreateDocumentEIDSA() {
      const idtoken = store.getters.getIdToken;
      const storageCode = store.getters.getStorageCode;
      await axios
        .post(
          `${this.baseurl}/management/document/createdocument`,
          {
            documentReferenceId: this.documentId,
            documentTypeId: this.formsid,
            typeValue: storageCode,
            documentType: "form",
            documentName: this.azuredata.Document.name,
            documentDescription: this.azuredata.DocumentDescription,
            folderId: "",
            folderName: "",
            documentVersions: [
              {
                documentVersionId: this.documentVersionId,
                versionNumber: "1",
                versionDescription: "",
                status: "",
                fileName: "",
                uploadedBy: "",
                uploadedOn: "2022-07-06T02:45:17.946Z",
                uploadedUserId: "",
                approveList: [],
              },
            ],
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("retreieve data", response);
          alert(this.$t("navigations.document created successfully"));
          this.CloseUploadModal();
          this.loading = false;
        })
        .catch((err) => {
          console.log("error", err);
          console.log(err.data);
          this.loading = false;
        });
    },
    async validateDocumentIsNull() {
      if (this.azuredata.Document && this.azuredata.Document.name) {
        this.docValidations = true;
      } else {
        this.docValidations = false;
      }
    },
    async getDocuments() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          ` ${this.baseurl}/management/document/listdocument?documentTypeId=` +
            this.formsid,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(" get doc result", res.data);
          console.log(" get doc result", res.data.results);
          this.documentlist = res.data.results;
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async getDocumentById(docId) {
      this.currentdocId = docId;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/document/getdocumentbyid?id=` + docId,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.Document = res.data;
          this.loaddata = true;
          this.documentName = this.Document.documentName;
        });
    },
    async deleteAzureDocument(documentId) {
      console.log("docid is ", documentId);
      await this.getDocumentById(documentId);
      console.log("document is ", this.Document);
      if (
        confirm(
          this.$t("navigations.are you sure you want to delete this document?")
        )
      ) {
        await axios
          .delete(
            `${this.fileuploadurl}/deletedocument?id=${this.Document.documentReferenceId}`,
            this.headerValues
          )
          .then((response) => {
            console.log(response.data);
            alert(this.$t("navigations.document deleted successfully"));
            this.deleteDocument(documentId);
          })
          .catch((err) => {
            console.log("nope", err);
          });
      }
    },
    async deleteDocument(docId) {
      const idtoken = store.getters.getIdToken;
      console;
      await axios
        .delete(`${this.baseurl}/management/document/delete?Id=` + docId, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.ClosemodalView();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async downloadfile(docId) {
      const idtoken = store.getters.getIdToken;
      await this.getDocumentById(docId);
      await this.getdmsdocument(docId);
      const fileName = this.Document.documentVersions[
        this.Document.documentVersions.length - 1
      ].documentVersionId;
      var a = document.createElement("a");
      a.href = `${this.fileuploadurl}/file/${this.Document.documentReferenceId}?versionid=${fileName}`;
      a.download = "FILENAME";
      a.click();
    },
    async viewDocument(docId) {
      // this.ShowDocName = false;
      await this.getDocumentById(docId);
      await this.getdmsdocument(docId);
      const versionId = this.Document.documentVersions[
        this.Document.documentVersions.length - 1
      ].documentVersionId;

      const filename = this.Document.documentVersions[
        this.Document.documentVersions.length - 1
      ].fileName;
      this.$router.push(
        `/documentview/${this.Document.documentReferenceId}/${filename}/${versionId}`
      );
    },
    async getdmsdocument() {
      console.log("dms document", this.Document);
      await axios
        .get(
          `${this.fileuploadurl}/getallfiles/${this.Document.documentReferenceId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "application/json",
              responseType: "blob",
            },
          }
        )
        .then((res) => {
          this.documentById = res.data;
          console.log("Docdownld............", this.documentById);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // End of  Form Document

    popupvalidate: function() {
      this.errormsg = {};
      this.isvalid = [];
      this.modelinputclass = "form-control";
      this.modelradioclass = "col-md-10 my-1";
      this.dropdownclass = "form-control m-b";
      this.modelelvalidation.forEach((valid) => {
        if (valid == "Required") {
          this.isRequired(this.modelvalue, this.modelname);
        }
        if (valid == "Number" && this.modelvalue != "") {
          this.isnumber(this.modelvalue, this.modelname);
        }
        if (valid == "Alphanumeric" && this.modelvalue != "") {
          this.isalphaneumeric(this.modelvalue, this.modelname);
        }
        if (valid == "email" && this.modelvalue != "") {
          this.isemail(this.modelvalue, this.modelname);
        }
        if (valid.includes("lengthrange") && this.modelvalue != "") {
          let regExp = /\(([^)]+)\)/;
          let matches = regExp.exec(valid);
          const myArr = matches[1].split(",");
          let minrange = parseInt(myArr[0]);
          let maxrange = parseInt(myArr[1]);
          this.lengthrange(this.modelvalue, this.modelname, minrange, maxrange);
        }

        if (valid.includes("valuerange") && this.modelvalue != "") {
          console.log("inside valuerange functiuon", valid);
          let regExp = /\(([^)]+)\)/;
          let matches = regExp.exec(valid);
          const myArr = matches[1].split(",");
          console.log("myArr is " + myArr);
          let minval = parseInt(myArr[0]);
          let maxval = parseInt(myArr[1]);
          this.valuerange(this.modelvalue, this.modelname, minval, maxval);
        }
      });
      if (this.isvalid.length != 0) {
        this.modelinputclass = "form-control errorborder";
        this.modelradioclass = "col-md-10 my-1 errorborder";
        this.dropdownclass = "form-control m-b errorborder";
      }
    },
    async saveaudit(status, type) {
      if (type == "numberanddate") {
        this.modelvalue =
          this.formatDateNum(this.dateVal) + "_" + this.numberVal;
      }
      if (type == "dateandtime") {
        this.modelvalue = this.formatDateTime(this.modelvalue);
      }
      if (type == "date") {
        this.modelvalue = this.payloadDate(this.modelvalue);
      }
      if (type == "time") {
        this.modelvalue = this.convertTime(this.modelvalue);
      }
      this.popupvalidate();
      if (this.isvalid.length == 0) {
        await this.setaudit(status);
      }
      this.getdata();
    },
    async setaudit(status) {
      const idtoken = store.getters.getIdToken;
      let data = {
        formId: this.formsid,
        pageNo: this.modelpgno,
        dataSource: "Web Browser",
        sourceID: this.iPaddress,
        updateFieldDTO: {
          fieldName: this.isModelRepeating
            ? this.repeatingFeildName
            : this.modelname,
          value: this.modelvalue ? this.modelvalue.toString() : "",
          status: status,
          comment: this.modelcomments,
          fieldIdentifier: this.feildIdentifier,
          repeatedFieldName: this.isModelRepeating ? this.modelname : "",
        },
      };
      await axios
        .put(`${this.baseurl}/forms/forms/updatefeild`, data, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
      if (
        this.modeltype == "repeatingfield" ||
        this.modeltype == "RepeatingMeasure"
      ) {
        this.formdata[`pageNo${this.modelpgno}`][this.isModelRepeating][
          this.feildIdentifier
        ] = this.modelvalue ? this.modelvalue.toString() : "";
      } else {
        this.formdata[`pageNo${this.modelpgno}`][this.modelname] = this
          .modelvalue
          ? this.modelvalue.toString()
          : "";
      }

      this.setformid();
      this.closeModal();
    },
    // getaudit
    async getaudit(pgno, fieldname) {
      const idtoken = store.getters.getIdToken;
      this.audit = [];
      await axios
        .get(
          `${this.baseurl}/forms/forms/getauditdata?formId=${this.formsid}&pageNo=${pgno}&FieldName=${fieldname}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          this.audit = res.data[0].fields;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeModal: function() {
      const modalWrapper = document.querySelector(".modal__wrapper");
      modalWrapper.classList.remove("active");
      // this.$router.go(0);
    },
    isRequired: function(value, compid) {
      if (value == "") {
        this.errormsg[compid] = "Please enter a value";
        this.isvalid.push("req");
        this.errorclasslist[compid] = "form-control errorlist";
      } else {
        // this.isvalid.pop();
      }
    },
    getdata: async function() {
      this.receivedData[0] = await showformService.getdatafromapi(this.formsid);

      this.receivedData[0].pages.forEach((item) => {
        item.field.forEach((subitem, index) => {
          if (
            subitem.type == "RepeatingMeasure" ||
            subitem.type == "repeatedmeasure" ||
            subitem.type == "repeatingfield"
          ) {
            if (
              subitem.repeatingFieldData != null &&
              subitem.repeatingFieldData.length != 0
            ) {
              this.newrepeatingField[index] = subitem.repeatingFieldData;
            }
          }
        });
      });
      this.isVerified = this.receivedData[0].formStatus;
      this.getformtemplate(this.receivedData[0].templateId);
      let mapformvariables = {
        createdUserEmail: this.receivedData[0].createdUserEmail,
        createdUserId: this.receivedData[0].createdUserId,
        createdAt: this.receivedData[0].createdAt,
        lastModifiedUserEmail: this.receivedData[0].lastModifiedUserEmail,
        lastModifiedUserId: this.receivedData[0].lastModifiedUserId,
        lastModifiedAt: this.receivedData[0].lastModifiedAt,
      };
      // console.log("mapform variables");
      // console.log(mapformvariables);
      store.dispatch("setmapform", mapformvariables);
    },
    isnumber: function(value, compid) {
      if (isNaN(value) || value == "") {
        this.errormsg[compid] = "Please enter a number";
        this.isvalid.push("numb");
      } else {
        // this.isvalid.pop();
      }
    },
    isalphaneumeric: function(value, compid) {
      if (value.match(/^[0-9a-z]+$/)) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Please enter Alphanumeric";
        this.isvalid.push("alph");
      }
    },
    lengthrange: function(value, compid, minleng, maxleng) {
      if (value.length >= minleng && value.length <= maxleng) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] =
          "Number of characters should be between " +
          minleng +
          " and " +
          maxleng;
        this.isvalid.push("minlength");
      }
    },
    valuerange: function(value, compid, minval, maxval) {
      if (/^\d+$/.test(value)) {
        //let intval = Integer.parseInt(value);
        let intval = parseInt(value);
        if (intval <= maxval && intval >= minval) {
          // this.isvalid.pop();
        } else {
          this.errormsg[compid] =
            "Please enter a value between " + minval + " and " + maxval;
          this.isvalid.push("maxval");
        }
      } else {
        this.errormsg[compid] =
          "Please enter a value between " + minval + " and " + maxval;
        this.isvalid.push("maxval");
      }
    },
    isemail: function(value, compid) {
      if (value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Invalid email address!";
        this.isvalid.push("mail");
      }
    },
    bindData: function() {
      this.totalpages = this.htmlarray[0].totalPages;
      this.htmlarray[0].pages.forEach((item) => {
        this.groups[item.pageNo] = [];
        this.formdata[`pageNo${item.pageNo}`] = {};
        item.field.forEach((subitem) => {
          if (
            !this.groups[item.pageNo].includes(parseInt(subitem.groupOrderNo))
          ) {
            this.groups[item.pageNo].push(parseInt(subitem.groupOrderNo));
          }
          if (
            subitem.type == "RepeatingMeasure" ||
            subitem.type == "repeatedmeasure" ||
            subitem.type == "repeatingfield"
          ) {
            this.repeatedgroupgroupnos[subitem.fieldName] = [];

            subitem.repeatingFields.forEach((subrepeat, repeatindex) => {
              subrepeat.fields.forEach((field, fieldIndex) => {
                if (
                  !this.repeatedgroupgroupnos[subitem.fieldName].includes(
                    field.groupOrderNo
                  )
                ) {
                  this.repeatedgroupgroupnos[subitem.fieldName].push(
                    field.groupOrderNo
                  );
                }
                // if(!this.formdata[`pageNo${item.pageNo}`][subitem.fieldName])
                // {
                //   this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.repeatingFields;
                // }

                // subitem.repeatingFields.forEach((eachrepeatingdata)=>{
                //   if(!this.formdata[`pageNo${item.pageNo}`][subitem.fieldName][eachrepeatingdata.fieldIdentifier])
                //   {
                //     this.formdata[`pageNo${item.pageNo}`][subitem.fieldName][eachrepeatingdata.fieldIdentifier]= {};
                //   }

                // })
              });
            });
          }
          if (subitem.type == "numberanddate") {
            this.numberanddateval[subitem.fieldName] = {
              numberdata: "",
              datedata: "",
            };
          } else {
            this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = null;
          }
        });
      });
      this.bindValue();
    },
    async deleterepeatedmeasure(item) {
      let indexToDelete = this.repeatedmeasurevalarray.indexOf(item);

      if (indexToDelete !== -1) {
        // Remove the element at the found index
        this.repeatedmeasurevalarray.splice(indexToDelete, 1);
      }
    },
    async addrepeatemesureentries() {
      let temprepeatedval = {};
      this.repratmeasureid = new Date();
      this.temprepeatedmeasureval.ID = this.repratmeasureid;
      temprepeatedval = this.temprepeatedmeasureval;
      this.repeatedmeasurevalarray.push({ ...temprepeatedval });
      temprepeatedval = {};
      this.repeatedmeasurlist();
    },
    async addrepeatedmeasure() {
      console.log(this.selectedrepeatedtemp);
      this.addfield("repeatedmeasure");
    },
    async saverepeatedmeasurdata() {
      this.repeathidden = false;
      this.formdata[`pageNo${this.repeatedpageno}`][
        this.repeatedfieldname
      ] = JSON.stringify(this.repeatedmeasurevalarray);
      this.repeatedmeasurepopup = false;
    },
    async repeatedmeasurlist() {
      this.repeathidden = false;
      this.repeatedmeasurelistpopup = true;
    },
    async repeatedmeasurdatapopup() {
      this.temprepeatedmeasureval = {};
      this.repratmeasureid = new Date();
      this.repeatedmeasurelistpopup = false;
    },
    async reloadrepeatarray(item) {
      this.repeatedmeasurelistpopup = false;
      this.repeathidden = true;
      this.temprepeatedmeasureval = item;
      this.repratmeasureid = item.ID;
    },
    async repeatedmeasuremodal(options, pageNo, fieldName) {
      this.repeatedpageno = pageNo;
      this.repeatedfieldname = fieldName;
      this.repeatedoptions = JSON.parse(options);
      console.log(this.repeatedoptions);
      this.repeatedmeasurepopup = true;
      this.repeatedmeasurelistpopup = true;
    },
    async closerepeatedmeasuremodal() {
      this.repeathidden = false;
      this.repeatedmeasurepopup = false;
    },
    async gridoptfun(fieldname, gridoptions, value) {
      this.gridoptarray[fieldname] = JSON.parse(gridoptions);
      this.gridlinkeddatas[fieldname] = {};
      console.log(JSON.parse(gridoptions)[0].Fielddata);

      this.gridlinkeddatas[fieldname] = JSON.parse(value);
    },
    bindValue: function() {
      console.log("Values are", this.receivedData[0].pages)
  this.receivedData[0].pages.forEach((item,pageindex) => {
    item.field.forEach((subitem,fieldIndex) => {
      if(subitem.type == 'grid'){
        this.gridoptfun(subitem.fieldName, subitem.options[0],subitem.value)
      }
      // if(subitem.type == 'date' && subitem.value){
      //   const dates = subitem.value.slice(0,4);
      //   if(dates.includes("-") || dates.includes("/")){
      //     if(subitem.value.slice(3,5).includes("-") || subitem.value.slice(3,5).includes("/") ){
      //         this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = `${subitem.value.slice(5,9)}-0${subitem.value.slice(3,4)}-${subitem.value.slice(0,2)}`;
      //     }
      //     else{
      //   this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = `${subitem.value.slice(6,10)}-${subitem.value.slice(3,5)}-${subitem.value.slice(0,2)}`;
      //     }
      // }
      //   else{
      //   this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.value;
      //   }
      // }
      
      else if(subitem.type == 'numberanddate'){
        this.numberanddateval[subitem.fieldName] = {
          "numberdata":"",
          "datedata":"",
        }
        if(subitem.value){
        if(subitem.value.includes("_")){
        let tempdatetimearray = subitem.value.split("_");
        const numval = tempdatetimearray[1]
        const dates = tempdatetimearray[0];
        this.numberanddateval[subitem.fieldName] = {
          "numberdata":numval,
          "datedata":dates,
        }
        this.numberanddateval[subitem.fieldName].datedata=this.getformatDateNum(this.numberanddateval[subitem.fieldName].datedata);
        this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.value;
      }
     }
      }
      else if(subitem.type == 'repeatingfield'){
        subitem.repeatingFieldData.forEach((fields)=>{
          fields.fields.forEach((eachfields, eachfieldindex)=>{
            this.visibility[eachfields.fieldName+fields.fieldIdentifier] = eachfields.visibility;
            if(subitem.value!=null && eachfields.value!=""){
              let selectedOptionforRF = null;
              if(eachfields.type == "radio"){
                selectedOptionforRF = eachfields.options.find(
                  (opt) => opt.codevalue === eachfields.value
                );
              }
            this.focusOutFunctionForRF(item.pageNo,subitem.fieldName,fieldIndex,eachfieldindex,eachfields.fieldName,fields.fieldIdentifier, selectedOptionforRF ? selectedOptionforRF.optionname : "");
            }
            if(eachfields.type == "numberanddate"){
              this.repeatednumberanddateval[eachfields.fieldName+fields.fieldIdentifier] = {
                "numberdata":"",
                "datedata":"",
              }
              if(eachfields.value){
              if(eachfields.value.includes("_")){
              let tempdatetimearray = eachfields.value.split("_");
              const numval = tempdatetimearray[1]
              const dates = tempdatetimearray[0];
              this.repeatednumberanddateval[eachfields.fieldName+fields.fieldIdentifier] = {
                "numberdata":numval,
                "datedata":dates,
              }
              this.repeatednumberanddateval[eachfields.fieldName+fields.fieldIdentifier].datedata=this.getformatDateNum(this.repeatednumberanddateval[eachfields.fieldName+fields.fieldIdentifier].datedata)
            }
          }
            }
            if(eachfields.type == "dateandtime"){
              if(eachfields.value){
                let newformatteddate = this.formatDate(eachfields.value,'dateandtime');
                const lastIndex = newformatteddate.lastIndexOf('.');
                const result = newformatteddate.slice(0, 16);
                eachfields.value = result;       
              }
                
            }
            if(eachfields.type == "date"){
              if(eachfields.value && eachfields.value!=null){
              eachfields.value = eachfields.value;
              }       
                 
            }
          })
        })
        
        this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.repeatingFieldData;
    }
    else if(subitem.type == 'dateandtime'){
      if(subitem.value && subitem.value!=null)
      { 
        let newformatteddate = this.formatDate(subitem.value,'dateandtime');
        const lastIndex = newformatteddate.lastIndexOf('.');
        const result = newformatteddate.slice(0, 16);
        this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = result;
      }
     
      }
      else if(this.inputElements.includes(subitem.type)){
        if(subitem.value && subitem.value!=null || subitem.value!="")
        { 
          this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.value;
        }
       
        }
      else{
        this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.value;
      }
      this.visibility[subitem.fieldName] = subitem.visibility;
      if(subitem.value!=null && subitem.value!=""){
        let selectedOption = null;
        if(subitem.type == "radio"){
          selectedOption = subitem.options.find(
            (opt) => opt.codevalue === subitem.value
          );
        }
      this.focusOutFunction(item.pageNo,subitem.fieldName, selectedOption ? selectedOption.optionname : "")
      }
    });
  });
    },
    async repeatednumbanddatefocusoutfun(fieldname) {
      if (!this.repeatednumberanddateval.hasOwnProperty(fieldname)) {
        this.repeatednumberanddateval[fieldname] = {
          numberdata: "",
          datedata: "",
        };
      }
    },

    isValidDateFormat: function(dateString) {
      const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
      return dateFormatRegex.test(dateString);
    },
    testfun: function() {
      this.isvalid = [];
      this.validate();
      if (this.isvalid.length == 0) {
        if (this.issavefun) {
          if (this.totalpages == 1) {
            console.log("in save fun");
            this.savefun();
          } else {
            console.log("in edit fun");
            this.editFun();
          }
        }
      }
    },
    validate: function() {
      this.htmlarray[0].pages.forEach((item) => {
        let pagenumber = `pageNo${item.pageNo}`;
        if (item.pageNo == this.pageno) {
          item.field.forEach((subitem) => {
            this.errorclasslist[subitem.id] = "form-control";
            if (subitem.validation != "") {
              subitem.validation.forEach((valid) => {
                if (valid == "Required") {
                  this.isRequired(
                    this.formdata[pagenumber][subitem.fieldName],
                    subitem.fieldName,
                    subitem.status
                  );
                }
                if (
                  valid == "Number" &&
                  this.formdata[pagenumber][subitem.fieldName] != ""
                ) {
                  this.isnumber(
                    this.formdata[pagenumber][subitem.fieldName],
                    subitem.fieldName
                  );
                }
                if (
                  valid == "Alphanumeric" &&
                  this.formdata[pagenumber][subitem.fieldName] != ""
                ) {
                  this.isalphaneumeric(
                    this.formdata[pagenumber][subitem.fieldName],
                    subitem.fieldName
                  );
                }
                if (
                  valid == "email" &&
                  this.formdata[pagenumber][subitem.fieldName] != "" &&
                  this.formdata[pagenumber][subitem.fieldName] != null
                ) {
                  this.isemail(
                    this.formdata[pagenumber][subitem.fieldName],
                    subitem.fieldName
                  );
                }
                if (
                  valid.includes("lengthrange") &&
                  this.formdata[pagenumber][subitem.fieldName] != "" &&
                  this.formdata[pagenumber][subitem.fieldName] != null
                ) {
                  let regExp = /\(([^)]+)\)/;
                  let matches = regExp.exec(valid);
                  const myArr = matches[1].split(",");
                  let minrange = parseInt(myArr[0]);
                  let maxrange = parseInt(myArr[1]);
                  this.lengthrange(
                    this.formdata[pagenumber][subitem.fieldName],
                    subitem.fieldName,
                    minrange,
                    maxrange
                  );
                }

                if (
                  valid.includes("valuerange") &&
                  this.formdata[pagenumber][subitem.fieldName] != ""
                ) {
                  console.log("inside valuerange functiuon");
                  let regExp = /\(([^)]+)\)/;
                  let matches = regExp.exec(valid);
                  const myArr = matches[1].split(",");
                  console.log("myArr is " + myArr);
                  let minval = parseInt(myArr[0]);
                  let maxval = parseInt(myArr[1]);
                  this.valuerange(
                    this.formdata[pagenumber][subitem.fieldName],
                    subitem.fieldName,
                    minval,
                    maxval
                  );
                }
              });
            }
          });
        }
      });
    },
    // save and edit starts

    async savefun() {
      alert("hello" + this.receivedData[0].templateId);
      this.sendData.totalPages = this.htmlarray[0].total_Pages;
      let createdpage = {};
      createdpage.pageName = this.htmlarray[0].pages[0].pageName;
      createdpage.pageNo = this.htmlarray[0].pages[0].pageNo;
      createdpage.pageStatus = this.htmlarray[0].pages[0].status;
      createdpage.field = [];
      this.htmlarray[0].pages[0].field.forEach((subitem) => {
        let val;
        if (subitem.type == "checkbox") {
          val = this.formdata[`pageNo${this.pageno}`][
            subitem.fieldName
          ].toString();
        }
        if (subitem.type == "numberanddate") {
          val =
            this.formatDateNum(
              this.numberanddateval[subitem.fieldName].datedata
            ) +
            "_" +
            this.numberanddateval[subitem.fieldName].numberdata;
        }
        if (subitem.type == "dateandtime") {
          val = this.formatDateTime(
            this.formdata[`pageNo${this.pageno}`][subitem.fieldName]
          );
        }
        if (subitem.type == "date") {
          val = this.payloadDate(
            this.formdata[`pageNo${this.pageno}`][subitem.fieldName]
          );
        } else {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
        }
        createdpage.field.push({
          fieldName: subitem.fieldName,
          label: subitem.label,
          type: subitem.type,
          value: val,
          status: "Not validated",
          validation: subitem.validation,
          groupOrderNo: subitem.groupOrderNo,
          validatedBy: "",
          limit: subitem.limit,
          validatedAt: "",
          comment: "",
          required: subitem.required,
          options: subitem.options,
          imagePath: subitem.imagePath,
        });
      });
      this.sendData.pages.push(createdpage);
      const idforsave = await formService.apicall(this.sendData);
      idforsave.then((result) => {
        this.saveid = result;
      });
    },
    editFun: function() {
      console.log("pageno is " + (this.pageno - 1));
      const pgn = this.pageno - 1;
      let createdpage = {};
      createdpage.formId = this.saveid;
      createdpage.isArchived = true;
      createdpage.sourceID = this.iPaddress;
      createdpage.dataSource = "Web Browser";
      createdpage.pages = [];
      createdpage.pages[0] = {};
      createdpage.pages[0].pageName = this.htmlarray[0].pages[pgn].pageName;
      createdpage.pages[0].pageNo = this.htmlarray[0].pages[pgn].pageNo;
      createdpage.pages[0].pageStatus = this.htmlarray[0].pages[pgn].status;
      createdpage.pages[0].field = [];
      this.htmlarray[0].pages[pgn].field.forEach((subitem) => {
        let val;
        if (subitem.type == "checkbox") {
          val = this.formdata[`pageNo${this.pageno}`][
            subitem.fieldName
          ].toString();
        }
        if (subitem.type == "date") {
          val = this.payloadDate(
            this.formdata[`pageNo${this.pageno}`][subitem.fieldName]
          );
        } else {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
        }
        createdpage.pages[0].field.push({
          fieldName: subitem.fieldName,
          label: subitem.label,
          type: subitem.type,
          value: val,
          status: "Not validated",
          validation: subitem.validation,
          groupOrderNo: subitem.groupOrderNo,
          validatedBy: "",
          limit: subitem.limit,
          validatedAt: "",
          comment: "",
          required: subitem.required,
          options: subitem.options,
          imagePath: subitem.imagePath,
          audit: [
            {
              oldValue: "",
              newValue: "",
              validatedBy: "",
              validatedDate: "2021-08-26T03:39:38.382Z",
              validatedTime: "2021-08-26T03:39:38.382Z",
              status: true,
              comment: "",
            },
          ],
        });
      });
      // console.log(JSON.stringify(createdpage));
      formService.editdata(createdpage);
    },

    // save and edit ends

    nextfun: function() {
      this.isvalid = [];
      this.validate();
      let totalpg = parseInt(this.totalpages);
      if (this.isvalid.length == 0) {
        if (this.pageno < totalpg) {
          if (this.issavefun) {
            if (this.pageno == 1) {
              console.log("pageno is one");
              this.savefun();
            } else {
              console.log("pageno is not one");
              this.editFun();
            }
          }
          this.pageno++;
          // }
        }
      }
    },
    async editedenevt() {
      alert(changed);
    },
    onpagechange: function(pageNumber) {
      console.log("Selected value is", this.pageNumber);
      console.log("Page no is", this.pageno);
      this.pageno = pageNumber;
    },
    async onformsave() {
      this.validate();
      if (this.isvalid.length > 0) {
        alert(
          "Some of the fields in the form contains invalid data. Validation Failed"
        );
      } else {
        this.pageSaveResult = [];
        this.htmlarray[0].pages.forEach(async (item) => {
          this.pagesavefieldnames = [];
          item.field.forEach((subitem) => {
            if (subitem.fieldName.includes("header")) {
            } else {
              this.pagesavefieldnames.push(subitem.fieldName);
            }
          });

          await this.formsaveapicall(item.pageNo);
        });
      }
      await this.getSignStatus();

      await this.setformid();
      await this.getById();
      await this.getPageName();
      await this.getFormHeader();
      await this.patientId(this.forms[0].subjectId);
      await this.siteId(this.forms[0].siteId);
      await this.FormOpenQueryCount();
      await this.FormCloseQueryCount();
      //});

      if (!this.pageSaveResult.includes(false)) {
        alert(
          this.$t("navigations.page validated successfully"),
          this.pageSaveResult.includes(false).length
        );
        console.log(
          "Form validated successfully",
          this.pageSaveResult.includes(false).length
        );
      }
      console.log("pageSaveResult is ...", this.pageSaveResult);
      await this.setformid();
    },

    async formsaveapicall(pagenumber) {
      // alert("validation in page "+pagenumber);
      let data = {
        formId: this.formsid,
        pageno: pagenumber,
        dataSource: "Web Browser",
        sourceID: this.iPaddress,
        fieldnames: this.pagesavefieldnames,
      };
      console.log("page save api call fn, data is ", data);
      const idtoken = store.getters.getIdToken;
      await axios
        .put(`${this.baseurl}/forms/forms/updatefieldsfrompagesave`, data, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.pageSaveResult.push(true);
          // if (this.pageno == 1) {
          //   //alert("Page validated successfully");
          // }
        })
        .catch((err) => {
          console.log(err);
          this.pageSaveResult.push(false);
          alert(`page ${this.pageno} is not validated`);
        });
    },
    async onpagesave() {
      this.pagesavefieldnames = [];
      this.htmlarray[0].pages.forEach((item) => {
        this.validate();
        // this.htmlarray[0].pages.forEach((item) => {
        // let pagenumber = `pageNo${item.pageNo}`;
        if (item.pageNo == this.pageno) {
          item.field.forEach((subitem) => {
            if (subitem.fieldName.includes("header")) {
            } else {
              this.pagesavefieldnames.push(subitem.fieldName);
            }
          });
          let showalert = false;
          if (this.isvalid.length > 0) {
            showalert = true;
            console.log("Show confirm alert on page save");
          }
          this.pagesave(showalert);
          // window.location.reload();
        }
      });
      await this.getSignStatus();

      await this.setformid();
      await this.getById();
      await this.getPageName();
      await this.getFormHeader();
      await this.patientId(this.forms[0].subjectId);
      await this.siteId(this.forms[0].siteId);
      await this.FormOpenQueryCount();
      await this.FormCloseQueryCount();
      //});

      if (!this.pageSaveResult.includes(false)) {
        alert(
          this.$t("navigations.page validated successfully"),
          this.pageSaveResult.includes(false).length
        );
        console.log(
          "Page validated successfully",
          this.pageSaveResult.includes(false).length
        );
      }
      console.log("pageSaveResult is ...", this.pageSaveResult);
      await this.setformid();
    },
    async pagesave(showalert) {
      if (showalert == true) {
        alert(
          "Some of the fields in the page contains invalid data. Validation Failed"
        );
      } else {
        await this.pagesaveapicall();
      }
    },
    async pagesaveapicall() {
      this.pageSaveResult = [];
      let data = {
        formId: this.formsid,
        pageno: this.pageno,
        dataSource: "Web Browser",
        sourceID: this.iPaddress,
        fieldnames: this.pagesavefieldnames,
      };
      console.log("page save api call fn, data is ", data);
      const idtoken = store.getters.getIdToken;
      await axios
        .put(
          `${this.baseurl}/forms/forms/updatefieldsfrompagesave`,
          data,
          //.put(`https://localhost:5002/forms/updatefieldsfrompagesave`, data ,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.pageSaveResult.push(true);
          // if (this.pageno == 1) {
          //   //alert("Page validated successfully");
          // }
        })
        .catch((err) => {
          console.log(err);
          this.pageSaveResult.push(false);
          alert(`page ${this.pageno} is not validated`);
        });
    },
    async getSignStatus() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/forms/signstatus?formId=${this.formsesid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Form sign is", res);
          this.signdate = res.data.signedAt;
          this.signtime = this.signdate;
          this.signedby = res.data.signedUserName;
          this.signStatus = res.data.signStatus;
          this.loggedinUser = res.data.loggedInUser;
          console.log("Form sign status is", this.signStatus);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async alertfun() {
      console.log("hello test");
    },
    previousFun: function() {
      if (this.pageno > 1) {
        console.log("previous page");
        this.pageno--;
      }
    },
    incrementcount: function() {
      this.totalpages++;
      console.log("increment" + this.totalpages);
    },
  },
  watch: {
    pageno(newpageno, oldpageno) {
      if (newpageno >= 1) {
        window.scrollTo(0, 0);
      }
    },
  },
};
