<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>{{ $t("navigations.sdtm to adam conversion") }}</h2>
        </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="ibox-content mb-1 px-3 py-2">
            <div class="row">
                <div class="col-12 co-md-6 input-group">
                    <input accept=".xls,.xlsx" id="InPutFile" type="file" class="form-control my-2 my-lg-0"
                        @change="previewFiles" />
                        
                    <span class="input-group-append">
                        <button type="button" class="stdy_upload_btn mr-0 py-1" @click="uploadDocument()"
                            :disabled="showLoading">
                            <div class="d-flex align-items-center" v-if="showLoading">
                                <img class="loading-img" width="20" src="../../assets/img/loading.webp"
                                    alt="Wait while the document is added" />
                            </div>
                            <div v-else>{{ $t("navigations.import") }}</div>
                        </button>
                    </span>
                </div>
                <div class="errorClass" v-if="validations[0] == true">
                    {{ $t("navigations.please upload a file") }}
                </div>
            </div>
        </div>

    </div>
</template>
<script src="./sdtm_conversion.js"></script>