<template>
  <div class="wrapper wrapper-content">
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5>{{ $t("navigations.site list") }}</h5>
      </div>
      <div class="ibox-content">
        <div v-if="sites.length === 0" class="no-data-message text-center">
          <p>No data available.</p>
        </div>
        <div v-else>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>
                    Site Code <sup><i class="fa fa-asterisk imp"></i></sup>
                  </th>
                  <th>Site Name</th>
                  <th>Site Contact Name</th>
                  <th>Site Contact Email</th>
                  <th>Status</th>
                  <th>Number of Subjects</th>
                  <th>Site Notes</th>
                  <th class="text-center">Edit</th>
                  <th class="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(site, index) in sites" :key="site.siteId">
                  <td v-if="editIndex !== index">{{ site.siteCode }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.siteCode"
                      class="form-control"
                      type="text"
                      @input="clearFieldError('siteCode')"
                    />
                    <div v-if="validationErrors.siteCode" class="text-danger">
                      {{ validationErrors.siteCode }}
                    </div>
                  </td>
                  <td v-if="editIndex !== index">
                    <div class="wrap-text">{{ site.siteName }}</div>
                  </td>
                  <td v-else>
                    <textarea
                      v-model="editableRow.siteName"
                      class="form-control"
                      type="text"
                      :cols="30"
                      :rows="3"
                      style="width: 100%;"
                    />
                  </td>
                  <td v-if="editIndex !== index">{{ site.siteContactName }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.siteContactName"
                      class="form-control"
                      type="text"
                    />
                  </td>
                  <td v-if="editIndex !== index">
                    {{ site.siteContactEmail }}
                  </td>
                  <td v-else>
                    <input
                      v-model="editableRow.siteContactEmail"
                      class="form-control"
                      type="email"
                    />
                  </td>
                  <td v-if="editIndex !== index">{{ site.status }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.status"
                      class="form-control"
                      type="text"
                    />
                  </td>
                  <td v-if="editIndex !== index">
                    {{ site.numberOfSubjects }}
                  </td>
                  <td v-else>
                    <input
                      v-model="editableRow.numberOfSubjects"
                      @change="checknegative"
                      class="form-control"
                      type="number"
                    />
                  </td>
                  <td v-if="editIndex !== index">
                    <div class="wrap-text">{{ site.siteNotes }}</div>
                  </td>
                  <td v-else>
                    <textarea
                      v-model="editableRow.siteNotes"
                      class="form-control"
                      type="text"
                      :cols="30"
                      :rows="3"
                      style="width: 100%;"
                    ></textarea>
                  </td>
                  <td class="text-center">
                    <template v-if="editIndex !== index">
                      <i
                        class="fa fa-edit edit-delete"
                        v-if="protocol.status !== 'Generated'"
                        @click.prevent="editRow(index, site)"
                      ></i>
                    </template>
                    <template v-else>
                      <i
                        class="fa fa-check action-icon text-success"
                        @click="updateRow(site.id, site.studyId)"
                        title="Update"
                      ></i>
                      <i
                        class="fa fa-times action-icon text-danger"
                        @click="cancelEdit"
                        title="Cancel"
                      ></i>
                    </template>
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-trash edit-delete"
                      v-if="protocol.status !== 'Generated'"
                      style="color: #a13b7c"
                      @click="deleteRow(site.id)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Pagination  -->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  "
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="currentPage"
                    :pages="totalPages"
                    :range-size="0"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.action-icon {
  cursor: pointer;
  font-size: 18px;
  margin: 0 5px;
}

.action-icon:hover {
  opacity: 0.8;
}

.text-success {
  color: #28a745;
}

.text-danger {
  color: #dc3545;
}
.wrap-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 400px;
  overflow: hidden;
}

textarea.form-control {
  resize: vertical;
  max-height: 150px;
}
</style>
<script src="./SiteManagement.js"></script>
