/* eslint-disable */
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
import jwt_decode from "jwt-decode";
import store from "../../store/index";
import auth0 from "auth0-js";
import {
    ref,
    computed,
    onBeforeMount
  } from "vue";

export default {
    name: "landingpage",
    setup() {
        let valnow = ref("");
        const router = useRouter();
        const profileBlock = ref(false)
        let baseurl = ref(`${process.env.VUE_APP_Service_URL}`);
        let currentTheme = ref(localStorage.getItem("theme-color"));
        let webAuth = new auth0.WebAuth({
            domain: "dev-datamatica.eu.auth0.com",
            clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
          });
        onBeforeMount(async () => {
         store.dispatch("setNavbar",false)
         document.getElementById("page-wrapper").style.width="100%"
         if (currentTheme.value == null) {
          localStorage.setItem("theme-color", "theme-default");
          currentTheme.value = localStorage.getItem("theme-color");
        }
        });
         router.beforeEach((to, from, next) => {
          if(to.name!='profileSettings'){
            store.dispatch("setNavbar",true)
            document.getElementById("page-wrapper").style.removeProperty("width");
          }     
          else{
            document.getElementById("page-wrapper").style.width="100%"
          }
          next()
        });
    let usersdetails = computed(function () {
      valnow.value = store.getters.getStudyIs;
      return jwt_decode(store.getters.getIdToken);
    });
    function displayProfile() {
        const profile = document.querySelector(".profile-block");
        const userProfile = document.querySelector(".tool-tip")
        if (profile.style.display == "block") {
          profile.style.display = "none";
        } else profile.style.display = "block";
        if (userProfile.style.display == "block") {
            userProfile.style.display = "none";
          }
      }
      async function logout() {
        await logoutaudit();
        let a = localStorage.clear();
        // let b = auth0Client.value.logout();
        let b = webAuth.logout({
          returnTo: `${process.env.VUE_APP_logout_URL}`,
          clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
        });
        return a && b;
      }
      async function logoutaudit() {
        const token = store.getters.getIdToken;
        const emailId = await jwt_decode(token).email;
        await axios
          .post(
            `${baseurl.value}/account-core/user/saveloginaudit`,
            {
              userEmail: emailId,
              logdetails: [
                {
                  action: "Log Out",
                  date: "2022-08-30T09:10:42.634Z",
                  time: "2022-08-30T09:10:42.634Z",
                },
              ],
            },
            {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("login audit res", res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      function setstatus(screen) {
        switch (screen) {
            case 'AI/Ml':
                store.dispatch("setDefaultMenu", "AI/Ml");
                break;
            case 'Analytical':
                store.dispatch("setDefaultMenu", "Analytical");
                break;
            case 'clinical':
                store.dispatch("setDefaultMenu", "Clinical");
                break;
            case 'stats':
              store.dispatch("setDefaultMenu", "Stats");
              break;
              
            default:
                console.warn("Invalid screen type:", screen);
                break;
        }
    }
      async function expandSidebar() {
        let x = document.getElementsByClassName("theme-menu")[0];
        if (x.style.display == "block") {
          x.style.display = "none";
        } else x.style.display = "block";
      }

      function switchTheme(theme) {
        localStorage.setItem("theme-color", theme);
        currentTheme.value = localStorage.getItem("theme-color");
      }

      return {
        usersdetails,
        displayProfile,
        valnow,
        profileBlock,
        logout,
        setstatus,
        switchTheme,
        expandSidebar       
      };

    }
}