/* eslint-disable */
// import jwtInterceptor from "../Shared/jwtInterceptor";
import axios from "axios";
import baseinput from "./form_elements/baseinput";
import basedate from "./form_elements/basedate";
import basedatetime from "./form_elements/basedatetime";
import basenumber from "./form_elements/basenumber";
import baseselect from "./form_elements/baseselect";
import basecheckbox from "./form_elements/basecheckbox";
import baseradiogroup from "./form_elements/baseradiogroup"
import * as formService from "./dynamicformservice";
import digitalFormModal from "../../components/digital_form_modal/digital_form_modal.vue"
import store from "../../store/index";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import QRCode from 'qrcode'
import { isConstructorDeclaration, isThrowStatement } from "typescript";
import CreateVideoCallSchedulePopUp from "../../components/CreateVideoCallSchedulePopUp/CreateVideoCallSchedulePopUp.vue";
import ValidationMessage from "./errormessages.vue";
import basetime from "./form_elements/basetime.vue";
import { toRaw } from 'vue';

const idtoken = store.getters.getIdToken;
export default {
  name: "newtemplate",
  async mounted() {
    this.getbyidforautofill();
    this.geteditcheckbytempid();
    this.CurrentPatientID = localStorage.getItem("patid");
  },
  async unmounted() {
    await store.dispatch("setautofillsiteid", null);
    await store.dispatch("setautofillsubjectid", null);
    await store.dispatch("setfrompatientschedule", null);
    let tempvar = {
      "Siteid": null,
      "Subject_id": null,
      "Visitname" : null,
      "Visitno" : null,
      "sheduleId":null
  }
    await store.dispatch("commitvisitdetails",tempvar);
  },
  
  async beforeMount() {
    // this.baseurl= process.env.VUE_APP_service_URL
    await this.retrievevisitinfo();
    this.encodedFormList = this.$route.query.tempObj;
    console.log("encodedFormList", this.encodedFormList);
    if (this.encodedFormList != undefined) {
      const decodedFormList = JSON.parse(decodeURIComponent(this.encodedFormList));
      this.selectedFormData = decodedFormList
    }
    if (this.isnewLink === true) {
      await this.getTemplate()
    }
    else {
      if (this.templatebasedon == "general") {
        await this.getformtemplategeneral();
      }
      else {
        await this.getformtemplate();
      }
    }
    await this.getuserIp();
    await this.languagelist();
    await this.getallroles()
    await this.getuserIp();
  },
  props: {
    isPatientVisit: {
      type: Boolean,
      default: false
    },
    templateid: {
      type: String,
      default: ''
    },
    stdyid: {
      type: String,
      default: ''
    },
    visitids: {
      type: String,
      default: ''
    },
    skipcheckingdependency:{
      type: Boolean,
      default: false
    },
    issavefun: {
      type: Boolean,
      default: false
    },
    requiredvalidate: {
      type: Boolean,
      default: true
    },
    requiredsubmit: {
      type: Boolean,
      default: true
    },
    requiredlanguage: {
      type: Boolean,
      default: true
    },
    requiredrole: {
      type: Boolean,
      default: false
    },
    fromUrlStatus: {
      type: Boolean,
      default: false
    },
    isSigned: {
      type: Boolean,
      default: false
    },
    isnewLink: {
      type: Boolean,
      default: false
    },
    templateName: {
      type: String,
      default: ''
    },
    templatebasedon: {
      type: String,
      default: 'category'
    }
  },
  components: {
    baseinput,
    QRCode,
    baseselect,
    basecheckbox,
    baseradiogroup,
    basedate,
    basenumber,
    basedatetime,
    digitalFormModal,
    VPagination,
    CreateVideoCallSchedulePopUp,
    ValidationMessage,
    basetime
  },
  data() {
    return {
      isvalidate:true,
      BlankForm:"BlankForm",
      CreateVideoCallSchedulePopUp:false,
      CurrentPatientID:"",
      autofield: [
        {
          "site_sub_autofill": [
            {
              "autofill": true,
              "labelsubject": "",
              "labelsite": ""
            }
          ]
        }
      ],
      encodedFormList: null,
      patientsId: "",
      sitesId: "",
      nextPageClick: false,
      repeatedgroupgroupnos: {},
      isrepeatdatavalid: {},
      selectedFormData: {},
      isrepeatvalid: {},
      calcfunvar: {},
      repeathidden: false,
      shouldContinue: true,
      repeatedpageno: "",
      repeatedfieldname: "",
      selectedrepeatedgroupno: 1,
      repeatedmeasurepopup: false,
      repeatedmeasurelistpopup: true,
      repeatedoptions: "",
      repeatedmeasurevalarray: [],
      temprepeatedmeasureval: {},
      repratmeasureid: new Date(),
      calculatedresult: {},
      previouscalculatedresult: {},
      currentgrid: [],
      gridoptarray: {},
      gridlinkeddatas: {},
      griddata: [
        {
          Rows: [""],
          Columns: [""],
          Fieldtypes: ["text"],
          Fielddata: {},
          Fieldoptions: {}
        }
      ],
      fileid: "",
      filepageno: null,
      filefieldname: null,
      numberanddateval: {},
      repeatednumberanddateval: {},
      qrcodetxt: {},
      selectedprivilege: "",
      selectedfield: "",
      selectedrole: "",
      fieldprivilegepopup: false,
      visitname: "",
      islanenglish: 'hr',
      fullPage: true,
      laguageselected: "",
      templist: "",
      versionlist: "",
      sourceTemplateId: "",
      language: [],
      roles: [],
      selectedLanguage: "",
      displayLanguage: false,
      showModal: false,
      groupNo: 0,
      newTempID: "",
      fileuploadurl: process.env.VUE_APP_File_upload_URL + "/document",
      baseurl: process.env.VUE_APP_Service_URL,
      saveid: "",
      editData: "",
      visitno: "",
      iPaddress: "",
      groups: {},
      repeatGroups: {},
      errormsg: {},
      errorsort: {},
      formdata: {},
      sendData: {
        sheduleID: "",
        formId: "",
        templateId: "",
        language: [],
        code: "",
        studyId: "",
        folderId: "",
        siteId: "",
        subjectId: "",
        visitId: "",
        visitName: "",
        totalPages: 0,
        version: "",
        formStatus: "New",
        formName: "",
        isDeleted: false,
        pages: [],
        sourceID: "",
        dataSource: "Web Browser"
      },
      errorclasslist: {},
      requiredclasslist: {},
      isvalid: [],
      bindarray: {
        page1: { siteCode: "test value", subjectNo: "" },
        page2: "",
        page3: "",
      },
      pageno: 1,
      totalpages: 0,
      initValue: 1,
      uploads: ["photo", "signature", "file"],
      inputElements: [
        "color",
        "email",
        "file",
        "hidden",
        "month",
        "number",
        "password",
        "range",
        "search",
        "tel",
        "text",
        "url",
        "week",
        "boxed",
        "textbox",
      ],
      htmlarray: [],
      sampledata: "",
      frompatid:"",
      messagetype:{},
      fileInputFocused: false,
      editcheckvar:{},
      calculatedfields:[],
      visibility:{},
    };
  },
  methods:
  {
    gettimeformat(validation) {
      if (!Array.isArray(validation) || validation.length === 0) {
        return 'h:mm A'; // Default value when array is empty
      }
    
      // Find the element that starts with "DATEFORMAT"
      const timeFormatElement = validation.find((item) => item.startsWith("TIMEFORMAT"));
    
      if (timeFormatElement) {
        // Extract the string between parentheses
        const match = timeFormatElement.match(/\(([^)]+)\)/); // Regular expression to capture text inside parentheses
        return match ? match[1] : 'h:mm A'; // Return the captured group or default
      }
    
      return 'h:mm A'; // Default value when no matching element is found
    },
    getdateformat(validation) {
      if (!Array.isArray(validation) || validation.length === 0) {
        return 'DD-MM-YYYY'; // Default value when array is empty
      }
    
      // Find the element that starts with "DATEFORMAT"
      const dateFormatElement = validation.find((item) => item.startsWith("DATEFORMAT"));
    
      if (dateFormatElement) {
        // Extract the string between parentheses
        const match = dateFormatElement.match(/\(([^)]+)\)/); // Regular expression to capture text inside parentheses
        return match ? match[1].replace(/[a-z]/g, char => char.toUpperCase()) : 'MM-DD-YYYY'; // Return the captured group or default
      }
    
      return 'DD-MM-YYYY'; // Default value when no matching element is found
    },
    onFileInputFocus(fieldName) {
      this.fileInputFocused = true;
    },
    onFileInputBlur(pageNo, fieldName, validations) {
      // Only trigger this if there was a focus previously, meaning the user opened the dialog
      if (this.fileInputFocused) {
          this.focusOutFunctionForFileUpload(pageNo, fieldName, validations);
        }
    },
    focusOutFunctionForFileUpload(pageNo,fieldName,validations){
      this.errormsg[fieldName] = "";
      this.messagetype[fieldName] = "";
      if(validations.includes("Required") || validations.includes("REQUIRED")){
        if(this.formdata[`pageNo${pageNo}`][fieldName] == "" || this.formdata[`pageNo${pageNo}`][fieldName] == null){
          this.errormsg[fieldName] = "Please upload a file to proceed.";
          this.messagetype[fieldName] = "Alert"
        }
      }
    },
    numberanddateblurfun(pageNo,fieldName)
    {
      const numbdata = this.numberanddateval[fieldName].numberdata;
      const datedata = this.numberanddateval[fieldName].datedata;
      this.formdata[`pageNo${pageNo}`][fieldName] = this.formatDateNum(datedata) + "_" + numbdata;
      this.focusOutFunction(pageNo,fieldName,'');
    },
    async getrepeatedValueByKey(key,fieldname,identifier) {
      for (const page in this.formdata) {
          if (this.formdata[page].hasOwnProperty(fieldname)) {
            console.log("formdata",this.formdata);
              return this.formdata[page][fieldname][identifier][key];
          }
      }
      return null; // Return null if the key is not found
  },
    async getValueByKey(key) {
      for (const page in this.formdata) {
          if (this.formdata[page].hasOwnProperty(key)) {
              return this.formdata[page][key];
          }
      }
      return null; // Return null if the key is not found
  },
    async focusOutFunction(pageno,fieldname,sentvalue) {
      this.errormsg[fieldname] = "";
      if(this.messagetype[fieldname]){
        delete this.messagetype[fieldname];
      }
     
      if(this.isvalid.includes(fieldname)){
        const index = this.isvalid.indexOf(fieldname);
        this.isvalid.splice(index, 1);

      }
      let value = "";
      if(sentvalue!=''){
        value = sentvalue;
      }
      else{
        value = this.formdata[`pageNo${pageno}`][fieldname];
      }
      if (value === true) {
        value = "true";
      } else if (value === false) {
        value = "false";
      }
     console.log("value",value);
      let idtoken = store.getters.getIdToken;
      if(value == null || value == undefined){
        value = "";
      }
      value = value.toString();
      let calcfieldarray = await this.getCalculationsForField(fieldname);
      console.log("calcfieldarray",calcfieldarray);
      let computedfieldobj={};
      if(calcfieldarray.length > 0){
      await calcfieldarray.forEach(async (eachfields)=>{
        computedfieldobj[eachfields] = await this.getValueByKey(eachfields);
      })
    }
      
      let checkdata = {
        "fiedValue": value,
        "fieldName": fieldname,
        "templateId": this.templateid,
        "computeFields": computedfieldobj
      }
      await axios.post(`${this.baseurl}/forms/editcheck/validatefield`,checkdata,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          const fieldVisibility = res.data.fieldVisibility;
          const computedField = res.data.computedField;
          if(res.data.validations.length > 0){
          const errval = res.data.validations[0];
          
          if(errval.messageType == "Alert"){
            if(!this.isvalid.includes(fieldname)){
           this.isvalid.push(fieldname);
         }
          }
          this.errormsg[fieldname] = errval.message;
          this.messagetype[fieldname] = errval.messageType;
          
        }
        if(fieldVisibility.length > 0){
          fieldVisibility.forEach((eachfieldvisibility)=>{
           this.visibility[eachfieldvisibility.fieldName] = eachfieldvisibility.isVisible
          })
         }
        if(computedField.length > 0){
          computedField.forEach((eachcomputedField)=>{
           console.log(this.htmlarray[0].pages[0]);
           this.htmlarray[0].pages.forEach((eachpages)=>{
             eachpages.field.forEach((eachfields)=>{
               if(eachfields.fieldName == eachcomputedField.fieldName){
                this.formdata[`pageNo${eachpages.pageNo}`][eachcomputedField.fieldName] = eachcomputedField.value;
               }
             })
           })
          })
         }
        })
        .catch((err) => {
          console.log(err);
        });
        
      // This function gets called when the input loses focus
      
      },
      async geteditcheckbytempid() {

      let idtoken = store.getters.getIdToken;

      await axios.get(`${this.baseurl}/forms/editcheck/geteditcheckbytempid?tempId=${this.templateid}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
         this.editcheckvar = res.data;
         this.editcheckvar.fields.forEach((eacheditcheck)=>{
          console.log("eacheditcheck",eacheditcheck);
          if(eacheditcheck.calculations.length != 0){
            eacheditcheck.calculations.forEach((calculationoutput)=>{
              let targetfield = calculationoutput.outputfield;
              console.log("targetfield",targetfield);
                if(!this.calculatedfields.includes(targetfield)){
                    this.calculatedfields.push(targetfield);
                 }
            })
          }
          
        })
        
        })
        .catch((err) => {
          console.log(err);
        });
      },
      getCalculationsForField(fieldName) {
        console.log("fieldName",fieldName);
        console.log("this.editcheckvar",this.editcheckvar)

  if (!this.editcheckvar || !Array.isArray(this.editcheckvar.fields)) {
    return [];
  }

  const fieldObj = this.editcheckvar.fields.find(field => field.fieldName === fieldName);
        if(fieldObj){
          console.log("fieldObj.calculations",fieldObj.calculations);
            return fieldObj.calculations != null && fieldObj.calculations.length != 0 ? fieldObj.calculations[0].fields : [];
        }
        else{
          return [];
        }
  
},
async focusOutFunctionForRF(pageno,mainfieldname,fieldname,fieldidentifier,sentvalue) {
  this.errormsg[fieldname+fieldidentifier] = "";
  if(this.messagetype[fieldname+fieldidentifier]){
    delete this.messagetype[fieldname+fieldidentifier];
  }
 
  if(this.isvalid.includes(fieldname+fieldidentifier)){
    const index = this.isvalid.indexOf(fieldname+fieldidentifier);
    this.isvalid.splice(index, 1);

  }
  let value = "";
if(sentvalue!=''){
  value = sentvalue;
}
else{
  value = this.formdata[`pageNo${pageno}`][mainfieldname][fieldidentifier][fieldname];
}
if (value === true) {
  value = "true";
} else if (value === false) {
  value = "false";
}
 console.log("value",value);
  let idtoken = store.getters.getIdToken;
  if(value == null || value == undefined){
    value = "";
  }
  value = value.toString();
  let calcfieldarray = await this.getCalculationsForField(fieldname);
  console.log("calcfieldarray",calcfieldarray);
  let computedfieldobj={};
  if(calcfieldarray.length > 0){
  await calcfieldarray.forEach(async (eachfields)=>{
    computedfieldobj[eachfields] = await this.getrepeatedValueByKey(eachfields,mainfieldname,fieldidentifier);
  })
}
  
  let checkdata = {
    "fiedValue": value,
    "fieldName": fieldname,
    "templateId": this.templateid,
    "computeFields": computedfieldobj
  }
  await axios.post(`${this.baseurl}/forms/editcheck/validatefield`,checkdata,
    {
      headers: {
        Authorization: "Bearer " + idtoken,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log(res.data);
      const fieldVisibility = res.data.fieldVisibility;
      const computedField = res.data.computedField;
      if(res.data.validations.length > 0){
      const errval = res.data.validations[0];
      
      if(errval.messageType == "Alert"){
        if(!this.isvalid.includes(fieldname+fieldidentifier)){
       this.isvalid.push(fieldname+fieldidentifier);
     }
      }
      this.errormsg[fieldname+fieldidentifier] = errval.message;
      this.messagetype[fieldname+fieldidentifier] = errval.messageType;
      
    }
    if(fieldVisibility.length > 0){
      fieldVisibility.forEach((eachfieldvisibility)=>{
        console.log("visiblefieldidentifier=",fieldidentifier);
       this.visibility[eachfieldvisibility.fieldName+fieldidentifier] = eachfieldvisibility.isVisible
      })
     }
    if(computedField.length > 0){
      computedField.forEach((eachcomputedField)=>{
       this.htmlarray[0].pages.forEach((eachpages)=>{
         eachpages.field.forEach((eachfields)=>{
          if (eachfields.type == 'RepeatingMeasure' || eachfields.type == 'repeatedmeasure' || eachfields.type == 'repeatingfield') {
            eachfields.repeatingFields.forEach((subrepeat, repeatindex) => {
              console.log("subrepeat",subrepeat);
              subrepeat.fields.forEach((repfields)=>{
if(repfields.fieldName == eachcomputedField.fieldName){
  console.log("this.formdata[`pageNo${eachpages.pageNo}`]",this.formdata[`pageNo${eachpages.pageNo}`]);
            this.formdata[`pageNo${eachpages.pageNo}`][mainfieldname][fieldidentifier][eachcomputedField.fieldName] = eachcomputedField.value;
           }
              })
           
         })
        }
       })
      })
     })
    }
    
    })
    .catch((err) => {
      console.log(err);
    });
    
  // This function gets called when the input loses focus
  
  },
      istruedateandtime: function (value, compid) {
        if (value != "") {
          const date = new Date(value);
            const year = date.getFullYear();
            if (isNaN(year)) {
              this.errormsg[compid] = "Invalid date and time";
              this.isvalid.push("invdate");
              this.errorclasslist[compid] = "form-control errorlist";
            } 
         else {
          this.errormsg[compid] = "";
          // this.isvalid.pop();
        }
      }
      },
    openVideoCallSchedulePopup(patientId) {
      const visitdetails = store.getters.getvisitdetails;
      this.frompatid = visitdetails.Subject_id;
      this.selectedPatientId = patientId;
      this.CreateVideoCallSchedulePopUp = true;
    },
    async removeRepeatedfields(pageIndex, fieldIndex, rfieldIndex, fieldname, fieldIdentifier, pageno) {
      if (this.htmlarray[0].pages[pageIndex].field[fieldIndex].repeatingFields.length > 1) {
        delete (this.formdata[`pageNo${pageno}`][fieldname])[fieldIdentifier];
        this.htmlarray[0].pages[pageIndex].field[fieldIndex].repeatingFields.splice(rfieldIndex, 1);
      }
      else {
        alert("Cannot delete! Atleast one field should be kept on a repeating measure field")
      }
    },
    //     async addrepeatedfields(repeatKey,fieldname,pageno){
    //       this.isrepeatdatavalid={};
    //       this.htmlarray[0].pages.forEach((item) => {      
    //         item.field.forEach((subitem,key) => {    
    //           if(subitem.type=='RepeatingMeasure' || subitem.type=="repeatingfield"  || subitem.type=='repeatingmeasure')
    //           {      
    //             const fieldid =   item.field[repeatKey].repeatingFields[(item.field[repeatKey].repeatingFields).length-1].fieldIdentifier +1;                
    //             if(key == repeatKey && pageno == item.pageNo){
    //               alert("inside the if condition");
    //               this.formdata[`pageNo${pageno}`][fieldname].fieldid={};

    //               item.field[repeatKey].repeatingFields.push({
    //                 fieldIdentifier:fieldid,
    //                 fields: item.field[repeatKey].repeatingFields[0].fields
    //               });
    //                 alert("inside the if condition");
    //             }     

    //           }      
    //         })
    //         });
    //       this.bindData()
    // },
    async addrepeatedfields(repeatKey, pageIndex, fieldname) {
      if (!this.isrepeatdatavalid[fieldname]) {
        this.isrepeatdatavalid[fieldname] = {}
      }

      const fieldid = this.htmlarray[0].pages[pageIndex].field[repeatKey].repeatingFields[(this.htmlarray[0].pages[pageIndex].field[repeatKey].repeatingFields).length - 1].fieldIdentifier + 1;

      this.htmlarray[0].pages[pageIndex].field[repeatKey].repeatingFields.push({
        fieldIdentifier: fieldid,
        fields: this.htmlarray[0].pages[pageIndex].field[repeatKey].repeatingFields[0].fields
      });
      this.htmlarray[0].pages[pageIndex].field[repeatKey].repeatingFields.forEach((item) => {
        if (!this.isrepeatdatavalid[fieldname][item.fieldIdentifier]) {
          this.isrepeatdatavalid[fieldname][item.fieldIdentifier] = {}
        }
        item.fields.forEach((field) => {
          if (!this.isrepeatdatavalid[fieldname][item.fieldIdentifier][field.fieldName]) {
            this.isrepeatdatavalid[fieldname][item.fieldIdentifier][field.fieldName] = ""
          }
        })

      })

      this.bindData()
    },
    async reloadrepeatarray(item) {
      this.repeatedmeasurelistpopup = false;
      this.repeathidden = true;
      this.temprepeatedmeasureval = item;
    },
    async repeatedmeasuremodal(options, pageNo, fieldName) {
      this.repeatedpageno = pageNo;
      this.repeatedfieldname = fieldName;
      this.repeatedoptions = JSON.parse(options);
      this.repeatedmeasurepopup = true;
    },
    async saverepeatedmeasurdata() {
      this.repeathidden = false;
      this.formdata[`pageNo${this.repeatedpageno}`][this.repeatedfieldname] = JSON.stringify(this.repeatedmeasurevalarray);
      this.repeatedmeasurepopup = false;
    },
    async closerepeatedmeasuremodal() {
      this.repeathidden = false;
      this.repeatedmeasurepopup = false;
    },
    async repeatedmeasurlist() {
      this.repeathidden = false;
      this.repeatedmeasurelistpopup = true;
    },
    async repeatedmeasurdatapopup() {
      this.temprepeatedmeasureval = {};
      this.repratmeasureid = new Date();
      this.repeatedmeasurelistpopup = false;
    },
    async deleterepeatedmeasure(item) {
      let indexToDelete = this.repeatedmeasurevalarray.indexOf(item);

      if (indexToDelete !== -1) {
        // Remove the element at the found index
        this.repeatedmeasurevalarray.splice(indexToDelete, 1);
      }
    },
    async addrepeatemesureentries() {
      let temprepeatedval = {};
      this.repratmeasureid = new Date();
      this.temprepeatedmeasureval.ID = this.repratmeasureid;
      temprepeatedval = this.temprepeatedmeasureval;
      this.repeatedmeasurevalarray.push({ ...temprepeatedval });
      temprepeatedval = {};
      this.repeatedmeasurlist();
    },
    async addrepeatedmeasure() {

      this.addfield('repeatedmeasure');
    },
    async archive(formId, subId) {
      if (confirm("Are you sure you want to archive this form?")) {
        await axios.put(
          `${this.baseurl}/forms/forms/archive`, {
          createdUserEmail: "string",
          createdUserId: "string",
          createdAt: "2022-11-01T05:24:16.754Z",
          lastModifiedUserEmail: "string",
          lastModifiedUserId: "string",
          lastModifiedAt: "2022-11-01T05:24:16.754Z",
          formId: formId,
          subjectId: subId
        },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log("archive status", res);
          })
          .catch((err) => {
            console.log(err);
            alert("Patient schedule not found!")
          });
      }
    },
    async calculateResult(pageno, caloptions, fieldname) {
      if (!this.calculatedresult[fieldname]) {
        this.calculatedresult[fieldname] = "";
        this.previouscalculatedresult[fieldname] = "0";
      }
      if (this.previouscalculatedresult[fieldname] != this.calculatedresult[fieldname]) {
        let optionnsparsed = JSON.parse(caloptions);
        let firstclcname = this.formdata[`pageNo${pageno}`][optionnsparsed.firstfield];
        let secondcalcname = this.formdata[`pageNo${pageno}`][optionnsparsed.secondfield];
        let calcoperator = optionnsparsed.operations.trim();
        if (firstclcname != undefined && secondcalcname != undefined) {
          if (firstclcname != "" && secondcalcname != "") {
            let firstclcnametoint = parseInt(firstclcname);
            let secondcalcnametoint = parseInt(secondcalcname);
            if (!isNaN(firstclcnametoint) || !isNaN(secondcalcnametoint)) {
              if (calcoperator.trim() == "*") {

                this.calculatedresult[fieldname] = firstclcnametoint * secondcalcnametoint;
              }
              else if (calcoperator.trim() == "+") {
                this.calculatedresult[fieldname] = firstclcnametoint + secondcalcnametoint;
              }
              else if (calcoperator.trim() == "-") {
                this.calculatedresult[fieldname] = firstclcnametoint - secondcalcnametoint;
              }
              else if (calcoperator.trim() == "/") {
                this.calculatedresult[fieldname] = firstclcnametoint / secondcalcnametoint;
              }
              else {
                this.calculatedresult[fieldname] = firstclcnametoint + secondcalcnametoint;
              }
            }
            else {
              this.calculatedresult[fieldname] = firstclcname + secondcalcname;
            }

            console.log("new calculatedresult[fieldname]");
            this.previouscalculatedresult[fieldname] = this.calculatedresult[fieldname];
          }
        }
      }
    },
    async selectfileslot(pageno, fieldname) {
      this.filepageno = pageno;
      this.filefieldname = fieldname;
    },
    truncateInput(event, fieldName, maxLength) {
      const inputValue = event.target.value.toString();
      if (inputValue.length > maxLength) {
        event.target.value = inputValue.slice(0, maxLength);
        this.formdata[`pageNo${item.pageNo}`][fieldName] = event.target.value;
      }
    },
    async helloButton(event, pageno, fieldName) {
      this.formdata[`pageNo${pageno}`][fieldName] = "";
      this.selectedFile = "";
      this.filedata = "";
      
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.filedata = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.selectedFile = input.files[0];
        console.log(this.selectedFile);
      }
      await this.submitFile(pageno, fieldName);
    },
    async qrcodefun(fieldname, dataurl) {
      try {
        this.qrcodetxt[fieldname] = await QRCode.toDataURL(dataurl);
      } catch (err) {
        console.error(err)
      }
    },
    async submitFile(pageno, fieldName) {
      var formData = new FormData();
      formData.append("Document", this.selectedFile);
      formData.append("DocumentTypeName", "FORMS_DEV_FILEUPLOAD");
      formData.append("DocumentTitle", "");
      formData.append(
        "DocumentDescription",
        ""
      );
      formData.append("OrganizationId", "");

      await axios
        .post(`${this.fileuploadurl}/create
`, formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          this.formdata[`pageNo${pageno}`][fieldName] = `${response.data.data[0]},${response.data.data[1]}`
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
    async closemodallll() {
      this.selectedprivilege = "";
      document.body.style.overflowY = "auto";
      this.fieldprivilegepopup = false;
    },
    openModal(label, groupno) {
      if (this.fromUrlStatus && this.isSigned === false) {
        this.showModal = true
        this.assignFieldValue = label
        this.groupNo = groupno
      }
    },
    async assignprivilege(fieldname, label, groupno) {
      if (this.fromUrlStatus && this.isSigned === false) {
        this.showModal = true
        this.assignFieldValue = label
        this.groupNo = groupno
      }
      if (this.requiredrole) {
        if (this.selectedrole == "" || this.selectedrole == null || this.selectedrole == "All") {
          alert("Please select a role");
        }
        else {
          await this.reloadprivilege(fieldname);
          this.selectedfield = fieldname;
          document.body.style.overflowY = "hidden";
          this.fieldprivilegepopup = true;
        }
      }
    },
    async numbanddatefocusoutfun(fieldname) {
      if (!this.numberanddateval.hasOwnProperty(fieldname)) {
        this.numberanddateval[fieldname] = {
          "numberdata": "",
          "datedata": "",
        }
      }
    },
    async reloadprivilege(fieldname) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/forms/getfieldprivilege?RoleId=${this.selectedrole}&TemplateId=${this.templateid}&FieldName=${fieldname}`
          ,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          this.selectedprivilege = res.data.privilage;
        })
        .catch((err) => {
          console.log("Have error After listed");
          console.log(err);
        });
    },
    async savefieldprivilege() {
      if (this.selectedprivilege == "" || this.selectedprivilege == null) {
        alert("Please select a privilege");
      }
      else {
        // alert("templateid is "+ this.templateid + "roleId is " + this.selectedrole + "fieldname is " + this.selectedfield);
        const idtoken = store.getters.getIdToken;
        await axios
          .post(`${this.baseurl}/forms/forms/addoneprivilage`,
            {
              "fieldName": this.selectedfield,
              "privilage": this.selectedprivilege,
              "roleId": this.selectedrole,
              "templateId": this.templateid

            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            // alert("Privilege added successfully");
            alert(this.$t('navigations.privilege added successfully'))
            this.closemodallll();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async getallroles() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/account-core/roles/search?status=ACTIVE`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
        )
        .then((res) => {
          this.roles = res.data.data.sort((a, b) => 
            a.role_Name.localeCompare(b.role_Name)
          );
        })
        .catch((err) => {
          console.log("Have error After listed");
          console.log(err);
        });
    },
    // User IP address
    async getuserIp() {
      await axios.get(`https://api.ipify.org?format=json`).then((res) => {
        this.iPaddress = res.data.ip;
      });
    },
    //ends here
    //Date time format starts//
    formatDateTime(inputDate) {
      // const hours = date.getUTCHours().toString().padStart(2, "0");
      // const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      // const seconds = date.getUTCSeconds().toString().padStart(2, "0");
      // const day = date.getUTCDate().toString().padStart(2, "0");
      // const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
      // const year = date.getUTCFullYear();

      // Convert input date to a Date object
      const date = new Date(inputDate);

      // Extract date and time components
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = "00"; // Assuming seconds are always 00 if not provided

      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear().toString();
      return `${hours}:${minutes}:${seconds}_${day}-${month}-${year}`;
    },
    formatDateNum(inputDate) {
      // const hours = date.getUTCHours().toString().padStart(2, "0");
      // const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      // const seconds = date.getUTCSeconds().toString().padStart(2, "0");
      // const day = date.getUTCDate().toString().padStart(2, "0");
      // const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
      // const year = date.getUTCFullYear();

      // Convert input date to a Date object
      const date = new Date(inputDate);

      // Extract date components
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear().toString();
      return `${day}-${month}-${year}`;
    },
    payloadDate(inputDate) {
      const date = new Date(inputDate);

      if (isNaN(date)) {
        console.error("Invalid date", inputDate);
        return "";
      }

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;

    },
    convertTime(timeString) {
      // Split the time string into parts
      const timeParts = timeString.split(':');

      // Check if the seconds part is missing
      if (timeParts.length === 2) {
        // Append ':00' to add seconds
        return `${timeString}:00`;
      }

      // If the seconds are already present, return the original time string
      return timeString;
    },
    //date time format ends//
    async testmsgfun() {
      console.log("inside test function");
    },
    //retreive visit info
    async retrievevisitinfo() {
      if (this.visitids == null || this.visitids == " " || this.visitids.trim() == "0") {
        const visitdetails = store.getters.getvisitdetails;
        this.visitname = visitdetails.Visitname;
        this.visitno = visitdetails.Visitno;
        this.sendData.subjectId = visitdetails.Subject_id ? visitdetails.Subject_id : "";
        this.sendData.siteId = visitdetails.Siteid;
        this.sendData.sheduleID = visitdetails.sheduleId;
        console.log("retrievevisitinfo senddata", this.sendData);
      }
      else {
        if (this.issavefun) {
          const idtoken = store.getters.getIdToken;
          await axios
            .get(
              `${this.baseurl}/management/visit/getbyid/${this.visitids}`,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              if (res.data === "No Visit Found") {
                console.log("No Visit Found");
              }
              this.visitname = res.data.visitFormName;
              this.visitno = res.data.visitNo;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    toggleLanguage() {
      this.displayLanguage = !this.displayLanguage;

    },
    selectLanguage(val) {
      this.displayLanguage = !this.displayLanguage;
      this.selectedLanguage = val;
      this.translatelabel(val);

    },
    save() {
      this.translatelabel.push({
        id: "string",
        language: "string",
        code: "string"

      });

    },
    languagelist: async function () {
      console.log("in language list fun");
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/forms/template/languages`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.language = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkLanguage(language) {
      return this.templist.some((obj) => {
        return obj.languageCode == language.toString()
      }
      )
    },
    translatelabel: async function (language) {
      if (!language) {
        alert(this.$t("Please select language"));
        return;
      }
      let checkValue = await this.checkLanguage(language)
      if (checkValue) {
        alert("The selected language already added in the available language")
      }
      else {
        if (confirm(`${this.$t("navigations.are you sure you want to convert this form to")} ${this.laguageselected.language}?`)) {
          const loader = this.$loading.show({
            container: this.$refs.formContainer,
          });
          const idtoken = store.getters.getIdToken;
          console.log("language : " + language + " TemplateId :" + this.templateid);
          await axios
            .get(`${this.baseurl}/forms/template/translate?id=${this.templateid}&language=${language}`,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }


            )
            .then((res) => {
              loader.hide();
              console.log("Transilted id is:");
              console.log(res.data);
              this.getformtemplateUpdated(res.data)
              this.displayLanguage = !this.displayLanguage;
              this.gettemplatelist()
              //TODO
            })
            .catch((err) => {
              loader.hide();
              console.log(err);
            });
        } else {
          console.log("cancelled language change request");
        }
      }
    },
    getVersionlist: async function (id) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/forms/template/getversionhistory?sourceId=${id}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.versionlist = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gettemplatelist: async function () {
      let tempname = this.htmlarray[0].templateName;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/forms/template/gettemplatebyname?TemplateName=${tempname}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.templist = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // getselectedtemplate: async function(){

    // },
    getselectedtemplate: async function (tempid) {
      const loader = this.$loading.show({
        container: this.$refs.formContainer,
      });
      const idtoken = store.getters.getIdToken;
      console.log("in get form function");
      await axios
        .get(
          `${this.baseurl}/forms/template/get/${tempid}?api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          loader.hide();
          // console.log(res.data);
          this.htmlarray[0] = res.data;
          this.htmlarray[0].pages.field.forEach((fielddata) => {
            if (fielddata.repeatingFields.length != 0) {
              fielddata.repeatingFields = [{
                "FieldIdentyfier": 1,
                "field": fielddata
              }]
            }
          })
          this.islanenglish = res.data.languageCode;
        })
        .catch((err) => {
          loader.hide();
          console.log(err);
        });

      await this.gettemplatelist();
      await this.bindData();
    },
    async getTemplate() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/template/gettemplatebylang?TemplateName=${this.templateName}&LanguageCode=en`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.htmlarray[0] = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      await this.gettemplatelist();
      await this.bindData();
    },
    getformtemplategeneral: async function () {
      console.log("id token is" + store.getters.getIdToken);
      let idtoken = store.getters.getIdToken;
      console.log("inside getformtemplate function ");
      await axios.get(`${this.baseurl}/forms/formslibrary/getbyid?id=${this.templateid}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.htmlarray[0] = res.data;
          this.islanenglish = res.data.languageCode;
          this.sourceTemplateId = res.data.sourceTemplateId;
        })
        .catch((err) => {
          console.log(err);
        });

      this.bindData();
    },
    getformtemplate: async function () {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/template/get/${this.templateid}?api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.htmlarray[0] = res.data;
          this.htmlarray[0].pages.forEach((page) => {
            page.field.forEach((fielddata) => {
              if (fielddata.type == "repeatingfield" && fielddata.repeatingFields.length != 0) {
                fielddata.repeatingFields.forEach((data) => {
                  data["value"] = ""
                })
                fielddata.repeatingFields = [{
                  fieldIdentifier: 1,
                  fields: fielddata.repeatingFields,
                }]
              }
            })
          })
          this.islanenglish = res.data.languageCode;
          this.sourceTemplateId = res.data.sourceTemplateId;

        })
        .catch((err) => {
          console.log(err);

        });
      console.log("HTML array is", this.htmlarray[0].pages)
      await this.gettemplatelist();
      await this.bindData();
      await this.getVersionlist(this.sourceTemplateId);
    },
    getformtemplateUpdated: async function (newtempid) {
      const idtoken = store.getters.getIdToken;
      console.log("in get form function");
      await axios
        .get(
          `${this.baseurl}/forms/template/get/${newtempid}?api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          this.htmlarray[0] = res.data;
          this.htmlarray[0].pages.forEach((page) => {
            page.field.forEach((fielddata) => {
              if (fielddata.type == "repeatingfield" && fielddata.repeatingFields.length != 0) {
                fielddata.repeatingFields.forEach((data) => {
                  data["value"] = ""
                })

                fielddata.repeatingFields = [{
                  fieldIdentifier: 1,
                  fields: fielddata.repeatingFields,
                }]
              }
            })
          })
        })
        .catch((err) => {
          console.log(err);
        });

      this.bindData();
    },
    isRequired: async function (value, compid, type) {
      if(type == "checkbox"){
        if(value == "false" || value == false || value == "" || value == null || value == undefined){
          await this.isvalidatefun(compid,"","false");
        }
        else {
          if(this.isvalid.includes(compid)){
            this.isvalid =  this.isvalid.filter(item => item !== compid);
          }
        }
      }
      else{
      if (value == "" || value == null || value == undefined) {
        await this.isvalidatefun(compid,"","");
      } 
      else {
        if(this.isvalid.includes(compid)){
          this.isvalid =  this.isvalid.filter(item => item !== compid);
        }
      }
    }
      return;
    },
    ischeckboxRequired: function (value, compid) {
      if (value == "" || value == null || value == undefined || value == "false") {
        this.errormsg[compid] = "Please check the checkbox to proceed.";
          this.messagetype[compid] = "Alert"
          if(!this.isvalid.includes(compid)){
           this.isvalid.push(compid);
         }
      } else {
        // this.isvalid.pop();
      }
    },
    isrepeatedRequired: async function (value, fieldIdentifier,repeatedfieldName, type) {
      if(type == "checkbox"){
        if(value == "false" || value == false || value == "" || value == null || value == undefined){
          await this.isvalidatefun(repeatedfieldName,fieldIdentifier,"false");
        }
        else {
          if(this.isvalid.includes(repeatedfieldName+fieldIdentifier)){
            this.isvalid =  this.isvalid.filter(item => item !== repeatedfieldName+fieldIdentifier);
          }
        }
      }
        else {
      if (value == "" || value == null || value == undefined || value == false) {
        await this.isvalidatefun(repeatedfieldName,fieldIdentifier,"");
      }
       else {
        if(this.isvalid.includes(repeatedfieldName+fieldIdentifier)){
          this.isvalid =  this.isvalid.filter(item => item !== repeatedfieldName+fieldIdentifier);
        }
      }
    }
    },
    isrepeatedcheckboxRequired: function (value, compid, fieldIdentifier,repeatedfieldName) {
      if (value == "" || value == null || value == undefined || value == "false") {
        this.errormsg[repeatedfieldName+fieldIdentifier] = "Please check the checkbox to proceed.";
          this.messagetype[repeatedfieldName+fieldIdentifier] = "Alert"
          if(!this.isvalid.includes(repeatedfieldName+fieldIdentifier)){
           this.isvalid.push(repeatedfieldName+fieldIdentifier);
         }
      } else {
        // this.isvalid.pop();
      }
    },

    async getbyidforautofill() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/study/getbyidforautofill/${this.stdyid}`
          ,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          console.log("autofield", res.data);
          if (res.data.config != null) {
            this.autofield = res.data.config;
          }
        })
        .catch((err) => {
          console.log("Have error After listed");
          console.log(err);
        });
    },
    bindData: async function () {
      this.totalpages = this.htmlarray[0].totalPages;
      this.htmlarray[0].pages.forEach((item, index) => {
        this.groups[item.pageNo] = [];
        if (!this.formdata[`pageNo${item.pageNo}`]) {
          this.formdata[`pageNo${item.pageNo}`] = {};
        }
        item.field.forEach((subitem, subindex) => {
          if(!Object.keys(this.visibility).includes(subitem.fieldName)){
            this.visibility[subitem.fieldName] = subitem.visibility;
          }
          if (subitem.type == 'grid') {
            this.gridoptfun(subitem.fieldName, subitem.options[0])
          }
          if (subitem.type == 'calculation') {
            this.calcfunvar[subitem.fieldName] = 1;
          }
          if (this.inputElements.includes(subitem.type)) {
            console.log("autofield", this.autofield[0]);
            if (this.autofield[0].site_sub_autofill[0].autofill == true) {
              let autofillsiteid = store.getters.getautofillsiteid != "null"?store.getters.getautofillsiteid:"";
              let autofisubjectid = store.getters.getautofillsubjectid != "null"?store.getters.getautofillsubjectid:"";
              if (subitem.fieldName == this.autofield[0].site_sub_autofill[0].labelsubject) {
                console.log("condition for adding subjectid");
                this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = autofisubjectid;
              }
              if (subitem.fieldName == this.autofield[0].site_sub_autofill[0].labelsite) {
                console.log("condition for adding siteid",);
                this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = autofillsiteid;
              }
            }
          }
          if (subitem.type == 'numberanddate') {
            this.numbanddatefocusoutfun(subitem.fieldName);
          }
          if (!this.groups[item.pageNo].includes(parseInt(subitem.groupOrderNo))) {
            this.groups[item.pageNo].push(parseInt(subitem.groupOrderNo));
          }
          if (subitem.type == 'RepeatingMeasure' || subitem.type == 'repeatedmeasure' || subitem.type == 'repeatingfield') {
            this.repeatedgroupgroupnos[subitem.fieldName] = [];
            subitem.repeatingFields.forEach((subrepeat, repeatindex) => {
              subrepeat.fields.forEach((field, fieldIndex) => {
                if(!Object.keys(this.visibility).includes(field.fieldName+subrepeat.fieldIdentifier)){
                  this.visibility[field.fieldName+subrepeat.fieldIdentifier] = field.visibility;
                }
                if (!this.repeatedgroupgroupnos[subitem.fieldName].includes(field.groupOrderNo)) {
                  this.repeatedgroupgroupnos[subitem.fieldName].push(field.groupOrderNo);
                }
                if (!this.formdata[`pageNo${item.pageNo}`][subitem.fieldName]) {
                  this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = {};
                }
                if (field.type == 'numberanddate') {
                  // this.repeatednumberanddateval[field.fieldName] = true;
                  this.repeatednumbanddatefocusoutfun(field.fieldName + subrepeat.fieldIdentifier);
                }
                subitem.repeatingFields.forEach(async (eachrepeatingdata) => {
                  if (!this.formdata[`pageNo${item.pageNo}`][subitem.fieldName][eachrepeatingdata.fieldIdentifier]) {
                    this.formdata[`pageNo${item.pageNo}`][subitem.fieldName][eachrepeatingdata.fieldIdentifier] = {};
                  }

                })
              })
            })

          }


          if (subitem.validation != "") {
            subitem.validation.forEach((valid) => {
              if (valid == "Required" || valid == "REQUIRED") {
                this.requiredclasslist[subitem.fieldName] = "required";

              }
            });
          }
        });
      });
      this.totalpages = this.htmlarray[0].pages.length;
    },
    isautofill(fieldName){
      let isautofillrequired = store.getters.getautofillenabled;
      if(this.autofield[0].site_sub_autofill[0].autofill == true){
        if(fieldName == this.autofield[0].site_sub_autofill[0].labelsubject || fieldName == this.autofield[0].site_sub_autofill[0].labelsite){
          if(isautofillrequired != "false"){
          return true;
        }
        else{
          return false;
        }
      }
        else{
          return false;
        }
      }
      else{
        return false;
      }
    },
    async repeatednumbanddatefocusoutfun(fieldname) {
      if (!this.repeatednumberanddateval.hasOwnProperty(fieldname)) {
        this.repeatednumberanddateval[fieldname] = {
          "numberdata": "",
          "datedata": "",
        }
      }
    },
    testfun: async function () {
      if (this.requiredvalidate) { await this.validate(); }

      if (this.isvalid.length == 0) {
        if (this.issavefun) {
          if (this.totalpages == 1) {
            this.savefun();
              this.saveid = await formService.apicall(this.sendData);
              if (this.saveid != undefined || this.saveid != null) {
                if (this.encodedFormList != undefined) {
                  await this.NewFormSave(false);
                }
                // alert("Form saved successfully")
                alert(this.$t('navigations.form saved successfully'))
                this.$router.push(`/submitForm`)
              }
          }
          else {
            if (this.saveid != undefined || this.saveid != null) {
              this.editFun();
                this.sendData.formId = this.saveid;
                await formService.editdata(this.sendData);
                if (this.saveid != undefined || this.saveid != null) {
                  // alert("Form saved successfully")
                  alert(this.$t('navigations.form saved successfully'))
                  this.$router.push(`/submitForm`)
                }
            }
          }
        }
      }
    },
    async NewFormSave(isReviewAndSubmit) {
      const idtoken = store.getters.getIdToken;
      await axios
        .post(`${this.baseurl}/management/patientschedule/addadditionalforms`,
          {
            "createdUserEmail": "string",
            "createdUserId": "string",
            "createdAt": "2024-10-11T08:24:19.504Z",
            "lastModifiedUserEmail": "string",
            "lastModifiedUserId": "string",
            "lastModifiedAt": "2024-10-11T08:24:19.504Z",
            "formId": this.saveid,
            "isReviewAndSubmit": isReviewAndSubmit
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async gridoptfun(fieldname, gridoptions) {
      this.gridoptarray[fieldname] = JSON.parse(gridoptions);
      this.gridlinkeddatas[fieldname] = {};

      this.gridlinkeddatas[fieldname] = JSON.parse(gridoptions)[0].Fielddata;
    },
    reviewAndSubmit: async function () {
      if (this.requiredvalidate) { await this.validate(); }

      if (this.isvalid.length == 0) {
        if (this.issavefun) {
          if (this.totalpages == 1) {
            await this.savefun();
              this.decodedFormList
              this.saveid = await formService.apicall(this.sendData);
              let isfrompatientschedule = store.getters.getfrompatientschedule;
              if (isfrompatientschedule!=null && isfrompatientschedule!='null'&& isfrompatientschedule!="") {
                this.NewFormSave(true);
              }
              await formService.isreviewstatus(this.saveid, this.selectedFormData);
              if (this.saveid != undefined || this.saveid != null) {
                // alert("Form submitted successfully")
                alert(this.$t('navigations.form submitted successfully'))
                this.$router.push(`/submitForm`)
              }

          }
          else {
            this.editFun();
              this.sendData.formId = this.saveid;
              await formService.editdata(this.sendData);
              await formService.isreviewstatus(this.saveid);
              if (this.saveid != undefined || this.saveid != null) {
                // alert("Form submitted successfully")
                alert(this.$t('navigations.form submitted successfully'))
                this.$router.push(`/submitForm`)
              }

          }

        }
      }

    },
    validate: async function () {
      this.errorsort = {};
      this.errorclasslist = {};
    
      // Loop through pages with for...of
      for (const item of this.htmlarray[0].pages) {
        const pagenumber = `pageNo${item.pageNo}`;
    
        if (item.pageNo === this.pageno) {
          // Loop through fields with for...of
          for (const subitem of item.field) {
            // Repeating field scenario
            if (subitem.type === 'repeatingfield') {
              this.isrepeatdatavalid[subitem.fieldName] = {};
    
              // Loop through repeatingFields
              for (const eachrepeatingdata of subitem.repeatingFields) {
                // Loop through the fields in repeatingFields
                for (const field of eachrepeatingdata.fields) {
                  if (field.validation) {
                    // Loop through each validation
                    for (const valid of field.validation) {
                      if (valid === 'Required' || valid === 'REQUIRED') {
                        await this.isrepeatedRequired(
                          this.formdata[pagenumber][subitem.fieldName][eachrepeatingdata.fieldIdentifier][field.fieldName],
                          eachrepeatingdata.fieldIdentifier,
                          field.fieldName,
                          field.type
                        );
                      }
                    }
                  }
                }
              }
    
            } else {
              // Non-repeating field scenario
              if (subitem.validation) {
                for (const valid of subitem.validation) {
                  
                  if (valid === 'Required' || valid === 'REQUIRED') {
                    await this.isRequired(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName,
                      subitem.type
                    );
                  
                  }
                }
              }
            }
          }
        }
      }
      await this.$nextTick(() => {
        const errorMsgs = toRaw(this.$refs.validationMsgs) || [];
        const visibleErrors = errorMsgs.filter((vm) => {
          return vm.errorMsg && vm.errorMsg.trim() !== "" && vm.messageType == "Alert";
        });
        if (visibleErrors.length === 0) return;
        const firstError = visibleErrors[0];
        const el = firstError.$el;
        const page = this.pageno;
          el.scrollIntoView({ behavior: "smooth", block: "center" });
      });
      return;
    },
    // save and edit starts
    async isvalidatefun(fieldname, fieldidentifier,receveddata) {
    
      let idtoken = store.getters.getIdToken;
      
      let checkdata = {
        "fiedValue": receveddata,
        "fieldName": fieldname,
        "templateId": this.templateid,
        "computeFields": {}
      }
      await axios.post(`${this.baseurl}/forms/editcheck/validatefield`,checkdata,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          if(res.data.validations.length > 0){
          const errval = res.data.validations[0];
          if(fieldidentifier == ""){
          if(errval.messageType == "Alert"){
            if(!this.isvalid.includes(fieldname)){
           this.isvalid.push(fieldname);
         }
          }
          this.errormsg[fieldname] = errval.message;
          this.messagetype[fieldname] = errval.messageType;
          
        }
        else{
          if(errval.messageType == "Alert"){
            if(!this.isvalid.includes(fieldname+fieldidentifier)){
           this.isvalid.push(fieldname+fieldidentifier);
         }
          }
          this.errormsg[fieldname+fieldidentifier] = errval.message;
          this.messagetype[fieldname+fieldidentifier] = errval.messageType;
        }
      }
        return;
        })
        .catch((err) => {
          console.log(err);
          return;
        });
      
      },
    savefun: async function () {
      const visitdetails = store.getters.getvisitdetails;
      let isfrompatientschedule = store.getters.getfrompatientschedule;
      this.sendData.pages = [];
      this.sendData.totalPages = this.htmlarray[0].totalPages;
      this.sendData.templateId = this.htmlarray[0].templateId;
      this.sendData.studyId = this.stdyid;
      this.sendData.folderId = (this.htmlarray[0].folderId ? this.htmlarray[0].folderId : "");
      this.sendData.sheduleID = isfrompatientschedule == 'frompatientschedule' ? "" : (this.htmlarray[0].sheduleID ? this.htmlarray[0].sheduleID : "");
      this.sendData.visitNo = isfrompatientschedule == 'frompatientschedule' ? this.selectedFormData.Visitno : this.visitno;
      this.sendData.subjectId = isfrompatientschedule == 'frompatientschedule' ? this.selectedFormData.Subject_id : visitdetails ? (visitdetails.Subject_id ? visitdetails.Subject_id : "") : ""
      this.sendData.sourceID = this.iPaddress;
      this.sendData.dataSource = "Web Browser";
      this.sendData.formName = isfrompatientschedule == 'frompatientschedule' ? this.selectedFormData.Template_name : this.htmlarray[0].templateName;
      this.sendData.visitId = this.visitids;
      this.sendData.version = this.htmlarray[0].version;
      this.sendData.visitName = isfrompatientschedule == 'frompatientschedule' ? this.selectedFormData.Visitname : this.visitname;
      let createdpage = {};
      createdpage.pageName = this.htmlarray[0].pages[0].pageName;
      createdpage.pageNo = this.htmlarray[0].pages[0].pageNo;
      createdpage.pageStatus = this.htmlarray[0].pages[0].status;
      createdpage.field = [];
      let repeatedfieldsavearray = {};
      this.htmlarray[0].pages[0].field.forEach((subitem) => {
        let val;
        let itemstatus;
        // if(subitem.repeatingFields.length!=0){           
        //   subitem.repeatingFields.forEach((field,index)=>{
        //     subitem.repeatingFields[index]={
        //       fieldIdentifier:""
        // }

        //     if(index==0)
        //     { 

        //       subitem.repeatingFields[0]={
        //         fieldIdentifier:1,
        //         ...field
        //       }
        //     }
        //     else{
        //       subitem.repeatingFields[index]={
        //         fieldIdentifier:subitem.repeatingFields[0].fieldName==field.fieldName ? 1 : index+1,
        //         ...field
        //       }
        //     }

        //   })
        // }
        if (subitem.type == 'checkbox') {
          val = (this.formdata[`pageNo${this.pageno}`][subitem.fieldName] != null) ? (this.formdata[`pageNo${this.pageno}`][subitem.fieldName].toString()) : "false";
          itemstatus = "Not Validated";
        }
        else if (subitem.type === 'RepeatingMeasure' || subitem.type === 'repeatingfield'
          || subitem.type === 'repeatedmeasure') {
          repeatedfieldsavearray[subitem.fieldName] = subitem.repeatingFields.map(repeatField => {
            return {
              fieldIdentifier: repeatField.fieldIdentifier,
              fields: repeatField.fields.map(data => {
                let value = this.formdata[`pageNo${this.pageno}`][subitem.fieldName][repeatField.fieldIdentifier][data.fieldName];
                if (data.type == 'checkbox') {
                  value = value ? value.toString() : "false"
                }
                if (data.type == 'numberanddate') {
                  value = this.repeatednumberanddateval[data.fieldName + repeatField.fieldIdentifier].numberdata != '' && this.repeatednumberanddateval[data.fieldName + repeatField.fieldIdentifier].datedata != '' ? this.formatDateNum(this.repeatednumberanddateval[data.fieldName + repeatField.fieldIdentifier].datedata) + "_" + this.repeatednumberanddateval[data.fieldName + repeatField.fieldIdentifier].numberdata : ""
                }
                if (data.type == 'dateandtime') {
                  value = value ? this.formatDateTime(value) : ""
                }
                if (data.type == 'time' && value) {
                  value = this.convertTime(value)
                }
                if(this.visibility[data.fieldName] == "false" || this.visibility[data.fieldName] == false ){
                  val = "";
                }
                return {
                  ...data,
                  value: value
                };
              })
            };
          });
          this.isrepeatvalid[subitem.fieldName] = [];           
          val = "";
        }
        else if (subitem.type == 'dateandtime') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName] ? this.formatDateTime(this.formdata[`pageNo${this.pageno}`][subitem.fieldName]) : "";
          itemstatus = "Not Validated";
        }
        else if (subitem.type == 'time') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName] ? this.convertTime(this.formdata[`pageNo${this.pageno}`][subitem.fieldName]) : ""
          itemstatus = "Not Validated";
        }
        else if (subitem.type == 'date') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
          itemstatus = "Not Validated";
        }
        else if (subitem.type == 'header' || subitem.type == 'subHeader') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
          itemstatus = "";
        }
        else if (subitem.type == 'numberanddate') {
          val = this.numberanddateval[subitem.fieldName].numberdata != '' && this.numberanddateval[subitem.fieldName].datedata != '' ? this.formatDateNum(this.numberanddateval[subitem.fieldName].datedata) + "_" + this.numberanddateval[subitem.fieldName].numberdata : ""
          itemstatus = "";
        }
        else if (subitem.type == 'year') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName]
          if (val) {
            val = val.toString();
          }
          itemstatus = "";
        }
        else if (subitem.type == 'grid') {
          val = JSON.stringify(this.gridlinkeddatas[subitem.fieldName]);
          itemstatus = "";
        }
        else if (subitem.type == 'scales') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName]
          if (val) {
            val = val.toString();
          }
          itemstatus = "";
        }
        else {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName]
          itemstatus = "Not Validated"
        }
        if(this.visibility[subitem.fieldName] == "false" || this.visibility[subitem.fieldName] == false ){
          val = "";
        }
        createdpage.field.push({
          fieldName: subitem.fieldName,
          label: subitem.label,
          type: subitem.type,
          value: val ? val : "",
          status: itemstatus,
          validation: subitem.validation,
          groupOrderNo: subitem.groupOrderNo,
          validatedBy: "",
          limit: subitem.limit,
          validatedAt: "",
          comment: "",
          required: subitem.required,
          options: subitem.options,
          imagePath: subitem.imagePath,
          visibility: subitem.visibility,
          qrData: subitem.qrData,
          repeatingFieldData: repeatedfieldsavearray[subitem.fieldName]
        });

      });
      this.sendData.pages.push(createdpage);
      // this.saveid = await formService.apicall(this.sendData);
    },

    editFun: function () {
      this.sendData.pages = []
      const pgn = this.pageno - 1;
      let createdpage = {};
      let repeatedfieldsavearray = {};
      createdpage.formId = this.saveid;
      createdpage.isArchived = true;
      createdpage.sourceID = this.iPaddress;
      createdpage.dataSource = "Web Browser";
      createdpage.pages = [];
      createdpage.pages[0] = {};
      createdpage.pages[0].pageName = this.htmlarray[0].pages[pgn].pageName;
      createdpage.pages[0].pageNo = this.htmlarray[0].pages[pgn].pageNo;
      createdpage.pages[0].pageStatus = this.htmlarray[0].pages[pgn].status;
      createdpage.pages[0].field = [];
      this.htmlarray[0].pages[pgn].field.forEach((subitem) => {
        let val;
        let itemstatus;

        // if(subitem.repeatingFields.length!=0){           
        //   subitem.repeatingFields.forEach((field,index)=>{
        //     subitem.repeatingFields[index]={
        //       fieldIdentifier:""
        // }
        //     if(index==0)
        //     {
        //       subitem.repeatingFields[0]={
        //         fieldIdentifier:1,
        //         ...field
        //       }
        //     }
        //     subitem.repeatingFields[index]={
        //       fieldIdentifier:subitem.repeatingFields[0].fieldName==field.fieldName ? 1 : index+1,
        //       ...field
        //     }
        //     subitem.repeatingFields[index].fieldIdentifier=index+1
        //   })
        // }
        if (subitem.type == 'checkbox') {
          val = (this.formdata[`pageNo${this.pageno}`][subitem.fieldName] != null) ? (this.formdata[`pageNo${this.pageno}`][subitem.fieldName].toString()) : "false";
          itemstatus = "Not Validated";
        }
        else if (subitem.type === 'RepeatingMeasure' || subitem.type === 'repeatingfield'
          || subitem.type === 'repeatedmeasure') {
          repeatedfieldsavearray[subitem.fieldName] = subitem.repeatingFields.map(repeatField => {
            return {
              fieldIdentifier: repeatField.fieldIdentifier,
              fields: repeatField.fields.map(data => {
                let value = this.formdata[`pageNo${this.pageno}`][subitem.fieldName][repeatField.fieldIdentifier][data.fieldName];
                if (data.type == 'checkbox') {
                  value = value ? value.toString() : "false"
                }
                if (data.type == 'scales') {
                  value = value ? value.toString() : ""
                }
                if (data.type == 'numberanddate' && this.repeatednumberanddateval[data.fieldName + repeatField.fieldIdentifier]) {
                  value = this.repeatednumberanddateval[data.fieldName + repeatField.fieldIdentifier].datedata + "_" + this.repeatednumberanddateval[data.fieldName + repeatField.fieldIdentifier].numberdata;
                }
                if (data.type == 'dateandtime' && value) {
                  value = this.formatDateTime(value)
                }
                if (data.type == 'time' && value) {
                  value = this.convertTime(value)
                }
                if(this.visibility[data.fieldName+repeatField.fieldIdentifier] == "false" || this.visibility[data.fieldName+repeatField.fieldIdentifier] == false ){
                  val = "";
                }
                return {
                  ...data,
                  value: value
                };
              })
            };
          });
          this.isrepeatvalid[subitem.fieldName] = [];
          val = "";

        }
        else if (subitem.type == 'dateandtime') {
          val = this.formatDateTime(this.formdata[`pageNo${this.pageno}`][subitem.fieldName]);
          itemstatus = "Not Validated";
        }
        else if (subitem.type == 'date') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
          itemstatus = "Not Validated";
        }
        else if (subitem.type == 'time') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName] ? this.convertTime(this.formdata[`pageNo${this.pageno}`][subitem.fieldName]) : ""
          itemstatus = "Not Validated";
        }
        else if (subitem.type == 'scales') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName]
          if (val) {
            val = val.toString();
          }
          itemstatus = "";
        }

        else if (subitem.type == 'header' || subitem.type == 'subHeader') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
          itemstatus = "";
        }
        else if (subitem.type == 'numberanddate' && this.numberanddateval[subitem.fieldName]) {
          val = this.formatDateNum(this.numberanddateval[subitem.fieldName].datedata) + "_" + this.numberanddateval[subitem.fieldName].numberdata
          itemstatus = "";
        }
        else if (subitem.type == 'year') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName]
          if (val) {
            val = val.toString();
          }
          itemstatus = "";
        }
        else {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
          itemstatus = "Not Validated";
        }
        if(subitem.visibility == "false" || subitem.visibility == false ){
          val = "";
        }
        createdpage.pages[0].field.push({
          fieldName: subitem.fieldName,
          label: subitem.label,
          type: subitem.type,
          value: val,
          status: itemstatus,
          validation: subitem.validation,
          groupOrderNo: subitem.groupOrderNo,
          limit: subitem.limit,
          validatedBy: "",
          validatedAt: "",
          comment: "",
          visibility: subitem.visibility,
          required: subitem.required,
          options: subitem.options,
          imagePath: subitem.imagePath,
          qrData: subitem.qrData,
          audit: [
            {
              oldValue: "",
              newValue: "",
              validatedBy: "",
              validatedDate: "",
              validatedTime: "",
              status: "Not validated",
              comment: "",
            },
          ],
          repeatingFieldData: repeatedfieldsavearray[subitem.fieldName]
        });
      });
      this.sendData.pages.push(createdpage.pages[0])

    },

    // save and edit ends

    nextfun: async function () {
      if (this.requiredvalidate) {
        // Wait for validate() to finish before moving on
        await this.validate();
      }
    
      if (this.isvalid.length == 0) {
        let totalpg = parseInt(this.totalpages);
        if (this.pageno < totalpg) {
          if (this.issavefun) {
            if (this.pageno == 1 && this.nextPageClick == false) {
              this.savefun();
              this.saveid = await formService.apicall(this.sendData);
              if (this.saveid != undefined || this.saveid != null) {
                if (this.encodedFormList != undefined) {
                  await this.NewFormSave(false);
                }
              }
              this.pageno++;
              this.nextPageClick = true;
            } else {
              this.editFun();
              this.sendData.formId = this.saveid;
              await formService.editdata(this.sendData);
              this.pageno++;
            }
          }
        }
      }
    },    
    previousFun: function () {
      if (this.pageno > 1) {
        console.log("previous page");
        this.pageno--;
      }
    },
    incrementcount: function () {
      this.totalpages++;
      console.log("increment" + this.totalpages);
    },
  },
  watch: {
    pageno(newpageno, oldpageno) {
      if (newpageno >= 1) {
        window.scrollTo(0, 0);
      }
    }
  }
};
