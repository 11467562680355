<template>
  <div
    v-if="isvalidate"
    :class="`edt_chk_validation-message edt_chk_${messageType}`"
  >
    <img
      :src="getImageSrc()"
      :alt="messageType"
      class="edt_chk_validation-icon"
    />
    <span>{{ errorMsg }}</span>
  </div>
</template>

<script>
export default {
  props: {
    isvalidate: {
      type: Boolean,
      required: true,
    },
    messageType: {
      type: String,
      required: true,
    },
    errorMsg: {
      type: String,
      required: true,
    },
  },
  methods: {
    getImageSrc() {
      const imageMap = {
        Warning: require("../../assets/img/warning2.png"),
        Information: require("../../assets/img/edt_info2.png"),
        Alert: require("../../assets/img/alert2.png"),
        Exclusion: require("../../assets/img/Exclusion2.png"),
      };
      return imageMap[this.messageType] || "";
    },
  },
};
</script>

<style scoped>
.edt_chk_validation-message {
  display: flex;
  align-items: center;
  padding: 4px;
  margin-top: 4px;
  border-radius: 6px;
  color: #68696b;
  font-size: 12px;
}
.edt_chk_validation-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.edt_chk_Warning {
  border: 1px solid #FFBB00;
  border-right: 6px solid #FFBB00;
}
.edt_chk_Information {
  border: 1px solid #62B2FD;
  border-right: 6px solid #62B2FD;
}
.edt_chk_Exclusion {
  border: 1px solid #B041B6;
  border-right: 6px solid #B041B6;
}
.edt_chk_Alert {
  border: 1px solid #FD3D3A;
  border-right: 6px solid #FD3D3A;
}
</style>
