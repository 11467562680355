/* eslint-disable */
import axios from "axios";
import store from "@/store";

export default {
  name: "study",
  data() {
    return {
      isEditable: false,
      studyId: "",
      studyRef: "",
      studyName: "",
      sponsor: "",
      status: "",
      studyNotes: "",
      preVisit: 0,
      postVisit: 0,
      numberOfSite: 0,
      numberOfPatient: 0,
      duration: 0,
      studyType: null,
      allocation: "",
      interventionModel: "",
      masking: "",
      primaryPurpose: "",
      officialTitle: "",
      studyNotes: "",
      errors: {},
      DaStudy: "",
      idToken: "",
      baseurl: process.env.VUE_APP_Service_URL,
      protocolRefId: "",
      daStudyId: "",
      classficationList: [],
      protocol: {},
    };
  },
  async mounted() {
    this.idToken = store.getters.getIdToken;
    // const protocolRefId = "cc9da0eb-65e9-489d-8a9f-73853c807eea";
    this.protocolRefId = this.$route.query.id;
    await this.getDastudy(this.protocolRefId);
    await this.getClassfications();
    await this.getProtocolbyId();
  },
  methods: {
    toggleEditMode() {
      this.isEditable = true;
    },
    validate() {
      this.errors = {};
      if (!this.studyRef.trim()) {
        this.errors.studyRef = "Study ID is required.";
      }
      if (!this.studyName.trim()) {
        this.errors.studyName = "Study Name is required.";
      }
      return Object.keys(this.errors).length === 0;
    },
    checknegative() {
      if (this.preVisit < 0) {
        this.preVisit = 0;
      }
      if (this.postVisit < 0) {
        this.postVisit = 0;
      }
      if (this.numberOfSite < 0) {
        this.numberOfSite = 0;
      }
      if (this.numberOfPatient < 0) {
        this.numberOfPatient = 0;
      }
      if (this.duration < 0) {
        this.duration = 0;
      }
    },
    async save() {
      if (this.validate()) {
        try {
          await this.updateStudy();
          alert("Study details updated successfully.");
          this.isEditable = false;
        } catch (err) {
          console.error("Error updating study details:", err);
          alert("Failed to update study details. Please try again.");
        }
      }
    },
    clear() {
      this.studyRef = "";
      this.studyName = "";
      this.sponsor = "";
      this.allocation = "";
      this.numberOfPatient = "";
      this.numberOfSite = "";
      this.studyNotes = "";
      this.studyType = "";
      (this.preVisit = ""),
        (this.postVisit = ""),
        (this.duration = ""),
        (this.status = "");
      this.masking = "";
      this.primaryPurpose = "";
      this.officialTitle = "";
      this.interventionModel = "";
      this.errors = {};
    },
    clearFieldError(fieldName) {
      if (this.errors[fieldName]) {
        delete this.errors[fieldName];
      }
    },

    async getDastudy(protocolrefId) {
      try {
        const response = await axios.get(
          `${this.baseurl}/management/studyprotocol/getdastudy?protocolId=${protocolrefId}`,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = response.data;
        this.daStudyId = data.id || "";
        this.DaStudy = data;
        const fields = [
          "studyRef",
          "studyName",
          "sponsor",
          "status",
          "studyNotes",
          "preVisit",
          "postVisit",
          "numberOfSite",
          "numberOfPatient",
          "duration",
          "studyType",
          "allocation",
          "interventionModel",
          "masking",
          "primaryPurpose",
          "officialTitle",
        ];

        fields.forEach((field) => {
          this[field] = data[field] || "";
        });
      } catch (err) {
        console.error("Error fetching study data:", err);
      }
    },
    async updateStudy() {
      try {
        const updateData = {
          daStudyId: this.daStudyId,
          studyRef: this.studyRef,
          studyName: this.studyName,
          sponsor: this.sponsor,
          status: this.status,
          studyNotes: this.studyNotes,
          preVisit: this.preVisit || 0,
          postVisit: this.postVisit || 0,
          numberOfSite: this.numberOfSite || 0,
          numberOfPatient: this.numberOfPatient || 0,
          duration: this.duration || 0,
          studyType: this.studyType,
          allocation: this.allocation,
          interventionModel: this.interventionModel,
          masking: this.masking,
          primaryPurpose: this.primaryPurpose,
          officialTitle: this.officialTitle,
          protocolRefId: this.protocolRefId,
        };

        const response = await axios.put(
          `${this.baseurl}/management/studyprotocol/updatedastudy`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        await this.getProtocolbyId();

        console.log("Update response:", response.data);
      } catch (err) {
        console.error("Error during updateStudy API call:", err);
        throw err;
      }
    },
    async getClassfications() {
      const idtoken = store.getters.getIdToken;
      axios
        .get(`${this.baseurl}/management/study/getstudyclassifications`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.classficationList = res.data;
          console.log("Form list", this.classficationList);
        })
        .catch((err) => console.log("error in get study", err));
    },
    async getProtocolbyId() {
      const token = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/document/getprotocolbyid?protocolId=${this.protocolRefId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("protocol", res.data);
          this.protocol = res.data;
        })
        .catch((err) => console.log("error in get protocol by id", err));
    },
  },
};
