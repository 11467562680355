<template>
  <div class="study_profile_modal__wrapper">
    <div class="study_profile_modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">Schedule Video Call</h4>
        <div
          class="cursor-pointer"
          :title="$t('close')"
          @click.prevent="closeModal"
        >
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="ibox-content p-0">
        <div class="modal-body pb-0">
          <div class="form-group row">
            <div class="col-lg-12 my-0">
              <label class="col-form-label">Notes</label>
              <input
                type="text"
                class="form-control"
                v-model="data.requestFor"
              />
            </div>
            <div class="col-lg-12 my-0" v-if="!isFromTrainingMaterials">
              <label class="col-form-label">Add/Select Attendees<sup><i class="fa fa-asterisk imp"></i></sup></label>
              <Multiselect
                v-model="data.users"
                :options="attendeeOptions"
                :multiple="true"
                :searchable="true"
                mode="multiple"
                valueProp="email"
                :closeOnSelect="false"
                placeholder="Select User"
                label="email"
                track-by="email"
                :hideSelected="false"
                class="multiselect-template-theme mr-0"
              >
                <template v-slot:option="{ option }">
                  {{ option.email }}
                </template>
              </Multiselect>
              <span class="errormsg ml-0" v-if="errors[0]">
                {{ "Require at least one user." }}
              </span>
            </div>
             <div class="col-lg-12 my-0"  v-if="shouldShowAttendee">
              <label class="col-form-label">Attendee</label>
              <input
                type="email"
                class="form-control"
                v-model="data.users[0]" 
                :readonly="true"
              />
            </div>
            <div class="col-lg-6 my-0">
              <label class="col-form-label"
                >Start Date<sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                class="form-control"
                type="date"
                v-model="data.startDate"
                :min="today"
              />
              <span class="errormsg ml-0" v-if="errors[1]">
                {{ "Select a start date." }}
              </span>
            </div>

            <div class="col-lg-6 my-0">
              <label class="col-form-label"
                >End Date<sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                class="form-control"
                type="date"
                v-model="data.endDate"
                :min="today"
              />
              <span class="errormsg ml-0" v-if="errors[2]">
                {{ "Select an end date." }}
              </span>
              <span class="errormsg ml-0" v-if="errors[5]">
                {{ "End date cannot be earlier than the start date." }}
              </span>
            </div>
            <div class="col-lg-6 my-0">
              <label class="col-form-label"
                >Start Time<sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                class="form-control"
                type="time"
                v-model="data.startTime"
              />
              <span class="errormsg ml-0" v-if="errors[3]">
                {{ "Select a start time." }}
              </span>
            </div>

            <div class="col-lg-6 my-0">
              <label class="col-form-label"
                >End Time<sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input class="form-control" type="time" v-model="data.endTime" />
              <span class="errormsg ml-0" v-if="errors[4]">
                {{ "Select an end time." }}
              </span>
              <span class="errormsg ml-0" v-if="errors[6]">
                {{ "End time cannot be earlier than start time." }}
              </span>
            </div>
            <div class="col-lg-12 mt-4 d-flex justify-content-lg-end">
              <button
                class="save_btn py-1 px-4"
                type="button"
                @click="Schedule()"
              >
                {{ isFromTrainingMaterials ? 'Request' : 'Invite' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./CreateVideoCallSchedulePopUp.js"></script>
<style scoped>
@import "../../assets/css/style.css";

.study_profile_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}

.study_profile_modal__container {
  position: fixed;
  width: min(50%, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}

.modal__title {
  background-color: #618bd7;
  color: #fff;
}

.save_btn {
  text-align: center;
  background-color: #145faf !important;
  border-color: #145faf !important;
  color: white !important;
  padding: 0.43rem 0.4rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out !important;
}

.multiselect-template-theme {
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
  --ms-radius: 0;
  --ms-line-height: 0.8;
  --ms-option-font-size: 0.8rem;
  --ms-font-size: 0.8rem;
}

.save_btn:not(:disabled):hover,
.save_btn:focus:focus-visible {
  background-color: #145faf !important;
  border-color: #145faf !important;
}
</style>