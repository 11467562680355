<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>{{ $t("navigations.SDTM to ADaM Conversion") }}</h2>
        </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="ibox-content mb-1 px-3 py-2">
            <div class="row">
                <div class="col-6">
                    <div class="input-group">
                    <input accept=".xls,.xlsx" id="sdtmFile" type="file" class="form-control my-2 my-lg-0"
                        @change="previewFiles" />
                        
                    <span class="input-group-append">
                        <!-- <button type="button" class="stdy_upload_btn mr-0 py-1" @click="uploadDocument()"
                            :disabled="showLoading">
                            <div class="d-flex align-items-center" v-if="showLoading">
                                <img class="loading-img" width="20" src="../../assets/img/loading.webp"
                                    alt="Wait while the document is added" />
                            </div>
                            <div v-else>{{ $t("navigations.generate") }}</div>
                        </button> -->
                        <button type="button" class="stdy_upload_btn mr-0 py-1 position-relative" :disabled="showLoading == true"
                            @click="uploadDocument()" :class="{ 'disabled-btn-cls': showLoading == true }">
                            <div class="toc-loader position-absolute"
                            :class="{ 'opacity-0': showLoading == false, 'opacity-1': showLoading == true }">
                            <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
                            </div>
                            <div :class="{ 'opacity-1': showLoading == false, 'opacity-0': showLoading == true }">{{
                            $t("navigations.generate") }}</div>
                        </button>
                    </span>
                </div>
                <div class="errorClass" v-if="validations[0] == true">
                    {{ $t("navigations.please upload a file") }}
                </div>
                </div>

            </div>
        </div>

    </div>
</template>
<script src="./sdtm_conversion.js"></script>
<style>
.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.loading-btn-img {
  display: block;
  mix-blend-mode: lighten;
}


.toc-loader {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>