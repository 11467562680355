<template>
  <div class="form-check rightalignradio">
  <input type="radio"
  class="form-check-input"
  :disabled="disable || fielddisabled==true"
  :checked ="modelValue === value"
  :value="value"
  @change="$emit('update:modelValue',value)"
  v-bind="$attrs"
  />
  <label v-if="label" class="form-check-label">{{label}}</label>
</div>
</template>

<script>
export default{
  props:{
    label:{
      type: String,
      default: ''
    },
    fielddisabled:{
        type: Boolean,
        default: false
    },
    modelValue: {
      type: [String, Number],
      default:''
    },
    disable:{
        type: Boolean,
        default: false
      },
    value:{
      type: [String, Number],
      default: ''
    },
     privilege:{
        type: String,
        default: ''
      },
       fromarchive: {
        type: String,
        default: 'false'
      }
  }
}
</script>
<style scoped>
.form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label,
.form-check-input:disabled{
  opacity :0.7 !important
}
.form-check-input:disabled ~ .form-check-label{
color:inherit
}
</style>
<style scoped>
.rightalignradio{
  margin-left: 10px;
}
</style>