<template>
  <div
    v-if="this.closedoption != 'displayform'"
    class="row wrapper border-bottom white-bg page-heading"
  >
    <div class="col-lg-10">
      <h2>{{ $t("navigations.edit form") }}</h2>
    </div>
    <div class="col-lg-2">
      <i class="fa fa-times close my-1" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content pt-0">
    <div class="ibox mb-lg-0">
      <div class="ibox-content form-bg">
        <div class="row">
          <div class="col-sm-12 col-lg-8 mb-4 mb-lg-auto">
            <div
              class="row my-0 p-0"
              v-for="form in forms"
              :key="form.folderId"
            >
              <div class="col-sm-3 col-lg-4 mt-1">
                <div class="text-left">
                  {{ $t("navigations.status") }} : &nbsp;
                  <span
                    class="label label-primary"
                    v-if="receivedData[0].formStatus == 'Verified'"
                    >{{ receivedData[0].formStatus }}</span
                  >
                  <span class="label label-danger" v-else>{{
                    receivedData[0].formStatus
                  }}</span>
                </div>
              </div>
              <div
                class="col-sm-5  col-lg-4 text-left mt-1"
                v-if="receivedData[0].formStatus == 'Verified'"
              >
                Verified by :
                <span>
                  <strong>{{ form.verifiedBy }}</strong></span
                >
              </div>
              <div
                class="col-sm-4  col-md-4 text-left mt-1"
                v-if="receivedData[0].formStatus == 'Verified'"
              >
                Verified date :
                <span>
                  <strong>{{ filter(form.verifiedAt) }}</strong></span
                >
              </div>
            </div>
          </div>
          <div
            class="col-sm-12 col-lg-4 d-flex flex-lg-row align-items-center justify-content-lg-end mx-lg-0 my-lg-0"
            v-if="isqueryfield && isReview == true"
          >
            <div>
              <button
                class="btn btn-danger float-right mr-2 tooltipvisibility"
                type="button"
                @click="FormQueryDetails(`Open`)"
              >
                <span class="pointer">
                  {{ $t("navigations.open queries") }} ({{
                    formOpenQueryCount
                  }})
                </span>
                <img src="../../assets/images/open_query.png" alt="" /><span
                  class="badge"
                  >{{ formOpenQueryCount }}</span
                >
              </button>
              <button
                class="btn btn-info float-right mr-2 tooltipvisibility"
                type="button"
                @click="FormQueryDetails(`Closed`)"
              >
                <span class="pointer">
                  {{ $t("navigations.closed queries") }} ({{
                    formClosedQueryCount
                  }})
                </span>
                <img src="../../assets/images/closed_query.png" /><span
                  class="badge"
                  >{{ formClosedQueryCount }}</span
                >
              </button>

              <button
                class="btn btn-info float-right mr-2 tooltipvisibility"
                type="button"
                @click="UploadDocumentModal()"
              >
                <span class="pointer">
                  {{ $t("navigations.upload document") }}
                </span>
                <img src="../../assets/images/Form_upload.png" />
              </button>

              <button
                class="btn btn-info float-right mr-2 tooltipvisibility"
                type="button"
                @click="ViewDocumentModal()"
              >
                <span class="pointer">
                  {{ $t("navigations.view documents") }}
                </span>
                <img src="../../assets/images/form_view.png" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content mb-2 form-bg">
      <div class="row">
        <div class="col-md-12 col-lg-8">
          <div class="row">
            <div class="col-sm-3 col-md-3 col-lg-3">
              {{ $t("navigations.site code") }} :
              <span>
                <strong>{{ sitesId }}</strong></span
              >
            </div>
            <div class="col-sm-4 col-md-4 col-lg-5 text-left">
              {{ $t("navigations.subject id") }} :
              <span>
                <strong>{{ patientsId }}</strong></span
              >
            </div>
            <div class="col-sm-5 col-md-5 col-lg-4 text-left">
              {{ $t("navigations.form name") }} :
              <span>
                <strong>{{ formName }}</strong></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox-content d-flex">
      <div class="row col-md-10">
        <div class="col-md-8">
          <div class="form-group my-lg-0">
            <label
              class="col-lg-12 col-form-label float-left px-lg-0 pb-lg-1 pt-lg-0"
              >{{ $t("navigations.page name") }}</label
            >

            <select
              class="col-lg-9 form-control"
              @change="onpagechange($event.target.value)"
              name="account"
              placeholder="Search"
            >
              <option
                v-for="page in pages"
                :key="page.pageNumber"
                :value="page.pageNumber"
                :selected="page.pageNumber == pageno"
                >{{ page.pageName }}</option
              >
            </select>
          </div>
        </div>
      </div>
  <div v-if="isReview" class="col-md-2 mx-lg-0 pr-0">
        <!-- to sign -->
          <span
            v-if="signStatus == `Unsigned` || signStatus == null"
            class="btn btn-rsquare float-right mr-1 tooltipvisibility"
            type="button"
            @click="signatureClick(`Signed`)"
          >
            <span class="pointer"> {{ $t("navigations.sign") }} </span>
            <i class="fa fa-pencil"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-sign mb-2" v-if="signStatus == `Signed`">
      <div class="row">
        <div class="col-md-12">
          <span class="mb-1"
            ><img src="../../assets/img/icons/sign.png" alt="sign_icon" /> This
            form was signed on {{ filter(signdate) }} at
            {{ formatTime(signtime) }} by {{ signedby }} </span
          ><span
            v-if="loggedinUser && signStatus == `Signed`"
            class="float-right"
            @click="signatureClick(`Unsigned`)"
          >
            <a href="#"
              ><u>{{ $t("navigations.unsign") }}</u></a
            ></span
          >
        </div>
      </div>
    </div>
  <div class="ibox-content mainbgchange bottommargin" id="getdfcontent">
    <div class="row">
        <div class="col-0 col-sm-4 col-md-4  col-lg-2 mb-2 d-none d-sm-block"><img class="float-left" src="../../assets/img/EIDSA_Clinical_Logo.png" alt=""></div>
        <div class="col-12 col-sm-4 col-md-4  col-lg-8 text-center mb-2"><h3 class="form-header">{{formHeader}}</h3></div>
    </div>
      <div id="formElements mt-3 ">
        <form>
          <div
            class="form-group"
            v-for="input in htmlarray"
            :key="input.folder_Id"
          >
            <div v-for="(item, pageIndex) in input.pages" :key="pageIndex">
              <div
                v-show="item.pageNo == pageno"
                class="pageContainer mt-2 mainbgchange"
              >
                <div>
                  
                  <div
                    class="groupsdiv"
                    v-for="(groupno, key) in groups[pageno]"
                    :key="key"
                  >
                    <div class="flexContainer mainbgchange">
                      <template
                        v-for="(newfield, fieldIndex) in item.field"
                        :key="fieldIndex"
                      >
                      
                        <div
                          class="flex-sub-container mainbgchange"
                          v-if="newfield.groupOrderNo == groupno"
                        >
                        <template v-if="visibility[newfield.fieldName]">
                          <!-- headder starts -->
                          <div
                            v-if="newfield.type == 'header'"
                            class="headerdiv text-center"
                          >
                            <h5 class="headdertext align-self-center my-1">
                              {{ newfield.label }}
                            </h5>
                          </div>
                          <!-- headder ends -->

                          <!-- input starts -->
                          <div
                            v-else-if="inputElements.includes(newfield.type)"
                            class="flex-item"
                            @load="testfun()"
                          >
                            <baseinput
                              v-model="
                                formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                ]
                              "
                              :autofill="isautofill(newfield.fieldName)"
                              :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                              :requiredname="
                                newfield.validation.includes('REQUIRED')
                                  ? 'Required'
                                  : ''
                              "
                              :label="newfield.label"
                              :inputtype="newfield.type"
                              type="text"
                              @focusoutfun="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                            />
                            <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                          </div>
                          <!-- input ends -->
                          <!-- image starts -->
                          <div
                            v-else-if="newfield.type == 'image'"
                            class="flex-item displayflex"
                          >
                            <div>
                              <div class="labelclass marginauto">
                                {{ newfield.label }}
                              </div>
                            </div>
                            <div class="d-flex flex-column">
                              <input
                                class="form-control col-md-6 mb-2"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                                type="text"
                              />
                              <img
                                class="templatepreview max-image"
                                :src="
                                  `${fileuploadurl}/file/${
                                    newfield.imagePath.split(',')[0]
                                  }?versionid=${
                                    newfield.imagePath.split(',')[1]
                                  }`
                                "
                              />
                            </div>
                          </div>
                          <!-- image ends -->
                          <!-- calculation starts -->
                          <div
                            v-else-if="newfield.type == 'calculation'"
                            class="flex-item"
                          >
                            <div class="d-flex p-2">
                              <div class="labelclass">
                                {{ newfield.label }}
                              </div>
                              <div hidden>
                                {{
                                  calculateResult(
                                    item.pageNo,
                                    newfield.options,
                                    newfield.fieldName
                                  )
                                }}
                              </div>
                              <div class="labelclass ml-2">
                                {{ calculatedresult[newfield.fieldName] }}
                              </div>
                            </div>
                          </div>
                          <!-- calculation ends -->
                          <!-- Grid starts -->
                          <div
                            class="elem"
                            v-else-if="newfield.type == 'grid'"
                            @click.prevent="inputfieldedit(newfield, groupno)"
                          >
                            <div class="notesstyle gridbackground">
                              <div
                                class="ibox-content-edit flextable gridbackground"
                              >
                                <div class="addfieldtable">
                                  <div
                                    class="rowcolmn"
                                    v-for="(gridrow, index) in gridoptarray[
                                      newfield.fieldName
                                    ][0].Rows"
                                    :key="gridrow"
                                  >
                                    <div class="rowlabelgrid rowcolmnc">
                                      {{ gridrow }}
                                    </div>
                                    <div
                                      class="rowcolmnc"
                                      v-for="gridcolumn in gridoptarray[
                                        newfield.fieldName
                                      ][0].Columns"
                                      :key="gridcolumn"
                                    >
                                      <div v-if="index == 0">
                                        {{ gridcolumn }}
                                      </div>
                                      <div
                                        v-if="
                                          gridoptarray[newfield.fieldName][0]
                                            .Fieldtypes[index] == 'text'
                                        "
                                      >
                                        <input
                                          class="form-control"
                                          v-model="
                                            gridlinkeddatas[newfield.fieldName][
                                              gridrow + gridcolumn
                                            ]
                                          "
                                          type="text"
                                        />
                                      </div>
                                      <div
                                        v-if="
                                          gridoptarray[newfield.fieldName][0]
                                            .Fieldtypes[index] == 'number'
                                        "
                                      >
                                        <input
                                          class="form-control"
                                          v-model="
                                            gridlinkeddatas[newfield.fieldName][
                                              gridrow + gridcolumn
                                            ]
                                          "
                                          type="number"
                                        />
                                      </div>
                                      <div
                                        v-if="
                                          gridoptarray[newfield.fieldName][0]
                                            .Fieldtypes[index] == 'date'
                                        "
                                      >
                                        <input
                                          class="form-control"
                                          v-model="
                                            gridlinkeddatas[newfield.fieldName][
                                              gridrow + gridcolumn
                                            ]
                                          "
                                          type="date"
                                        />
                                      </div>
                                      <div
                                        v-if="
                                          gridoptarray[newfield.fieldName][0]
                                            .Fieldtypes[index] == 'time'
                                        "
                                      >
                                        <input
                                          class="form-control"
                                          v-model="
                                            gridlinkeddatas[newfield.fieldName][
                                              gridrow + gridcolumn
                                            ]
                                          "
                                          type="time"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Grid ends -->
                          <!-- scales starts -->
                          <div
                            v-else-if="newfield.type == 'scales'"
                            class="flex-item"
                          >
                            <div class="labelclass float-left mr-2">
                              {{ newfield.label
                              }}<sup
                                v-if="newfield.validation.includes('REQUIRED')"
                                ><i class="fa fa-asterisk imp"></i
                              ></sup>
                            </div>
                            <div>
                              <div class="d-flex">
                                <h6>{{ newfield.limit[0].min }}</h6>
                                <input
                                  type="range"
                                  :min="newfield.limit[0].min"
                                  :max="newfield.limit[0].max"
                                  step="1"
                                  :disabled="signStatus == 'Signed' || calculatedfields.includes(newfield.fieldName)"
                                  v-model="
                                    formdata[`pageNo${item.pageNo}`][
                                      newfield.fieldName
                                    ]
                                  "
                                  @blur="focusOutFunction(item.pageNo,
                                  newfield.fieldName,'')"
                                />
                                <h6>{{ newfield.limit[0].max }}</h6>
                              </div>
                              <input
                                class="form-control"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                type="number"
                                :disabled="signStatus == 'Signed' || calculatedfields.includes(newfield.fieldName)"
                                :min="newfield.limit[0].min"
                                :max="newfield.limit[0].max"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                                @blur="focusOutFunction(item.pageNo,
                                  newfield.fieldName,'')"
                              />
                              <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                            </div>
                          </div>
                          <!-- scales ends -->
                          <!-- time element starts -->
                          <div
                            v-else-if="newfield.type == 'time'"
                            class="text-start m-1"
                          >
                          <basetime
                              v-model="
                                formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                ]
                              "
                              :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                              :timeFormat="gettimeformat(newfield.validation)"
                              :requiredname="
                                newfield.validation.includes('REQUIRED')
                                  ? 'Required'
                                  : ''
                              "
                              :label="newfield.label"
                              type="text"
                              @focusoutfun="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                            />
                            
                            <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                          </div>
                          <!-- time element ends -->
                          <!-- date element starts -->
                          <div
                            v-else-if="newfield.type == 'date'"
                            class="flex-item"
                            @load="testfun()"
                          >
                            <basedate
                              v-model="
                                formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                ]
                              "
                              :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                              :dateFormat="getdateformat(newfield.validation)"
                              :requiredname="
                                newfield.validation.includes('REQUIRED')
                                  ? 'Required'
                                  : ''
                              "
                              :label="newfield.label"
                              type="text"
                              @focusoutfun="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                            />
                            <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                              ref="validationMsgs"
                              :data-page="item.pageNo"
                            />
                          </div>
                          <!-- date element ends -->
                          <!-- dateandtime element starts -->
                          <div
                            v-else-if="newfield.type == 'dateandtime'"
                            class="flex-item"
                          >
                            <basedatetime
                              :label="newfield.label"
                              :requiredname="
                                newfield.validation.includes('REQUIRED')
                                  ? 'Required'
                                  : ''
                              "
                              :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                              type="datetime-local"
                              :disabled="signStatus == 'Signed'"
                              v-model="
                                formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                ]
                              "
                              @focusoutfun="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                            />
                            <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                          </div>
                          <!-- date element ends -->
                          <!-- dropdown menu starts -->
                          <div
                            v-else-if="newfield.type == 'dropdown'"
                            class="flex-item"
                          >
                            <baseselect
                              :options="newfield.options"
                              :requiredname="
                                newfield.validation.includes('REQUIRED')
                                  ? 'Required'
                                  : ''
                              "
                              :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                              v-model="
                                formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                ]
                              "
                              :label="newfield.label"
                              @focusoutfun="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  $event
                                )
                              "
                            />
                            <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                          </div>
                          <!-- dropdown menu ends -->
                          <!-- summery starts -->
                          <div
                            class="elem"
                            v-else-if="newfield.type == 'summary'"
                          >
                            <div class="txtarealabel">
                              <i
                                ><label
                                  :for="newfield.fieldName"
                                  class="textsize"
                                  >{{ newfield.label }}</label
                                ></i
                              >
                            </div>
                          </div>
                          <!-- summery ends -->
                          <!-- text area starts -->
                          <div
                            class="elem"
                            v-else-if="
                              (newfield.type == 'description') |
                                (newfield.type == 'textarea')
                            "
                          >
                            <div class="txtarealabel">
                              <label :for="newfield.fieldName" class="textsize"
                                >{{ newfield.label
                                }}<sup
                                  v-if="
                                    newfield.validation.includes('REQUIRED')
                                  "
                                  ><i class="fa fa-asterisk imp"></i></sup
                              ></label>
                            </div>
                            <div class="txtar">
                              <textarea
                              :disabled="signStatus == 'Signed' || calculatedfields.includes(newfield.fieldName)"
                                :id="newfield.fieldName"
                                :name="newfield.fieldName"
                                class="textbox"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                                @blur="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                              />

                              <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                            </div>
                          </div>
                          <!-- text area ends -->
                          <!-- notes starts -->
                          <div
                            class="ml-2 pb-2"
                            v-else-if="newfield.type == 'notes'"
                          >
                            <div>
                              <i> {{ newfield.label }}</i>
                            </div>
                          </div>
                          <!-- notes ends -->
                          <!-- file upload starts -->
                          <div
                            v-else-if="newfield.type == 'uploadfile'"
                            class="flex-item"
                          >
                            <div class="labelclass">
                              {{ newfield.label }}
                              <sup
                                v-if="newfield.validation.includes('REQUIRED')"
                                ><i class="fa fa-asterisk imp"></i
                              ></sup>
                            </div>
                            <div hidden>
                              {{
                                getdownloadFilename(
                                  newfield.fieldName,
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                )
                              }}
                            </div>
                            <div class="default-text">
                              {{ downloadedfilenames[newfield.fieldName] }}
                            </div>
                            <div class="fileuploadbtm d-flex">
                              <div class="col-md-10">
                                <input
                                  class="form-control"
                                  :label="newfield.label"
                                  type="file"
                                  @change="helloButton($event, item.pageNo, newfield.fieldName)"
                                   @focus="onFileInputFocus(newfield.fieldName)"
                                   @blur="onFileInputBlur(item.pageNo, newfield.fieldName, newfield.validation)"
                                />
                              </div>
                              <button
                                class="btn btn-primary btn-sm"
                                @click.prevent="
                                  downloaduploadedfile(
                                    formdata[`pageNo${item.pageNo}`][
                                      newfield.fieldName
                                    ]
                                  )
                                "
                              >
                                Download
                              </button>
                            </div>
                          </div>
                          <!-- file upload ends -->
                          <!-- checkboxes starts -->
                          <div
                            v-else-if="newfield.type == 'checkbox'"
                            class="form-group sticktobottom"
                          >
                            <label class="labelstyle">&nbsp;</label>
                            <div class="checkboxentry">
                              <input
                                type="checkbox"
                                :disabled="signStatus == 'Signed' || calculatedfields.includes(newfield.fieldName)"
                                class="form-check-input"
                                :id="newfield.fieldName"
                                :name="newfield.fieldName"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                                @change="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                              /><span class="checklabel labelstyle">
                                {{ newfield.label }}&nbsp;<sup
                                  v-if="
                                    newfield.validation.includes('required')
                                  "
                                  ><i class="fa fa-asterisk imp px-1"></i
                                ></sup>
                              </span>
                            </div>
                            <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                          </div>
                          <!-- checkboxes ends -->
                          <!-- qr code starts -->
                          <div
                            class="elem"
                            v-else-if="newfield.type == 'qrcode'"
                          >
                            <div hidden>
                              {{
                                qrcodefun(newfield.fieldName, newfield.qrData)
                              }}
                            </div>
                            <div>{{ newfield.label }}</div>
                            <img
                              :src="qrcodetxt[newfield.fieldName]"
                              title="qr code"
                            />
                          </div>
                          <!-- qr code ends -->
                          <!-- year starts -->
                          <div
                            v-else-if="newfield.type == 'year'"
                            class="flex-item"
                          >
                            <basenumber
                              :label="newfield.label"
                              :id="newfield.fieldName"
                              :requiredname="
                                newfield.validation.includes('REQUIRED')
                                  ? 'Required'
                                  : ''
                              "
                              :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                              type="number"
                              step="1"
                              :minValue="1900"
                              v-model="
                                formdata[`pageNo${item.pageNo}`][
                                  newfield.fieldName
                                ]
                              "
                              @input="
                                truncateInput($event, newfield.fieldName, 4)
                              "
                              @focusoutfun="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  ''
                                )
                              "
                            />
                            <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                          </div>
                          <!-- year ends -->
                          <!-- radio button starts -->
                          <div
                            v-else-if="
                              newfield.type == 'radio' &&
                                newfield.align == 'vertical'
                            "
                            class="form-group sticktobottom"
                          >
                            <div>
                              <baseradiogroup
                                :label="newfield.label"
                                :name="newfield.fieldName"
                                :requiredname="
                                  newfield.validation.includes('REQUIRED')
                                    ? 'Required'
                                    : ''
                                "
                                :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                                :options="newfield.options"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                                @focusoutfunwithopt="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  $event
                                )
                              "
                              >
                              </baseradiogroup>
                            </div>
                            <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                          </div>
                          <!-- radio button ends -->
                          <!-- radio button starts -->
                          <div
                            v-else-if="
                              newfield.type == 'radio' &&
                                newfield.align != 'vertical'
                            "
                            class="form-group sticktobottom"
                          >
                            <div>
                              <baseradiogroup
                                :label="newfield.label"
                                :name="newfield.fieldName"
                                :options="newfield.options"
                                :disable="signStatus == 'Signed'"
                                orientation="horizondal"
                                :requiredname="
                                  newfield.validation.includes('Required') ||
                                  newfield.validation.includes('REQUIRED')
                                    ? 'Required'
                                    : ''
                                "
                                :fielddisabled="calculatedfields.includes(newfield.fieldName)"
                                v-model="
                                  formdata[`pageNo${item.pageNo}`][
                                    newfield.fieldName
                                  ]
                                "
                                 @focusoutfunwithopt="
                                focusOutFunction(
                                  item.pageNo,
                                  newfield.fieldName,
                                  $event
                                )
                              "
                              >
                              </baseradiogroup>
                            </div>
                            <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                          </div>
                          <!-- radio button ends -->
                          <!-- numberanddate starts -->
                          <div
                            v-else-if="newfield.type == 'numberanddate'"
                            class="flex-item single-line-input"
                          >
                            <label class="labelclass">
                              {{ newfield.label
                              }}<sup
                                v-if="newfield.validation.includes('REQUIRED')"
                                ><i class="fa fa-asterisk imp"></i
                              ></sup>
                            </label>
                            <div
                              v-if="numberanddateval[newfield.fieldName]"
                              class="twoitemflex input-container"
                            >
                              <input
                                class="form-control  number-input"
                                :label="newfield.label"
                                :disabled="signStatus == 'Signed' || calculatedfields.includes(newfield.fieldName)"
                                type="number"
                                v-model="
                                  numberanddateval[newfield.fieldName]
                                    .numberdata
                                "
                                @blur="numberanddateblurfun(
                                  item.pageNo,
                                  newfield.fieldName
                                )"
                              />
                              <input
                                class="form-control date-input"
                                :label="newfield.label"
                                :disabled="signStatus == 'Signed' || calculatedfields.includes(newfield.fieldName)"
                                type="date"
                                v-model="
                                  numberanddateval[newfield.fieldName].datedata
                                "
                                @blur="numberanddateblurfun(
                                  item.pageNo,
                                  newfield.fieldName
                                )"
                              />
                            </div>
                            <ValidationMessage
                              v-if="
                                isvalidate && messagetype[newfield.fieldName]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[newfield.fieldName]"
                              :errorMsg="errormsg[newfield.fieldName]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                          </div>
                          <!-- numberanddate ends -->
                          <!-- subHeader starts -->
                          <div
                            v-else-if="newfield.type == 'subHeader'"
                            class="subheadder"
                          >
                            <h4>{{ newfield.label }}</h4>
                          </div>
                          <!-- subHeader ends -->
                          <!-- Repeating field starts -->
                          <div
                            class="border-div"
                            v-else-if="
                              newfield.type == 'repeatedmeasure' ||
                                newfield.type == 'RepeatingMeasure' ||
                                newfield.type == 'repeatingfield'
                            "
                          >
                            <div
                              class="headerdiv d-flex justify-content-between"
                            >
                              <h5 class="headdertext align-self-center mb-0">
                                {{ newfield.label }}
                              </h5>
                              <span
                                class="position-relative create-repeated-block"
                              >
                                <img
                                  class="cursor-pointer p-2"
                                  @click.prevent="
                                    addrepeatedfields(
                                      fieldIndex,
                                      pageIndex,
                                      newfield.fieldName,
                                      item.pageNo
                                    )
                                  "
                                  src="../../assets/img/create.png"
                                  alt="Add repeated fields"
                                />
                                <span class="tool-tip"
                                  >Create repeated fields</span
                                >
                              </span>
                            </div>
                            <div class="repeatContainer">
                              <div
                                v-for="(fields, rfieldIndex) in formdata[
                                  `pageNo${item.pageNo}`
                                ][newfield.fieldName]"
                                :key="rfieldIndex"
                                class="flexContainer mainbgchange justify-content-end"
                              >
                                <div class="text-right">
                                  <span
                                    class="position-relative create-repeated-block"
                                  >
                                    <img
                                      class="cursor-pointer p-2"
                                      @click.prevent="
                                        removeRepeatedfields(
                                          item.pageNo,
                                          newfield.fieldName,
                                          rfieldIndex
                                        )
                                      "
                                      src="../../assets/img/remove.png"
                                      alt="Remove repeated fields"
                                    />
                                    <span class="tool-tip"
                                      >Remove repeated fields</span
                                    >
                                  </span>
                                </div>
                                <div
                                  class="groupsdiv mainbgchange"
                                  v-for="(repgroupno,
                                  key) in repeatedgroupgroupnos[
                                    newfield.fieldName
                                  ]"
                                  :key="key"
                                >
                                  <div class="flexContainer mainbgchange">
                                    <template
                                      v-for="(field,
                                      fieldindex) in fields.fields"
                                      :key="field.groupOrderNo"
                                    >
                                      <div
                                        v-if="field.groupOrderNo == repgroupno"
                                        class="flex-sub-container mainbgchange"
                                      >
                                      <template v-if="visibility[field.fieldName+fields.fieldIdentifier] || visibility[field.fieldName]">
                                        <div
                                          v-if="field.type == 'header'"
                                          class="headerdiv"
                                        >
                                          <div>
                                            <h5
                                              class="headdertext align-self-center mb-0"
                                              @click.prevent="
                                                openModal(
                                                  field.label,
                                                  field.groupOrderNo
                                                )
                                              "
                                            >
                                              <div class="text-center">
                                                {{ field.label }}
                                              </div>
                                            </h5>
                                          </div>
                                        </div>
                                        <div
                                          v-else-if="
                                            inputElements.includes(field.type)
                                          "
                                          class="flex-item"
                                          @load="testfun()"
                                        >
                                          <baseinput
                                            :label="field.label"
                                            :inputtype="field.type"
                                            :autofill="isautofill(newfield.fieldName)"
                                            :fielddisabled="calculatedfields.includes(field.fieldName)"
                                            :requiredname="
                                              field.validation.includes(
                                                'REQUIRED'
                                              )
                                                ? 'Required'
                                                : ''
                                            "
                                            v-model="
                                              formdata[`pageNo${item.pageNo}`][
                                                newfield.fieldName
                                              ][rfieldIndex]['fields'][
                                                fieldindex
                                              ].value
                                            "
                                             @focusoutfun="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  rfieldIndex,
                                  fieldindex,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                            type="text"
                                          />

                                          <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                                        </div>
                                        <div
                                          v-else-if="field.type == 'date'"
                                          class="flex-item"
                                          :class="{
                                            'cursor-pointer':
                                              fromUrlStatus === true,
                                          }"
                                          @load="testfun()"
                                        >
                                          <basedate
                                            :sourceValue="fromUrlStatus"
                                            :dateFormat="getdateformat(field.validation)"
                                            :fielddisabled="calculatedfields.includes(field.fieldName)"
                                            :requiredname="
                                              field.validation.includes(
                                                'REQUIRED'
                                              )
                                                ? 'Required'
                                                : ''
                                            "
                                            :class="{
                                              'cursor-pointer':
                                                fromUrlStatus === true,
                                            }"
                                            v-model="
                                              formdata[`pageNo${item.pageNo}`][
                                                newfield.fieldName
                                              ][rfieldIndex]['fields'][
                                                fieldindex
                                              ].value
                                            "
                                            @focusoutfun="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  rfieldIndex,
                                  fieldindex,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                            :label="field.label"
                                            type="text"
                                          />
                                          <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                                        </div>
                                        <div
                                          v-else-if="field.type == 'subHeader'"
                                          class="subheadder"
                                          @click.prevent="
                                            openModal(
                                              field.label,
                                              field.groupOrderNo
                                            )
                                          "
                                          :class="{
                                            'cursor-pointer':
                                              fromUrlStatus === true,
                                          }"
                                        >
                                          <h4>{{ field.label }}</h4>
                                        </div>
                                        <div
                                          v-else-if="field.type == 'year'"
                                          class="flex-item"
                                        >
                                          <basenumber
                                            :label="field.label"
                                            :id="field.fieldName"
                                            :fielddisabled="calculatedfields.includes(field.fieldName)"
                                            :requiredname="
                                              field.validation.includes(
                                                'REQUIRED'
                                              )
                                                ? 'Required'
                                                : ''
                                            "
                                            v-model="
                                              formdata[`pageNo${item.pageNo}`][
                                                newfield.fieldName
                                              ][rfieldIndex]['fields'][
                                                fieldindex
                                              ].value
                                            "
                                            type="number"
                                            :minValue="1900"
                                            step="1"
                                            @input="
                                              truncateInput(
                                                $event,
                                                field.fieldName,
                                                4
                                              )
                                            "
                                             @focusoutfun="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  rfieldIndex,
                                  fieldindex,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                          />
                                          <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                                          >
                                        </div>
                                        <!-- text area starts -->
                                        <div
                                          class="elem"
                                          v-else-if="
                                            field.type == 'description' ||
                                              field.type == 'textarea'
                                          "
                                        >
                                          <div
                                            class="txtarealabel"
                                            :class="{
                                              'cursor-pointer':
                                                fromUrlStatus === true,
                                            }"
                                          >
                                            <label
                                              :for="field.fieldName"
                                              class="labelstyle"
                                              >{{ field.label
                                              }}<sup
                                                v-if="
                                                  field.validation.includes(
                                                    'REQUIRED'
                                                  )
                                                "
                                                ><i
                                                  class="fa fa-asterisk imp"
                                                ></i></sup
                                            ></label>
                                          </div>
                                          <div
                                            class="txtar"
                                            :class="{
                                              'cursor-pointer':
                                                fromUrlStatus === true,
                                            }"
                                          >
                                            <textarea
                                              @click="
                                                assignprivilege(
                                                  field.fieldName,
                                                  field.label,
                                                  field.groupOrderNo
                                                )
                                              "
                                              :id="field.fieldName"
                                              :name="field.fieldName"
                                              :disabled="calculatedfields.includes(field.fieldName)"
                                              :class="[
                                                `form-control`,
                                                {
                                                  'cursor-pointer':
                                                    fromUrlStatus === true,
                                                },
                                              ]"
                                              class="textbox "
                                              v-model="
                                                formdata[
                                                  `pageNo${item.pageNo}`
                                                ][newfield.fieldName][
                                                  rfieldIndex
                                                ]['fields'][fieldindex].value
                                              "
                                              @blur="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  rfieldIndex,
                                  fieldindex,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                            />
                                            <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                                            >
                                          </div>
                                        </div>
                                        <!-- text area ends -->
                                        <!-- qr code starts -->
                                        <div
                                          class="elem"
                                          v-else-if="field.type == 'qrcode'"
                                        >
                                          <div hidden>
                                            {{
                                              qrcodefun(
                                                field.fieldName,
                                                field.qrData
                                              )
                                            }}
                                          </div>
                                          <div>{{ field.label }}</div>
                                          <img
                                            :src="qrcodetxt[field.fieldName]"
                                            title="qr code"
                                          />
                                        </div>
                                        <!-- qr code ends -->
                                        <!-- summary starts -->
                                        <div
                                          class="ml-2 pb-2"
                                          v-else-if="field.type == 'summary'"
                                        >
                                          <div class="txtarealabel">
                                            <i
                                              ><label
                                                :for="field.fieldName"
                                                class="textsize"
                                                >{{ field.label }}</label
                                              ></i
                                            >
                                          </div>
                                        </div>
                                        <!-- summary ends -->
                                        <!-- notes starts -->
                                        <div
                                          class="ml-2 pb-2"
                                          v-else-if="field.type == 'notes'"
                                        >
                                          <div class="notesstyle">
                                            {{ field.label }}
                                          </div>
                                        </div>
                                        <!-- notes ends -->
                                        <!-- scales starts -->
                                        <div
                                          v-else-if="field.type == 'scales'"
                                          :class="{
                                            'cursor-pointer':
                                              fromUrlStatus === true,
                                          }"
                                          @click.prevent="
                                            openModal(
                                              field.label,
                                              field.groupOrderNo
                                            )
                                          "
                                          class="flex-item text-start"
                                        >
                                          <label
                                            class="labelclass mr-2"
                                            :class="{
                                              'cursor-pointer':
                                                fromUrlStatus === true,
                                            }"
                                          >
                                            {{ field.label
                                            }}<sup
                                              v-if="
                                                field.validation.includes(
                                                  'REQUIRED'
                                                )
                                              "
                                              ><i class="fa fa-asterisk imp"></i
                                            ></sup>
                                          </label>

                                          <div class="d-flex">
                                            <h6>
                                              {{
                                                field.limit[0].min
                                                  ? field.limit[0].min
                                                  : 0
                                              }}
                                            </h6>
                                            <input
                                              type="range"
                                              :disabled="calculatedfields.includes(field.fieldName)"
                                              :class="{
                                                'cursor-pointer':
                                                  fromUrlStatus === true,
                                              }"
                                              :min="
                                                parseFloat(field.limit[0].min)
                                              "
                                              :max="
                                                parseFloat(field.limit[0].max)
                                              "
                                              v-model="
                                                formdata[
                                                  `pageNo${item.pageNo}`
                                                ][newfield.fieldName][
                                                  rfieldIndex
                                                ]['fields'][fieldindex].value
                                              "
                                              @blur="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  rfieldIndex,
                                  fieldindex,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                              step="1"
                                            />
                                            <h6>{{ field.limit[0].max }}</h6>
                                          </div>
                                          <input
                                            class="form-control"
                                            :disabled="calculatedfields.includes(field.fieldName)"
                                            :class="[
                                              {
                                                'cursor-pointer':
                                                  fromUrlStatus === true,
                                              },
                                            ]"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                            type="number"
                                            v-model="
                                              formdata[`pageNo${item.pageNo}`][
                                                newfield.fieldName
                                              ][rfieldIndex]['fields'][
                                                fieldindex
                                              ].value
                                            "
                                            :min="
                                              parseFloat(field.limit[0].min)
                                            "
                                            :max="
                                              parseFloat(field.limit[0].max)
                                            "
                                            @blur="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  rfieldIndex,
                                  fieldindex,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                          />

                                          <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                                        </div>
                                        <!-- scales ends -->
                                        <div
                                          v-else-if="field.type == 'dropdown'"
                                          class="flex-item"
                                        >
                                          <baseselect
                                            :options="field.options"
                                            :fielddisabled="calculatedfields.includes(field.fieldName)"
                                            :requiredname="
                                              field.validation.includes(
                                                'REQUIRED'
                                              )
                                                ? 'Required'
                                                : ''
                                            "
                                            v-model="
                                              formdata[`pageNo${item.pageNo}`][
                                                newfield.fieldName
                                              ][rfieldIndex]['fields'][
                                                fieldindex
                                              ].value
                                            "
                                            :label="field.label"
                                            @focusoutfun="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  rfieldIndex,
                                  fieldindex,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  $event
                                )
                              "
                                          />
                                          <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                                        </div>
                                            <!-- checkboxes starts -->
                                           <div
                                            v-else-if="field.type == 'checkbox'"
                                            class="form-group sticktobottom"
                                            :class="{
                                            'cursor-pointer':
                                              fromUrlStatus === true,
                                            }"
                                            >
                                           <div class="ml-4">
                                            <input
                                              type="checkbox"
                                              class="form-check-input"
                                              :disabled="calculatedfields.includes(field.fieldName)"
                                              :checked="
                                                formdata[
                                                  `pageNo${item.pageNo}`
                                                ][newfield.fieldName][
                                                  rfieldIndex
                                                ]['fields'][fieldindex].value ==
                                                  'true'
                                              "
                                              @input="
                                                updateCheckboxValue(
                                                  item.pageNo,
                                                  newfield.fieldName,
                                                  rfieldIndex,
                                                  fieldindex,
                                                  $event.target.checked
                                                )
                                              "
                                              :id="field.fieldName"
                                              :name="field.fieldName"
                                              @change="
                                                    focusOutFunctionForRF(
                                                                  item.pageNo,
                                                                  newfield.fieldName,
                                                                  rfieldIndex,
                                                                  fieldindex,
                                                                  field.fieldName,
                                                                  fields.fieldIdentifier,
                                                                  ''
                                                                    )
                                                                    "
                                            />
                                            <span class="checklabel labelstyle">
                                {{ field.label }}&nbsp;<sup
                                  v-if="
                                    field.validation.includes('required')
                                  "
                                  ><i class="fa fa-asterisk imp px-1"></i
                                ></sup>
                              </span>
                                            <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                                          </div>
                                        </div>
                                        <!-- checkboxes ends -->
                                        <!-- number and date starts -->
                                        <div
                                          v-else-if="
                                            field.type == 'numberanddate'
                                          "
                                          class="flex-item single-line-input"
                                        >
                                          <label class="labelclass">
                                            {{ field.label
                                            }}<sup
                                              v-if="
                                                field.validation.includes(
                                                  'REQUIRED'
                                                )
                                              "
                                              ><i class="fa fa-asterisk imp"></i
                                            ></sup>
                                          </label>
                                          <div hidden>
                                            {{
                                              repeatednumbanddatefocusoutfun(
                                                field.fieldName +
                                                  fields.fieldIdentifier
                                              )
                                            }}
                                          </div>
                                          <div
                                            v-if="
                                              repeatednumberanddateval[
                                                field.fieldName +
                                                  fields.fieldIdentifier
                                              ]
                                            "
                                            class="twoitemflex input-container"
                                          >
                                            <input
                                              class="form-control number-input"
                                              :disabled="calculatedfields.includes(field.fieldName)"
                                              v-model="
                                                repeatednumberanddateval[
                                                  field.fieldName +
                                                    fields.fieldIdentifier
                                                ].numberdata
                                              "
                                              :label="field.label"
                                              type="number"
                                              @blur="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  rfieldIndex,
                                  fieldindex,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                            />
                                            <input
                                              class="form-control date-input"
                                              :disabled="calculatedfields.includes(field.fieldName)"
                                              v-model="
                                                repeatednumberanddateval[
                                                  field.fieldName +
                                                    fields.fieldIdentifier
                                                ].datedata
                                              "
                                              :label="field.label"
                                              type="date"
                                              @blur="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  rfieldIndex,
                                  fieldindex,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                            />
                                          </div>
                                          <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                                        </div>
                                        <!-- number and date ends -->
                                        <!-- radio button starts -->
                                        <div
                                          v-else-if="
                                            field.type == 'radio' &&
                                              field.align == 'vertical'
                                          "
                                          class="form-group sticktobottom"
                                          :class="{
                                            'cursor-pointer':
                                              fromUrlStatus === true,
                                          }"
                                        >
                                          <baseradiogroup
                                            :sourceValue="fromUrlStatus"
                                            :fielddisabled="calculatedfields.includes(field.fieldName)"
                                            :class="{
                                              'cursor-pointer':
                                                fromUrlStatus === true,
                                            }"
                                            :label="field.label"
                                            :name="field.fieldName"
                                            v-model="
                                              formdata[`pageNo${item.pageNo}`][
                                                newfield.fieldName
                                              ][rfieldIndex]['fields'][
                                                fieldindex
                                              ].value
                                            "
                                            :options="field.options"
                                            :requiredname="
                                              field.validation.includes(
                                                'REQUIRED'
                                              )
                                                ? 'Required'
                                                : ''
                                            "
                                             @focusoutfunwithopt="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  rfieldIndex,
                                  fieldindex,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  $event
                                )
                              "
                                          >
                                          </baseradiogroup>
                                          <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                                        </div>
                                        <!-- radio button ends -->
                                        <!-- radio button starts -->
                                        <div
                                          v-else-if="
                                            field.type == 'radio' &&
                                              field.align != 'vertical'
                                          "
                                          class="form-group sticktobottom"
                                          :class="{
                                            'cursor-pointer':
                                              fromUrlStatus === true,
                                          }"
                                        >
                                          <baseradiogroup
                                            :sourceValue="fromUrlStatus"
                                            :fielddisabled="calculatedfields.includes(field.fieldName)"
                                            :class="{
                                              'cursor-pointer':
                                                fromUrlStatus === true,
                                            }"
                                            :label="field.label"
                                            :name="field.fieldName"
                                            v-model="
                                              formdata[`pageNo${item.pageNo}`][
                                                newfield.fieldName
                                              ][rfieldIndex]['fields'][
                                                fieldindex
                                              ].value
                                            "
                                            :options="field.options"
                                            orientation="horizondal"
                                            :requiredname="
                                              field.validation.includes(
                                                'REQUIRED'
                                              )
                                                ? 'Required'
                                                : ''
                                            "
                                            @focusoutfunwithopt="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  rfieldIndex,
                                  fieldindex,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  $event
                                )
                              "
                                          >
                                          </baseradiogroup>
                                          <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                                        </div>
                                        <!-- radio button ends -->
                                        <!-- time element starts -->
                                        <div
                                          v-else-if="field.type == 'time'"
                                          class="elem"
                                        >
                                          <label class="labelclass text-start">
                                            {{ field.label
                                            }}<sup
                                              v-if="
                                                field.validation.includes(
                                                  'REQUIRED'
                                                )
                                              "
                                              ><i class="fa fa-asterisk imp"></i
                                            ></sup>
                                          </label>
                                          <basetime
                                          :fielddisabled="calculatedfields.includes(field.fieldName)"
                                            class="form-control"
                                            classname="time"
                                            :label="field.label"
                                            :timeFormat="gettimeformat(field.validation)"
                                            type="time"
                                            v-model="
                                              formdata[`pageNo${item.pageNo}`][
                                                newfield.fieldName
                                              ][rfieldIndex]['fields'][
                                                fieldindex
                                              ].value
                                            "
                                             @focusoutfun="
                                focusOutFunctionForRF(
                                  item.pageNo,
                                  newfield.fieldName,
                                  rfieldIndex,
                                  fieldindex,
                                  field.fieldName,
                                  fields.fieldIdentifier,
                                  ''
                                )
                              "
                                          />
                                          <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                                        </div>
                                        <!-- time element ends-->
                                        <!-- dateandtime element starts -->
                                        <div
                                          v-else-if="
                                            field.type == 'dateandtime'
                                          "
                                          class="flex-item bgblue"
                                        >
                                          <basedatetime
                                            @click="
                                              openModal(
                                                field.label,
                                                field.groupOrderNo
                                              )
                                            "
                                            :fielddisabled="calculatedfields.includes(field.fieldName)"
                                            :sourceValue="fromUrlStatus"
                                            :class="{
                                              'cursor-pointer':
                                                sourceValue === true,
                                            }"
                                            :label="field.label"
                                            v-model="
                                              formdata[`pageNo${item.pageNo}`][
                                                newfield.fieldName
                                              ][rfieldIndex]['fields'][
                                                fieldindex
                                              ].value
                                            "
                                            :requiredname="
                                              field.validation.includes(
                                                'REQUIRED'
                                              )
                                                ? 'Required'
                                                : ''
                                            "
                                            type="datetime-local"
                                          />
                                          <ValidationMessage
                              v-if="
                                isvalidate && messagetype[field.fieldName+fields.fieldIdentifier]
                              "
                              :isvalidate="isvalidate"
                              :messageType="messagetype[field.fieldName+fields.fieldIdentifier]"
                              :errorMsg="errormsg[field.fieldName+fields.fieldIdentifier]"
                               ref="validationMsgs"
                               :data-page="item.pageNo"
                            />
                                        </div>
                                        <!-- date element ends -->
                                      </template>
                                    </div>
                                    </template>
                                  </div>
                                </div>
                                <div
                                  class="repeat-border-bottom col-12 mb-2"
                                ></div>
                              </div>
                            </div>
                            <!-- Repeating field ends -->
                          </div>
                        </template></div>
                      </template>
                    </div>
                  </div>
                  <!------ pagination -------->
                  <div class="row mx-0 paginationpadding">
                    <div class="col-lg-12 mx-0 px-0 mt-4">
                      <div
                        class="dataTables_paginate paging_simple_numbers pagination float-right"
                      >
                        <div
                          class="col-lg-12 d-flex justify-content-lg-end px-0"
                        >
                          <v-pagination
                            v-model="pageno"
                            :pages="totalpages"
                            :range-size="1"
                            active-color="#618bd7"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="buttonContainer col-md-10">
                    <div class="leftbutton">
                      <button
                        v-if="pageno > 1"
                        type="button"
                        class="save_btn py-1 px-4"
                        @click.prevent="previousFun"
                      >
                        Previous
                      </button>
                    </div>
                    <div class="rightbutton">
                      <button
                        v-if="pageno == totalpages && isPatientValue != 'true'"
                        type="button"
                        class="save_btn py-1 px-4"
                        @click.prevent="savefun"
                      >
                        {{ $t("navigations.save") }}
                      </button>
                    </div>

                    <div class="rightbutton">
                      <button
                        v-if="pageno < totalpages"
                        type="button"
                        class="save_btn py-1 px-4"
                        @click.prevent="nextfun"
                      >
                        Next
                      </button>
                      <button
                        v-if="pageno == totalpages && isPatientValue != 'true'"
                        type="button"
                        class="save_btn py-1 px-4"
                        @click.prevent="reviewAndSave"
                      >
                        {{ $t("navigations.review and submit") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  <!-- repeatedmeasure  starts -->
  <teleport to="#app">
    <div class="add_page_modal_wrapper" v-if="repeatedmeasurepopup">
      <div class="option_modal__containers">
        <div
          class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
        >
          <h4 class="my-0">Manage Repeated Measures</h4>
          <a class="close-link">
            <i
              class="fa fa-times"
              @click.prevent="closerepeatedmeasuremodal()"
            ></i>
          </a>
        </div>

        <div
          class="form-group col-lg-10 mx-auto"
          v-if="!repeatedmeasurelistpopup"
        >
          <div class="form-group col-lg-10 mx-auto textgroup">
            <label for="optionscheckbox">ID</label>
            <div class="optgroup">
              <input
                type="text"
                class="form-control"
                id="optionscheckbox"
                v-model="repratmeasureid"
                placeholder="options"
              />
            </div>
          </div>
          <div
            v-for="measureopts in repeatedoptions"
            :key="measureopts"
            class="form-group col-lg-10 mx-auto textgroup"
          >
            <label for="optionscheckbox">{{ measureopts.fieldName }}</label>
            <div v-if="measureopts.type == 'text'" class="optgroup">
              <input
                type="text"
                class="form-control"
                v-model="temprepeatedmeasureval[measureopts.fieldName]"
                id="optionscheckbox"
                :disabled="repeathidden"
              />
            </div>
            <div v-if="measureopts.type == 'date'" class="optgroup">
              <input
                type="date"
                class="form-control"
                v-model="temprepeatedmeasureval[measureopts.fieldName]"
                id="optionscheckbox"
                :disabled="repeathidden"
              />
            </div>
            <div v-if="measureopts.type == 'number'" class="optgroup">
              <input
                type="number"
                class="form-control"
                v-model="temprepeatedmeasureval[measureopts.fieldName]"
                id="optionscheckbox"
                :disabled="repeathidden"
              />
            </div>
            <div v-if="measureopts.type == 'time'" class="optgroup">
              <input
                type="time"
                class="form-control"
                v-model="temprepeatedmeasureval[measureopts.fieldName]"
                id="optionscheckbox"
                :disabled="repeathidden"
              />
            </div>
            <div v-if="measureopts.type == 'dropdown'" class="optgroup">
              <select
                class="form-control"
                v-model="temprepeatedmeasureval[measureopts.fieldName]"
              >
                <option
                  v-for="(option, index) in measureopts.options"
                  :key="index"
                  :value="option"
                  >{{ option }}</option
                >
              </select>
            </div>
            <div v-if="measureopts.type == 'radio'" class="optgroup">
              <label
                class="mr-2"
                v-for="(option, index) in measureopts.options"
                :key="index"
              >
                <input
                  type="radio"
                  :value="option"
                  v-model="temprepeatedmeasureval[measureopts.fieldName]"
                />
                {{ option }}
              </label>
            </div>
          </div>

          <div class="form-group col-lg-10 mx-auto textgroup">
            <button
              v-if="!repeathidden"
              type="button"
              class="btn btn-primary float-right ml-1"
              @click.prevent="addrepeatemesureentries()"
            >
              Add
            </button>
            <button
              type="button"
              class="btn btn btn btn-secondary float-right"
              @click.prevent="repeatedmeasurlist()"
            >
              Back
            </button>
          </div>
        </div>
        <div
          class="form-group d-flex flex-column col-lg-10 mx-auto maxheight"
          v-if="repeatedmeasurelistpopup"
        >
          <div class="table-responsive">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th>Repeated Measure Name</th>
                  <th class="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in repeatedmarray" :key="item.ID">
                  <td @click.prevent="reloadrepeatarray(item)">
                    {{ item.ID }}
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-trash edit-delete"
                      aria-hidden="true"
                      @click="deleterepeatedmeasure(item)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-group col-lg-10 mx-auto mt-auto">
            <button
              type="button"
              class="save_btn py-1 px-4"
              @click.prevent="repeatedmeasurdatapopup()"
            >
              Add Measures
            </button>
            <button
              type="button"
              class="save_btn py-1 px-4"
              @click.prevent="saverepeatedmeasurdata()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
  <!-- repeat measures ends -->
  <!-- Document upload modal starts -->
  <div class="modal-wrapper-upload">
    <div class="modal-container-upload">
      <div class="titleclass">
        <h4 class="ml-lg-2">{{ $t("navigations.document upload") }}</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i
              class="fa fa-times"
              title="Close"
              @click.prevent="CloseUploadModal()"
            ></i>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <div class="mb-3 p-2">
          <div class="form-group row my-lg-2">
            <div class="col-lg-12 my-2">
              <textarea
                  name="description"
                  id="description"
                  class="form-control"
                  maxlength="1000"
                  v-model="azuredata.DocumentDescription"
                  :placeholder="$t('navigations.description')">
              </textarea>
            </div>

            <div class="col-lg-12 my-2">
              <input
                accept=".xls,.xlsx,.jpg,.jpeg,.png,.pdf"
                id="InPutFile"
                type="file"
                @change="previewFiles"
                class="form-control"
              />
            </div>
            <span class="errorClass" v-if="docValidations == false">{{
              $t("navigations.please select a file")
            }}</span>

            <div class="d-flex my-2 mb-lg-0">
              <div class="assignbtncontainer">
                <button
                  class="btn btn-primary px-5 mx-1"
                  @click.prevent="CreateDocumentDMS()"
                >
                  {{ $t("navigations.upload") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Document upload modal ends -->

  <!-- Document view modal starts -->
  <div class="modal-wrapper-view">
    <div class="modal-container-view">
      <div class="titleclass">
        <h4 class="ml-lg-2">{{ $t("navigations.documents") }}</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i class="fa fa-times" @click.prevent="ClosemodalView()"></i>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <div class="ibox-content">
          <div class="table-responsive">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th>{{ $t("navigations.file name") }}</th>
                  <th>{{ $t("navigations.date") }}</th>
                  <th>{{ $t("navigations.view") }}</th>
                  <th>{{ $t("navigations.download") }}</th>
                  <th>{{ $t("navigations.delete") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="document in documentlist" :key="document.fileName">
                  <td>{{ document.documentName }}</td>
                  <td>{{ filter(document.CreatedAt) }}</td>
                  <td class="text-center">
                    <i
                      class="fa fa-eye edit-delete"
                      @click="viewDocument(document.id)"
                    ></i>
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-download edit-delete"
                      @click="downloadfile(document.id)"
                    ></i>
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-trash edit-delete"
                      style="color: #a13b7c"
                      @click="deleteAzureDocument(document.id)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Document view modal ends -->
  <signaturePopUp
    v-if="signpopUp == true"
    :status="signatuestatus"
    :formId="signatureformId"
    :modalText="modalStatus"
    @signatureclosemodel="CloseModelCall()"
  >
  </signaturePopUp>
  <!-- Video call starts -->
  <div class="small-chat-box fadeInRight animated">
    <div class="heading" draggable="true">
      Schedule Video Call
    </div>
    <div class="form-chat">
      <div class="input-group input-group-sm">
        <input type="text" class="form-control" />
        <span class="input-group-append">
          <button class="btn btn-primary" type="button">Sent Invite</button>
        </span>
      </div>
    </div>
  </div>
  <span class="vidercallicon"  v-if="rollsprivilages.includes('Video Call Schedule Video Call')">
  <div id="small-chat">
    <a
      data-toggle="modal"
      @click="openVideoCallSchedulePopup(subID)"
      data-target="#inviteVideocall"
    >
      <img
        src="../../assets/img/video_call.png"
        alt="Video Call Floating Icon"
      />
    </a>
    <div class="tool-tip">Schedule Video call</div>
  </div>
  <videocallpopup v-if="videocallpopupon" @opendvidpopup="opendvidpopup">
  </videocallpopup>
</span>

  <!-- Video call ends -->
  <CreateVideoCallSchedulePopUp
    v-if="CreateVideoCallSchedulePopUp"
    @closeModal="CreateVideoCallSchedulePopUp = false"
    :patientid="selectedPatientId"
    :fromPage="'EditFrom'"
  />
</template>

<script src="./editForm.js"></script>

<style scoped>
@import "./style.css";
.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid #6d57ce !important;
  border-radius: inherit;
  margin: 0;
}
.add_page_modal_wrapper,
.modal__wrapper__component {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  height: 100pc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}
.option_modal__containers {
  width: min(600px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  height: 400px;
}
.add-block-title {
  color: #ffffff;
  background: #768dd3;
}
.paginationpadding {
  padding-bottom: 10px;
  padding-right: 10px;
}
.pageContainer {
  margin-top: 30px;
}
.seperator {
  font-size: 23px !important;
}
.linkstyleleft {
  font-weight: bold;
  color: #d42ca2 !important;
  font-size: 16px !important;
  float: left;
}
.linkcontent {
  width: 50%;
}
.errorborder {
  border-width: 2px !important;
  border-color: red !important;
  border-style: double;
}
.radioerror {
  margin-left: 16% !important;
}
.maximumwidth {
  inline-size: 150px;
  overflow-wrap: break-word;
  max-width: 150px;
}
/* .titleclass{
background-color:#6198fc;
}
.titlestyle {
    background-color: ());
} */
.hiddenvisibility {
  visibility: hidden;
}
.max-image {
  max-width: 500px; /* Maximum width of 500 pixels */
  max-height: 300px; /* Maximum height of 300 pixels */
}
.maincontainer {
  height: 100px;
  width: 100%;
  z-index: -2;
  position: absolute;
  background-color: blue;
  overflow: hidden;
}
.maincontainertwo {
  width: 100%;
  z-index: -1;
  position: absolute;
  background-color: white;
}
.gridbackground {
  background-color: #f0f0ff !important;
}
.rowlabelgrid {
  margin-top: auto;
}
.bottommargin {
  margin-bottom: 25px;
}
.modal-container-upload {
  background: #fff;
  width: min(50%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}
.modal-container-view {
  background: #fff;
  width: min(50%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}
.labelclass {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
}
.labelstyle {
  font-size: 14px;
  margin-bottom: 0.1rem !important;
  font-weight: 600;
}
.rowcolmn {
  margin-top: 0px;
  display: flex;
  gap: 5px;
  margin: 10px;
}
.create-repeated-block .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 5;
  top: -38px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.create-repeated-block:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.repeat-border-bottom {
  border-bottom: 1px dashed #3c4192;
}
.repeatContainer {
  border: groove;
}
.single-line-input {
  display: flex;
  flex-direction: column;
}

.single-line-input label {
  margin-bottom: 8px;
}

.input-container {
  display: flex;
}

.input-container input {
  flex: 1;
  margin-right: 0px;
}

.input-container input:last-child {
  margin-right: 0;
}
.default-text {
  font-size: 1rem;
  word-wrap: break-word;
  font-weight: 600;
}
.vidercallicon .tool-tip {
visibility: hidden;
width: max-content;
background-color: #616161;
color: #fff;
text-align: center;
border-radius: 0.3em;
padding: 0.3rem 0.8rem;
right: 50px;
bottom: 10px;
font-size: 0.8rem;

/* Position the tooltip */
position: absolute;
z-index: 1;
-webkit-transition: visibility 75ms ease-in;
transition: visibility 75ms ease-in;
}
.vidercallicon:hover .tool-tip {
visibility: visible;
-webkit-transition: visibility 30ms ease-in;
transition: visibility 30ms ease-in;
}
</style>
