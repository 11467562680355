/* eslint-disable */
import axios from "axios";
import store from "@/store";
import mapProgrammePopup from "../mapProgrammePopup/mapProgrammePopup.vue";

export default {
  name: "tocgenerate",
  components: {
    mapProgrammePopup
  },
  data() {
    return {
      headingId:"",
      editType:"",
      editpopup:false,
      Addheadnodeafterpopup:false,
      Addheadnodebeforepopup:false,
      editheadpopup:false,
      newheadnode:"",
      addpopup:false,
      studyId: "",
      currentheadindex:"",
      studyref:"",
      foldername: "Adam_set",
      baseUrl: process.env.VUE_APP_Service_URL,
      showTree: false,
      showSidePopup: [[[false]]],
      showHeaderSidePopup:[false],
      treeData: [],
      treeModal:[],
      showLoading: false,
      tocId: "fc240456-d1c3-4610-a6d3-1370b9feab9e",
      mapValue:[],
      idSet:[],
      tocid:"",
      resultData: [],
      resultViewData: "",
      displayModal: false,
      headerValues: {},
      loadingSaveBlock:false,
      loadingAssignBlock:false,
      openAssignModal:false,
      openSettings:false,
      selectedChildren:[],
      childrenChecked: {},
      clickedTab: "",
      refreshFlags: [],
      files: [],
      userList:[],
      htmlContent: {},
      headingname:"",
      headindex:"",
      activeTab: null,
      currentheadingid:"",
      subindexedit:"",
      reportnamedit:"",
      headingtext:"",
      reportid:"",
      subindex:"",
      headingid:"",
      subheadingid:"",
      newreportname:"",
      currentheadname:"",
      newheadname:"",
      editreportid:"",
      editheadingid:"",
      tocid:"",
      editindex:"Subindex",
      addindex:"subreport",
      editheadingname:"Reportname:",
      subreport:"",      
      modalChildLabels:{},
      rootChecked: {},
      childrenChecked: {}, 
      modalTocId:"",
      activeTab: null,
      programme: [],
      status:"",
      changedStatus:"",
      statusReportId:"",
      qcProgrammer:"",
      programmer:"",
      statusOptions:[
        {label:"Old Output",value:"Old Output"},
        {label:"Ready For Validation",value:"Ready For Validation"},
        {label:"In Development",value:"In Development"},
        {label:"Current Output",value:"Current Output"},
        {label:"Validated",value:"Validated"}
      ],
      mapProgrammePopup: false,
      displayStatusModal:false
    };
  },

  async mounted() {

    this.token = store.getters.getIdToken;
    this.headerValue = {
      headers: {
        Authorization: "Bearer " + this.token,
        "Content-Type": "application/json",
      },
    }
    this.studyId = store.getters.getStudyIs;
    const cachedTreeData = store.getters.getTreeData;
    if (cachedTreeData && cachedTreeData.length > 0) {
      this.treeData = cachedTreeData;
      this.treeModal=cachedTreeData
      this.showTree = true;

    }
    await this.getstudyinfo();
    await this.fetchToC();
    await this.getMappings()
    window.addEventListener("studyIdChanged", async () => {
      this.studyId = store.getters.getStudyIs;
      await this.fetchToC();
      await this.getstudyinfo();
      await this.getMappings()
    });
  },

  methods: {
    closeModal() {
      this.displayModal = false;
    },
    async getstudyinfo() {
      const idtoken = store.getters.getIdToken;
          await axios
        .get(
          `${this.baseUrl}/management/study/getbyid/${store.getters.getStudyIs}?api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.studyInfo = res.data;
          this.studyref = res.data.studyRef
        })
        .catch((err) => {
          console.log(err);
        });
      },
    closemodalforcreate(){
       this.addpopup=false;
    },
    goto(childlabels) {
      childlabels["tocid"] = this.tocid
      localStorage.setItem("Reportname",childlabels.Reportname)
      localStorage.setItem("Reportid",childlabels.Reportid)
      localStorage.setItem("TocId",childlabels.tocid)
      this.$router.push(`/stats/${childlabels.Reportid}`)
    },
    async generateToC(studyId, foldername) {
      console.log("this.studyRef",this.studyref);
      this.showLoading = true;
      try {
        const response = await axios.post(
          `${this.baseUrl}/stats-python/toc/generatetoc`,
          {
            study_id: studyId,
            input_folder: foldername,
            studyref: this.studyref
          },this.headerValue
        );

        store.dispatch("setTocGenerateBtn", true);
        this.treeData = response.data.toc_data.toc_data || [];
        this.treeModal = response.data.toc_data.toc_data || [];
        this.showTree = true;
        this.showLoading = false;
      } catch (error) {
        console.error("Error in API call:", error);
        this.showTree = false;
        throw error;
      }
    },
    changeStatus(childlabels,id){
      this.displayStatusModal=true
      if(this.idSet.includes(childlabels.Reportid)){
        this.mapValue.forEach((item)=>{
          if(item.ReportId == childlabels.Reportid){
            this.statusReportId=item.ReportId
            this.status=item.Status
            return;
          }
        })
      }
    },
    async saveData() {
      this.loadingSaveBlock = true
      const idtoken = store.getters.getIdToken;
      await axios
        .put(
          `${this.baseUrl}/stats-python/programme/updatestatus`,
          {
            "Status": this.changedStatus,
            "ReportId": this.statusReportId
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSaveBlock=false
          alert(this.$t('navigations.status saved successfully'))
          if(this.idSet.includes(this.statusReportId)){
            this.mapValue.forEach((item)=>{
              if(item.ReportId == this.statusReportId){
                item.Status=this.changedStatus
                return;
              }
            })
          }
          this.displayStatusModal=false
        })
        .catch((err) => {
          this.loadingSaveBlock = false
          this.displayStatusModal=false
        });
      },
    async addreport(headingid) {
      this.addpopup = true;
      this.headingid = headingid;
      if (!reportName || reportName.trim() === "") {
        //alert("Please enter a valid report name.");
        return;
      }
      console.log(`Adding report: ${reportName}`);
      
    },
    
    async Editheading(headingid,headingname) {
      console.log("headingname",headingname);
      this.editheadpopup = true;
      this.editType= "Heading";
      this.headingname=headingname;
      this.headingid = headingid;

    },
    async assignProgrammer(){
      this.loadingAssignBlock = true
      const idtoken = store.getters.getIdToken;
      await axios
        .put(
          `${this.baseUrl}/stats-python/programme/update-qc-developer-info`,
          {
            "ReportId": this.selectedChildren,
            "DeveloperName": this.programmer,
            "QCName": this.qcProgrammer
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingAssignBlock=false
          alert(this.$t('navigations.programmer assigned successfully'))
          this.openAssignModal=false
        })
        .catch((err) => {
          this.loadingAssignBlock = false
        });
    },
    closeModal(type){
      if(type=='validation'){
        this.displayStatusModal=false
        this.status=""
        this.changedStatus=""
      }
      if(type=='assign'){
        this.openAssignModal=false
      }     
    
    },
    async fetchToC() {
      console.log("ID TOK",this.headerValue)
      try {
        const response = await axios.get(
          `${this.baseUrl}/stats-python/toc/getstudytoc`,
          {
            params: { studyid: this.studyId },
            ...this.headerValue
          }
        );
        this.tocid = response.data.toc_studydata._id
        if (response.data.toc_studydata.toc_data) {
          this.tocid =  response.data.toc_studydata._id;
          this.treeData = response.data.toc_studydata.toc_data || [];
          this.treeModal=response.data.toc_studydata.toc_data || [];
          this.showTree = true;
          this.showLoading = false;
          store.dispatch("setTocGenerateBtn", true);
        } else {
          console.warn("Study ID does not exist or is invalid.");
          this.treeData = [];
          this.treeModal=[]
          
          store.dispatch("setTocGenerateBtn", false);
          this.showTree = false;
        }
      } catch (error) {
        console.error("Error in ID check API call:", error);
        this.treeData = [];
        this.treeModal=[]
        store.dispatch("setTocGenerateBtn", false);
        this.showTree = false;
      }
    },
    toggleSidePopup(index, vIndex) {
        if (!this.showSidePopup[index]) {
          this.showSidePopup[index] = [];
        }
        if (typeof this.showSidePopup[index][vIndex] === "undefined") {
          this.showSidePopup[index][vIndex] = false;
        }
          this.showSidePopup[index][vIndex] = !this.showSidePopup[index][vIndex];
    },
    closeSidePopup(index, vIndex) {
        if (this.showSidePopup[index] && this.showSidePopup[index][vIndex]) {
          this.showSidePopup[index][vIndex] = false;
      }
      
    },
    async runBtn(reportid) {
      await this.runToc(reportid)
      await this.$nextTick();
      this.files.forEach((eachitem,index) => {
         this.loadIframeContent(`${eachitem.Page}-${index}`, eachitem.Value)
      })
    },
    async runToc(reportid) {
      this.displayModal = true
      const idtoken = store.getters.getIdToken;
      try {
        const res = await axios.get(
          `${this.baseUrl}/stats-python/programme/runprogrammap/${reportid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        );

        this.files = res.data;
        if(this.files!=null){
          this.files.forEach((eachitem) => {
            eachitem.Value = this.hexToUtf8(eachitem.Value);
          })
          this.displayModal = true;
        }
      } catch (err) {
        this.displayModal=false
        console.error("Error fetching files:", err);
      }
    },
    async openAssignPopup(){
        this.openSettings=false
        this.openAssignModal=true
        await this.getUserList();
    },
    async mapProgramme(childlabels,id) {
      this.modalChildLabels=childlabels
      this.modalTocId=this.tocid
      this.mapProgrammePopup = true;

    },
    hexToUtf8(hexString) {
      
      hexString = hexString.replace(/\s+/g, '');


      
      let utf8String = '';
      for (let i = 0; i < hexString.length; i += 2) {
        const hexCode = hexString.substr(i, 2);
        utf8String += String.fromCharCode(parseInt(hexCode, 16));
      }

      return utf8String;
    },
    loadIframeContent(page, value) {
      const iframe = document.getElementById(page);
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(value);
      doc.close();
    },
    adjustIframeWidth(event,page) {
      const iframe = document.getElementById(page);
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

      if (iframeDocument && iframeDocument.body) {

        const contentWidth = iframeDocument.body.scrollWidth;
        if(contentWidth>300){
          iframe.style.width = `${contentWidth}px`;
        }
        
      }
    },
    async getMappings() {
      this.mapValue=[]
      this.idSet=[]
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseUrl}/stats-python/programme/getmappings`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.mapValue=res.data
          this.mapValue.forEach((value)=>{
            this.idSet.push(value.ReportId)
          })

        })
        .catch((err) => {
          console.log(err)

        });

    },

    async Editsubheading(headingid,subheadingid, subheadingname){
      console.log("subheadingid",subheadingid)
      this.reportnamedit = subheadingname;
      this.editType ='Subheading'
      this.headingid = headingid;
      this.subheadingid=subheadingid;
      this.editpopup =true;
    },
    async Addheadnodeafter(headingId) {
      this.headingId=headingId;
      this.Addheadnodeafterpopup=true;
    },
async closeaftermodal(){
  this.Addheadnodeafterpopup=false;
},
async Saveheadnodeafter() {
  await axios
  .post(`${this.baseUrl}/stats-python/toc/addheadnodeafter`,  
        {
          tocid: this.tocid, 
          currentheadingid: this.headingId, 
          newheadname: this.newheadnode,
  
        },this.headerValue)
      .then((res) => {
        console.log("Import study...", res);
        this.fetchToC();
      })
      .catch((err) => {
        console.log("err",err);
            })
            this.newheadnode="";
            this.Addheadnodeafterpopup=false;
},
    

    async Addheadnodebefore(headingId) {
      this.headingId=headingId;
      this.Addheadnodebeforepopup=true;
    },
    async closebeforemodal(){
      this.Addheadnodebeforepopup=false;
    },

    async Saveheadnodebefore() {
      
      await axios
          .post(`${this.baseUrl}/stats-python/toc/addheadnodebefore`, 
            {
              tocid: this.tocid, 
              currentheadingid: this.headingId, 
              newheadname: this.newheadnode, 
      
            },this.headerValue)
          .then((res) => {
            console.log("Import study...", res);
            this.fetchToC();
          })
          .catch((err) => {
            console.log("err",err);
                })
                this.newheadnode="";
                this.Addheadnodebeforepopup=false;
    },

    async DeleteReport(headingid, reportid, subindex) {
     
      if (confirm(this.$t("Are you sure you want to delete this item?"))) {
        try {
         
          const url = `${this.baseUrl}/stats-python/toc/deletesubheading?tocid=${this.tocid}&headingid=${headingid}&reportid=${reportid}&subindex=${subindex}`;
    
          console.log("Delete URL:", url);
 
          const response = await axios.delete(url,this.headerValue);

          console.log("Item successfully deleted:", response.data);
          
          
          alert(this.$t("Deleted successfully"));
          this.fetchToC();
        } catch (err) {
          console.error("Error while deleting the item:", err);
          alert(this.$t("navigations.deletion failed"));
        }
      }
    },  
    async DeleteHeading(headingid,headindex) {
      
      if (confirm(this.$t("Are you sure you want to delete this item?"))) {
        try {
        
          const url = `${this.baseUrl}/stats-python/toc/deleteheadnode?tocid=${this.tocid}&headingid=${headingid}&headindex=${headindex}`;
    
          console.log("Delete URL:", url);
    
          
          const response = await axios.delete(url,this.headerValue);
    
          console.log("Item successfully deleted:", response.data);
          
          
          alert(this.$t("navigations.deleted successfully"));
          this.fetchToC();
        } catch (err) {
          console.error("Error while deleting the item:", err);
          alert(this.$t("navigations.deletion failed"));
        }
      }
    },  
async closemodal(){
      this.editpopup = false;
      this.editheadpopup=false; 
    },
async createsave() {
  try {

    const response = await axios.post(`${this.baseUrl}/stats-python/toc/addsubheading`, {
      tocid: this.tocid,
      headingid: this.headingid,
      subheadnewname: this.subreport,
    },this.headerValue);
    
    if (response) {
      console.log("Created successfully:", response);
      this.fetchToC();
    } else {
      console.error("Failed to create subheading:", response);
    }
  } 
  catch (error) {
    console.error("Error during API call:", error);
  }
  this.addpopup = false;
  console.log("editpopup",this.editpopup);
},


    async onsave(){
      if(this.editType=='Heading'){
        try {
          const response = await fetch(`${this.baseUrl}/stats-python/toc/updateheadnode`, {
            method: 'PUT',
            headers: this.headerValue.headers,
            body: JSON.stringify({
              tocid: this.tocid,
              headingid: this.headingid,
              newheadname:this.newheading
            }),
          });
      
          if (response.ok) {
            const data = await response.json();
            console.log('API call successful:', data);
            this.fetchToC();
            // Handle successful response here if needed
          } else {
            console.error('Failed to update heading:', response.statusText);
          }
        } catch (error) {
          console.error('Error during API call:', error);
        }
        this.editheadpopup =false;
      }
      else if(this.editType=='Subheading')
      {
        try {
          const response = await fetch(`${this.baseUrl}/stats-python/toc/updatesubheading`, {
            method: 'PUT',
            headers: this.headerValue.headers,
            body: JSON.stringify({
              tocid: this.tocid,
              headingid: this.headingid,
              subheadingid: this.subheadingid,
              newsubheadnewname:this.newreportname
            }),
          });
      
          if (response.ok) {
            const data = await response.json();
            console.log('API call successful:', data);
            // Handle successful response here if needed
            this.fetchToC();
          } else {
            console.error('Failed to update heading:', response.statusText);
          }
        } catch (error) {
          console.error('Error during API call:', error);
        }
      }
      this.editpopup =false;
    },
    async handleTabClick(file) {
      this.activeTab = file.file;
      await this.fetchHtmlContent(file);
    },
    toggleChildren(nodeIndices,index, rootId) {
      console.log("index",nodeIndices)
      // Check if the rootId is checked
      const isChecked = this.rootChecked[rootId];
    
      // Iterate over all the node indices provided
      nodeIndices.Reports.forEach((child,nodeIndex) => {
          const childId = `treeModal-${index}-${nodeIndex}`;   
          
          if (isChecked) {
            // Add child to childrenChecked and add to the list
            this.childrenChecked[childId] = true;
            if (!this.selectedChildren.includes(child)) {
              this.selectedChildren.push(child.Reportid); // Add the child only if it’s not already in the list
            }
          } else {
            // Remove child from childrenChecked and remove from the list
            delete this.childrenChecked[childId];
            this.selectedChildren = this.selectedChildren.filter(
              (selectedChild) => selectedChild !== child.Reportid
            ); // Remove the child from the list
          }
    
      });
      
      console.log("Updated selectedChildren:", this.selectedChildren);
    },
    toggleChild(nodeIndex, childIndex, isChecked) {
      const childId = `treeModal-${nodeIndex}-${childIndex}`;
      const child = this.treeModal[Object.keys(this.treeModal)[nodeIndex]].Reports[childIndex];
    
      if (isChecked) {
        // Add the child to childrenChecked and the selectedChildren list
        this.childrenChecked[childId] = true;
        if (!this.selectedChildren.includes(child.Reportid)) {
          this.selectedChildren.push(child.Reportid);
        }
      } else {
        // Remove the child from childrenChecked and the selectedChildren list
        delete this.childrenChecked[childId];
        this.selectedChildren = this.selectedChildren.filter(
          (selectedChild) => selectedChild !== child.Reportid
        );
      }
    },
    async getUserList()
    {
      const idtoken = store.getters.getIdToken;
      await axios
      .get(
        `${this.baseUrl}/account-core/user/userlist`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        this.userList=(res.data.results || []).sort((a, b) => {
          if (a.fullName < b.fullName) return -1;
          if (a.fullName > b.fullName) return 1;
          return 0;
        });
      })
      .catch((err)=>{
        console.log(err)
      })
    },
    getCurrentFileId() {
      const currentFile = this.files.find(
        (file) => file.file === this.activeTab
      );
      return currentFile ? currentFile.Id : "";
    },
  }
}
