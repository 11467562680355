import axios from "axios";
import VPagination from "@hennge/vue3-pagination";
import store from "@/store";

export default {
  name: "VisitManagement",
  components: {
    VPagination,
  },
  data() {
    return {
      visits: [],
      editIndex: null,
      editableRow: null,
      protocolId: "",
      idToken: "",
      pageindex: 1,
      pageSize: 10,
      SortProperty: "CreatedAt",
      sortorder: 1,
      totalPages: 1,
      totalCount: 0,
      currentPage: 1,
      loadNextPage: false,
      baseurl: process.env.VUE_APP_Service_URL,
      validationErrors: {},
      visitList: [],
      protocol: {},
    };
  },
  async mounted() {
    this.idToken = store.getters.getIdToken;
    this.protocolId = this.$route.query.id;
    await this.getVisitType();
    await this.getProtocolbyId();
    if (this.protocolId) {
      await this.ListVisits(this.protocolId);
    }
  },
  methods: {
    async ListVisits(protocolId) {
      await axios
        .get(
          `${this.baseurl}/management/studyprotocol/listdavisits?protocolId=${protocolId}&pageindex=${this.pageindex}&pagesize=${this.pageSize}&SortProperty=${this.SortProperty}&SortOrder=${this.sortorder}`,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.visits = res.data.results;
          this.totalCount = res.data.totalCount;
          this.totalPages = Math.ceil(res.data.totalCount / this.pageSize);
        })
        .catch((err) => console.log("Error in fetching visits:", err));
    },
    editRow(index, visit) {
      this.editIndex = index;
      this.editableRow = { ...visit };
      this.validationErrors = {};
    },
    validateRow() {
      this.validationErrors = {};
      if (!this.editableRow.visitNumber) {
        this.validationErrors.visitNumber = "Visit Number is required.";
      }
      if (!this.editableRow.visitName) {
        this.validationErrors.visitName = "Visit Name is required.";
      }
      if (!this.editableRow.visitType) {
        this.validationErrors.visitType = this.$t(
          "navigations.please select a visit type"
        );
      }
      return Object.keys(this.validationErrors).length === 0;
    },
    async updateRow(index, sudyId) {
      try {
        if (!this.editableRow) {
          console.error("No editable row found.");
          return;
        }
        if (!this.validateRow()) {
          return;
        }
        const formNameValue = Array.isArray(this.editableRow.formName)
          ? this.editableRow.formName
          : [this.editableRow.formName];

        const updateData = {
          visitId: index,
          protocolRefId: this.protocolId,
          visitNumber: this.editableRow.visitNumber,
          visitName: this.editableRow.visitName,
          visitType: this.editableRow.visitType,
          visitNotes: this.editableRow.visitNotes,
          formName: formNameValue,
          studyId: sudyId,
        };

        const response = await axios.put(
          `${this.baseurl}/management/studyprotocol/updatedavisit`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        await this.getProtocolbyId();
        console.log("Update response:", response.data);

        this.visits[index] = { ...this.editableRow };

        this.cancelEdit();
        await this.ListVisits(this.protocolId);
        alert("Visit details updated successfully.");
      } catch (err) {
        console.error("Error during updateRow API call:", err);
        alert("Failed to update visit. Please try again.");
      }
    },

    cancelEdit() {
      this.editIndex = null;
      this.editableRow = null;
    },
    clearFieldError(fieldName) {
      if (this.validationErrors[fieldName]) {
        delete this.validationErrors[fieldName];
      }
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageindex = page;
      this.loadNextPage = true;
      await this.ListVisits(this.protocolId);
    },
    async getVisitType() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/management/visittype/listvisittypes`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.visitList = res.data.entities;
          console.log("Result visit is", this.visitList);
        })
        .catch((err) => {
          console.log(err);
          alert(err.response.data.detail);
        });
    },
    async deleteRow(id) {
      try {
        const confirmation = confirm(
          "Are you sure you want to delete this visit?"
        );
        if (!confirmation) {
          return;
        }

        const response = await axios.delete(
          `${this.baseurl}/management/studyprotocol/deletebyid?collectionName=DaScrape_Visits&id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Delete response:", response.data);
        await this.ListVisits(this.protocolId);

        alert("Visit deleted successfully");
      } catch (error) {
        console.error("Error during visit delete API call:", error);
        alert("Failed to delete the visit. Please try again.");
      }
    },
    async getProtocolbyId() {
      const token = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/document/getprotocolbyid?protocolId=${this.protocolId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("protocol", res.data);
          this.protocol = res.data;
        })
        .catch((err) => console.log("error in get protocol by id", err));
    },
  },
};
