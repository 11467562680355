<template>
  <div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-12 d-flex align-items-center justify-content-between">
        <h2 class="my-0 py-0">Manage Study Protocol</h2>
        <div class="d-flex align-items-center">
          <button
           v-if="protocol.status !== 'Generated'"
            class="save_btn py-1 px-4"
            type="button"
            @click="createStudy()"
            :disabled="showLoading"
          >
            <div class="d-flex align-items-center">
              <img
                v-if="showLoading"
                class="loading-img me-2"
                width="20"
                src="../../../assets/img/loading.webp"
                alt="Wait while the document is added"
              />
              <span>{{ $t("navigations.create study") }}</span>
            </div>
          </button>

          <span class="tooltip-wrapper pointer ms-3">
            <i class="fa fa-times close" @click="onclosed()"></i>
            <span class="custom-tooltip">Close</span>
          </span>
        </div>
      </div>
    </div>

    <div class="wrapper wrapper-content">
      <div class="tabs-container col-md-12">
        <ul class="nav nav-tabs col-lg-12 mb-2" role="tablist">
          <li>
            <a
              href="#tab-1"
              class="nav-link"
              :class="{ activeTab: selectedTab === 1 }"
              data-toggle="tab"
              @click="selectTab(1)"
            >
              {{ $t("navigations.study") }}
            </a>
          </li>
          <li>
            <a
              href="#tab-2"
              class="nav-link"
              :class="{ activeTab: selectedTab === 2 }"
              data-toggle="tab"
              @click="selectTab(2)"
            >
              {{ $t("navigations.forms") }}
            </a>
          </li>
          <li>
            <a
              href="#tab-3"
              class="nav-link"
              :class="{ activeTab: selectedTab === 3 }"
              data-toggle="tab"
              @click="selectTab(3)"
            >
              {{ $t("navigations.visit") }}
            </a>
          </li>
          <li>
            <a
              href="#tab-4"
              class="nav-link"
              :class="{ activeTab: selectedTab === 4 }"
              data-toggle="tab"
              @click="selectTab(4)"
            >
              {{ $t("navigations.site") }}
            </a>
          </li>
          <li>
            <a
              href="#tab-5"
              class="nav-link"
              :class="{ activeTab: selectedTab === 5 }"
              data-toggle="tab"
              @click="selectTab(5)"
            >
              {{ $t("navigations.subjects") }}
            </a>
          </li>
          <li>
            <a
              href="#tab-6"
              class="nav-link"
              :class="{ activeTab: selectedTab === 6 }"
              data-toggle="tab"
              @click="selectTab(6)"
            >
              {{ $t("navigations.schedule") }}
            </a>
          </li>
        </ul>
      </div>

      <div class="row"></div>
      <div class="col-lg-12">
        <component :is="currentComponent"></component>
      </div>
    </div>
  </div>
</template>
<style scoped>
.activeTab {
  color: #495057;
  background-color: #fff !important;
  border-color: #dee2e6 #dee2e6 #fff;
}
</style>
<script src="./StudyProtocolMng.js"></script>
