/* eslint-disable */
import axios from "axios";
import VPagination from "@hennge/vue3-pagination";
import store from "@/store";
export default {
  name: "forms",
  components: {
    VPagination,
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      FormList: [],
      editIndex: null,
      editableRow: { formName: "", language: "" },
      idToken: "",
      pageindex: 1,
      pageSize: 10,
      SortProperty: "CreatedAt",
      sortorder: 1,
      totalPages: 1,
      totalCount: 0,
      currentPage: 1,
      loadNextPage: false,
      protocolRefId: "",
      daFormId: "",
      validationErrors: {},
      protocol : {},
    };
  },
  async mounted() {
    this.idToken = store.getters.getIdToken;
    // const protocolRefId = "cc9da0eb-65e9-489d-8a9f-73853c807eea";
    this.protocolRefId = this.$route.query.id;
    await this.ListForms(this.protocolRefId);
    await this.getProtocolbyId();
  },
  methods: {
    async ListForms(protocolId) {
      await axios
        .get(
          `${this.baseurl}/management/studyprotocol/listdaforms?protocolId=${protocolId}&pageindex=${this.pageindex}&pagesize=${this.pageSize}&SortProperty=${this.SortProperty}&SortOrder=${this.sortorder}`,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.FormList = res.data.results;
          this.totalCount = res.data.totalCount;
          this.totalPages = Math.ceil(res.data.totalCount / this.pageSize);
        })
        .catch((err) => console.log("error in listing form...", err));
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageindex = page;
      this.loadNextPage = true;
      await this.ListForms(this.protocolRefId);
    },
    editRow(index, study) {
      this.editIndex = index;
      this.editableRow = { ...study };
      this.validationErrors = {};
    },
    clearFieldError(fieldName) {
      if (this.validationErrors[fieldName]) {
        delete this.validationErrors[fieldName]; 
      }
    },
    validateRow() {
      this.validationErrors = {}; 
      let isValid = true;
    
      if (!this.editableRow.formName) {
        this.validationErrors.formName = "Form Name is required.";
        isValid = false;
      }

      return isValid;
    },
    
    async updateRow(index,studyid) {
      try {
        let language = this.editableRow.language;
        if (typeof language === "string") {
          language = language
            .split(",")
            .map((lang) => lang.trim())
            .filter((lang) => lang !== "");
        }
        if (!this.validateRow()) {
          return;
        }
      

        const updateData = {
          formName: this.editableRow.formName,
          language: language,
          protocolRefId: this.protocolRefId,
          formId: index,
          studyId:studyid,
        };

        const response = await axios.put(
          `${this.baseurl}/management/studyprotocol/updatedaform`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Update response:", response.data);
        this.FormList[index] = { ...this.editableRow };
        this.cancelEdit();
        await this.ListForms(this.protocolRefId);
        await this.getProtocolbyId();
        alert("Form details updated successfully.");
      } catch (err) {
        console.error("Error during updateRow API call:", err);
        alert("Failed to update form. Please try again.");
      }
    },
    cancelEdit() {
      this.editIndex = null;
      this.editableRow = { formName: "", language: "" };
    },
    cancelEdit() {
      this.editIndex = null;
      this.editableRow = null;
    },
    async deleteRow(formId) {
      try {
        const confirmation = confirm("Are you sure you want to delete this form?");
        if (!confirmation) {
          return;
        }
    
        const response = await axios.delete(
          `${this.baseurl}/management/studyprotocol/deletebyid?collectionName=DaScrape_Forms&id=${formId}`,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
          }
        );
    
        console.log("Delete response:", response.data);
        await this.ListForms(this.protocolRefId);

    
        alert("Form deleted successfully");
      } catch (error) {
        console.error("Error during schedule delete API call:", error);
        alert("Failed to delete the schedule. Please try again.");
      }
    },
    async getProtocolbyId(){
      const token = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/management/document/getprotocolbyid?protocolId=${this.protocolRefId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        console.log("protocol", res.data);
        this.protocol = res.data;
      }).catch(err => console.log("error in get protocol by id", err));
    }
    
  },
};
