import axios from "axios";
import store from "@/store";
import auth0 from "auth0-js";
import jwt_decode from "jwt-decode";
import StudyManagment from "../StudyManagment/StudyManagment.vue";
import FormManagement from "../Formmanagement/FormManagement.vue";
import VisitManagement from "../VisitManagement/VisitManagement.vue";
import SiteManagement from "../SiteManagement/SiteManagement.vue";
import SubjectManagement from "../SubjectManagement/SubjectManagement.vue";
import ScheduleManagement from "../ScheduleManagement/ScheduleManagement.vue";
let webAuth = new auth0.WebAuth({
  domain: "dev-datamatica.eu.auth0.com",
  clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
});
export default {
  data() {
    return {
      selectedTab: 1,
      baseurl: process.env.VUE_APP_Service_URL,
      protocolRefId: "",
      studies:[],
      protocol : {},
      showLoading: false,
      
    };
  },
  computed: {
    currentComponent() {
      switch (this.selectedTab) {
        case 1:
          return "StudyManagment";
        case 2:
          return "FormManagement";
        case 3:
          return "VisitManagement";
        case 4:
          return "SiteManagement";
        case 5:
          return "SubjectManagement";
        case 6:
          return "ScheduleManagement";
        default:
          return null;
      }
    },
  },
  components: {
    StudyManagment,
    FormManagement,
    VisitManagement,
    SiteManagement,
    SubjectManagement,
    ScheduleManagement,
  },
  async mounted() {
    this.idToken = store.getters.getIdToken;
    this.protocolRefId = this.$route.query.id;
    window.addEventListener("StudyListChanged", (event) => {
      this.loadstudy();
    });
    this.getProtocolbyId();
    this.loadstudy();

  },
  methods: {
    selectTab(tabIndex) {
      this.selectedTab = tabIndex;
    },
    async onclosed() {
      this.$router.push(`/uploadstudyprotocol`);
    },
    async createStudy() {
      const userConfirmed = confirm("Are you sure you want to create study?");
      if (!userConfirmed) {
        console.log("Study creation canceled by the user.");
        return;
      }
      try {
        this.showLoading = true;
        let response = await axios.post(
          `${this.baseurl}/management/studyprotocol/generatestudybyid?protocolId=${this.protocolRefId}`,{},
          // `https://localhost:5001/studyprotocol/generatestudybyid?protocolId=${this.protocolRefId}`,{},

          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
            responseType: "blob",
          }
        );
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        const fileName = `Study_Details_Summary.xlsx`;
        link.download = fileName;

        link.click();

        window.URL.revokeObjectURL(link.href);
        alert("Study created successfully");
        await this.getProtocolbyId();
        await this.loadstudy();
        this.showLoading = false;
        console.log("Update response:", response.data);
        window.dispatchEvent(
          new CustomEvent("StudyListChanged", {

          })
        );
      } catch (err) {
        if (err.response && err.response.data instanceof Blob) {
          err.response.data
            .text()
            .then((blobText) => {
              const errorData = JSON.parse(blobText);
              console.log("Error during study creation:", errorData);

              if (errorData.errors && errorData.errors.DomainValidations) {
                const validationErrors = errorData.errors.DomainValidations;
                if (validationErrors.includes("Import Failed Invalid Study")) {
                  alert("Study creation failed - Invalid Study Id/Study Name");
                } else if (validationErrors.includes("Invalid File Type")) {
                  alert("Study creation failed - Invalid File Type");
                } else {
                  alert("Something went wrong during study creation.");
                }
              } else {
                alert("Something went wrong during study creation.");
              }
            })
            .catch((blobError) => {
              console.error("Error reading blob data:", blobError);
              alert("Something went wrong during study creation.");
            });
        } else {
          console.error("Error during create study API call:", err);
          alert("Failed to connect to the server.");
        }
      }
      this.showLoading = false;
    },
    async  loadstudy() {
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      axios
        .get(`${this.baseurl}/management/study/getallstudy?user=${usermail}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.studies.value = res.data; //orderBy(res.data, 'studyName');
          //valnow.value = store.getters.getStudyIs ? store.getters.getStudyIs : res.data[0].studyId;
        })
        .catch(async (err) => {
          if (err.response.status == "401") {
            //token expired..
             this.logoutaudit();
            let a = localStorage.clear();
            let b = webAuth.logout({
              returnTo: `${process.env.VUE_APP_logout_URL}`,
              clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
            });
            return a && b;
          }
          console.log(err);
        });
    },
    async  logoutaudit() {
      const token = store.getters.getIdToken;
      const emailId = await jwt_decode(token).email;
      await axios
        .post(
          `${this.baseurl.value}/account-core/user/saveloginaudit`,
          {
            userEmail: emailId,
            logdetails: [
              {
                action: "Log Out",
                date: "2022-08-30T09:10:42.634Z",
                time: "2022-08-30T09:10:42.634Z",
              },
            ],
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("login audit res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async  logout() {
      this.logoutaudit();
      let a = localStorage.clear();
      // let b = auth0Client.value.logout();
      let b = webAuth.logout({
        returnTo: `${process.env.VUE_APP_logout_URL}`,
        clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
      });
      return a && b;
    },
    async getProtocolbyId(){
      const token = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/management/document/getprotocolbyid?protocolId=${this.protocolRefId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        console.log("protocol", res.data);
        this.protocol = res.data;
      }).catch(err => console.log("error in get protocol by id", err));
    }
    
  },
};
