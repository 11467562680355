/* eslint-disable */
import axios from "axios";
import VPagination from "@hennge/vue3-pagination";
import store from "@/store";

export default {
  name: "SiteManagement",
  components: {
    VPagination,
  },
  data() {
    return {
      sites: [],
      editIndex: null,
      editableRow: null,
      baseurl: process.env.VUE_APP_Service_URL,
      idToken: "",
      protocolId: "",
      currentPage: 1,
      pageSize: 10,
      pageindex: 1,
      SortProperty: "CreatedAt",
      sortorder: 1,
      totalPages: 1,
      totalCount: 0,
      validationErrors: {},
      protocol: {},
    };
  },
  async mounted() {
    this.idToken = store.getters.getIdToken;
    this.protocolId = this.$route.query.id;
    await this.getProtocolbyId();
    if (this.protocolId) {
      await this.ListSites(this.protocolId);
    }
  },
  methods: {
    async ListSites(protocolId) {
      try {
        const response = await axios.get(
          `${this.baseurl}/management/studyprotocol/listdasites?protocolId=${protocolId}&pageindex=${this.pageindex}&pagesize=${this.pageSize}&SortProperty=${this.SortProperty}&SortOrder=${this.sortorder}`,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.sites = response.data.results;
        this.totalCount = response.data.totalCount;
        this.totalPages = Math.ceil(this.totalCount / this.pageSize);
      } catch (error) {
        console.error("Error in fetching sites:", error);
      }
    },
    editRow(index, site) {
      this.editIndex = index;
      this.editableRow = { ...site };
      this.validationErrors = {};
    },
    validateRow() {
      this.validationErrors = {};
      let isValid = true;
      if (!this.editableRow.siteCode) {
        this.validationErrors.siteCode = "Site Code is required.";
        isValid = false;
      }
      return isValid;
    },
    async updateRow(index, studyId) {
      try {
        if (!this.editableRow) {
          console.error("No editable row found.");
          return;
        }
        if (!this.validateRow()) {
          return;
        }
        const updateData = {
          siteId: index,
          siteCode: this.editableRow.siteCode,
          siteName: this.editableRow.siteName,
          siteContactName: this.editableRow.siteContactName,
          siteContactEmail: this.editableRow.siteContactEmail,
          status: this.editableRow.status,
          numberOfSubjects: this.editableRow.numberOfSubjects
            ? String(this.editableRow.numberOfSubjects)
            : "",
          siteNotes: this.editableRow.siteNotes,
          studyId: studyId,
          protocolRefId: this.protocolId,
        };
        const response = await axios.put(
          `${this.baseurl}/management/studyprotocol/updatedasite`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Update response:", response.data);
        this.sites[index] = { ...this.editableRow };
        this.cancelEdit();
        alert("Site details updated successfully");
        await this.getProtocolbyId();
        await this.ListSites(this.protocolId);
      } catch (err) {
        console.error("Error during updateRow API call:", err);
        alert("Failed to update site. Please try again.");
      }
    },

    cancelEdit() {
      this.editIndex = null;
      this.editableRow = null;
    },
    clearFieldError(fieldName) {
      if (this.validationErrors[fieldName]) {
        delete this.validationErrors[fieldName];
      }
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageindex = page;
      await this.ListSites(this.protocolId);
    },
    async deleteRow(id) {
      try {
        const confirmation = confirm(
          "Are you sure you want to delete this site?"
        );
        if (!confirmation) {
          return;
        }

        const response = await axios.delete(
          `${
            this.baseurl
          }/management/studyprotocol/deletebyid?collectionName=${"DaScrape_Sites"}&id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Delete response:", response.data);
        await this.ListSites(this.protocolId);

        alert("Site deleted successfully");
      } catch (error) {
        console.error("Error during site delete API call:", error);
        alert("Failed to delete the site. Please try again.");
      }
    },
    checknegative() {
      if (this.editableRow.numberOfSubjects < 0) {
        this.editableRow.numberOfSubjects = 0;
      }
    },
    async getProtocolbyId() {
      const token = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/document/getprotocolbyid?protocolId=${this.protocolId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("protocol", res.data);
          this.protocol = res.data;
        })
        .catch((err) => console.log("error in get protocol by id", err));
    },
  },
};
