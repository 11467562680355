<template>
  <div ref="component" class="stat-card-white">
    <div class="stat-header-white">
      <span>{{ chartlabel }}</span>
      <div v-show="!loader" class="d-flex align-items-center" v-if="!loader">
        <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
          <img class="cursor-pointer" @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
          <div class="more-expand">
            <span>{{$t("navigations.data overview")}}</span>
          </div>
        </div>
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock=true">
          <img src="../../assets//img/expand.png" alt="Expand block">
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false; exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stat-body-white chart-container d-flex flex-column" ref="chartContainerBlock">
      <div v-if="loader" class="loader"></div>
      <svg ref="chartContainer" viewBox="0 0 500 500"></svg>
      <div ref="tooltip" class="tooltip"></div>
    </div>

    <enlargerpopup v-if="expandBlock" @closeModal="expandBlock=false; createChart()">
      <div class="stat-body-white d-flex flex-column">
        <div class="histogram-content overflow-x-scroll">
          <svg ref="popupChartContainer" width="400" height="400"></svg>
        </div>
      </div>
    </enlargerpopup>
  </div>
</template>

<script>
import * as d3 from "d3";
import html2pdf from 'html2pdf.js';
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";

export default {
  name: "PieChart",
  components: {
    enlargerpopup
  },
  props: {
    chartdata: {
      type: Array,
      required: true,
      default: () => [],
    },
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    chartlabel: {
      type: String,
      default: "Chart",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    legendprop: {
      type: String,
      default: "",
    },
    colors: {
      type: Array,
      required: true,
      default: () => ['#A0D568','#FFCE54','#FF6663','#FFB44F','#57D9A5'],
    },
  },
  data() {
    return {
      expandBlock: false,
      showVisitPopup: false,
      tooltip: null,
      svg: null,
      chartContainer: null,
      resizeObserver: null,
      mouseX: 0,
      mouseY: 0,
    };
  },
  watch: {
    expandBlock(newValue) {
      if (newValue) {
        // Wait for the popup to render before drawing the chart
        this.$nextTick(() => {
          this.createChart();
        });
      } else {
        // When closing the popup, redraw the original chart
        this.$nextTick(() => {
          this.createChart();
        });
      }
    }
  },
  mounted() {
    window.addEventListener('mousemove', this.updateMousePosition);
    this.tooltip = this.$refs.tooltip;
    this.createChart();

    // Add resize observer to make SVG responsive
    this.resizeObserver = new ResizeObserver(() => this.updateChart());
    this.resizeObserver.observe(this.$refs.chartContainerBlock);
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    showtable() {
      this.$emit("opendrillpopup", this.chartdata);
    },
    async exporttoexcell() {
      this.$emit("exporttoexcell");
    },
    exporttopdf() {
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.component.cloneNode(true);
      pdfContainer.appendChild(captureArea);
      pdfContainer.style.width = '100%';
      pdfContainer.style.display = 'block';

      const opt = {
        margin: 0,
        filename: `${this.chartlabel}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };
      html2pdf().set(opt).from(pdfContainer).save();
    },
    updateMousePosition(event) {
      const rect = this.$refs.component.getBoundingClientRect();
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
    },
    createChart() {
      this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;

      d3.select(this.chartContainer).selectAll("*").remove();
      this.svg = null;

      let rawData = this.data;
      if (!rawData || Object.keys(rawData).length === 0) {
        console.error("Data is invalid or empty:", rawData);
        return;
      }

      const aggregatedData = Object.entries(rawData).map(([key, value]) => ({
        key,
        value,
      }));

      // Determine container dimensions based on mode
      let containerWidth, containerHeight = 280;
      if (this.expandBlock && this.$refs.popupChartContainer) {
        // Use the popup container's parent for width calculation
        containerWidth = 500;
      } else {
        // Use the default container block for width
        containerWidth = this.$refs.chartContainerBlock.clientWidth;
      }

      const radius = Math.min(containerWidth, containerHeight) / 2 - 20;
      const customColors = this.colors;

      const color = d3
        .scaleOrdinal()
        .domain(aggregatedData.map((d) => d.key))
        .range([...customColors, ...d3.schemeCategory10.slice(customColors.length)]);

      this.svg = d3
        .select(this.chartContainer)
        .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`)
        .append("g")
        .attr("transform", `translate(${containerWidth / 2}, ${containerHeight / 2})`);

      const pie = d3
        .pie()
        .sort(null)
        .value((d) => d.value);

      const arc = d3
        .arc()
        .innerRadius(0)
        .outerRadius(radius);

      const arcs = this.svg
        .selectAll(".arc")
        .data(pie(aggregatedData))
        .enter()
        .append("g")
        .attr("class", "arc")
        .on("mouseover", (event, d) => {
          d3.select(this.tooltip)
            .style("opacity", 1)
            .html(`Category: ${d.data.key}<br/>Value: ${d.data.value}`)
            .style("left", this.mouseX + "px")
            .style("top", this.mouseY + "px");
        })
        .on("mouseout", () => {
          d3.select(this.tooltip).style("opacity", 0);
        })
        .on("mousemove", () => {
          d3.select(this.tooltip)
            .style("left", this.mouseX + "px")
            .style("top", this.mouseY + "px");
        });

      arcs
        .append("path")
        .attr("d", arc)
        .attr("fill", (d) => color(d.data.key));

      // Add legends with values
      const legend = this.svg
        .append("g")
        .attr("class", "legend")
        .attr("transform", `translate(${-containerWidth / 2}, ${-containerHeight / 2 + 20})`);

      const legendItem = legend
        .selectAll(".legend-item")
        .data(aggregatedData)
        .enter()
        .append("g")
        .attr("class", "legend-item")
        .attr("transform", (d, i) => `translate(0, ${i * 20})`);

      legendItem
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 18)
        .attr("height", 18)
        .attr("fill", (d) => color(d.key));

        legendItem
          .append("text")
          .attr("x", 24)
          .attr("y", 9)
          .attr("dy", "0.35em")
          .text((d) => `${d.key.charAt(0).toUpperCase() + d.key.slice(1)}: ${d.value} ${this.legendprop}`);
    },
    updateChart() {
      this.createChart();
    },
    cancelselection() {
      // Implement as needed
    },
    recreatechart() {
      // Implement as needed
    },
  },
};
</script>

<style scoped>
.stat-card-white {
  border: 1px solid #ddd;
}
.stat-header-white {
  font-weight: bold;
  margin-bottom: 1rem;
}
.chart-container {
  overflow-x: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.legend {
  margin-top: 10px;
}
.legend-item {
  display: flex;
  align-items: center;
}
.legend-item rect {
  margin-right: 5px;
}
.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.tooltip {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  background: #030303;
  padding: 5px 10px;
  border: 1px solid #020202;
  font-size: 14px;
  border-radius: 4px;
}
</style>
