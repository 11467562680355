<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Cost Monitoring</h2>
    </div>
    <div class="col-lg-2">
      <i class="fa fa-times close my-0" title="Close" @click="pageclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-md-12 mb-2">
        <historytab :historyarray="historykeys" @gotohistorylevel="gotohistorylevel" @exporttopdf="exporttopdf"
        @exporttoexcell="exporttoexcell" />
      </div>
      <span class="row mr-0 pr-0">

      
          <div id="captureArea1" class="col-md-12 mb-2 pr-1 pr-0"  ref="captureArea1">
            <div> </div>
            <groupbarchart
            :loader="false"
            :data="filtereddata"
            :graphdata="data"
            :templateId="templateId"
            xAxisKey="site_id"
            yAxisValue=""
            yAxisLabel="Cost ($)"
            xAxisLabel="Sites"
            :fromPage="'independent'"
            :requiredOptions="false"
            chartlabel="Comparison of Baseline and Actual Costs by Site"
            :custcolors="customcolors"
            :customisationfields="customisationfields"
            :colorcodes="barcolorcodes"
            @changefiltereddata="filtereddatafun"
            @drilldowncurrentchart="drilldowncurrentchartforbar"
            @opendrillpopup="drillpopupopened"
            @setgroupkeys="retaingroupchartvars"
            @exporttoexcell="exporttoexcell"
          />
          </div>
          <div id="captureArea2" class="col-md-6 mb-2 pr-1 pr-0"  ref="captureArea2">
        <div> </div>
        <D3PieChart
          :data="data"
          :tableData="data"
          xaxiskey="status"
          :requiredtoclip="false"
          :enableselectoption="false"
          :templateId="''"
          :customColors="customColors"
          :fromPage="'descriptiveStatistics'"
          chartlabel="Varience Status Distribution" 
          @opendrillpopup="drillpopupopened"
          @drilldowncurrentchart="drilldowncurrentchartforbar"
          @undobutton="undobutton"
          @exporttoexcell="exporttoexcell"
        />
        </div>
        <div id="captureArea3" class="col-md-6 mb-2 pr-1 pr-0"  ref="captureArea3">
        <div> </div>
        <simplelinechart
            :loader="false"
            :data="data"
            :templateId="templateId"
            xAxisValue="site_id"
            :fromPage="'labSummary'"
            :requiredOptions="false"
            chartLabel="Cost Varience per Site"
            :custcolors="customcolors"
            :colorcodes="colorcodes"
            @drilldowncurrentchart="drilldowncurrentchartforbar"
            @opendrillpopup="drillpopupopened"
            @setgroupkeys="retaingroupchartvars"
            @exporttoexcell="exporttoexcell"
          />
        </div>
        </span>
      <div class="col-lg-12 mb-1">
        <div class="stat-card-white ">
          <div class="stat-header-white">
            <span>Baseline vs. Actual Cost Comparison</span>
          </div>
          <div class="stat-body-white">
            <advtable :namesobj="namesobj" fileheadding="Demographics Summary" :loader="loader" :data="data" fromPage="patientRetention"
              @drilldowncurrentchart="drilldowncurrentchartforbar" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <chartdrill v-if="drillpopup" fileheadding="Demographics Summary" :drilldata="drilldata" :templateId="templateId" :fromPage="'adverseEventsPop'"
    @closepopup="onclosed()" />
</template>
<script src="./CostMonitoringReport.js"></script>