<template>
  <div class="wrapper wrapper-content">
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5>Schedule List</h5>
      </div>
      <div class="ibox-content">
        <div v-if="schedules.length === 0" class="no-data-message text-center">
          <p>No data available.</p>
        </div>
        <div v-else>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>
                    Visit No <sup><i class="fa fa-asterisk imp"></i></sup>
                  </th>
                  <th>Pre Visit (Days)</th>
                  <th>Post-visit (Days)</th>
                  <th>Visit Period (Days)</th>
                  <th>Visit Period (Weeks)</th>
                  <th>Visit Period (Months)</th>
                  <th>Visit Period (Year)</th>
                  <th class="text-center">Edit</th>
                  <th class="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(schedule, index) in schedules"
                  :key="schedule.visitNo"
                >
                  <td v-if="editIndex !== index">{{ schedule.visitNo }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.visitNo"
                      class="form-control"
                      type="text"
                      @input="clearFieldError('visitNo')"
                    />
                    <div v-if="validationErrors.visitNo" class="text-danger">
                      {{ validationErrors.visitNo }}
                    </div>
                  </td>
                  <td v-if="editIndex !== index">{{ schedule.preVisit }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.preVisit"
                      class="form-control"
                      type="number"
                      :min="0"
                      @change="checknegative"
                    />
                  </td>
                  <td v-if="editIndex !== index">{{ schedule.postVisit }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.postVisit"
                      class="form-control"
                      type="number"
                      :min="0"
                      @change="checknegative"
                    />
                  </td>
                  <td v-if="editIndex !== index">
                    {{ schedule.visitPeriodDay }}
                  </td>
                  <td v-else>
                    <input
                      v-model="editableRow.visitPeriodDay"
                      class="form-control"
                      type="number"
                      :min="0"
                      @change="checknegative"
                    />
                  </td>
                  <td v-if="editIndex !== index">
                    {{ schedule.visitPeriodWeek }}
                  </td>
                  <td v-else>
                    <input
                      v-model="editableRow.visitPeriodWeek"
                      class="form-control"
                      type="number"
                      :min="0"
                      @change="checknegative"
                    />
                  </td>
                  <td v-if="editIndex !== index">
                    {{ schedule.visitPeriodMonth }}
                  </td>
                  <td v-else>
                    <input
                      v-model="editableRow.visitPeriodMonth"
                      class="form-control"
                      type="number"
                      :min="0"
                      @change="checknegative"
                    />
                  </td>
                  <td v-if="editIndex !== index">
                    {{ schedule.visitPeriodYear }}
                  </td>
                  <td v-else>
                    <input
                      v-model="editableRow.visitPeriodYear"
                      class="form-control"
                      type="number"
                      :min="0"
                      @change="checknegative"
                    />
                  </td>
                  <td class="text-center">
                    <template v-if="editIndex !== index">
                      <i
                        class="fa fa-edit edit-delete"
                       v-if="protocol.status !== 'Generated'"
                        @click.prevent="editRow(index, schedule)"
                      ></i>
                    </template>
                    <template v-else>
                      <i
                        class="fa fa-check action-icon text-success"
                        @click="updateRow(schedule.id, schedule.studyId)"
                        title="Update"
                      ></i>
                      <i
                        class="fa fa-times action-icon text-danger"
                        @click="cancelEdit"
                        title="Cancel"
                      ></i>
                    </template>
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-trash edit-delete"
                      v-if="protocol.status !== 'Generated'"
                      style="color: #a13b7c"
                      @click="deleteRow(schedule.id)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Pagination  -->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  "
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="currentPage"
                    :pages="totalPages"
                    :range-size="0"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.action-icon {
  cursor: pointer;
  font-size: 18px;
  margin: 0 5px;
}

.action-icon:hover {
  opacity: 0.8;
}

.text-success {
  color: #28a745;
}

.text-danger {
  color: #dc3545;
}
</style>
<script src="./ScheduleManagement.js"></script>
