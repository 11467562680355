<template>
  <div class="baseselect mainbgchange" :class="{'cursor-pointer': sourceValue === true}">
    <label class="labelstyle" :class="{'cursor-pointer': sourceValue === true}">
      {{ label }}
      <sup v-if="requiredname === 'required' || requiredname === 'Required'">
        <i class="fa fa-asterisk imp px-1"></i>
      </sup>
    </label>

    <select
      :disabled="privilege === 'View' || fielddisabled === true || fromarchive === 'true'"
      :value="modelValue"
      :class="[classname,{'cursor-pointer': sourceValue === true}]"
      @change="handleChange"
      @blur="handleChange"
    >
      <option
        v-for="option in options"
        :key="option"
        :selected="option.codevalue === modelValue"
        :value="option.codevalue"
      >
        {{ option.optionname }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  props: {
    sourceValue: {
      type: Boolean,
      default: false
    },
    fielddisabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    classname: {
      type: String,
      default: 'fill-form-select'
    },
    requiredname: {
      type: String,
      default: ''
    },
    privilege: {
      type: String,
      default: ''
    },
    fromarchive: {
      type: String,
      default: 'false'
    }
  },
  methods: {
    handleChange(event) {
      // 1) Update the modelValue
      console.log("base selectvalue",event.target.value);
      const selectedValue = event.target.value;
      this.$emit('update:modelValue', selectedValue);

      // 2) Find the selected option
      const selectedOption = this.options.find(
        (opt) => opt.codevalue === selectedValue
      );

      // 3) Emit the selected option name (or empty string)
      this.$emit('focusoutfun', selectedOption ? selectedOption.optionname : '');
    }
  }
};
</script>

<style scoped>
.baseselect{
  display: flex;
flex-direction: column;
align-items: flex-start;
}
.labelstyle{
  font-size: 14px;
  margin-bottom: 0.1rem !important;
  font-weight: 600;
}
.minwidth{
  min-width: max-content;
}
.errorlist{
  border-width: 2px !important;
  border-color: red !important;
}
.hightlight{
  /* border-color: red !important; */
  border-bottom: 6px solid #f8ac59;
  
}
.imp {
    color: #ff0000;
    font-size: 7px;
}
.borderValidated {
  border:2px solid green!important;
}
.borderIncorrect{
   border:2px solid orange!important;
}
.borderFail{
   border:2px solid red!important;
}
.fill-form-select{
  display: block;
    width: 100%;
    padding: 0.2rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.fill-form-select:focus,
.fill-form-select:focus-visible {
  border-color: #145faf !important;
  outline:#145faf !important;
  box-shadow: 0 0 0 !important;
  border-radius:0
}
</style>
