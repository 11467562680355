<template> 
<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{templateName}}</h2>
    </div>
    <div class="col-lg-2">
       <i class="fa fa-times close my-1" @click="onclosed()"></i>
    </div>
  </div>
<div class="wrapper wrapper-content">
    <dynamicform
    :templateid = "tempId"
    :requiredvalidate = 'false'
    :requiredsubmit = 'false'
    :requiredrole=requiredRole
    :requiredlanguage = 'false'
    templatebasedon="general"
    :skipcheckingdependency="true"
    />
</div>
</template>

<script src="./viewTemplateallComponent.js"></script>