/* eslint-disable */
// import jwtInterceptor from "../Shared/jwtInterceptor";
import store from "../../store/index";
import axios from "axios";
export default {
  name: "newtemplate",
  props: { closeoption: String, formsesid: String },
  async beforeMount() {
    await this.setformid();
  },
  async mounted() {
    //await this.setunverified();
    if(this.closeoption == "forms"){
    this.whenclosed = 'listform';
  }
  else if(this.closeoption == "sitesummary"){
    this.whenclosed = 'sitesummary'
  }
  else if(this.closeoption == "home"){
    this.whenclosed = 'home'
  }
  else if(this.closeoption == "patientlist"){
    this.whenclosed = 'patientlist'
  }
  else if(this.closeoption == "PatientScheduleView"){
    this.whenclosed = 'PatientScheduleView'
  }
  else{
    this.whenclosed = this.closeoption
  }
  },
  data() {
    return {
      whenclosed: '',
      baseurl: process.env.VUE_APP_Service_URL,
      showpopup: true,
      formsid: "",
}
  },
  methods: {
    async setformid(){
      this.formsid = this.formsesid;
    },
    async setunverified(){
      console.log("unverified "+ this.formsid);
      const idtoken = store.getters.getIdToken;
      await axios
        .put(
          `${this.baseurl}/forms/forms/unverifyformstatus`,
          {
            "formId": this.formsid
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("form unverified "+ res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
}
};